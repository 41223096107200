import React from "react";
import "../../Css/root.css";
import { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import axios from "../../axios";
import api from "../../api";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import {actions} from "../../Components/Toaster/ToastReducer";
import DatePicker from "react-datepicker";
import { Form } from "react-bootstrap";

export default function CreateAffiliate(props) {
	
    const dispatch = useDispatch();
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const [tohome, setTohome] = React.useState(false);

    const [startTime, setStartTime] = useState(new Date());
    const [phoneNumber, setPhoneNumber] = useState();
    const [user, setUser] = useState();
    const [message, setMessage] = useState();

    const [paymentamount, setPaymentAmount] = useState();
    const [selectedSubsCriptionID, setSelectedSubsCriptionID] = useState();
    const [selectedSubscriptionPlanID, setSelectedSubsCriptionPlanID] = useState();
    const [transactionid, setTransactionID] = useState();
    const [storeamount, setStoreAmount] = useState();
    const [status, setStatus] = useState();
    const [coupon, setCoupon] = useState();
    const [paymentdate, setPaymentDate] = useState();
    const [walletFromAmount, setWalletFromAmount] = useState();
    const [disbursedAmount, setDisbursedAmount] = useState(0)
    const [goals, setGoals] = useState();
    const [subs, setSubs] = useState();
    const [subsPlan, setSubsPlan] = useState();

    const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

    useEffect(() => {
        goal();
    }, []);

    async function goal() {
        settoken(localStorage.getItem("user"));
        const response = await axios.get(api.getgoalsubject, data);
        setGoals(response.data);
    }
    async function getSubs(selected) {
        const response = await axios.get(api.getsubsubject + selected, data);
        setSubs(response.data.subscriptions);
    }
    async function getSubsPlan(selected) {
        const response = await axios.get(api.paymentGetSubscription + selected, data);
        setSubsPlan(response.data.subscriptionPlans);
    }
    const data = {
        headers: {
            "x-auth-token": token,
        },
    };
    if (tohome === true) {
        return <Redirect to="/affiliate-payment" />;
    }

    const handleSubmit = () => {
        if(!user) return dispatch(actions.Danger("Enter a user"));
        var body = {
            user: user ? user._id : '',
            disbursedAmount : disbursedAmount,
            disburseDate: startTime
        }
        axios
            .post(api.affiliatePaymentCreate, body, data)
            .then(async (resp) => {
                const { status } = resp;
                if (status == 200) {
                    dispatch(actions.Success("Affiliate Payment created Succssfully"));
                    setIsPaymentSuccess(true)
                    setTohome(true)
                }
            })
            .catch((error) => {
                dispatch(actions.Danger("Something went wrong"));
            });
    }

    const handleSeacrhUser = () => {
        const url = `${api.getuserByphone}/${phoneNumber}`;
        axios
            .get(url, data)
            .then((resp) => {
                const { user, message } = resp.data;
                if(!user){
                    dispatch(actions.Danger("User not found"));
                }
                setUser(user);
                setMessage(message);
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    return dispatch(props.logout());
                } else {
                    return error.response.status;
                }
            });
    }
    const handleSubmitPaymentAddToUser = async () => {
        const body = {
            userId: user ? user._id : '',
            transactionId: transactionid,
            amountFromWallet: walletFromAmount,
        }
        axios
            .post(api.paymentAddUser, body, data)
            .then(async (resp) => {
                const { status } = resp;
                if (status == 200) {
                }
            })
            .catch((error) => {
            });
    }
    if (tohome === true) {
        return <Redirect to="/payment" />;
    }
    return (
        <div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-header">
                                    <h3 className="card-title"> Create Affiliate Payment</h3>
                                    <div className="card-toolbar">
                                        <div className="example-tools justify-content-center">
                                            <Link
                                                className="btn btn-success font-weight-bolder font-size-sm"
                                                to="/affiliate-payment"
                                            >
                                                <i className="flaticon2-list"></i>
                                                All Affiliate Payment
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <form className="form">
                                    <input type="hidden" />
                                    <div className="card-body">

                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Phone Number :
                                            </label>
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    placeholder="01xxxxxxxxx"
                                                    onChange={(e) => {
                                                        setPhoneNumber(`88${e.target.value}`)
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-success mr-2"
                                                    onClick={handleSeacrhUser}
                                                >
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            user ?
                                                <div className="form-group row">
                                                    <label className="col-lg-10 col-form-label text-left">
                                                        {message}
                                                    </label>

                                                </div> : null
                                        }
                                        {
                                            user ?

                                                <>
                                                    <div className="form-group row">
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Selected User :
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                value={user ? user.email : ''}
                                                                placeholder="User Email"
                                                                onChange={(e) => {
                                                                    //setName(e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                       
                                                    </div>

                                                   

                                                    <div className="form-group row">
                                                        <label className="col-lg-2 col-form-label text-left">
														   DisbursedAmount
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                placeholder="Enter Amount"
                                                                onChange={(e) => {
																	setDisbursedAmount(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Payment Date :
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <DatePicker timeIntervals ={1} showTimeSelect selected={startTime} onChange={(date) => setStartTime(date)} />
                                                        </div>
                                                    </div>

                                                </> : null
                                        }


                                        <div className="form-group row"></div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-lg-2"></div>
                                            <div className="col-lg-10">
                                                <button
                                                    type="button"
                                                    className="btn btn-success mr-2"
                                                    onClick={handleSubmit}
                                                >
                                                    Submit
                                                </button>
                                                {
                                                    isPaymentSuccess ?
                                                        <button onClick={handleSubmitPaymentAddToUser} type="button" className="btn btn-secondary">
                                                            Add to User
                                                        </button> : null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
