import React, { useEffect } from "react";
import { ToastContainer, Toast } from 'react-bootstrap'
import "./style.css";
import { actions } from "../../Components/Toaster/ToastReducer";
import { useDispatch } from "react-redux";
export default function SimpleToaster({ message, type, show}) {
    const dispatch = useDispatch();
    useEffect(() => {
        const timeId = setTimeout(() => {
          dispatch(actions.Close())
        }, 5000)
        return () => {
          clearTimeout(timeId)
        }
      }, []);
    
    return (
        <>
            <ToastContainer className="toast-container-custom">
                <Toast
                    show={show}  className="d-inline-block m-1 toaster-body" bg={type.toLowerCase()}>
                    <Toast.Body className={type === 'Success' ? 'text-white' : '' }>
                        {message}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    );
}

/* selct toaster
[
  'Primary',
  'Secondary',
  'Success',
  'Danger',
  'Warning',
  'Info',
  'Light',
  'Dark',
] */