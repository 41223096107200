/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import "../../Css/root.css";
import "react-telephone-input/css/default.css";
import { InputGroup, FormControl } from "react-bootstrap";
import { Form } from "react-bootstrap";
import axios from "../../axios";
import api from "../../api";
import {actions} from "../../Components/Toaster/ToastReducer";
import {useDispatch } from "react-redux";

// import {
// 	makeStyles,
// 	useTheme,
// 	FormControl as Fm,
// 	Select,
// 	MenuItem,
// 	InputLabel,
// 	Input,
// } from "@material-ui/core/";

import { Link, Redirect } from "react-router-dom";

// const useStyles = makeStyles((theme) => ({
// 	formControl: {
// 		margin: theme.spacing(1),
// 		minWidth: 160,
// 		maxWidth: 360,
// 	},
// 	chips: {
// 		display: "flex",
// 		flexWrap: "wrap",
// 	},
// 	chip: {
// 		margin: 2,
// 	},
// 	noLabel: {
// 		marginTop: theme.spacing(3),
// 	},
// }));
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
// 	PaperProps: {
// 		style: {
// 			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
// 			width: 250,
// 		},
// 	},
// };
// function getStyles(name, personName, theme) {
// 	return {
// 		fontWeight:
// 			personName.indexOf(name) === -1
// 				? theme.typography.fontWeightRegular
// 				: theme.typography.fontWeightMedium,
// 	};
// }
// const goalnames = [
//   'JSC',
//    'SSC',
//    'HSC',
//    'Engineering Admission',
//   'Medical Admission',
//    'Varsity Admission',
// ];
export default function UserCreate() {
	const [token, settoken] = React.useState(localStorage.getItem("user"));
	const dispatch = useDispatch();
	const [name, setName] = React.useState("");
	const [fname, setFname] = React.useState("");
	const [mname, setMname] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [batchyear, setBatchyear] = React.useState();
	const [phone, setPhone] = React.useState("");
	const [date, setDate] = React.useState("");
	const [gender, setGender] = React.useState("");
	const [address, setAddress] = React.useState("");
	const [institution, setInstitution] = React.useState("");
	const [fphone, setFphone] = React.useState("");
	const [mphone, setMphone] = React.useState("");
	const [clas, setClas] = React.useState("");
	const [sgroup, setSgroup] = React.useState("");
	const [sversion, setSversion] = React.useState("");
	const [errr, setErrr] = React.useState("");
	const [tohome, setTohome] = React.useState(false);

	if (tohome === true) {
		return <Redirect to="/" />;
	}
	const data = {
		headers: {
			"x-auth-token": token,
		},
	};
	// const handleChange = (event) => {
	// 	setGoal(event.target.value);
	// };
	const body = {
		name: name,
		email: email,
		phone: "88" + phone,
		password: password,

		dateOfBirth: date,
		gender: gender,
		address: address,

		fatherName: fname,
		motherName: mname,
		fatherPhone: "88" + fphone,
		motherPhone: "88" + mphone,

		institution: institution,
		class: clas,
		studyVersion: sversion,
		studyGroup: sgroup,
		batchYear: batchyear,

		role: "student",
	};
	function handleSubmit() {
		axios
			.post(api.createUser, body, data)
			.then(async (resp) => {
				if (resp.status === 200) {
					dispatch(actions.Success("User created Succssfully"));
					setTohome(true);
				}
			})
			.catch((error) => {
				dispatch(actions.Danger("Something Went Wrong"));
				setErrr(error.response.data);
			});
	}

	return (
		<div>
			<div className="d-flex flex-column-fluid">
				<div className=" container ">
					<div className="row">
						<div className="col-md-12">
							<div className="card card-custom">
								<div className="card-header">
									<h3 className="card-title"> Create User</h3>
									<div className="card-toolbar">
										<div className="example-tools justify-content-center">
											<Link
												className="btn btn-success font-weight-bolder font-size-sm"
												to="/"
											>
												<i className="flaticon2-list"></i>
												All User
											</Link>
										</div>
									</div>
								</div>
								<form className="form">
									<input type="hidden" />
									<div className="card-body">
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Name:
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													placeholder="Enter Name"
													onChange={(e) => {
														setName(e.target.value);
													}}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Password:
											</label>
											<div className="col-lg-3">
												<input
													type="password"
													className="form-control "
													placeholder="Enter Password"
													onChange={(e) => {
														setPassword(e.target.value);
													}}
												/>
											</div>

											<label className="col-lg-2 col-form-label text-left">
												Email:
											</label>
											<div className="col-lg-3">
												<input
													type="email"
													className="form-control "
													placeholder="Enter Email"
													onChange={(e) => {
														setEmail(e.target.value);
													}}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Batch Year :
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													placeholder="Enter Batch Year"
													onChange={(e) => {
														setBatchyear(e.target.value);
													}}
												/>
											</div>

											<label className="col-lg-2 col-form-label text-left">
												Phone:
											</label>

											<InputGroup className="col-lg-3">
												<InputGroup.Text>88</InputGroup.Text>
												<FormControl
													name="telephone"
													placeholder="Phone Number"
													type="tel"
													onChange={(e) => {
														setPhone(e.target.value);
													}}
												/>
											</InputGroup>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Date Of Birth :
											</label>
											<div className="col-lg-3">
												<input
													type="date"
													className="form-control "
													onChange={(e) => {
														setDate(e.target.value);
													}}
												/>
											</div>

											<label className="col-lg-2 col-form-label text-left">
												Gender :
											</label>
											<div className="col-lg-3">
												<Form.Control
													as="select"
													onChange={(e) => {
														setGender(e.target.value);
													}}
												>
													<option value="" disabled selected>
														Gender
													</option>
													<option value="Male">Male</option>
													<option value="Female">Female</option>
													<option value="Others">Others</option>
												</Form.Control>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Address :
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													placeholder="Enter Address"
													onChange={(e) => {
														setAddress(e.target.value);
													}}
												/>
											</div>

											<label className="col-lg-2 col-form-label text-left">
												Institution:
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													placeholder="Enter Institution"
													onChange={(e) => {
														setInstitution(e.target.value);
													}}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Father's Name :
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													placeholder="Enter Father's Name"
													onChange={(e) => {
														setFname(e.target.value);
													}}
												/>
											</div>

											<label className="col-lg-2 col-form-label text-left">
												Mother's Name :
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													placeholder="Enter Mother's Name"
													onChange={(e) => {
														setMname(e.target.value);
													}}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Father's Phone:
											</label>
											<InputGroup className="col-lg-3">
												<InputGroup.Text>88</InputGroup.Text>
												<FormControl
													placeholder="Phone Number"
													type="tel"
													onChange={(e) => {
														setFphone(e.target.value);
													}}
												/>
											</InputGroup>

											<label className="col-lg-2 col-form-label text-left">
												Mother's Phone:
											</label>
											<InputGroup className="col-lg-3">
												<InputGroup.Text>88</InputGroup.Text>
												<FormControl
													id="inlineFormInputGroup"
													placeholder="Phone Number"
													type="tel"
													onChange={(e) => {
														setMphone(e.target.value);
													}}
												/>
											</InputGroup>
										</div>

										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Class :
											</label>
											<div className="col-lg-3">
												<Form.Control
													as="select"
													onChange={(e) => {
														setClas(e.target.value);
													}}
												>
													<option value="" disabled selected>
														Class
													</option>
													<option value="VIII">Eight</option>
													<option value="IX">Nine</option>
													<option value="X">Ten</option>
													<option value="XI">Eleven</option>
													<option value="XII">Twelve</option>
													<option value="undergrad">Undergrad</option>
													<option value="postgrad">Postgrad</option>
												</Form.Control>
											</div>

											<label className="col-lg-2 col-form-label text-left">
												Study Group :
											</label>
											<div className="col-lg-3">
												<Form.Control
													as="select"
													onChange={(e) => {
														setSgroup(e.target.value);
													}}
												>
													<option value="" disabled selected>
														Study Group
													</option>
													<option value="Science">Science</option>
													<option value="Commerce">Commerce</option>
													<option value="Humanities">Humanities</option>
												</Form.Control>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Study Version :
											</label>
											<div className="col-lg-3">
												<Form.Control
													as="select"
													onChange={(e) => {
														setSversion(e.target.value);
													}}
												>
													<option value="" disabled selected>
														Study Version
													</option>
													<option value="Bangla">Bangla</option>
													<option value="English">English</option>
												</Form.Control>
											</div>

											{/* <label className="col-lg-2 col-form-label text-left">Goal :</label>
        <div className="col-lg-3">
        <Fm className={classes.formControl}>
        <InputLabel id="demo-mutiple-name-label">Select Goal</InputLabel>
        <Select
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"
          multiple
          value={goal}
          onChange={handleChange}
          input={<Input />}
          MenuProps={MenuProps}
        >
          {goalnames.map((name) => (
            <MenuItem key={name} value={name} style={getStyles(name, goal, theme)}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </Fm>
        </div> */}
										</div>
										<div className="form-group row">
											<label
												n
												className="col-lg-2 col-form-label text-left"
												style={{ color: "red", fontSize: "22px" }}
											>
												{errr}
											</label>
											<div className="col-lg-3" hidden>
												<span className="switch switch-icon switch-success ">
													<label>
														<input type="hidden" value="0" name="is_active" />
														<input type="checkbox" value="1" name="is_active" />

														<span></span>
													</label>
												</span>
											</div>
										</div>
										<div className="form-group row"></div>
									</div>
									<div className="card-footer">
										<div className="row">
											<div className="col-lg-2"></div>
											<div className="col-lg-10">
												<button
													type="button"
													onClick={handleSubmit}
													className="btn btn-success mr-2"
												>
													Submit
												</button>
												<button type="reset" className="btn btn-secondary">
													Reset
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
