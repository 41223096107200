
import axios from "../../../../roots_Comp/axios"
import api from '../../../../roots_Comp/api'

export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;


export function login(email, password) {
	
	const data = {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
		},
	};
	const body = {
		email: email,
		password: password,
	};
	return axios.post(api.login, body, data);
}
