import React from "react";
import "../Css/root.css";
import { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import axios from "../axios";
import api from "../api";
import { Link, Redirect } from "react-router-dom";
import {actions} from "../Components/Toaster/ToastReducer";
import { useDispatch } from "react-redux";


export default function CreateGoal() {
	const dispatch = useDispatch();
	const [goalnames, setgoalnames] = useState([]);
	const [Datas, setDatas] = useState([]);
	const [name, setName] = React.useState(" ");
	const [Error, setError] = React.useState("");
	const [token, settoken] = React.useState(localStorage.getItem("user"));
	const [tohome, setTohome] = React.useState(false);
	const [isActive, setisActive] = useState(true);
	const [isFree, setisFree] = useState(false);
	const [Order, setOrder] = useState();
	const options = [];
	const SubscriptionArray = [];
	const data = {
		headers: {
			"x-auth-token": token,
		},
	};

	async function my_async_fn() {
		const response = await axios.get(api.getSubsGoal, data);
		setDatas(response.data);
	}
	function sortData() {
		Datas.map((data) => options.push({ label: data.title, value: data._id }));
	}
	function modifyData() {
		goalnames.map((data) => SubscriptionArray.push(data.value));
	}
	useEffect(() => {
		my_async_fn();
	}, []);
	if (tohome === true) {
		return <Redirect to="/goals" />;
	}
	sortData();
	const body = {
		title: name,
		subscriptions: SubscriptionArray,
		isFree: isFree,
		isActive: isActive,
		order: Order,
	};
	function handleSubmit() {
		modifyData();
		axios
			.post(api.createGoal, body, data)
			.then(async (resp) => {
				if (resp.status === 200) {
					dispatch(actions.Success("Goal created successfully"));
					setTohome(true);
				}
			})
			.catch((error) => {
				setError(error.response.data);
			});
	}
	return (
		<div>
			<div className="d-flex flex-column-fluid">
				<div className=" container ">
					<div className="row">
						<div className="col-md-12">
							<div className="card card-custom">
								<div className="card-header">
									<h3 className="card-title"> Create Goal</h3>
									<div className="card-toolbar">
										<div className="example-tools justify-content-center">
											<Link
												className="btn btn-success font-weight-bolder font-size-sm"
												to="/goals"
											>
												<i className="flaticon2-list"></i>
												All Goal
											</Link>
										</div>
									</div>
								</div>
								<form className="form">
									<input type="hidden" />
									<div className="card-body">
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Name:
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													placeholder="Enter Name"
													onChange={(e) => {
														setName(e.target.value);
													}}
												/>
											</div>
											<label className="col-lg-2 col-form-label text-left">
												Subscriptions:
											</label>
											<div className="col-lg-3">
												<MultiSelect
													options={options}
													value={goalnames}
													onChange={setgoalnames}
													labelledBy="Select"
												/>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Is Active:
											</label>
											<div className="col-lg-3">
												<span className="switch switch-icon switch-success ">
													<label>
														<input
															type="checkbox"
															checked={isActive}
															onChange={(e) => setisActive(e.target.checked)}
														/>

														<span></span>
													</label>
												</span>
											</div>
											<label className="col-lg-2 col-form-label text-left">
												Is Free:
											</label>
											<div className="col-lg-3">
												<span className="switch switch-icon switch-success ">
													<label>
														<input
															type="checkbox"
															checked={isFree}
															onChange={(e) => setisFree(e.target.checked)}
														/>

														<span></span>
													</label>
												</span>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Order :
											</label>
											<div className="col-lg-3">
												<input
													type="number"
													className="form-control "
													placeholder="Enter Order"
													onChange={(e) => setOrder(e.target.value)}
												/>
											</div>
										</div>
										<div className="form-group row">
											<p>{Error}</p>
											<label
												n
												className="col-lg-2 col-form-label text-left"
												style={{ color: "red", fontSize: "22px" }}
											></label>
											<div className="col-lg-3" hidden>
												<span className="switch switch-icon switch-success ">
													<label>
														<input type="hidden" value="0" name="is_active" />
														<input type="checkbox" value="1" name="is_active" />

														<span></span>
													</label>
												</span>
											</div>
										</div>
										<div className="form-group row"></div>
									</div>
									<div className="card-footer">
										<div className="row">
											<div className="col-lg-2"></div>
											<div className="col-lg-10">
												<button
													type="button"
													className="btn btn-success mr-2"
													onClick={handleSubmit}
												>
													Submit
												</button>
												<button type="reset" className="btn btn-secondary">
													Reset
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
