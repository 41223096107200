import React, {useState} from 'react'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import RoomList from "./component/RoomList"
import Create from "./Create"

export default function LiveClassDashBoard() {
    const [mode, setMode] = useState("dashboard") //create//
    const [type, setType] = useState("studio")

    const dashbaord = (
        <div>
            <Card sx={{ width: '100%', padding: 2 }}>
                <CardHeader
                    title={"Studio Room"}
                    action={
                        <Tooltip title="add new room">
                            <IconButton onClick={() => {
                                setType("studio")
                                setMode("create")
                                }}>
                                <AddBoxIcon fontSize='large' />
                            </IconButton>
                        </Tooltip>
                    }
                />
                <CardContent>
                    <RoomList id={'studio'} />
                </CardContent>
            </Card>
            <Card sx={{ width: '100%', padding: 2 , mt: 2}}>
                <CardHeader
                    title={"Teachers Room"}
                    action={
                        <Tooltip title="add new room">
                            <IconButton onClick={() => {
                                setType("teachers")
                                setMode("create")
                                }}>
                                <AddBoxIcon fontSize='large' />
                            </IconButton>
                        </Tooltip>
                    }
                />
                <CardContent>
                    <RoomList id={'teachers'} />
                </CardContent>
            </Card>
        </div>
    )

    const create = (
        <Card sx={{ width: '100%', padding: 2 }}>
            <CardHeader
                title={"add new room"}
                action={
                    <Tooltip title="Back to dashboard">
                        <IconButton onClick={()=>setMode("dashboard")}>
                            <ArrowBackIosIcon sx={{fontWeight : 800}} fontSize='large' />
                        </IconButton>
                    </Tooltip>
                }
            />
            <CardContent>
               <Create type={type} />
            </CardContent>
        </Card>
    )

    return (
        <div>
            {mode === "dashboard" && dashbaord}
            {mode === "create" && create}
        </div>
    )
}
