import React, { useEffect, useState } from "react"
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import RestoreIcon from '@mui/icons-material/Restore'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Stack from '@mui/material/Stack'
import BasicCircularProgress from "../../../Components/BasicCircularProgress"
import { useSnackbar } from "notistack"


export default function ChapterAddUpdateDialog(props) {
    const [chapterLoading, setChapterLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    const [title, setTitle] = useState('')
    const [order, setOrder] = useState('')
    const [isActive, setIsActive] = useState(true)
    const [isFree, setIsFree] = useState(false)

    const [errorMessage, setErrorMessage] = useState({})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const subjectId = props.subjectId

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    function resetFormData() {
        setTitle('')
        setOrder('')
        setIsActive(true)
        setIsFree(false)
        setErrorMessage({})
    }

    const handleClose = () => {
        props.handleClose()
    }

    const handleReset = () => (
        resetFormData()
    )

    const handleSubmit = () => {
        if(!isFormDataValid()) return
        props.chapterId ? updateChapter() : addNewChapter()
    }

    const isFormDataValid = () => {
        var isValid = true
        if(!title.length) {
            isValid = false
            setErrorMessage(message => {
                return {...message, title: 'Title must not be empty.'}
            })
        }
        if(!order) {
            isValid = false
            setErrorMessage(message => {
                return {...message, order: 'Order must be a valid number'}
            })
        }
        return isValid
    }

    async function fetchChapterData(chapterId) {
        setChapterLoading(true)
        axios
            .get(api.getChapterById + chapterId, config)
            .then((response) => {
                setTitle(response.data.title)
                setIsActive(response.data.isActive)
                setIsFree(response.data.isFree)
                if(response.data.order) setOrder(response.data.order)
                setChapterLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Chapter data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function addNewChapter() {
        setSubmitLoading(true)
        const chapterPayload = {
            title: title,
            classes: [],
            order: order,
            isFree: isFree,
            isActive: isActive
        }
        axios
            .post(api.createChapter, chapterPayload, config)
            .then((response) => {
                axios
                    .post(
                        api.addChapterToSubject,
                        {
                            chapterId: response.data,
                            subjectId: subjectId
                        },
                        config
                    )
                    .then((response) => {
                        enqueueSnackbar(response.data, { variant: 'success' })
                        setSubmitLoading(false)
                        handleClose()
                        props.handleSuccess()
                    })
                    .catch((error) => {
                        enqueueSnackbar("Add new chapter failed: " + error.response.data, { variant: 'error' })
                    })
            })
            .catch((error) => {
                enqueueSnackbar("Add new chapter failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function updateChapter() {
        const updatePayload = {
            title: title,
            order: order,
            isFree: isFree,
            isActive: isActive,
        }
        axios
            .put(api.updateChapter + props.chapterId, updatePayload, config)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
                handleClose()
                props.handleSuccess()
            })
            .catch((error) => {
                enqueueSnackbar("Update chapter failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        // TODO: Stop initial loading
        if(!props.open) {
            return
        }
        if(props.chapterId) fetchChapterData(props.chapterId)
    }, [props.open])

    const appBar = (
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Add or Update Chapter
                </Typography>
                <LoadingButton
                    color="secondary"
                    onClick={handleSubmit}
                    loading={chapterLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
                <LoadingButton
                    color="inherit"
                    sx={{ ml: 2 }}
                    onClick={handleReset}
                    loading={chapterLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<RestoreIcon />}
                    variant="contained"
                >
                    Reset
                </LoadingButton>
            </Toolbar>
        </AppBar>
    )

    const titleField = (
        <TextField
            required
            error={!!errorMessage.title}
            variant="outlined"
            label="Title"
            helperText={errorMessage.title}
            sx={{ minWidth: 300 }}
            value={title}
            onChange={(event) => {
                const text = event.target.value
                if(!text.length) {
                    setErrorMessage(message => {
                        return {...message, title: 'Title must not be empty.'}
                    })
                } else {
                    setErrorMessage(message => {
                        return {...message, title: ''}
                    })
                }
                setTitle(event.target.value)
            }}
        />
    )

    const orderField = (
        <TextField
            required
            error={!!errorMessage.order}
            variant="outlined"
            type="number"
            label="Order"
            helperText={errorMessage.order}
            sx={{ minWidth: 300 }}
            value={order}
            onChange={(event) => {
                const order = event.target.value
                if(!order) {
                    setErrorMessage(message => {
                        return {...message, order: 'Order must be a valid number.'}
                    })
                } else {
                    setErrorMessage(message => {
                        return {...message, order: ''}
                    })
                }
                setOrder(event.target.value)
            }}
        />
    )

    const isActiveSwitch = (
        <FormControlLabel
            label="Is Active"
            control={
                <Switch
                    checked={isActive}
                    onChange={(event) => setIsActive(event.target.checked)}
                />
            }
        />
    )

    const isFreeSwitch = (
        <FormControlLabel
            label="Is Free"
            control={
                <Switch
                    checked={isFree}
                    onChange={(event) => setIsFree(event.target.checked)}
                />
            }
        />
    )

    return (
        <Dialog fullScreen disableEnforceFocus open={Boolean(props.open)} onClose={handleClose}>
            {appBar}
            {chapterLoading && <BasicCircularProgress />}
            <Box sx={{ m: 2 }}>
                <Stack direction="column" spacing={4}>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                        {titleField}
                        {orderField}
                    </Stack>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                        {isActiveSwitch}
                        {isFreeSwitch}
                    </Stack>
                </Stack>
            </Box>
        </Dialog>
    )
}