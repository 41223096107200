import React from 'react'
import Box from '@mui/material/Box'

import RolseWiseUserLogout from './RolseWiseUserLogout'
import ExcelUploadUsersLogout from './ExcelUploadUsersLogout'

export default function BulkUserLogout() {
    return (
        <div>
            <Box>
                <RolseWiseUserLogout />
            </Box>
            <Box sx={{ mt: 3 }}>
                <ExcelUploadUsersLogout />
            </Box>
        </div>
    )
}
