import React, { useState, useEffect } from "react"
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from "@mui/material/Stack"
import Tooltip from '@mui/material/Tooltip'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import FileDownload from "@mui/icons-material/FileDownload"
import PreviewIcon from '@mui/icons-material/Preview'
import UploadIcon from '@mui/icons-material/Upload'
import QuestionAddUpdateDialog from "./QuestionAddUpdateDialog"
import SimpleDeleteModal from '../../../Components/SimpleDeleteModal'
import QuestionPreview from "./QuestionPreview"
import { useSnackbar } from "notistack"
import QuestionUploadDialog from "./QuestionUploadDialog"

export default function QuestionTable(props) {

    const [questions, setQuestions] = useState([])
    const [questionPage, setQuestionPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const [dialogQuestionId, setDialogQuestionId] = useState('')
    const [openQuestionDialog, setOpenQuestionDialog] = useState(false)

    const [openQuestionPreview, setOpenQuestionPreview] = useState(false)
    const [openUploadDialog, setOpenUploadDialog] = useState(false)

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deleteModalQuestionId, setDeleteModalQuestionId] = useState('')
    const [refreshKey, setRefreshKey] = useState(0)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const TAG = "SubscriptionExamList"
    const generateKey = (pre) => {
        return `${TAG}_${pre}_${new Date().getTime()}`
    }
    const refresh = () => {
        //  props.handleSuccess('')
        setRefreshKey(prevKey => prevKey + 1)
    }

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user")
        }
    }

    const handleAddOrEditPressed = (questionId) => {
        setDialogQuestionId(questionId)
        setOpenQuestionDialog(true)
    }

    const handleDownloadPressed = () => {
        if(questions.length == 0) {
            enqueueSnackbar("Exam has no questions", { variant: "error" })
            return
        }
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
          JSON.stringify(questions)
        )}`
        const link = document.createElement("a")
        link.href = jsonString
        link.download = "questions.json"

        link.click()
    }

    const handleUploadPressed = () => {
        if(questions.length) {
            enqueueSnackbar('Exam already has questions', { variant: 'error' })
            return
        }

        setOpenUploadDialog(true)
    }

    const handleDeleteAction = (questionId) => {
        setDeleteModalQuestionId(questionId)
        setOpenDeleteModal(true)
    }

    async function deleteQuestion(questionId) {
        axios
            .delete(`/admin/mcq-questions/${questionId}`, {
                headers: {
                    "x-auth-token": localStorage.getItem("user")
                },
                data: {
                    examId : props.examId 
                }
              })
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
                refresh()
            })
            .catch((error) => {
                enqueueSnackbar("Question delete failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        if (!props.examId) return
        axios
            .get(api.examExplorerGetQuestions + props.examId, config)
            .then((response) => {
                setQuestions(response.data)
            })
            .catch((error) => {
                enqueueSnackbar("Exam data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }, [props.examId, refreshKey])

    return (
        <Card sx={{ width: "46vw" }}>
            <CardHeader
                title="Questions"
                action={
                    <Stack direction="row">
                        <Tooltip title="Add New Question">
                            <IconButton aria-label="add" onClick={(_event) => handleAddOrEditPressed('')}>
                                <AddIcon color="primary" sx={{ width: 30, height: 30 }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Download Questions">
                            <IconButton aria-label="add" onClick={handleDownloadPressed}>
                                <FileDownload color="primary" sx={{ width: 30, height: 30 }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Upload Questions">
                            <IconButton aria-label="add" onClick={handleUploadPressed}>
                                <UploadIcon color="primary" sx={{ width: 30, height: 30 }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                }
            />
            <CardContent>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell width="10%">Serial</TableCell>
                                <TableCell width="60%" align="left">Question</TableCell>
                                <TableCell width="30%" align="left">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                questions
                                    .slice(questionPage * rowsPerPage, questionPage * rowsPerPage + rowsPerPage)
                                    .map((question) => (
                                        <TableRow
                                            key={question._id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {question.serialNo}
                                            </TableCell>
                                            <TableCell align="left">
                                                <div dangerouslySetInnerHTML={{ __html: `${question.question}` }} />
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Preview">
                                                    <IconButton onClick={()=>{setOpenQuestionPreview(true);setDialogQuestionId(question._id)}}>
                                                        <PreviewIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit">
                                                    <IconButton onClick={() => handleAddOrEditPressed(question._id)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <IconButton onClick={() => handleDeleteAction(question._id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={questions.length}
                    rowsPerPage={rowsPerPage}
                    page={questionPage}
                    onPageChange={(_event, newPage) => setQuestionPage(newPage)}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10))
                        setQuestionPage(0)
                    }}
                />
            </CardContent>

            <QuestionAddUpdateDialog
                key={generateKey("question-dialog")}
                examId={props.examId}
                questionId={dialogQuestionId}
                open={openQuestionDialog}
                defaultSerialNo={questions?.length +1}
                handleClose={() => {setOpenQuestionDialog(false)}}
                handleSuccess={refresh}
            />

            <QuestionUploadDialog
                key={generateKey("question-upload-dialog")}
                examId={props.examId}
                open={openUploadDialog}
                handleClose={() => {setOpenUploadDialog(false)}}
                handleSuccess={refresh}
            />

            <QuestionPreview
                questionId={dialogQuestionId}
                open={openQuestionPreview}
                handleCloseQuestionPreview={() => setOpenQuestionPreview(false)}
            />

            <SimpleDeleteModal
                title="Delete Question"
                open={openDeleteModal}
                handleClose={() => {
                    setDeleteModalQuestionId('')
                    setOpenDeleteModal(false)
                }}
                handleDelete={() => deleteQuestion(deleteModalQuestionId)}
            />
        </Card>
    )
}
