import React, { useEffect, useRef, useState } from "react"

import AppBar from "@mui/material/AppBar"
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from "@mui/material/Card"
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Dialog from "@mui/material/Dialog"
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import RestoreIcon from '@mui/icons-material/Restore'
import Stack from "@mui/material/Stack"
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { useSnackbar } from "notistack"
import Paper from "@mui/material/Paper"

import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import BasicCircularProgress from "../../../Components/BasicCircularProgress"

const Input = styled('input')({
    display: 'none',
})

export default function QuestionUploadDialog(props) {
    const [questions, setQuestions] = useState([])
    const [submitLoading, setSubmitLoading] = useState(false)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    const handleUpload = e => {
        const fileReader = new FileReader()
        fileReader.readAsText(e.target.files[0], "UTF-8")
        fileReader.onload = e => {
            const json = JSON.parse(e.target.result)
            if(Array.isArray(json) && json.length != 0 && Object.hasOwn(json[0], '_id')) {
                setQuestions(json)
            } else {
                enqueueSnackbar("File not supported", { variant: "error" })
            }
        }
    }

    const handleSubmit = () => {
        setSubmitLoading(true)
        const payload = {
            examId: props.examId,
            questions: questions.map(x => x._id)
        }
        axios
            .post(api.addBulkMcq, payload, config)
            .then((response) => {
                setSubmitLoading(false)
                enqueueSnackbar(response.data, { variant: 'success' })
                props.handleClose()
                props.handleSuccess()
            })
            .catch((error) => {
                enqueueSnackbar("Update question failed: " + error.response.data, { variant: 'error' })
            })
    }

    const handleReset = () => {
        setQuestions([])
    }

    const appBar = (
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={props.handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Upload Questions
                </Typography>
                <LoadingButton
                    color="secondary"
                    onClick={handleSubmit}
                    loading={submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
                <LoadingButton
                    color="inherit"
                    sx={{ ml: 2 }}
                    onClick={handleReset}
                    loading={submitLoading}
                    loadingPosition="start"
                    startIcon={<RestoreIcon />}
                    variant="contained"
                >
                    Reset
                </LoadingButton>
            </Toolbar>
        </AppBar>
    )

    const uploadCard = (
        <Card sx={{ minWidth: 275, p: 2 }}>
            <CardContent>
                <Typography variant="h6" component="div">
                    Upload questions
                </Typography>
            </CardContent>
            <CardActions>
            <label htmlFor="question-file-upload">
                <Input
                    type="file"
                    accept="application/json"
                    style={{ display: 'none' }}
                    id="question-file-upload"
                    onChange={handleUpload}
                />
                <Button component="span" variant="contained" color="primary">Upload File</Button>
            </label>
            </CardActions>
        </Card>
    )

    const questionsTable = (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell width="10%">Serial</TableCell>
                        <TableCell width="60%" align="left">Question</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        questions
                            .map((question) => (
                                <TableRow
                                    key={question._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {question.serialNo}
                                    </TableCell>
                                    <TableCell align="left">
                                        <div dangerouslySetInnerHTML={{ __html: `${question.question}` }} />
                                    </TableCell>
                                </TableRow>
                            ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )

    return (
        <Dialog fullScreen disableEnforceFocus open={Boolean(props.open)} onClose={props.handleClose}>
            {appBar}
            {
                submitLoading ? <BasicCircularProgress /> :
                <Box sx={{ m: 2 }}>
                    <Stack direction="row" gap={2}>
                        {uploadCard}
                        {questionsTable}
                    </Stack>
                </Box>
            }
        </Dialog>
    )
}