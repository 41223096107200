import React, { useState } from "react"
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import ExplorerModeMenu from "../../Components/ExplorerModeMenu"
import LiveClass from "./SubscriptionCourse/LiveClass";
import CatalogCourseLiveClasses from "./CatalogCourse/CatalogCourseLiveClasses";

const ExplorerMode = {
    SubscriptionLiveClass: "SubscriptionLiveClass",
    CatalogCourseLiveClass: "CatalogCourseLiveClass"
}

const ExplorerModeMenuItem = {
    SubscriptionLiveClass: "Subscription Live Class",
    CatalogCourseLiveClass: "Catalog Course Live Class"
}

const FilterTitleByMode = {
    SubscriptionLiveClass: "Subscription Live Class",
    CatalogCourseLiveClass: "Catalog Course Live Class"
}

export default function LiveClassMode() {
    const [explorerMode, setExplorerMode] = useState(localStorage.getItem("liveClass-activeMode") ?? ExplorerMode.SubscriptionLiveClass)

    const handleModeChange = (mode) => {
        setExplorerMode(ExplorerMode[mode])
        localStorage.setItem("liveClass-activeMode", ExplorerMode[mode]);
    }

    const filterBox = (
        <Card>
            <CardHeader
                title={FilterTitleByMode[explorerMode]}
                action={
                    <ExplorerModeMenu
                        currentMode={explorerMode}
                        explorerModes={ExplorerModeMenuItem}
                        handleModeSelection={handleModeChange}
                    />
                }
            />
        </Card>
    )

    return (
        <React.Fragment>
            {filterBox}
            {explorerMode === "SubscriptionLiveClass" && <LiveClass/>}
            {explorerMode === "CatalogCourseLiveClass" && <CatalogCourseLiveClasses/>}
            {/* <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                
            </Grid> */}
        </React.Fragment>
    )
}