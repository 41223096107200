import React, {useMemo} from "react";

import {useHtmlClassService} from "../../_core/MetronicLayout";

export function Header() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true)
    };
  }, [uiService]);

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}>
    

      </div>
      {/*end::Header*/}
    </>
  );
}
