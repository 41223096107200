import * as React from 'react'
/* @MUI COMPONENT */
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { Stack } from '@mui/material'

import MathJax from 'react-mathjax-preview'

export default function ExamPreview(props) {
    const exam = props.exam
    const questionItem = (item, index) => {
        return (
            <ListItem key={item._id}>
                <Stack direction="column">
                    <Typography variant="h6" component="div">
                        Question {index + 1}
                    </Typography>
                    <Divider />
                    <Stack spacing={2}>
                        <MathJax sanitizeOptions={{USE_PROFILES: {html: true, mathMl: true, svg: true}}} math={`QUESTION: ${item.question}`}  />
                        <MathJax sanitizeOptions={{USE_PROFILES: {html: true, mathMl: true, svg: true}}} math={`A: ${item.optionA}`} />
                        <MathJax sanitizeOptions={{USE_PROFILES: {html: true, mathMl: true, svg: true}}} math={`B: ${item.optionB}`} />
                        <MathJax sanitizeOptions={{USE_PROFILES: {html: true, mathMl: true, svg: true}}} math={`C: ${item.optionC}`} />
                        <MathJax sanitizeOptions={{USE_PROFILES: {html: true, mathMl: true, svg: true}}} math={`D: ${item.optionD}`} />
                        {
                            item.optionE ?
                                <MathJax sanitizeOptions={{USE_PROFILES: {html: true, mathMl: true, svg: true}}} math={`E: ${item.optionE}`} /> : null
                        }
                        {
                            item.solution ?
                                <>Solution : <MathJax sanitizeOptions={{USE_PROFILES: {html: true, mathMl: true, svg: true}}} math={`SOLUTION: ${item.solution}`} /> </> : null
                        }
                    </Stack>
                </Stack>
            </ListItem>
        )
    }

    return (
        <Card sx={props.sx}>
            <CardHeader title={exam.title} />
            <CardContent>
                <List>
                    {exam.questions.map(questionItem)}
                </List>
            </CardContent>
        </Card>
    )
}