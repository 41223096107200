import React, { useEffect } from "react"
import MathJax from 'react-mathjax-preview'
/* @MUI COMPONENT */
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid"
import Divider from "@mui/material/Divider"
import axios from "../../../axios"
import BasicCircularProgress from "../../../Components/BasicCircularProgress"

export default function QuestionPreview(props) {

    const [loading, setLoading] = React.useState(true)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const [data, setData] = React.useState({
        correctAns: "",
        optionA: "",
        optionB: "",
        optionC: "",
        optionD: "",
        optionE: "",
        question: "",
        serialNo: null,
        solution: ""
    })
    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user")
        }
    }
    useEffect(async () => {
        try {
            const response = await axios.get(`/admin/mcq-questions/get-question?questionId=${props.questionId}`, config);
            setLoading(false)
            setData(response.data.result)
        } catch (error) {
        }
    }, [props.questionId])


    const questionPreviewUi = (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
        >
            <Grid item md={12} xs={12}>
                <Typography variant="h6" component="div">
                    Question Serial  No: {data.serialNo}
                </Typography>
            </Grid>

            <Grid item md={12} xs={12}>
                <Typography variant="h6" sx={{ display: 'inline-block' }}>
                    Question. &nbsp;<MathJax style={{ display: 'inline-block' }} sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }} math={`${data.question}`} />
                </Typography>
            </Grid>
            <Divider />

            <Grid item md={12} xs={12}>
                <Typography variant="h6" sx={{ display: 'inline-block' }}>
                    A. &nbsp;<MathJax style={{ display: 'inline-block' }} sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }} math={`${data.optionA}`} />
                </Typography>
            </Grid>
            <Divider />

            <Grid item md={12} xs={12}>
                <Typography variant="h6" sx={{ display: 'inline-block' }}>
                    B. &nbsp;<MathJax style={{ display: 'inline-block' }} sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }} math={`${data.optionB}`} />
                </Typography>
            </Grid>
            <Divider />

            <Grid item md={12} xs={12}>
                <Typography variant="h6" sx={{ display: 'inline-block' }}>
                    C. &nbsp;<MathJax style={{ display: 'inline-block' }} sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }} math={`${data.optionC}`} />
                </Typography>
            </Grid>
            <Divider />

            <Grid item md={12} xs={12}>
                <Typography variant="h6" sx={{ display: 'inline-block' }}>
                    D. &nbsp;<MathJax style={{ display: 'inline-block' }} sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }} math={`${data.optionD}`} />
                </Typography>
            </Grid>
            <Divider />


            {
                data.optionE ?
                    <><Grid item md={12} xs={12}>
                        <Typography variant="h6" sx={{ display: 'inline-block' }}>
                            E. &nbsp;<MathJax style={{ display: 'inline-block' }} sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }} math={`${data.optionE}`} />
                        </Typography>
                    </Grid>
                        <Divider /> </> : null
            }

            <Grid item md={12} xs={12}>
                <Typography variant="h6" sx={{ display: 'inline-block' }}>
                    Correct Ans. &nbsp;<MathJax style={{ display: 'inline-block' }} sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }} math={`${data.correctAns}`} />
                </Typography>
            </Grid>
            <Divider />


            {
                data.solution ?
                    <><Grid item md={12} xs={12}>
                        <Typography variant="h6" sx={{ display: 'inline-block' }}>
                            Solution. &nbsp;<MathJax style={{ display: 'inline-block' }} sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }} math={`${data.solution}`} />
                        </Typography>
                    </Grid>
                        <Divider /> </> : null
            }

        </Grid>
    )
    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            {loading && <BasicCircularProgress /> }
            <DialogTitle id="Question Preview">
            </DialogTitle>

            <DialogContent>
                {!loading && questionPreviewUi}
            </DialogContent>

            <DialogActions>
                <Button onClick={props.handleCloseQuestionPreview} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
