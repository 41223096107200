import React from 'react'
import "../Css/root.css"
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Skeleton from '@mui/material/Skeleton'

export default function IconLabelListSkeleton(props) {
    return (
        <ListItem >
            <ListItemIcon>
                <Skeleton variant="circular" width={20} height={20}/>
            </ListItemIcon>
            <Skeleton variant="text" width={200}/>
        </ListItem>
    )
}
