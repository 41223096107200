import React, { useState, useEffect } from 'react'
import { useSnackbar } from "notistack"

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import axios from "../../axios"


export default function Create({ explorerMode, selectedSubscription, selectedCourse, handleSuccess }) {
   
    const { enqueueSnackbar } = useSnackbar()
    const [formData, setFormData] = useState({
        title: '',
        validity: '',
        price: '',
        flatDiscount: '',
        percentDiscount: '',
        serviceCharge: ''
    })
    const config = { headers: { "x-auth-token": localStorage.getItem("user") } }

    const createSubscriptionPaymentPlan = async () => {
        try {
            const body = {
                ...formData,
                subscriptionPlan: {
                    subscriptions : [selectedSubscription],
                    services : []
                },
            }
            const response = await axios.post(`/admin/paymentPlan/subscriptionsPlan/${selectedSubscription}`, body, config);
            enqueueSnackbar(response.data, { variant: 'success' })
            handleSuccess()
        } catch (error) {
            return enqueueSnackbar(error.response.data, { variant: 'error' })
        }
    }

    const createCoursePaymentPlan = async () => {
        try {
            const body = {
                ...formData,
                catalogPlan: {
                    courses : [selectedCourse],
                    services : []
                },
            }
            const response = await axios.post(`/admin/paymentPlan/coursesPlan/${selectedCourse}`, body, config);
            enqueueSnackbar(response.data, { variant: 'success' })
            handleSuccess()
        } catch (error) {
            return enqueueSnackbar(error.response.data, { variant: 'error' })
        }
    }

    const handleSubmit = async () => {
        if (!formData.title) return enqueueSnackbar("Title is not allowed to be empty", { variant: 'warning' })
        if (!formData.validity) return enqueueSnackbar("Validity is not allowed to be empty", { variant: 'warning' })
        if (!formData.price) return enqueueSnackbar("Price is not allowed to be empty", { variant: 'warning' })
        
        if (selectedSubscription && explorerMode === 'SubscriptionPaymentPlan') {
            createSubscriptionPaymentPlan()
        } else if (selectedCourse && explorerMode === 'CoursePaymentPlan') {
            createCoursePaymentPlan()
        } else {
            return enqueueSnackbar("SubscriptionId or CourseId not found", { variant: 'error' })
        }
    }

    const handleClear = async () => {
        setFormData({
            title: '',
            validity: '',
            price: '',
            flatDiscount: '',
            percentDiscount: '',
            serviceCharge: ''
        })
    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: { xs: 1, md: 2 }, width: '40ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    required
                    id="outlined-required"
                    label="Title"
                    value={formData.title}
                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                />
                <TextField
                    required
                    id="outlined-number"
                    label="Validity"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={formData.validity}
                    onChange={(e) => setFormData({ ...formData, validity: e.target.value })}
                />
            </div>

            <div>
                <TextField
                    required
                    id="outlined-number"
                    label="Price"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={formData.price}
                    onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                />
                <TextField
                    id="outlined-number"
                    label="Flat Discount"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={formData.flatDiscount}
                    onChange={(e) => setFormData({ ...formData, flatDiscount: e.target.value })}
                />
            </div>

            <div>
                <TextField
                    id="outlined-number"
                    label="Percent Discount"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={formData.percentDiscount}
                    onChange={(e) => setFormData({ ...formData, percentDiscount: e.target.value })}
                />
                <TextField
                    id="outlined-number"
                    label="Service Charge"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={formData.serviceCharge}
                    onChange={(e) => setFormData({ ...formData, serviceCharge: e.target.value })}
                />
            </div>

            <Stack justifyContent={'center'} spacing={2} direction="row">
                <Button onClick={handleSubmit} variant="contained">Submit</Button>
                <Button onClick={handleClear} variant="outlined">Clear</Button>
            </Stack>
        </Box>
    )
}
