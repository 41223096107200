import React from 'react'
import { useSnackbar } from "notistack"

/* @MUI COMPONENT */
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Grid from '@mui/material/Grid'
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

/* @CUSTOM COMPONENT */
import axios from "../../axios";
import UserPaymentdetails from "./UserPaymentdetails";

export default function UserPayment(props) {

    const [phoneOrEmail, setPhoneOrEmail] = React.useState('')
    const [token, setToken] = React.useState(localStorage.getItem("user"));
    const [isShowUserDetails, setIsShowUserDetails] = React.useState(false)
    const [userPaymentDetails, setUserPaymentDetails] = React.useState([])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    let data = {
        headers: {
          "x-auth-token": token,
        },
      };

      const handlecloseUserDetails = ()=>{
        setIsShowUserDetails(false)
      }

    const handleSearchUser = async () => {
        axios
            .get(`/admin/payments/searchByEmailOrPhone?PhoneOrEmail=${phoneOrEmail}`, data)
            .then((resp) => {
               if(resp.status == 200){
                setUserPaymentDetails(resp.data.result)
                setIsShowUserDetails(true)
               }
            })
            .catch((error) => {
                return enqueueSnackbar(`${error.response.data}`, { variant: 'error'});
            });
    }

    const searchByPhoneOrEmail = (
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <FormControl sx={{minWidth : '300px'}} variant="standard">
                <InputLabel htmlFor="input-with-icon-adornment">
                    search user by PhoneOrEmail
                </InputLabel>
                <Input
                    id="input-with-icon-adornment"
                    onChange={(e) => setPhoneOrEmail(e.target.value)}
                    startAdornment={
                        <InputAdornment position="start">
                            <AccountCircle />
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Box>
    )

    return (
        <>
        <Card sx={{ mt: 6, width: '100%' }}>
            <CardHeader title="search user" />
            <CardContent>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                    <Grid item>
                        {searchByPhoneOrEmail}
                        {phoneOrEmail.length > 0 && <Fab onClick={handleSearchUser} sx={{minWidth : '300px', mt: 3}} variant="extended">
                            <PersonSearchIcon />
                              Seacrh user
                        </Fab>}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
            {isShowUserDetails && userPaymentDetails &&
                <UserPaymentdetails
                    userPaymentDetails={userPaymentDetails}
                    isShowUserDetails={isShowUserDetails}
                    handlecloseUserDetails={handlecloseUserDetails}
                />
            }
        </>
    )
}
