/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {forwardRef} from "react";

export function QuickActions() {
  return (
      <>

      </>
  );
}
