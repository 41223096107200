import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from '@mui/icons-material/Delete';
import Label from '@mui/icons-material/Label';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InfoIcon from '@mui/icons-material/Info';
import ForumIcon from '@mui/icons-material/Forum';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

function StyledTreeItem(props) {
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        ...other
    } = props;

    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string,
};

export default function QuestionSideMenu({handleSelectMenu, handleCorrectAnswer, correctAnswer}) {

    return (
        <TreeView
            aria-label="gmail"
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultSelected="1"
            defaultEndIcon={<div style={{ width: 24 }} />}
            sx={{flexGrow: 1, maxWidth: 400 }}
        >
            <StyledTreeItem  onClick={()=>handleSelectMenu("question")} nodeId="1" labelText="Question" labelIcon={QuestionMarkIcon} />

            <StyledTreeItemRoot nodeId="2"
                onClick={()=>handleSelectMenu("optionA")}
                label={
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                        <Checkbox onClick={()=>handleCorrectAnswer('A')} checked={correctAnswer == 'A' ? true : false } /> &nbsp;
                        <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                            Option A
                        </Typography>
                    </Box>
                }
            />

            <StyledTreeItemRoot nodeId="3"
                onClick={()=>handleSelectMenu("optionB")}
                label={
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                         <Checkbox onClick={()=>handleCorrectAnswer('B')} checked={correctAnswer == 'B' ? true : false } /> &nbsp;
                        <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                            Option B
                        </Typography>
                    </Box>
                }
            />

            <StyledTreeItemRoot nodeId="4"
                onClick={()=>handleSelectMenu("optionC")}
                label={
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                         <Checkbox onClick={()=>handleCorrectAnswer('C')} checked={correctAnswer == 'C' ? true : false } /> &nbsp;
                        <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                            Option C
                        </Typography>
                    </Box>
                }
            />

            <StyledTreeItemRoot nodeId="5"
                onClick={()=>handleSelectMenu("optionD")}
                label={
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                         <Checkbox onClick={()=>handleCorrectAnswer('D')} checked={correctAnswer == 'D' ? true : false } /> &nbsp;
                        <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                            Option D
                        </Typography>
                    </Box>
                }
            />

            <StyledTreeItemRoot nodeId="6"
                onClick={()=>handleSelectMenu("optionE")}
                label={
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                        <Checkbox onClick={()=>handleCorrectAnswer('E')} checked={correctAnswer == 'E' ? true : false } /> &nbsp;
                        <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                            Option E
                        </Typography>
                    </Box>
                }
            />

            <StyledTreeItem  onClick={()=>handleSelectMenu("solution")} nodeId="7" labelText="Solution" labelIcon={EmojiObjectsIcon} />

        </TreeView>
    );
}
