import React, { useEffect, useState } from 'react'
import axios from "../../../axios"
import api from "../../../api"
import AddIcon from '@mui/icons-material/Add'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import FolderIcon from '@mui/icons-material/Folder'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import QuizIcon from '@mui/icons-material/Quiz';
import IconLabelListSkeleton from "../../../Components/IconLabelListSkeleton"
import EditDeleteMenu from "../../../Components/EditDeleteMenu"


import { useSnackbar } from "notistack"
import ExamAddUpdateDialog from '../ExamAddUpdateDialog'
import SimpleDeleteModal from '../../../Components/SimpleDeleteModal'

const TAG = "SubscriptionExamList"
const generateKey = (pre) => {
    return `${TAG}_${ pre }_${ new Date().getTime() }`
}

export default function SubscriptionQuizList(props) {
    const [exams, setExams] = useState([])
    const [loading, setLoading] = useState(false)

    const [dialogExamId, setDialogExamId] = useState('')
    const [openExamDialog, setOpenExamDialog] = useState(false)

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deleteModalExamId, setDeleteModalExamId] = useState('')

    const [refreshKey, setRefreshKey] = useState(0)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user")
        }
    }

    const refresh = () => {
        props.handleExamSelection('')
        setRefreshKey(prevKey => prevKey + 1)
    }

    const handleAddOrEditPressed = (examId) => {
        setDialogExamId(examId)
        setOpenExamDialog(true)
    }

    const handleDeletePressed = (examId) => {
        setDeleteModalExamId(examId)
        setOpenDeleteModal(true)
    }

    const handleExamSelection = (event) => {
        props.handleExamSelection(event.currentTarget.getAttribute('value'))
    }

    const handleSuccessAddQuiztoExam = (examId) => {
        const url = `${api.updateExam}classId=${props?.classId ? props?.classId : ''}&quizId=${examId}`
        axios
            .post(url, {}, config)
            .then(async (resp) => {
                if (resp.status === 200) {
                   // setTohome(true);
                   enqueueSnackbar("Add quiz to class successfull", { variant: 'success' })
                   refresh()
                }
            })
            .catch((error) => {
               // setError(error.response);
                enqueueSnackbar("" + error.response.data, { variant: 'error' })
            });
    }

    async function getSubscriptionExamsByClass(classId) {
        setLoading(true)
        axios
            .get(api.getExamExplorerCourseQuizExams+`${classId}`, config)
            .then((response) => {
               setLoading(false)
               if(response?.data?.quiz){
                setExams([response.data.quiz])
               }else{
                   setExams([])
               }
            })
            .catch((error) => {
                enqueueSnackbar("Exam fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function deleteExam(examId) {
        axios
            .delete(api.examExplorerDeleteExam + examId, config)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
                refresh()
            })
            .catch((error) => {
                enqueueSnackbar("Exam delete failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        getSubscriptionExamsByClass(props.classId)
    }, [props.classId, refreshKey])

    useEffect(() => {
        return () => {
            props.handleExamSelection('')
        }
    }, [])

    const examItem = (item) => {
        return (
            <ListItem
                key={item?._id}
                secondaryAction={
                    <EditDeleteMenu
                        value={item?._id}
                        editAction={handleAddOrEditPressed}
                        deleteAction={handleDeletePressed}
                        exportItem={true}
                    />
                }
                disablePadding
            >
                <ListItemButton
                    selected={props.selectedExam === item?._id}
                    value={item?._id}
                    onClick={handleExamSelection}
                >
                    <ListItemIcon>
                       <span style={{color: 'black', fontWeight : '700'}}>{item?.order}</span>&nbsp;<QuizIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={item?.title}
                    />
                </ListItemButton>
            </ListItem>
        )
    }

    const examListSkeleton = [1, 2, 3].map((value) => <IconLabelListSkeleton key={value} />)

    return (
        <Card sx={{ width: "20vw" }}>
            <CardHeader
                title="Exams"
                action={
                    !exams?.length && <Tooltip title="Add New Exam">
                        <IconButton aria-label="add" onClick={(_event) => handleAddOrEditPressed('')}>
                            <AddIcon color="primary" sx={{ width: 30, height: 30 }} />
                        </IconButton>
                    </Tooltip>
                    }
            />
            <CardContent>
                <List>
                    {loading ? examListSkeleton : exams?.map(examItem)}
                </List>
            </CardContent>

            <ExamAddUpdateDialog
                key={generateKey("exam-dialog")}
                courseId={props?.courseId}
                classId={props?.classId}
                examId={dialogExamId}
                examType='quiz'
                open={openExamDialog}
                handleClose={() => setOpenExamDialog(false)}
                handleSuccessAddQuiztoExam={(examId)=>handleSuccessAddQuiztoExam(examId)}
                handleSuccess={()=>refresh}
            />

            <SimpleDeleteModal
                title="Delete Subject"
                open={openDeleteModal}
                handleClose={() => {
                    setDeleteModalExamId('')
                    setOpenDeleteModal(false)
                }}
                handleDelete={() => deleteExam(deleteModalExamId)}
            />
        </Card>
    )
}
