import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "../../../axios";
import api from "../../../api";
import { useSnackbar } from "notistack";

export default function DoubtSolverList({
  selectedSolverId,
  handleChangeSolver,
}) {
  const [doubtSolvers, setDoubtSolvers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const config = {
    headers: {
      "x-auth-token": localStorage.getItem("user"),
    },
  };

  const handleChange = (event) => {
    handleChangeSolver(event.target.value);
  };

  const fetchDoubtSolvers = async () => {
    try {
      const response = await axios.get(api.getDoubtSolverList, config);
      setDoubtSolvers(response?.data);
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchDoubtSolvers();
  }, []);

  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="demo-simple-select-standard-label">
          Doubt Solver Name
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={selectedSolverId}
          onChange={handleChange}
          label="Doubt Solver Name"
        >
          {doubtSolvers?.map((item, index) => (
            <MenuItem key={index} value={item?._id}>
              {item?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
