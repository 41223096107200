/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import "../Css/root.css";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import TablePagination from "@mui/material/TablePagination";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../axios";
import api from "../api";
import DeleteModal from "../Components/DeleteModal";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import * as auth from "../../app/modules/Auth/_redux/authRedux";
function Subscription(props) {
	const [token, settoken] = useState(localStorage.getItem("user"));
	const [Datas, setData] = useState([]);
	const [row, setRow] = useState(10);
	const [page, setpage] = useState(0);
	const [Count, setCount] = useState();
	const [searchSub, setsearchSub] = useState("");
	const [modal, setModal] = React.useState({});
	const [showDelete, setShowDelete] = useState(false);
	const handleCloseDelete = () => setShowDelete(false);
	const handleShowDelete = () => setShowDelete(true);
	const [subscriptions, setsubscriptions] = useState([]);
	const [url, seturl] = useState(api.getSubscription);
	const [Datass, setDatass] = React.useState([]);
	const dispatch = useDispatch();
	var i = (page * row) + 1;
	const handleChangePage = (event, newPage) => {
		setpage(newPage);
		my_async_fn(row, newPage, url);
	};

	const handleChangeRowsPerPage = (event) => {
		setRow(parseInt(event.target.value));
		setpage(0);
		my_async_fn(event.target.value, 0, url);
	};
	let data = {
		headers: {
			"x-auth-token": token,
		},
	};
	async function goals() {
		settoken(localStorage.getItem("user"));
		const response = await axios.get(api.getgoalsubscription, data);
		setDatass(response.data);

	}


	async function my_async_fn(row, page, url) {
		axios
			.get(url + "pageNo=" + page + "&row=" + row, data)
			.then((resp) => {
				setData(resp.data.result.subscriptions);
				setCount(resp.data.count);
			})
			.catch((error) => {
				if (error.response.status == 401) {
					return dispatch(props.logout());
				} else {
					return error.response.status;
				}
			});
	}
	function handleSearch() {
		seturl(api.searchSubscription + "title=" + searchSub + "&");
		let url = api.searchSubscription + "title=" + searchSub + "&";
		my_async_fn(row, page, url);
	}
	useEffect(() => {
		my_async_fn(row, page, url);
		goals();
	}, []);

	return (
		<div>
			<div>
				<div className="card card-custom">
					<div className="card-header border-0 py-5 cent">
						<h3 className="card-title align-items-start flex-column">
							<span className="card-label font-weight-bolder text-dark">
								Subscription Search
							</span>
						</h3>
					</div>
					<div></div>
					<div className="card-body pt-0 pb-3 ">
						<div className="form-group row">
							<div className="col-lg-12">
								<form>
									<div className="col-lg-12 col-md-12 col-sm-12">
										<div
											className="form-group row"
											style={{ marginBottom: "0px !important" }}
										>
											<div className="col-md-3"></div>

											<div className="col-md-6">
												<Form.Control
													as="select"
													onChange={(e) => {
														const selected = e.target.value;
														my_async_fn(
															row,
															page,
															api.getgoalsub + selected + "&"
														);
													}}
												>
													<option value="" defaultChecked>
														Select Goal
													</option>
													{Datass.map((details) => (
														<option value={details._id}>{details.title}</option>
													))}
												</Form.Control>
											</div>

											<div className="col-md-2"></div>

											<div className="col-md-12 my-4 text-center"></div>
										</div>
									</div>
								</form>
							</div>
							<div className="col-lg-12">
								<form className="custom-form">
									<div className="input-group col-lg-6 col-md-6 col-sm-6 cent">
										<input
											type="text"
											id="search"
											value={searchSub}
											className="form-control"
											placeholder="Search By title"
											onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
											onChange={(e) => {
												setsearchSub(e.target.value);
											}}
										></input>
										<div className="input-group-append">
											<button
												className="btn btn-sm btn-success"
												type="button"
												onClick={handleSearch}
											>
												Search!
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<br></br>
				<br></br>
				{/* Search Ends */}
			</div>

			<DeleteModal
				detail={modal}
				url={api.deleteSubscription}
				showDelete={showDelete}
				handleCloseDelete={handleCloseDelete}
				reset={() => {
					my_async_fn(row, page, url);
				}}
			/>
			<div className={`card card-custom `}>
				<div className="card-header border-0 py-5">
					<h3 className="card-title align-items-start flex-column">
						<span className="card-label font-weight-bolder text-dark">
							Subscriptions
						</span>
					</h3>

					<div className="card-toolbar">
						<Link
							className="btn btn-success font-weight-bolder font-size-sm"
							to="/Create_subscriptions"
						>
							<span className="svg-icon svg-icon-md svg-icon-white">
								<SVG
									src={toAbsoluteUrl(
										"/media/svg/icons/Communication/Add-user.svg"
									)}
									className="h-50 align-self-center"
								></SVG>
							</span>
							Add New Subscriptions
						</Link>
					</div>
				</div>
				{/* end::Header */}

				{/* begin::Body */}
				<div className="card-body py-0">
					{/* begin::Table */}
					<div className="table-responsive">
						<table
							className="table table-head-custom table-vertical-center"
							id="kt_advance_table_widget_1"
						>
							<thead>
								<tr className="text-left">
									<th className="pl-0" style={{ width: "50px" }}>
										SERIAL
									</th>
									<th className="pl-0" style={{ width: "50px" }}></th>
									<th className="pr-0 " style={{ width: "250px" }}>
										SUBSCRIPTION TITLE
									</th>
									<th style={{ minWidth: "180px" }}>SUBSCRIPTION PLANS</th>
									<th
										className="pr-0 text-center"
										style={{ minWidth: "100px" }}
									>
										ACTION
									</th>
								</tr>
							</thead>

							{Datas.map((detail) => (
								<tbody key={detail._id}>
									<tr>
										<td className="pl-0">
											<span className="text-dark-75 font-weight-bolder d-block font-size-lg">
												{i++}
											</span>
										</td>
										<td className="pr-0">
											<div className="symbol symbol-50 symbol-lightv mt-1">
												<span className="symbol-label">
													<img
														src={
															`https://cdn.rootsedulive.com/` +
															detail.banner
														}
														width="50px"
														height="50px"
														alt=""
													/>
												</span>
											</div>
										</td>

										<td>
											<span className="text-dark-75 font-weight-bolder d-block font-size-lg">
												{detail.title}
											</span>
										</td>
										<td>
										</td>
										<td className="pr-0 text-center">
											<Link
												to={{
													pathname: "/Edit_subscriptions",
													state: detail,
												}}
												className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
											>
												<span className="svg-icon svg-icon-md svg-icon-warning">
													<SVG
														src={toAbsoluteUrl(
															"/media/svg/icons/Communication/Write.svg"
														)}
													></SVG>
												</span>
											</Link>
											<a
												href="#"
												onClick={() => setModal(detail) & setShowDelete(true)}
												className="btn btn-icon btn-light btn-hover-primary btn-sm"
											>
												<span className="svg-icon svg-icon-md svg-icon-danger">
													<SVG
														src={toAbsoluteUrl(
															"/media/svg/icons/General/Trash.svg"
														)}
													></SVG>
												</span>
											</a>
										</td>
									</tr>
								</tbody>
							))}
						</table>
						<div className="row align-items-center">
							<div className="col-md-8 col-sm-8">
								<div className="d-flex flex-wrp py-2 mr-3">
									<TablePagination
										component="div"
										count={Count}
										page={page}
										onPageChange={handleChangePage}
										rowsPerPage={row}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</div>
							</div>
							<br></br> <br></br>
							<br></br>
							<br></br>
						</div>
					</div>
					{/* end::Table */}
				</div>
			</div>
		</div>
	);
}
export default connect(null, auth.actions)(Subscription);
