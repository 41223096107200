import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import DoubtSolverList from "./component/DoubtSolverList";
import { Stack, Box } from "@mui/material";
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import moment from "moment";
import axios from "../../axios";
import api from "../../api";
import SolvedDoubtsTable from "./component/SolvedDoubtsTable";
import SimpleBackdrop from "../../Components/SimpleBackDrop";
import { useSnackbar } from "notistack";
import DoubtSolveDialog from "../DoubtExplorer/DoubtSolveDialog";
import ExplorerModeMenu from "../../Components/ExplorerModeMenu";
const ExplorerMode = {
  DoubtSolver: "DoubtSolver",
  DoubtUser: "DoubtUser",
}

const ExplorerModeMenuItem = {
  DoubtSolver: "Doubt Solver",
  DoubtUser: "Doubt User",
}

export default function DoubtHistory() {
  const [explorerMode, setExplorerMode] = useState(ExplorerMode.DoubtSolver)
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [doubts, setDoubts] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showUsersDoubt, setShowUsersDoubt] = useState(false)
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectDoubt, setSelectDoubt] = useState({ show: false, doubtId: "" });
  const [selectedSolverId, setSelectedSolverId] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const config = {
    headers: {
      "x-auth-token": localStorage.getItem("user"),
    },
  };

  const handleChangePage = (state) => {
    setPage(state);
  };

  const handleChangeRowsPerPage = (state) => {
    setRowsPerPage(state);
  };

  const handleOpenDoubtDialouge = (isShow, doubtId) => {
    setSelectDoubt({ show: isShow, doubtId: doubtId });
  };

  const handleModeChange = (mode) => {
    setSelectDoubt({ show: false, doubtId: '' })
    setPage(0)
    setRowsPerPage(10)
    setShowUsersDoubt(false)
    setDoubts([]);
    setCount(0);
    setSelectedSolverId('')
    setExplorerMode(ExplorerMode[mode])
  }

  async function fetchDoubtsByUserPhone() {
    if (phoneNumber.length !== 11) return enqueueSnackbar("Invalid phone number.", { variant: "warning" })
    setLoading(true);
    try {
      const response = await axios.get(api.getUserDoubtsByPhone + `phoneNumber=88${phoneNumber}&pageNo=${page}&row=${rowsPerPage}`, config);
      setDoubts(response?.data?.results);
      setCount(response?.data?.count);
      setLoading(false);
      setShowUsersDoubt(true)
    } catch (error) {
      enqueueSnackbar(error?.response?.data, { variant: "error" })
      setLoading(false);
      setDoubts([]);
      setCount(0);
    }
  }

  const fetchSolvedDoubts = async () => {
    try {
      setLoading(true);
      let url =
        api.getSolvedDoubts +
        `solver=${selectedSolverId}&pageNo=${page}&row=${rowsPerPage}`;
      if (fromDate) {
        url += `&fromDate=${moment(fromDate)
          .startOf("day")
          .utc()}`;
      }
      if (toDate) {
        url += `&toDate=${moment(toDate)
          .endOf("day")
          .utc()}`;
      }
      const response = await axios.get(url, config);
      setDoubts(response?.data?.results);
      setCount(response?.data?.count);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: "error" });
    }
  };

  useEffect(() => {
    if (!selectedSolverId) return;
    if (!fromDate && !toDate && selectedSolverId) fetchSolvedDoubts();
    if (fromDate && toDate && selectedSolverId) fetchSolvedDoubts();
  }, [selectedSolverId, fromDate, toDate, page, rowsPerPage]);

  useEffect(() => {
    if (phoneNumber.length === 11) fetchDoubtsByUserPhone()
  }, [page, rowsPerPage]);

  const doubtUser = (
    <Stack
      display={"flex"}
      direction="row"
      justifyContent={"center"}
      spacing={4}
    >
      <Box sx={{ maxWidth: "400px" }}>
        <TextField
          value={phoneNumber}
          onChange={(event) => setPhoneNumber(event.target.value)}
          id="outlined-basic"
          placeholder="01xxx"
          label="Enter Phone Number"
          variant="outlined"
          sx={{ minWidth: 300 }}
        />
      </Box>
      <Button onClick={fetchDoubtsByUserPhone} variant="contained">Search</Button>
    </Stack>
  )

  const doubtSolver = (
    <Stack
      display={"flex"}
      direction="row"
      justifyContent={"center"}
      spacing={4}
    >
      <Box sx={{ maxWidth: "400px" }}>
        <DoubtSolverList
          selectedSolverId={selectedSolverId}
          handleChangeSolver={(solverId) => setSelectedSolverId(solverId)}
        />
      </Box>
      <Box sx={{ maxWidth: "400px" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <DesktopDatePicker
              label="From Date"
              inputFormat="MM/dd/yyyy"
              value={fromDate}
              onChange={(newValue) => setFromDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      </Box>

      <Box sx={{ maxWidth: "400px" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <DesktopDatePicker
              label="To Date"
              inputFormat="MM/dd/yyyy"
              value={toDate}
              onChange={(newValue) => setToDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      </Box>
    </Stack>
  )

  const filterBox = (
    <Card>
      <CardHeader
        title="Filter Doubt"
        action={
          <ExplorerModeMenu
            currentMode={explorerMode}
            explorerModes={ExplorerModeMenuItem}
            handleModeSelection={handleModeChange}
          />
        }
      />
      <CardContent>
        {explorerMode === "DoubtSolver" && doubtSolver}
        {explorerMode === "DoubtUser" && doubtUser}
      </CardContent>
    </Card>
  )

  const solvedDoubtsList = (
    <Card sx={{ p: { xs: 2, md: 4 }, m: 2, width: "100%" }}>
      <CardHeader title={"Doubts"} />
      <CardContent>
        <SolvedDoubtsTable
          page={page}
          rowsPerPage={rowsPerPage}
          count={count}
          handleChangePage={(state) => handleChangePage(state)}
          handleChangeRowsPerPage={(state) => handleChangeRowsPerPage(state)}
          doubts={doubts}
          handleOpenDoubtDialouge={(isShow, doubtId) =>
            handleOpenDoubtDialouge(isShow, doubtId)
          }
        />
      </CardContent>
    </Card>
  );

  return (
    <React.Fragment>
      {filterBox}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        {selectDoubt.show && (
          <DoubtSolveDialog
            open={selectDoubt.show}
            doubtId={selectDoubt.doubtId}
            handleCloseDialouge={() =>
              setSelectDoubt({ show: false, doubtId: "" })
            }
          />
        )}
        {loading && <SimpleBackdrop />}
        {selectedSolverId && !loading && solvedDoubtsList}
        {showUsersDoubt && solvedDoubtsList}
      </Grid>
    </React.Fragment>
  );
}
