import React from "react";
import "../../Css/root.css";
import { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "../../axios";
import api from "../../api";
import Form from "react-bootstrap/Form";
import { actions } from "../../Components/Toaster/ToastReducer";
import { useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Rolenames = [
    'superAdmin',
    'admin',
    'contentAdmin',
    'questionAdmin',
    'smsAdmin',
    'programDirector',
    'student',
    'educator',
    'brandPartner',
    'doubtSolver',
    'orderAdmin'
];

function getStyles(name, roleName, theme) {
    return {
        fontWeight:
            roleName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


export default function SmsRoleWise() {

    const dispatch = useDispatch();
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const [tohome, setTohome] = React.useState(false);
    const [message, setMessage] = useState("");
    const [maskID, setMaskID] = useState("");
    const theme = useTheme();
    const [roleName, setRoleName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setRoleName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    let data = {
        headers: {
            "x-auth-token": token,
        },
    };

    if (tohome === true) {
        return <Redirect to="/sms" />;
    }

    const handleSubmit = () => {
        if (roleName.length <= 0) return actions.Danger('Please choose at least one role');
        if (message.length <= 0) return actions.Danger('Message not allowed to be empty');
        if (!maskID) return actions.Danger('Select a mask ID');
        // const url = `${api.SmsSendSingleUser}sId=${maskID}&parentSms=${isActive ? 1 : 0 }&userId=${user._id}`
        const body = {
            message: message,
            sId: maskID,
            roles: [...roleName]
        }
        axios
            .post(api.smsSendRoleWise, body, data)
            .then(async (resp) => {
                const {
                    status,
                } = resp;
                if (status === 200) {
                    dispatch(actions.Success("Sms send successfully"));
                    setTohome(true);
                }
            })
            .catch((error) => {
                dispatch(actions.Danger("Something went wrong"));
            });
    }

    return (
        <div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-header">
                                    <h3 className="card-title"> SEND SMS</h3>
                                </div>
                                <form className="form">
                                    <input type="hidden" />
                                    <div className="card-body">

                                        <div className="form-group row">
                                            <FormControl sx={{ m: 1, width: '50%' }}>
                                                <InputLabel id="demo-multiple-chip-label">Role</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-chip-label"
                                                    id="demo-multiple-chip"
                                                    multiple
                                                    value={roleName}
                                                    onChange={handleChange}
                                                    input={<OutlinedInput id="select-multiple-chip" label="Role" />}
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                            {selected.map((value) => (
                                                                <Chip key={value} label={value} />
                                                            ))}
                                                        </Box>
                                                    )}
                                                    MenuProps={MenuProps}
                                                >
                                                    {Rolenames.map((name) => (
                                                        <MenuItem
                                                            key={name}
                                                            value={name}
                                                            style={getStyles(name, roleName, theme)}
                                                        >
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Masking
                                            </label>
                                            <div className="col-lg-4">
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => {
                                                        setMaskID(e.target.value)
                                                    }}
                                                >
                                                    <option value="" defaultChecked>
                                                        Masking Type
                                                    </option>
                                                    <option value="sIdNonMasking">
                                                        sIdNonMasking
                                                    </option>
                                                    <option value="sIdMasking">
                                                        sIdMasking
                                                    </option>

                                                </Form.Control>
                                            </div>
                                        </div>


                                        <div className="form-group row">

                                            <div className="col-lg-6">
                                                <div className="form-group green-border-focus">
                                                    <label style={{ fontSize: '12px' }} for="exampleFormControlTextarea5">write message</label>
                                                    <textarea onChange={(e) => setMessage(e.target.value)} className="form-control" id="exampleFormControlTextarea5" rows="5"></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">

                                            <div className="col-lg-6">
                                                <div className="form-group green-border-focus">
                                                    <Button onClick={handleSubmit} variant="contained" endIcon={<SendIcon />}>
                                                        Send Sms
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
