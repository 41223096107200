import React, { useState } from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FullScreenDialog from "./FullScreenDialog";
export default function McqPDFDownloadLink({ examId, handleCloseMenu }) {
    const [open, setOpen] = useState(false);
    return (<div>
        <div onClick={() => setOpen(true)}>
        <ExitToAppIcon /> &nbsp; Export To Pdf
        </div>
        {open &&
            <FullScreenDialog
                examId={examId}
                open={open}
                handleCloseDialog={(state) => {
                    setOpen(state)
                    handleCloseMenu()
                }}
            />}
    </div>)
}