import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box'
export default function QuestionsLoading() {
    return (
        <Stack sx={{ p: 2, width: 'md' }} spacing={3}>
            <Skeleton width={500} variant="text" sx={{ fontSize: '1.5rem', width: '100%' }} />
            <Divider />
            <Box sx={{ mt: 2 }}>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item =>
                    <div style={{ marginTop: '20px' }} key={item}>
                        <Skeleton width={'90%'} variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton width={'20%'} variant="text" sx={{ fontSize: '.8rem' }} />
                        <Skeleton width={'20%'} variant="text" sx={{ fontSize: '.8rem' }} />
                        <Skeleton width={'20%'} variant="text" sx={{ fontSize: '.8rem' }} />
                        <Skeleton width={'20%'} variant="text" sx={{ fontSize: '.8rem' }} />
                    </div>
                )}
            </Box>
        </Stack>
    )
}
