import React, { useState } from 'react'
import * as XLSX from 'xlsx';
import { useSnackbar } from "notistack"
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import Button from "@mui/material/Button"
import { styled } from '@mui/material/styles'
import { green } from "@mui/material/colors"
const Input = styled('input')({
    display: 'none',
})

export default function ExcelToUploadPhone({handleMergeUser, message}) {
    const [users, setUsers] = useState([])
    const [fileInfo, setFileInfo] = useState({ fileName: '', fileSize: '', totalUser: 0 })

    const { enqueueSnackbar } = useSnackbar()

    //replace function
    function replaceAll(str, find, replace) {
        var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return str.replace(new RegExp(escapedFind, 'g'), replace);
    }

    function dynamicMessage(cols, data, e) {
  
        if(!cols.includes('Phone')) return enqueueSnackbar("Phone Column not found", {variant :"warning"})
        //process columns
        for(var index in cols){
            cols[index] = `[${cols[index]}]`
            if(cols[index] !== '[Phone]'){
                if(!message.includes(cols[index])){
                    return enqueueSnackbar(""+cols[index]+" column is not matched", {variant :"warning"})
                }
            }
        }
        var userSms = []

        //generate user message
        for (var index in data) {
            var singleMessage = message
            for (var index2 in cols) {
                var obj = cols[index2].slice(1, cols[index2].length - 1)
                singleMessage = replaceAll(singleMessage, cols[index2], data[index][obj]);
            }
            userSms.push({ phone: data[index].Phone, text: singleMessage })
        }
        setUsers(userSms)
        handleMergeUser(userSms)
    }

    const onUploadExcel = (e) => {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var fileName = e.target.files[0]?.name
        var fileSize = e.target.files[0]?.size
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 0 });
            const dataParseCol = XLSX.utils.sheet_to_json(ws, { header: 1 });
            dynamicMessage(dataParseCol[0], dataParse, e)
            setFileInfo({ fileName: fileName, totalUser: dataParse.length, fileSize: fileSize })
        };
        reader.readAsBinaryString(f)
    }


    const fileInfoBox = (
        <Box component={Paper} elevation={2} sx={{ p: 1 }}>
            <Typography fontWeight={650} color={green[300]} variant='subtitle1' gutterBottom align='center'>
                File Name : {fileInfo.fileName}
            </Typography>
            <Typography fontWeight={650} color={green[300]} variant='subtitle1' gutterBottom align='center'>
                File Size  : {(fileInfo.fileSize / 1024).toFixed(2)} kb
            </Typography>
            <Typography fontWeight={650} color={green[300]} variant='subtitle1' gutterBottom align='center'>
                Total users  : {fileInfo.totalUser}
            </Typography>
        </Box>
    )

    const userFileUpload = (
        <Card sx={{ width: '100%', p : 1}}>
            <Typography variant='h6' gutterBottom align='center'>Required column : [Phone] </Typography>
            {
                users.length > 0 ?
                    fileInfoBox : <Skeleton variant="rectangular" width={'100%'} height={218} />
            }
            <CardActions disableSpacing>
                <label htmlFor="subject-banner">
                    <Input value="" onChange={onUploadExcel} accept="*" id="subject-banner" type="file" />
                    <Button component="span" size="small">Upload Excel</Button>
                </label>
            </CardActions>
        </Card>
    )
    return (
        <React.Fragment>
            <Box sx={{mb: 4, mt : 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} component={Paper} elevation={4}>
              {userFileUpload}
            </Box>
        </React.Fragment>
    )
}
