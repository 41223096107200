import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import {DesktopDatePicker} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSnackbar } from "notistack"

import MultiSelectorChip from "../../../Components/MultiSelectorChip"
import axios from "../../../axios"
import api from "../../../api"

export default function Create(props) {

  const [formData, setFormData] = useState({ name: '', isActive: true, expiration: null, hosts: [], type: props.type === 'studio'? true : false }) // type false => default select teachStack-remote
  const [educators, setEducators] = useState([])
  const [selectedEducators, setSelectedEducators] = useState([])
  const [educatorLoading, setEducatorLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState({})
  const { enqueueSnackbar } = useSnackbar()

  const educatorData = {
    title: "Educator",
    items: educators,
    index: "name",
    selected: selectedEducators
  }

  const config = {
    headers: {
      "x-auth-token": localStorage.getItem("user"),
    }
  }

  const handleEducatorSelection = (value) => {
    setSelectedEducators(value)
    setFormData({ ...formData, hosts: value })
  }

  const handleSubmit = async () => {

    if (!formData.name) return enqueueSnackbar("Name is required", { variant: 'error' })
    if (!formData.expiration) return enqueueSnackbar("Expiration date is required", { variant: 'error' })
    if(!formData.type){
      if(formData.hosts.length <= 0) return enqueueSnackbar("Host is not allowed to be empty", { variant: 'error' })
    }
    var body = {}
    if (formData.name) {
      body.name = formData.name
      body.expiration = formData.expiration
    }
    if (formData.isActive) {
      body.isActive = formData.isActive
    }
    if (formData.type) {
      body.type = "teachStack-studio";
    } else {
      body.type = "teachStack-remote";
      body.hosts = formData.hosts
    }
   
    axios
      .post('/admin/liveClass-room', { ...body }, config)
      .then((response) => {
        enqueueSnackbar("" + response.data, { variant: 'success' })
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data, { variant: 'error' })
      })
  }

  const handleClear = () => {
    setFormData({ name: '', hosts: [], isActive: null, expiration: null })
    setEducators([])
  }

  const educatorSelector = (
    <MultiSelectorChip
      required
      error={!!errorMessage.educator}
      helperText={errorMessage.educator}
      data={educatorData}
      handleSelection={handleEducatorSelection}
      sx={{ width: '100%' }}
    />
  )

  React.useEffect(() => {
    fetchEducators()
  }, [])

  async function fetchEducators() {
    setEducatorLoading(true)
    axios
      .get(api.getCourseExplorerEducators, config)
      .then((response) => {
        setEducators(response.data)
        setEducatorLoading(false)
      })
      .catch((error) => {
        enqueueSnackbar("Educator fetch failed: " + error.response.data, { variant: 'error' })
      })
  }

  return (
    <Box
      component={Paper}
      elevation={2}
      sx={{
        p: { md: 12, xs: 2 },
        display: "flex",
        justifyContent: "center",
      }}
      noValidate
      autoComplete="off"
    >
      <Box maxWidth="650px">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          component={Paper}
          variant="outlined"
          spacing={2}
          sx={{ p: { md: 4, xs: 2 } }}
        >
          <Grid item md={12} xs={12}>
            <TextField
              sx={{ width: "100%" }}
              required
              id="outlined-required"
              label="Name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
          </Grid>

          {!formData.type && <Grid item md={6} xs={12}>
            {educatorSelector}
          </Grid>}

          <Grid item md={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Expiry Date"
                inputFormat="MM/dd/yyyy"
                value={formData.expiration}
                onChange={(newValue) =>
                  setFormData({ ...formData, expiration: newValue })
                }
                renderInput={(params) => (
                  <TextField sx={{ width: "100%" }} {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item md={12} xs={12}>
            <FormControl sx={{mr: 1}}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ width: "40px", height: "40px" }}
                    checked={formData.isActive}
                    onChange={() =>
                      setFormData({ ...formData, isActive: !formData.isActive })
                    }
                    name="isActive"
                  />
                }
                label="Active"
              />
            </FormControl>
            <FormControl sx={{ml: 1}}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ width: "40px", height: "40px" }}
                    checked={formData.type}
                    onChange={() =>
                      setFormData({ ...formData, type: !formData.type })
                    }
                    name="type"
                  />
                }
                label="Teach Stack Studio"
              />
            </FormControl>
          </Grid>

          <Grid item md={12} xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 2 }}
              spacing={2}
            >
              <Button
                onClick={handleSubmit}
                sx={{ width: "150px", m: 1 }}
                variant="contained"
              >
                Submit
              </Button>
              <Button
                onClick={handleClear}
                sx={{ width: "150px", m: 1 }}
                variant="outlined"
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
