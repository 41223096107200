import React from 'react'
import { useSnackbar } from "notistack"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CourseMultiSelect from "../../../Components/Filters/CourseMultiSelect/MultiSelect"
import ExcelUploadCoupon from "./ExcelUploadCoupon"
import axios from "../../../axios"
import api from "../../../api"

export default function CourseAddToCoupon() {
    const [formData, setFormData] = React.useState(
        {
            coupons: [],
            catalogCourses: [],
            percentDiscount: 0,
            flatDiscount: 0,
            expiredDate: new Date(),
            isActive: true
        })
    const { enqueueSnackbar } = useSnackbar()
    const config = { headers: { "x-auth-token": localStorage.getItem("user") } }

    const handleMergeCoupon = (coupons) => { setFormData({ ...formData, coupons: coupons }) }
    const handleChangecatalogCourses = (catalogCourses) => { setFormData({ ...formData, catalogCourses: catalogCourses }) }
    
    const handleSubmit = async () => {
        if (formData.coupons.length == undefined || formData.coupons?.length <= 0) return enqueueSnackbar("Coupon is not allowed to be empty", { variant: "warning" })
        if (formData.catalogCourses.length == undefined || formData.course?.length <= 0) return enqueueSnackbar("Course is not allowed to be empty", { variant: "warning" })
        const subs_Ids = formData.catalogCourses.map(function (item) { return item._id })
        try {
            const response = await axios.put(api.CatalogCourseAddToCoupon, { catalogCourses: subs_Ids, coupons: formData.coupons }, config)
            enqueueSnackbar(response.data, { variant: "success" })
            setFormData({
                coupons: [],
                catalogCourses: []
            })
        } catch (error) {
            enqueueSnackbar("" + error.response.data, { variant: "error" })
        }
    }

    const form = (
        <Paper variant="outlined" sx={{ m: 2, p: 4, width: { md: 600, xs: 300 } }} square>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                rowSpacing={4}
                columnSpacing={4}
            >
                <Grid item xs={12} md={12}>
                    <CourseMultiSelect handleChangecatalogCourses={(data) => handleChangecatalogCourses(data)} />
                </Grid>

                <Grid item md={12}>
                    <ExcelUploadCoupon handleMergeCoupon={(coupons) => handleMergeCoupon(coupons)} />
                </Grid>

                <Grid sx={{ display: 'flex', justifyContent: 'center', width: '100%' }} item md={12}>
                    <Button onClick={handleSubmit} sx={{ width: '95%', color: 'white', textTransform: 'none' }} variant="contained">Submit</Button>
                </Grid>

            </Grid>
        </Paper>
    )

    return (
        <Box elevation={2} component={Paper} sx={{ p: 2 }}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Typography sx={{ color: '#0000009e' }} fontWeight={800} variant='h5' gutterBottom align='center'>Catalog Course add to coupon</Typography>
                {form}
            </Grid>
        </Box>
    )
}
