import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import OrderMenu from "./component/OrderMenu";
import SubscriptionCreate from "./Subscription/SubscriptionCreate";
import Subscriptionlimit from "./Subscription/Subscriptionlimit";
import CatalogCourseCreate from "./CatalogCourse/CatalogCourseCreate";
import CatalogCourselimit from "./CatalogCourse/CatalogCourselimit";

const pages = [
  "create-subscription",
  "increase-subscription",
  "create-course",
  "increase-course",
];
export default function BulkOrder() {
  const [state, setState] = useState(pages[0]);
  return (
    <Grid
      container
      spacing={3}
      alignItems={"flex-start"}
      justifyContent={"space-between"}
    >
      <Grid item md={3}>
        <OrderMenu
          selectedItem={state}
          pages={pages}
          handleChange={(pageName) => setState(pageName)}
        />
      </Grid>
      <Grid item md={9}>
        {state === pages[0] && <SubscriptionCreate />}
        {state === pages[1] && <Subscriptionlimit />}
        {state === pages[2] && <CatalogCourseCreate />}
        {state === pages[3] && <CatalogCourselimit />}
      </Grid>
    </Grid>
  );
}
