import React, { useState } from 'react'
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import Stack from "@mui/material/Stack"
import Card from "@mui/material/Card"
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import axios from '../../../axios';
import BasicCircularProgress from '../../../Components/BasicCircularProgress';

import ExcelToUploadPhone from '../../../Components/ExcelUploads/ExcelToUploadPhone';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

export default function ExcelUploadUsersLogout() {
    const [loading, setLoading] = useState(false)
    const [removeRole, setRemoveRole] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([]);
    const history = useHistory()

    const { enqueueSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        },
    };

    const handleChange = (event) => {
        setRemoveRole(event.target.checked);
    };

    const handleClear = () => {
        setRemoveRole(false)
        setSelectedUsers([])
    }

    const handleSubmit = async () => {
        if (selectedUsers.length <= 0) return enqueueSnackbar("Empty users list", { variant: "warning" })

        let body = {
            users: selectedUsers
        }
        if (removeRole) {
            body.removeRole = removeRole
        }
        try {
            setLoading(true)
            const response = await axios.post('/admin/users/bulk-user-logout-by-phone', body, config)
            enqueueSnackbar(response?.data, { variant: "success" })
            setSelectedUsers([])
            setRemoveRole(false);
            setLoading(false)
            history.push('/users')
        } catch (error) {
            enqueueSnackbar(error?.response?.data, { variant: "error" })
            setLoading(false)
        }
    }

    return (
        <Card>
            {loading && <BasicCircularProgress />}
            <CardHeader
                title={"Bulk User Logout by Phone"}
            />
            <CardContent>
                <Stack width={"100%"} justifyContent={'center'} alignItems={'center'} sx={{ p: 2 }} spacing={4}>

                    <Paper variant='outlined' sx={{ p: 2 }}>
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            minWidth={450}
                        >
                            <Stack direction={'column'} justifyContent={'flex-start'} alignItems={'center'} spacing={3}>
                                <ExcelToUploadPhone
                                    handleMergeUser={(users) => setSelectedUsers(users)}
                                />
                                <FormGroup>
                                    <FormControlLabel
                                        sx={{ maxWidth: '200px' }}
                                        control={<Checkbox checked={removeRole} onChange={handleChange} />}
                                        label="Remove Role"
                                    />
                                </FormGroup>
                            </Stack>
                            <Stack spacing={3} justifyContent={'center'} sx={{ mt: 2 }} alignItems={'center'} direction={'row'}>
                                <Button onClick={handleSubmit} variant="contained">Submit</Button>
                                <Button onClick={handleClear} variant="outlined">Clear</Button>
                            </Stack>
                        </Box>
                    </Paper>
                </Stack>
            </CardContent>
        </Card>
    )
}
