import React , {useRef} from "react";
import "../Css/root.css";
import { useState, useEffect, useReducer } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Link, Redirect } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardActions from '@mui/material/CardActions'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import { styled } from '@mui/material/styles'
import { useSnackbar } from "notistack"

import axios from "../axios";
import api from "../api";
import { useDispatch } from "react-redux";
import {actions} from "../Components/Toaster/ToastReducer";

export default function CreateSubscription() {

	const dispatch = useDispatch();
	const [courses, setCourses] = useState([]);
	const [Datas, setDatas] = useState([]);
	const [name, setName] = React.useState(" ");
	const [urlSlug, seturlSlug] = React.useState("");
	const [Error, setError] = React.useState("");
	const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
	const [token, settoken] = React.useState(localStorage.getItem("user"));
	const [tohome, setTohome] = React.useState(false);
	const [isActive, setisActive] = useState(true);
	const [isFree, setisFree] = useState(false);
	const [Order, setOrder] = useState();
	const [introVideo , setIntroVideo] = useState()
	const [isTrialEnabled, setIsTrialEnabled] = useState(false)
	const [trialPeriod, setTrialPeriod] = useState(0)
	//  Social Link State 
	const [facebookLink, setFacebookLink] = useState('')
	const [youtubeLink, setYoutubeLink] = useState('')
	const [whatsappLink, setWhatsappLink] = useState('')
	const [telegramLink, setTelegramLink] = useState('')

	const CoursesArray = [];

	const [bannerFile, setBannerFile] = useState('')
	const [banner, setBanner] = useState('')
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const description = useRef(null);

	const Input = styled('input')({
		display: 'none',
	});

	let data = {
		headers: {
			"x-auth-token": token,
		},
	};

	const bannerUploadconfig = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
            "Content-Type" : "multipart/form-data"
        }
    }

	async function my_async_fn() {
		const response = await axios.get(api.getCoursesSubs, data);
		setDatas(response.data);
	}
	const options = [];
	function sortData() {
		Datas.map((data) => options.push({ label: data.title, value: data._id }));
	}
	useEffect(() => {
		my_async_fn();
		sortData();
	}, []);
	sortData();
	function modifyData() {
		courses.map((data) => CoursesArray.push(data.value));
	}

	
	if (tohome === true) {
		return <Redirect to="/subscriptions" />;
	}

	async function uploadSubscriptionBanner(subscriptionID) {
        if(!bannerFile) return
        let formData = new FormData()
        formData.append("banner", bannerFile)

        axios.post(api.uploadSubscriptionBanner + subscriptionID, formData, bannerUploadconfig)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
            })
            .catch((error) => {
                enqueueSnackbar("Upload Subscription banner failed: " + error.response.data, { variant: 'error' })
            })
    }

	function handleSubmit() {
        var body = {
			title: name,
			courses: CoursesArray,
			isFree: isFree,
			isActive: isActive,
			order: Order,
			description: description.current.getContent(),
			urlSlug : urlSlug,
			social: [],
			freeTrial: {
				isEnabled: isTrialEnabled,
				duration: trialPeriod
			}
		};
		if(introVideo){
			if(introVideo.includes("watch?v")) return dispatch(actions.Danger("Please Enter Valid Youtube Video"))
			body = {...body, introVideo:introVideo}
		}
		if(facebookLink){
			body.social.push({type: 'facebook', url: facebookLink})
		}
		if(youtubeLink){
			body.social.push({type: 'youtube', url: youtubeLink})
		}
		if(whatsappLink){
			body.social.push({type: 'whatsapp', url: whatsappLink})
		}
		if(telegramLink){
			body.social.push({type: 'telegram', url: telegramLink})
		}
		modifyData();
		axios
			.post(api.createSubscription, body, data)
			.then(async (resp) => {
				if (resp.status === 200) {
					enqueueSnackbar("Subscription created successfully", { variant: 'success' })
					uploadSubscriptionBanner(resp.data._id)
					setTohome(true)
				}
			})
			.catch((error) => {
				dispatch(actions.Danger("Something went wrong"));
				setError(error.response.data);
			});
	}

	const onUploadBanner = ({ target }) => {
        if(target.files[0]) {
            setBannerFile(target.files[0])
        }
    }

	const filePickerCallBack = (editor, cb) => {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {

                var id = 'blobid' + (new Date()).getTime();
                var blobCache = editor.editorUpload.blobCache;
                var base64 = reader.result.split(',')[1];
                var blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                /* call the callback and populate the Title field with the file name */
                cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
        };

        input.click();
    }
	
	const bannerUploadCard = (
        <Card sx={{ width: 200 }}>
            {
                bannerFile ?
                    <CardMedia
                        component="img"
                        height="118"
                        image={URL.createObjectURL(bannerFile)}
                    /> : (
                        banner ?
                            <CardMedia
                                component="img"
                                height="118"
                                image={"https://cdn.rootsedulive.com/" + banner}
                            /> : <Skeleton variant="rectangular" width={200} height={118} />
                    )
            }
            <CardActions disableSpacing>
                <label htmlFor="subscription-banner">
                    <Input onChange={onUploadBanner} accept="image/*" id="subscription-banner" type="file" />
                    <Button component="span" size="small">Upload Banner</Button>
                </label>
            </CardActions>
        </Card>
    )

	return (
		<div>
			<div className="d-flex flex-column-fluid">
				<div className=" container ">
					<div className="row">
						<div className="col-md-12">
							<div className="card card-custom">
								<div className="card-header">
									<h3 className="card-title"> Create Subscription</h3>
									<div className="card-toolbar">
										<div className="example-tools justify-content-center">
											<Link
												className="btn btn-success font-weight-bolder font-size-sm"
												to="/subscriptions"
											>
												<i className="flaticon2-list"></i>
												All Subscriptions
											</Link>
										</div>
									</div>
								</div>
								<form className="form">
									<input type="hidden" />
									<div className="card-body">

										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Name:
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													placeholder="Enter Name"
													onChange={(e) => {
														setName(e.target.value);
													}}
												/>
											</div>


											<label className="col-lg-2 col-form-label text-left">
												Url Slug :
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													placeholder="Enter url slug"
													onChange={(e) => {
														seturlSlug(e.target.value)
													}}
												/>
											</div>

										</div>

										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Intro Video:
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													placeholder="Enter Youtube Video"
													onChange={(e) => {
														setIntroVideo(e.target.value);
													}}
												/>
											</div>
										</div>

										<div className="form-group row">
											<div className="col-6">

												<label className="col col-form-label text-left">
													Enter Subscription Details :
												</label>

												<div className="col-lg-3">
													<Editor
														tinymceScriptSrc="https://cdn.rootsedulive.com/scripts/js/tinymce/tinymce.min.js"
														onInit={(evt, editor) => (description.current = editor)}
														initialValue="<p></p>"
														init={{
															height: 350,
															width: 300,
															menubar: false,
															automatic_uploads: false,
															file_picker_types: 'image',
															file_picker_callback: (cb, _value, _meta) => filePickerCallBack(description.current, cb),
															plugins: [
																"advlist autolink lists link image charmap print preview anchor",
																"searchreplace visualblocks code fullscreen",
																"insertdatetime media table paste code help wordcount",
															],
															external_plugins: {
																tiny_mce_wiris:
																	"https://cdn.jsdelivr.net/npm/@wiris/mathtype-tinymce4@7.24.0/plugin.min.js",
															},
															toolbar:
																"tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image",
															content_style:
																"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
														}}
														scriptLoading={{ async: true }}
													/>
												</div>
											</div>


										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Select Course
											</label>
											<div className="col-lg-3">
												<MultiSelect
													options={options}
													value={courses}
													onChange={setCourses}
													labelledBy="Select"
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Is Active:
											</label>
											<div className="col-lg-3">
												<span className="switch switch-icon switch-success ">
													<label>
														<input
															type="checkbox"
															checked={isActive}
															onChange={(e) => setisActive(e.target.checked)}
														/>

														<span></span>
													</label>
												</span>
											</div>
											<label className="col-lg-2 col-form-label text-left">
												Is Free:
											</label>
											<div className="col-lg-3">
												<span className="switch switch-icon switch-success ">
													<label>
														<input
															type="checkbox"
															checked={isFree}
															onChange={(e) => setisFree(e.target.checked)}
														/>

														<span></span>
													</label>
												</span>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Enable Free Trial:
											</label>
											<div className="col-lg-3">
												<span className="switch switch-icon switch-success ">
													<label>
														<input
															type="checkbox"
															checked={isTrialEnabled}
															onChange={(e) => setIsTrialEnabled(e.target.checked)}
														/>

														<span></span>
													</label>
												</span>
											</div>
											<label className="col-lg-2 col-form-label text-left">
												Trial Period(In days):
											</label>
											<div className="col-lg-3">
												<input
													type="number"
													className="form-control "
													placeholder="Enter number of days"
													value={trialPeriod}
													onChange={(e) => setTrialPeriod(e.target.value)}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Order :
											</label>
											<div className="col-lg-3">
												<input
													type="number"
													className="form-control "
													placeholder="Enter Order"
													onChange={(e) => setOrder(e.target.value)}
												/>
											</div>
										</div>

										<div className="form-group row">
											<Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
												{bannerUploadCard}
											</Stack>
										</div>

										<div className="form-group row">
											<p style={{ color: "red", fontSize: "22px" }}>{Error}</p>
											<label
												n
												className="col-lg-2 col-form-label text-left"
												style={{ color: "red", fontSize: "22px" }}
											></label>
											<div className="col-lg-3" hidden>
												<span className="switch switch-icon switch-success ">
													<label>
														<input type="hidden" value="0" name="is_active" />
														<input type="checkbox" value="1" name="is_active" />

														<span></span>
													</label>
												</span>
											</div>
										</div>
										{/* Social Link Input */}
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Facebook:
											</label>
											<div className="col-lg-8">
												<input
													type="text"
													className="form-control"
													value={facebookLink}
													placeholder="Enter Facebook link.."
													onChange={(e) => {
														setFacebookLink(e.target.value);
													}}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Youtube:
											</label>
											<div className="col-lg-8">
												<input
													type="text"
													className="form-control"
													value={youtubeLink}
													placeholder="Enter Youtube link.."
													onChange={(e) => {
														setYoutubeLink(e.target.value);
													}}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Whatsapp:
											</label>
											<div className="col-lg-8">
												<input
													type="text"
													className="form-control"
													value={whatsappLink}
													placeholder="Enter Whatsapp link.."
													onChange={(e) => {
														setWhatsappLink(e.target.value);
													}}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												TeleGram:
											</label>
											<div className="col-lg-8">
												<input
													type="text"
													value={telegramLink}
													className="form-control "
													placeholder="Enter TeleGram link.."
													onChange={(e) => {
														setTelegramLink(e.target.value);
													}}
												/>
											</div>
										</div>
										<div className="form-group row"></div>
									</div>
									<div className="card-footer">
										<div className="row">
											<div className="col-lg-2"></div>
											<div className="col-lg-10">
												<button
													type="button"
													className="btn btn-success mr-2"
													onClick={handleSubmit}
												>
													Submit
												</button>
												<button type="reset" className="btn btn-secondary">
													Reset
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
