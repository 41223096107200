import * as React from 'react';
import PropTypes from 'prop-types';

/* @MUI COMPONENT */
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from "notistack"

import { Link, Redirect } from "react-router-dom";

import moment from "moment"
import axios from "../../axios";
import api from "../../api";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function OrderDeleteModal({ showDeleteModal, setShowDeleteModal, selectedUser , reset}) {

    const [checkedSubscriptionID, setCheckedSubscriptionID] = React.useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [token, settoken] = React.useState(localStorage.getItem("user"));

    const handleChange = (newValue) => {
        if (checkedSubscriptionID.includes(newValue)) {
            const removeItem = checkedSubscriptionID.filter(item => item !== newValue)
            setCheckedSubscriptionID(removeItem)
        } else {
            setCheckedSubscriptionID([...checkedSubscriptionID, newValue])
        }
    };

    const handleClose = () => {
        setShowDeleteModal(false)
    };

    const handleSubmit = async () => {
        if (checkedSubscriptionID.length <= 0) {
            return enqueueSnackbar("Please select subscription", { variant: 'error' })
        }
        try {
            const body = {
                subscriptionsID: [...checkedSubscriptionID],
                userID: selectedUser._id
            }
            //const response = await axios.delete(api.orderDelete, body, config);
            const response = await axios.delete(api.orderDelete, {
                data: {
                    subscriptionsID: [...checkedSubscriptionID],
                    userID: selectedUser._id
                }, headers: {
                    "x-auth-token": token,
                },
            });

            enqueueSnackbar(`${response.data}`, { variant: 'success' })
            setShowDeleteModal(false)
            reset()
        } catch (error) {
            enqueueSnackbar(`${error.response.data}]`, { variant: 'error' })
        }

    };

    const subscriptionList = (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem sx={{ ml: 2 }} alignItems="flex-start">
                <ListItemText
                    primary={`Email : ${selectedUser && selectedUser.subscriptions && selectedUser.email}`}
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {`Name : ${selectedUser && selectedUser.subscriptions && selectedUser.name}`}
                            </Typography>
                            <br />
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {`Phone :  ${selectedUser && selectedUser.subscriptions && selectedUser.phone}`}
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            {
                selectedUser && selectedUser.subscriptions && selectedUser.subscriptions.map((item, index) =>
                    <>
                        <ListItem key={index} alignItems="flex-start">
                            <Checkbox
                                checked={checkedSubscriptionID.includes(item.subscriptionId._id) ? true : false}
                                onChange={() => handleChange(item.subscriptionId._id)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <ListItemText
                                primary={item.subscriptionId && item.subscriptionId.title}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {`End Date : ${moment(item.endDate).format("YYYY-MM-DD HH:mm:ss A")}`}
                                        </Typography>
                                        <br />
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {`Last Payment Date : ${moment(item.lastPaymentDate).format("YYYY-MM-DD HH:mm:ss A")}`}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </>)
            }
        </List>
    )

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={showDeleteModal}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Delete Order
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {selectedUser && showDeleteModal && subscriptionList}
                </DialogContent>
                <DialogActions>
                    <Button color="success" onClick={handleClose} variant="outlined">
                        Close
                    </Button>

                    <Button color="warning" onClick={handleSubmit} variant="outlined" startIcon={<DeleteIcon />}>
                        Delete
                    </Button>

                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
