import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { useHistory } from "react-router-dom";
import SimpleDeleteModal from "../../../Components/SimpleDeleteModal";
import PdfDialogModal from "./PdfDialogModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ResultsTable({ data, handledeleteResult }) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPdfDialog, setOpenPdfDialog] = useState(false);
  const [pdfResultUrl, setPdfResultUrl] = useState("");
  const [deleteModalResultId, setDeleteModalResultId] = useState("");

  const history = useHistory();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Exam</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            <StyledTableCell align="left">Pdf</StyledTableCell>
            <StyledTableCell align="left">Created At</StyledTableCell>
            <StyledTableCell align="left">Delete</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {item?.exam?.title}
              </StyledTableCell>
              <StyledTableCell align="left">{item?.status}</StyledTableCell>
              <StyledTableCell align="left">
                {item?.resultUrl ? (
                  <IconButton
                    onClick={() => {
                      setPdfResultUrl(item?.resultUrl);
                      setOpenPdfDialog(true);
                    }}
                    aria-label="view pdf"
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                ) : (
                  "N/A"
                )}
              </StyledTableCell>
              <StyledTableCell align="left">
                {moment(item?.createdAt).format("YYYY-MM-DD HH:mm:ss A")}
              </StyledTableCell>
              <StyledTableCell align="left">
                <Button
                  sx={{ color: "red" }}
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setDeleteModalResultId(item?._id);
                    setOpenDeleteModal(true);
                  }}
                >
                  Delete
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <SimpleDeleteModal
        title="Delete Result"
        open={openDeleteModal}
        handleClose={() => {
          setDeleteModalResultId("");
          setOpenDeleteModal(false);
        }}
        handleDelete={() => handledeleteResult(deleteModalResultId)}
      />
      {openPdfDialog && (
        <PdfDialogModal
          key={"pdf-dialog"}
          resultUrl={pdfResultUrl}
          open={openPdfDialog}
          handleClose={() => {
            setOpenPdfDialog(false);
            setPdfResultUrl("");
          }}
        />
      )}
    </TableContainer>
  );
}
