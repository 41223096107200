import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import { useSnackbar } from "notistack"
import axios from "../../../axios"
import api from "../../../api"
import BasicCircularProgress from '../../../Components/BasicCircularProgress';
const Input = styled('input')({
    display: 'none',
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function UpdateEbook({ ebookId, open, title, onCloseEbook, handleSuccess }) {
    const [loading, setLoading] = useState(false)
    const [ebookFile, setEbookFile] = useState({ isShow: false, pdf: {}, thumbnail: {}, title: title })
    const { enqueueSnackbar } = useSnackbar()
    const fileUploadconfig = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
            "Content-Type": "multipart/form-data"
        }
    }
    const handleClose = () => {
        onCloseEbook()
    };

    const onUploadEbookThumbnail = ({ target }) => {
        setEbookFile({ ...ebookFile, thumbnail: target.files[0] })
    }

    const onUploadEbookPdf = ({ target }) => {
        setEbookFile({ ...ebookFile, pdf: target.files[0] })
    }

    async function updateEbook() {
        if (!ebookFile.pdf && !ebookFile.title) return enqueueSnackbar("Ebook file pdf and title required", { variant: 'error' })
        setLoading(true)
        let formData = new FormData();
        formData.append("ebook", ebookFile.pdf);
        formData.append("thumbnail", ebookFile.thumbnail);
        formData.append("title", ebookFile.title);
        axios
            .put(api.updateEbook + `?id=${ebookId}&title=${ebookFile.title}`, formData, fileUploadconfig)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
                setLoading(false)
                handleSuccess()
            })
            .catch((error) => {
                enqueueSnackbar("Upload ebook failed: " + error.response.data, { variant: 'error' })
                handleClose()
                setLoading(false)
            })
    }

    const fileUploadCard = (
        <Card elevation={2} sx={{ width: 400, p: 4 }}>
            <TextField
                sx={{ width: '100%' }}
                required
                id="outlined-required"
                label="Ebook title"
                value={ebookFile.title}
                onChange={(e) => setEbookFile({ ...ebookFile, title: e.target.value })}
            />
            <Grid sx={{ width: '100%' }}>
                <label htmlFor="contained-button-file-thumbnail-edit">
                    <Input onChange={onUploadEbookThumbnail} accept="image/*" id="contained-button-file-thumbnail-edit" type="file" />
                    <Button sx={{ mt: 2, width: '100%' }} variant="contained" component="span">
                        Upload ebook thumbnail
                    </Button>
                </label>

                {ebookFile.thumbnail.name ?
                    <Typography sx={{ mt: 2, width: '100%' }} gutterBottom variant="body" component="div">
                        Uploaded thumbnail : {ebookFile.thumbnail.name}
                    </Typography> : null
                }
            </Grid>
            <Grid sx={{ width: '100%' }}>
                <label htmlFor="contained-button-file-pdf-edit">
                    <Input onChange={onUploadEbookPdf} accept=".pdf" id="contained-button-file-pdf-edit" type="file" />
                    <Button sx={{ mt: 2 }} variant="contained" component="span">
                        Upload ebook pdf
                    </Button>
                </label>
                {ebookFile.pdf.name ?
                    <Typography sx={{ mt: 2 }} gutterBottom variant="body" component="div">
                        Uploaded pdf  : {ebookFile.pdf.name}
                    </Typography> : null
                }
            </Grid>
        </Card>
    )

    return (
        <div>
            {loading && <BasicCircularProgress/>}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Update Ebook
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {fileUploadCard}
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading ? true : false } autoFocus onClick={updateEbook}>
                        Save changes
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
