/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import "../../Css/root.css";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import api from "../../api";
import TablePagination from "@mui/material/TablePagination";
import DeleteModal from "../../Components/DeleteModal";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import * as auth from "../../../app/modules/Auth/_redux/authRedux";
import CouponExportToExcel from "./ExportToExcel/CouponExportToExcel"
import BulkCouponUpdate from "./BulkCouponUpdate/BulkCouponUpdate";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

function Coupon(props) {
	const [token, settoken] = useState(localStorage.getItem("user"));
	const [Datas, setData] = useState([]);
	const [row, setRow] = useState(10);
	const [page, setpage] = useState(0);
	var i = (page * row) + 1;
	const [Count, setCount] = useState();
	const dispatch = useDispatch();
	const [modal, setModal] = React.useState({});
	const [showDelete, setShowDelete] = useState(false);
	const handleCloseDelete = () => setShowDelete(false);
	const handleShowDelete = () => setShowDelete(true);
	const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);
	const [url, seturl] = useState(api.getCoupon);
	const [searchSub, setsearchSub] = useState("");
	const data = {
		headers: {
			"x-auth-token": token,
		},
	};
	const handleChangePage = (event, newPage) => {
		setpage(newPage);
		my_async_fn(row, newPage, url);
	};
	function formatDate(date) {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	}
	const handleChangeRowsPerPage = (event) => {
		setRow(parseInt(event.target.value));
		setpage(0);
		forceUpdate();
		my_async_fn(event.target.value, 0, url);
	};
	async function my_async_fn(row, page, url) {
		axios
			.get(url + "pageNo=" + page + "&row=" + row, data)
			.then((resp) => {
				setData(resp.data.result);
				setCount(resp.data.count);
			})
			.catch((error) => {
				if (error.response.status == 401) {
					return dispatch(props.logout());
				} else {
					return error.response.status;
				}
			});
	}
	function handleSearch() {
		seturl(api.searchCoupon + searchSub + "&");
		let url = api.searchCoupon + searchSub + "&";
		my_async_fn(row, page, url);
	}
	useEffect(() => {
		my_async_fn(row, page, url);
	}, []);
	return (
		<div>
			<div>
				<div className="card card-custom">
					<div className="card-header border-0 py-5 cent">
						<h3 className="card-title align-items-start flex-column">
							<span className="card-label font-weight-bolder text-dark">
								Coupon Search
							</span>
						</h3>
					</div>
					<div></div>
					<div className="card-body pt-0 pb-3 ">
						<div className="form-group row">
							<div className="col-lg-12">
								<form className="custom-form">
									<div className="input-group col-lg-6 col-md-6 col-sm-6 cent">
										<input
											type="text"
											id="search"
											value={searchSub}
											className="form-control"
											placeholder="Search By title"
											onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
											onChange={(e) => {
												setsearchSub(e.target.value);
											}}
										></input>
										<div className="input-group-append">
											<button
												className="btn btn-sm btn-success"
												type="button"
												onClick={handleSearch}
											>
												Search!
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '10px' }}>
							<CouponExportToExcel />
						</div>
					</div>
				</div>
				<br></br>
				<br></br>
				{/* Search Ends */}
			</div>

			<DeleteModal
				detail={modal}
				url={api.deleteCoupon}
				showDelete={showDelete}
				handleCloseDelete={handleCloseDelete}
				reset={() => {
					my_async_fn(row, page, url);
				}}
			/>
			<div className={`card card-custom `}>
				<div className="card-header border-0 py-5">
					<h3 className="card-title align-items-start flex-column">
						<span className="card-label font-weight-bolder text-dark">
							Coupon
						</span>
					</h3>

					<div className="card-toolbar">

						<div style={{ marginRight: '10px' }}>
							<Link
								className="btn btn-success font-weight-bolder font-size-sm"
								to="/bulk-coupon-update"
							>
								<span className="svg-icon svg-icon-md svg-icon-warning">
									<SVG
										src={toAbsoluteUrl(
											"/media/svg/icons/Communication/Write.svg"
										)}
										className="h-50 align-self-center"
									></SVG>
								</span>
								Bulk Coupon Update
							</Link>
						</div>

						<div style={{ marginRight: '10px' }}>
							<Link
								className="btn btn-success font-weight-bolder font-size-sm"
								to="/bulk-coupon-delete"
							>
								<span className="svg-icon svg-icon-md svg-icon-danger">
									<SVG
										src={toAbsoluteUrl(
											"/media/svg/icons/General/Trash.svg"
										)}
										className="h-50 align-self-center"
									></SVG>
								</span>
								Bulk Coupon delete
							</Link>
						</div>
						<div style={{ marginRight: '10px' }}>
							<Link
								className="btn btn-success font-weight-bolder font-size-sm"
								to="/subscription-add-bulkCoupon"
							>
								<span className="svg-icon svg-icon-md svg-icon-white">
									<SVG
										src={toAbsoluteUrl(
											"/media/svg/icons/Communication/Add-user.svg"
										)}
										className="h-50 align-self-center"
									></SVG>
								</span>
								Subscription add to bulk coupon
							</Link>
						</div>

						<div style={{ marginRight: '10px' }}>
							<Link
								className="btn btn-success font-weight-bolder font-size-sm"
								to="/catalogCourse-add-bulkCoupon"
							>
								<span className="svg-icon svg-icon-md svg-icon-white">
									<SVG
										src={toAbsoluteUrl(
											"/media/svg/icons/Communication/Add-user.svg"
										)}
										className="h-50 align-self-center"
									></SVG>
								</span>
								Catalog-Course add to bulk coupon
							</Link>
						</div>

						<div>
							<Link
								className="btn btn-success font-weight-bolder font-size-sm"
								to="/createCoupon"
							>
								<span className="svg-icon svg-icon-md svg-icon-white">
									<SVG
										src={toAbsoluteUrl(
											"/media/svg/icons/Communication/Add-user.svg"
										)}
										className="h-50 align-self-center"
									></SVG>
								</span>
								Add New Coupon
							</Link>
						</div>
					</div>

				</div>
				{/* end::Header */}

				{/* begin::Body */}
				<div className="card-body py-0">
					{/* begin::Table */}
					<div className="table-responsive">
						<table
							className="table table-head-custom table-vertical-center"
							id="kt_advance_table_widget_1"
						>
							<thead>
								<tr className="text-left">
									<th className="pl-0" style={{ width: "100px" }}>
										SERIAL
									</th>
									<th className="pr-0 " style={{ width: "250px" }}>
										COUPON CODE
									</th>
									<th style={{ minWidth: "130px" }}>SUBSCRIPTION</th>
									<th style={{ minWidth: "130px" }}>Catalog Course</th>
									<th style={{ minWidth: "130px" }}>Expiry Date</th>
									<th
										className="pr-0 text-center"
										style={{ minWidth: "250px" }}
									>
										ACTION
									</th>
								</tr>
							</thead>

							{Datas.map((detail) => (
								<tbody>
									<tr>
										<td className="pl-0">
											<span className="text-dark-75 font-weight-bolder d-block font-size-lg">
												{i++}
											</span>
										</td>

										<td>
											<span className="text-dark-75 font-weight-bolder d-block font-size-lg">
												{detail.code}
											</span>
										</td>
										<td>
											{detail.subscriptions.map((det) => (
												<span style={{margin :'10px'}} className="text-dark-75 font-weight-bolder d-block font-size-lg">
													<RadioButtonCheckedIcon/>{det.title}
												</span>
											))}
										</td>
										<td>
											{detail?.catalogCourses?.map((det) => (
												<span style={{margin :'10px'}} className="text-dark-75 font-weight-bolder d-block font-size-lg">
													<RadioButtonCheckedIcon/>{det.title}
												</span>
											))}
										</td>
										<td>
											<span className="text-dark-75 font-weight-bolder d-block font-size-lg">
												{formatDate(detail.expiredDate)}
											</span>
										</td>

										<td className="pr-0 text-center">
											<Link
												to={{
													pathname: "/EditCoupon",
													state: detail,
												}}
												className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
											>
												<span className="svg-icon svg-icon-md svg-icon-warning">
													<SVG
														src={toAbsoluteUrl(
															"/media/svg/icons/Communication/Write.svg"
														)}
													></SVG>
												</span>
											</Link>
											<a
												href="#"
												onClick={() => setModal(detail) & setShowDelete(true)}
												className="btn btn-icon btn-light btn-hover-primary btn-sm"
											>
												<span className="svg-icon svg-icon-md svg-icon-danger">
													<SVG
														src={toAbsoluteUrl(
															"/media/svg/icons/General/Trash.svg"
														)}
													></SVG>
												</span>
											</a>
										</td>
									</tr>
								</tbody>
							))}
						</table>
						<div className="row align-items-center">
							<div className="col-md-8 col-sm-8">
								<div className="d-flex flex-wrp py-2 mr-3">
									<TablePagination
										component="div"
										count={Count}
										page={page}
										onPageChange={handleChangePage}
										rowsPerPage={row}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</div>
							</div>
							<br></br> <br></br>
							<br></br>
							<br></br>
						</div>
					</div>
					{/* end::Table */}
				</div>
			</div>
		</div>
	);
}
export default connect(null, auth.actions)(Coupon);
