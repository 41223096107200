import React, { useState } from "react";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import moment from "moment";
import SubscriptionFilter from "../../../Components/Filters/SubscriptionFilter";
import CatalogCourseFilter from "../../../Components/Filters/CatalogCourseFilter";
import GoalFilter from "../../../Components/Filters/GoalFilter";
import BasicSelect from "./BasicSelect";
import axios from "../../../axios";
import api from "../../../api";
import ExcelToUploadPhone from "../../../Components/ExcelUploads/ExcelToUploadPhone";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const userMode = ["Goal", "Subscription", "Catalog Course", "Upload user"];

export default function UserSelectModal({
  open,
  mode,
  alertData,
  noticeData,
  handleCloseModal,
}) {
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [selectedGoal, setSelectedGoal] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("")
  const [type, setType] = useState("");
  const [excelUploads, setExcelUploads] = useState([]);
  const [subscriptionMode, setSubscriptionMode] = useState("Current");
  const { enqueueSnackbar } = useSnackbar();
  let config = { headers: { "x-auth-token": localStorage.getItem("user") } };
  const handleClose = () => {
    handleCloseModal(false);
  };

  const handleSubmit = async () => {
    let payload = {
      type: type,
      userSelectMode: subscriptionMode,
      subscription: selectedSubscription,
      courseId: selectedCourse,
      course: selectedCourse,
      goal: selectedGoal,
      excelUploadUsers: excelUploads
    };
    if (type === "Catalog Course") {
      if (!selectedCourse)
        return enqueueSnackbar("Course is not allowed to be empty", {
          variant: "warning",
        });
    }
    if (type === "Subscription") {
      if (!selectedSubscription)
        return enqueueSnackbar("Subscription is not allowed to be empty", {
          variant: "warning",
        });
    }
    if (type === "Goal") {
      if (!selectedGoal)
        return enqueueSnackbar("Goal is not allowed to be empty", {
          variant: "warning",
        });
    }
    if (type === "Upload user") {
      if (!excelUploads.length)
        return enqueueSnackbar("No users found.", {
          variant: "warning",
        });
    }

    if (mode === "Alert") {
      payload.title = alertData?.title;
      payload.description = alertData?.description;
      try {
        const response = await axios.post(
          api.adminSendNotification,
          payload,
          config
        );
        enqueueSnackbar(response?.data, {
          variant: "success",
        });
        handleCloseModal(false);
      } catch (error) {
        enqueueSnackbar(error?.response?.data, {
          variant: "error",
        });
      }
    }
    if (mode === "Notice") {
      payload.title = noticeData?.title;
      payload.shortDescription = noticeData?.shortDescription;
      payload.description = noticeData?.description;
      payload.expiryDate = moment(noticeData?.expiryDate)
        .endOf("day")
        .utc();
      payload.isActive = noticeData?.isActive;
      try {
        const response = await axios.post(api.adminSendNotice, payload, config);
        enqueueSnackbar(response?.data, {
          variant: "success",
        });
        handleCloseModal(false);
      } catch (error) {
        enqueueSnackbar(error?.response?.data, {
          variant: "error",
        });
      }
    }
  };

  const subscriptionSelector = (
    <SubscriptionFilter
      isGoalSelect={true}
      selectedSubscription={selectedSubscription}
      handleGoalSelection={(goalId)=>{
        setSelectedGoal(goalId)
      }}
      handleSubscriptionSelection={(subscriptionId) => {
        setSelectedSubscription(subscriptionId);
      }}
    />
  );

  const catalogCourseSelector = (
    <CatalogCourseFilter
        isGoalSelect={true}
        selectedCourse={selectedCourse}
        handleCourseSelection={(courseId) => {
            setSelectedCourse(courseId)
        }}
        handleGoalSelection={(goalId)=>{
          setSelectedGoal(goalId)
        }}
    />
)

  const goalSelector = (
    <GoalFilter
      selectedGoal={selectedGoal}
      handleGoalSelection={(goalId) => {
        setSelectedGoal(goalId);
      }}
    />
  );

  const excelUploadPhoneNumber = (
    <Box sx={{ minWidth: 350 }}>
      <ExcelToUploadPhone
        handleMergeUser={(users) => setExcelUploads(users)}
      />
    </Box>
  )

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Select User
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
            sx={{ p: 8 }}
          >
            <BasicSelect
              value={type}
              title={"Select Mode"}
              data={userMode}
              handleChangeMode={(state) => setType(state)}
            />
            {type === "Goal" && goalSelector}
            {type === "Subscription" && subscriptionSelector}
            {type === "Catalog Course" && catalogCourseSelector}
            {type === "Upload user" && excelUploadPhoneNumber}
            {type === "Subscription" && (
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={subscriptionMode}
                onChange={(event) => setSubscriptionMode(event.target.value)}
              >
                <FormControlLabel
                  value="Current"
                  control={<Radio />}
                  label="Current"
                />
                <FormControlLabel
                  value="Expired"
                  control={<Radio />}
                  label="Expired"
                />
                <FormControlLabel value="All" control={<Radio />} label="All" />
              </RadioGroup>
            )}
            {type === "Catalog Course" && (
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={subscriptionMode}
                onChange={(event) => setSubscriptionMode(event.target.value)}
              >
                <FormControlLabel
                  value="Current"
                  control={<Radio />}
                  label="Current"
                />
                <FormControlLabel
                  value="Expired"
                  control={<Radio />}
                  label="Expired"
                />
                <FormControlLabel value="All" control={<Radio />} label="All" />
              </RadioGroup>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSubmit}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
