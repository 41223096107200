import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Stack from "@mui/material/Stack"
import axios from '../../../../axios';
import moment from 'moment';
import Logo from "../../../../images/logo.png"

export default function WhiteBoard(props) {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: true,
    });

    const [whiteBoardState, setWhiteBoardState] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const config = { headers: { "x-auth-token": localStorage.getItem("user") } }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
        // props.handleHideWhiteBoard(open)
    };

    const fetchWhiteBoard = async () => {
        try {
            const response = await axios.get(`/admin/liveClass-room/whiteBoard?roomId=${props.roomId}`, config);
            setWhiteBoardState(response?.data?.reverse())
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        fetchWhiteBoard()
    }, [props.roomId])

    const list = () => (
        <Box
            sx={{p: 1}}
        >
            <List>
                {whiteBoardState?.map((item, index) =>
                    <div key={index}>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon>
                                <PictureAsPdfIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={`Created at : ${moment(item.sessionSaves[0]?.createdAt).format("YYYY-MM-DD HH:mm:ss A")}`}
                                secondary={
                                    <React.Fragment style={{ margin: '10px' }}>
                                        {item?.sessionSaves?.map((item2, index) =>
                                            <div key={index}>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    File Name : {item2?.fileName}
                                                </Typography>
                                                <Button sx={{ m : 1 }} target="_blank" href={`${item2?.url}`} variant="outlined">Download Pdf</Button>
                                            </div>
                                        )}
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </div>
                )}
            </List>
        </Box>
    );

    const whiteBoardLoader = (
        <Box
            sx={{ width: 'auto' }}
            role="presentation"
        >
            <List>
                {[1, 2, 3, 4].map(item =>
                    <div key={item}>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon>
                                <Skeleton variant="circular" width={40} height={40} />
                            </ListItemIcon>
                            <ListItemText
                                primary={<><Skeleton variant="text" sx={{ fontSize: '1rem' }} /></>}
                                secondary={
                                    <React.Fragment>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="rectangular" width={60} height={35} />
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </div>
                )}
            </List>
        </Box>
    )

    const emptyList = (
        <Box
            sx={{ width: 'auto' }}
            role="presentation"
            onClick={toggleDrawer('top', false)}
            onKeyDown={toggleDrawer('top', false)}
        >
            <Box alignItems={'center'} justifyContent={'center'}>
                <Stack>
                    <img style={{ width: '20%', marginLeft: '40%' }} src={Logo} alt="logo" />
                </Stack>
                <Stack>
                    <Typography align='center' variant='h3' sx={{ fontWeight: 550, opacity: '0.4' }}>Empty White-Board</Typography>
                </Stack>
            </Box>
        </Box>
    )

    return (
        <div>
            <React.Fragment>
                    {!loading && whiteBoardState?.length !== 0 && list()}
                    {loading && whiteBoardLoader}
                    {!loading && whiteBoardState?.length === 0 && emptyList}
            </React.Fragment>
        </div>
    );
}
