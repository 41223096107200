import React from "react"
import Box from '@mui/material/Box'
import CircularProgress from "@mui/material/CircularProgress"

export default function BasicCircularProgress() {
    return (
        <Box sx={{
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            position: 'absolute',
            zIndex: '1000',
            bgcolor: '#f2f3f8'
        }}>
            <CircularProgress />
        </Box>
    )
}