import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditDialog from "./EditDialog"
import SimpleDeleteModal from '../../../../Components/SimpleDeleteModal'
import axios from "../../../../axios"
import { useSnackbar } from "notistack"

export default function CardMenuItem({ name, roomId, type, hosts, isActive, isLive, expiration, handleRefreshData }) {

    const [anchorEl, setAnchorEl] = React.useState(null)
    const [editOpen, setEditOpen] = React.useState(false)
    const [deleteOpen, setDeleteOpen] = React.useState(false)
    const open = Boolean(anchorEl);

    const { enqueueSnackbar } = useSnackbar()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
   
    const config = {
        headers: {
          "x-auth-token": localStorage.getItem("user"),
        }
      }

    const handleDeleteRoom = async ()=>{
        try{
            const response = await axios.delete(`/admin/liveClass-room/${roomId}`, config)
            enqueueSnackbar(response?.data, { variant: 'success' })
            handleRefreshData()
            setDeleteOpen(false)
        }catch(error){
            enqueueSnackbar(error?.response?.data, { variant: 'error' })
        }
    }

    return (
        <React.Fragment>
            {editOpen && <EditDialog
                editOpen={editOpen}
                handleCloseEdit={() => setEditOpen(false)}
                name={name}
                roomId={roomId}
                hosts={hosts}
                isActive={isActive}
                isLive={isLive}
                type={type}
                expiration={expiration}
                handleRefreshData={handleRefreshData}
            />}

            {deleteOpen &&
                <SimpleDeleteModal
                    title="Delete Room"
                    open={deleteOpen}
                    handleClose={() => {
                        setDeleteOpen(false)
                    }}
                    handleDelete={handleDeleteRoom}
                />
            }

            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Live settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => setEditOpen(true)}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Edit
                </MenuItem>
                <Divider />
                <MenuItem sx={{ color: 'red' }} onClick={() => setDeleteOpen(true)}>
                    <ListItemIcon>
                        <DeleteForeverIcon sx={{ color: 'red' }} fontSize="small" />
                    </ListItemIcon>
                    Delete
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}