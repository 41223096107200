import React, { useEffect, useRef, useState } from "react"
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import MultiSelectorChip from "../../../Components/MultiSelectorChip"
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import RestoreIcon from '@mui/icons-material/Restore'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Stack from '@mui/material/Stack'
import RichTextEditor from "../../../Components/RichTextEditor"
import BasicCircularProgress from "../../../Components/BasicCircularProgress"
import { useSnackbar } from "notistack"

export default function FreeCourseAddUpdateDialog(props) {
    const [courseLoading, setCourseLoading] = useState(false)
    const [educatorLoading, setEducatorLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [educators, setEducators] = useState([])

    const [title, setTitle] = useState('')
    const [introVideo , setIntroVideo] = useState("")
    const [order, setOrder] = useState('')
    const [isActive, setIsActive] = useState(true)
    const [isFree, setIsFree] = useState(false)
    const [description, setDescription] = useState('')
    const [selectedEducators, setSelectedEducators] = useState([])

    const [errorMessage, setErrorMessage] = useState({})
    const editorRef = useRef(null)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const goalId = props.goalId

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    const educatorData = {
        title: "Educator",
        items: educators,
        index: "name",
        selected: selectedEducators
    }

    function resetFormData() {
        setTitle('')
        setOrder('')
        setIsActive(true)
        setIsFree(false)
        setDescription('')
        setSelectedEducators([])
        setErrorMessage({})
    }

    const handleSelection = (value) => {
        if(!value.length) {
            setErrorMessage(message => {
                return {...message, educator: 'Select at least one teacher.'}
            })
        } else {
            setErrorMessage(message => {
                return {...message, educator: ''}
            })
        }
        setSelectedEducators(value)
    }

    const handleClose = () => {
        props.handleClose()
    }

    const handleReset = () => (
        resetFormData()
    )

    const handleSubmit = () => {
        if(!isFormDataValid()) return
        props.courseId ? updateCourse() : addNewCourse()
    }

    const isFormDataValid = () => {
        var isValid = true
        if(!title.length) {
            isValid = false
            setErrorMessage(message => {
                return {...message, title: 'Title must not be empty.'}
            })
        }
        if(introVideo) {
            if(introVideo.includes("watch?v")){
                isValid = false
                setErrorMessage(message => {
                    return {...message, introVideo: 'Please enter valid youtube video.'}
                })
            }
        }
        if(!order) {
            isValid = false
            setErrorMessage(message => {
                return {...message, order: 'Order must be a valid number'}
            })
        }
        if(!selectedEducators.length) {
            isValid = false
            setErrorMessage(message => {
                return {...message, educator: 'Select at least one teacher.'}
            })
        }
        if(editorRef.current && editorRef.current.getContent() === '') {
            isValid = false
            enqueueSnackbar("Description can not be empty", { variant: 'error' })
        }
        return isValid
    }

    async function fetchCourseData(courseId) {
        setCourseLoading(true)
        axios
            .get(api.getCourseById + courseId, config)
            .then((response) => {
                setTitle(response.data.title)
                setIntroVideo(response?.data?.introVideo)
                setIsActive(response.data.isActive)
                setIsFree(response.data.isFree)
                setDescription(response.data.description)
                setSelectedEducators(response.data.teachers)
                if(response.data.order) setOrder(response.data.order)
                setCourseLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Course data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function fetchEducators() {
        setEducatorLoading(true)
        axios
            .get(api.getCourseExplorerEducators, config)
            .then((response) => {
                setEducators(response.data)
                setEducatorLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Educator fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function addNewCourse() {
        setSubmitLoading(true)
        const coursePayload = {
            title: title,
            introVideo: introVideo,
            description: editorRef.current.getContent(),
            category: {
                value: 'free',
                parent: props.goalId
            },
            subjects: [],
            order: order,
            isFree: isFree,
            isActive: isActive,
            teachers: selectedEducators
        }
        axios
            .post(api.createCourse, coursePayload, config)
            .then((response) => {
                axios
                    .post(
                        api.addFreeCourseToGoal,
                        {
                            courseId: response.data,
                            goalId: goalId
                        },
                        config
                    )
                    .then((response) => {
                        enqueueSnackbar(response.data, { variant: 'success' })
                        setSubmitLoading(false)
                        handleClose()
                        props.handleSuccess()
                    })
                    .catch((error) => {
                        enqueueSnackbar("Add new course failed: " + error.response.data, { variant: 'error' })
                    })
            })
            .catch((error) => {
                enqueueSnackbar("Add new course failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function updateCourse() {
        const updatePayload = {
            title: title,
            introVideo: introVideo,
            description: editorRef.current.getContent(),
            order: order,
            isFree: isFree,
            isActive: isActive,
            teachers: selectedEducators
        }
        axios
            .put(api.updateCourse + props.courseId, updatePayload, config)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
                handleClose()
                props.handleSuccess()
            })
            .catch((error) => {
                enqueueSnackbar("Update course failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        // TODO: Stop initial loading
        if(!props.open) {
            return
        }
        props.courseId ? fetchCourseData(props.courseId) : setCourseLoading(false)
        fetchEducators()
    }, [props.open])

    const appBar = (
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Add or Update Course
                </Typography>
                <LoadingButton
                    color="secondary"
                    onClick={handleSubmit}
                    loading={courseLoading || educatorLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
                <LoadingButton
                    color="inherit"
                    sx={{ ml: 2 }}
                    onClick={handleReset}
                    loading={courseLoading || educatorLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<RestoreIcon />}
                    variant="contained"
                >
                    Reset
                </LoadingButton>
            </Toolbar>
        </AppBar>
    )

    const titleField = (
        <TextField
            required
            error={!!errorMessage.title}
            variant="outlined"
            label="Title"
            helperText={errorMessage.title}
            sx={{ minWidth: 300 }}
            value={title}
            onChange={(event) => {
                const text = event.target.value
                if(!text.length) {
                    setErrorMessage(message => {
                        return {...message, title: 'Title must not be empty.'}
                    })
                } else {
                    setErrorMessage(message => {
                        return {...message, title: ''}
                    })
                }
                setTitle(event.target.value)
            }}
        />
    )
    const introField = (
        <TextField
            required
            error={!!errorMessage.introVideo}
            variant="outlined"
            label="Intro Video"
            helperText={errorMessage.introVideo}
            sx={{ minWidth: 300 }}
            value={introVideo}
            onChange={(event) => {
                setIntroVideo(event.target.value)
            }}
        />
    )
    const orderField = (
        <TextField
            required
            error={!!errorMessage.order}
            variant="outlined"
            type="number"
            label="Order"
            helperText={errorMessage.order}
            sx={{ minWidth: 300 }}
            value={order}
            onChange={(event) => {
                const order = event.target.value
                if(!order) {
                    setErrorMessage(message => {
                        return {...message, order: 'Order must be a valid number.'}
                    })
                } else {
                    setErrorMessage(message => {
                        return {...message, order: ''}
                    })
                }
                setOrder(event.target.value)
            }}
        />
    )

    const isActiveSwitch = (
        <FormControlLabel
            label="Is Active"
            control={
                <Switch
                    checked={isActive}
                    onChange={(event) => setIsActive(event.target.checked)}
                />
            }
        />
    )

    const isFreeSwitch = (
        <FormControlLabel
            label="Is Free"
            control={
                <Switch
                    checked={isFree}
                    onChange={(event) => setIsFree(event.target.checked)}
                />
            }
        />
    )

    const educatorSelector = (
        <MultiSelectorChip
            required
            error={!!errorMessage.educator}
            helperText={errorMessage.educator}
            data={educatorData}
            handleSelection={handleSelection}
            sx={{ width: 400 }}
        />
    )

    const richTextEditor = (
        <RichTextEditor
            sx={{ width: 600, height: 600 }}
            initialValue={description}
            onInit={(_evt, editor) => {
                editorRef.current = editor
            }}
        />
    )

    return (
        <Dialog fullScreen disableEnforceFocus open={Boolean(props.open)} onClose={handleClose}>
            {appBar}
            {(courseLoading || educatorLoading) ? <BasicCircularProgress /> :
                <Box sx={{ m: 2 }}>
                    <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={8}>
                        <Stack direction="column" spacing={4}>
                            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                                {titleField}
                                {orderField}
                            </Stack>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                {introField}
                                {isActiveSwitch}
                                {isFreeSwitch}
                            </Stack>
                            <Stack justifyContent="center" alignItems="center">
                                {educatorSelector}
                            </Stack>
                        </Stack>
                        {richTextEditor}
                    </Stack>
                </Box>}
        </Dialog>
    )
}