import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import WrittenExamFilter from './WrittenExamFilter'

export default function WrittenExamExplorer(props) {
    return (
        <React.Fragment>
            <Box sx={{ p: 2, mb: 4 }} component={Paper} elevation={4}>
                <WrittenExamFilter />
            </Box>
        </React.Fragment>
    )
}
