import React, { useState } from "react"
import "../../Css/root.css"
import axios from "../../axios"
import api from "../../api"
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import PolicyIcon from '@mui/icons-material/Policy';
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import CourseList from "./Course/CourseList"
import Box from '@mui/material/Box'
import SubscriptionFilter from "../../Components/Filters/SubscriptionFilter"
import SubjectList from "./Subject/SubjectList"
import ChapterList from "./Chapter/ChapterList"
import ClassList from "./Class/ClassList"
import GoalFilter from "../../Components/Filters/GoalFilter"
import FreeCourseList from "./FreeCourse/FreeCourseList"
import CourseExplorerModeMenu from "./CourseExplorerModeMenu"
import CatalogCourseList from "./CatalogCourse/CatalogCourseList"
import SearchCourseList from "./AllCourses/SearchCourseList"

const ExplorerMode = {
    SubscriptionCourse: "SubscriptionCourse",
    FreeCourse: "FreeCourse",
    CatalogCourse: "CatalogCourse",
    AllCourses: "AllCourses"
}

const ExplorerModeMenu = {
    SubscriptionCourse: "Subscription Course",
    FreeCourse: "Free Course",
    CatalogCourse: "Catalog Course",
    AllCourses: "All Course"
}

const FilterTitleByMode = {
    SubscriptionCourse: "Select Subscription",
    FreeCourse: "Select Goal",
    CatalogCourse: "Select Goal"
}

export default function CourseExplorer() {
    const [explorerMode, setExplorerMode] = useState(ExplorerMode.SubscriptionCourse)
    const [selectedGoal, setSelectedGoal] = useState('')
    const [selectedSubscription, setSelectedSubscription] = useState('')
    const [selectedCourse, setSelectedCourse] = useState('')
    const [selectedSubject, setSelectedSubject] = useState('')
    const [selectedChapter, setSelectedChapter] = useState('')
    const [selectedClass, setSelectedClass] = useState('')

    const [seachCourse, setSearchCourse] = useState({ title: '', isSeacrh: false })

    const handleModeChange = (mode) => {
        setSelectedGoal('')
        setSelectedSubscription('')
        setSelectedCourse('')
        setSelectedSubject('')
        setSelectedChapter('')
        setSelectedClass('')

        setExplorerMode(ExplorerMode[mode])
    }

    const goalSelector = (
        <GoalFilter
            selectedGoal={selectedGoal}
            handleGoalSelection={(goalId) => {
                setSelectedCourse('')
                setSelectedSubject('')
                setSelectedChapter('')
                setSelectedClass('')
                setSelectedGoal(goalId)
            }}
        />
    )

    const subscriptionSelector = (
        <SubscriptionFilter
            selectedSubscription={selectedSubscription}
            handleSubscriptionSelection={(subscriptionId) => {
                setSelectedCourse('')
                setSelectedSubject('')
                setSelectedChapter('')
                setSelectedClass('')
                setSelectedSubscription(subscriptionId)
            }}
        />
    )

    const courseNameInput = (
        <FormControl variant="standard">
            <InputLabel htmlFor="input-with-icon-adornment">
                Enter Course Title
            </InputLabel>
            <Input
                onChange={(e)=>setSearchCourse({isSeacrh : false, title : e.target.value})}
                id="input-with-icon-adornment"
                startAdornment={
                    <InputAdornment position="start">
                        <PolicyIcon />
                    </InputAdornment>
                }
            />
        </FormControl>
    )

    const filterBox = (
        <Card>
            <CardHeader
                title={FilterTitleByMode[explorerMode]}
                action={
                    <CourseExplorerModeMenu
                        currentMode={explorerMode}
                        explorerModes={ExplorerModeMenu}
                        handleModeSelection={handleModeChange}
                    />
                }
            />
            <CardContent>
                {explorerMode === "SubscriptionCourse" && subscriptionSelector}
                {explorerMode === "FreeCourse" && goalSelector}
                {explorerMode === "CatalogCourse" && goalSelector}
            </CardContent>
        </Card>
    )

    const searchCourseName = (
        <Card>
            <CardHeader
                title={FilterTitleByMode[explorerMode]}
                action={
                    <CourseExplorerModeMenu
                        currentMode={explorerMode}
                        explorerModes={ExplorerModeMenu}
                        handleModeSelection={handleModeChange}
                    />
                }
            />
            <CardContent>
                {explorerMode === "AllCourses" && courseNameInput}
                <Button onClick={()=>setSearchCourse({...seachCourse, isSeacrh : true })} sx={{m : 1 }} variant="outlined">Search</Button>
            </CardContent>
        </Card>
    )

    const courseBox = (
        <Box sx={{ pt: 1 }}>
            <CourseList
                subscriptionId={selectedSubscription}
                handleCourseSelection={(courseId) => {
                    setSelectedSubject('')
                    setSelectedChapter('')
                    setSelectedClass('')
                    setSelectedCourse(courseId)
                }}
            />
        </Box>
    )

    const inActiveCourse = (
        <Box sx={{ pt: 1 }}>
            <SearchCourseList
                title={seachCourse.title}
                handleCourseSelection={(courseId) => {
                    setSelectedSubject('')
                    setSelectedChapter('')
                    setSelectedClass('')
                    setSelectedCourse(courseId)
                }}
            />
        </Box>
    )

    const freeCourseBox = (
        <Box sx={{ pt: 1 }}>
            <FreeCourseList
                goalId={selectedGoal}
                handleCourseSelection={(courseId) => {
                    setSelectedSubject('')
                    setSelectedChapter('')
                    setSelectedClass('')
                    setSelectedCourse(courseId)
                }}
            />
        </Box>
    )

    const catalogCourseBox = (
        <Box sx={{ pt: 1 }}>
            <CatalogCourseList
                goalId={selectedGoal}
                handleCourseSelection={(courseId) => {
                    setSelectedSubject('')
                    setSelectedChapter('')
                    setSelectedClass('')
                    setSelectedCourse(courseId)
                }}
            />
        </Box>
    )

    const subjectBox = (
        <Grid item>
            <Box sx={{ pt: 1 }}>
                <SubjectList
                    courseId={selectedCourse}
                    selectedSubject={selectedSubject}
                    handleSubjectSelection={(subjectId) => {
                        setSelectedChapter('')
                        setSelectedClass('')
                        setSelectedSubject(subjectId)
                    }}
                />
            </Box>
        </Grid>
    )

    const chapterBox = (
        <Grid item>
            <Box sx={{ pt: 1 }}>
                <ChapterList
                    subjectId={selectedSubject}
                    selectedChapter={selectedChapter}
                    handleChapterSelection={(chapterId) => {setSelectedChapter(chapterId);setSelectedClass('')}}
                />
            </Box>
        </Grid>
    )

    const classBox = (
        <Grid item>
            <Box sx={{ pt: 1 }}>
                <ClassList selectedClass={selectedClass} subjectId={selectedSubject} chapterId={selectedChapter}
                    handleClassSelection={(classId) => setSelectedClass(classId)}
                />
            </Box>
        </Grid>
    )

    return (
        <React.Fragment>
            {explorerMode !== "AllCourses" && filterBox}

            {explorerMode === "AllCourses" && searchCourseName}
            {explorerMode === "AllCourses" && seachCourse.isSeacrh && inActiveCourse }

            {explorerMode === "SubscriptionCourse" && selectedSubscription && courseBox}
            {explorerMode === "FreeCourse" && selectedGoal && freeCourseBox}
            {explorerMode === "CatalogCourse" && selectedGoal && catalogCourseBox}

            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                {selectedCourse && subjectBox}
                {selectedSubject && chapterBox}
                {selectedChapter && classBox}
            </Grid>
        </React.Fragment>
    )
}