import React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {DesktopDatePicker} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Grid from '@mui/material/Grid'
import DoubtFilterSubjectWiseDataExport from "./DoubtFilterSubjectWiseDataExport"
import Button from '@mui/material/Button';

export default function DoubtFilterSubjectWise() {

    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null)

    const handleChangeStartDate = (newValue) => {setStartDate(newValue)}
    const handleChangeEndDate = (newValue) => {setEndDate(newValue)}

    const dateFilter = (
        <Card sx={{minWidth: 350}}>
          <CardHeader title="Doubt filter group by subject" />
          <CardContent>
            <Grid sx={{ mt: 2 }} container direction="row" justifyContent="center" alignItems="center" spacing={4}>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      label="START DATE"
                      inputFormat="MM/dd/yyyy"
                      value={startDate}
                      onChange={handleChangeStartDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
    
                    <DesktopDatePicker
                      label="END DATE"
                      inputFormat="MM/dd/yyyy"
                      value={endDate}
                      onChange={handleChangeEndDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
    
                   <DoubtFilterSubjectWiseDataExport startDate={startDate} endDate={endDate} />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )
      
  return (
    <div>
     {dateFilter}
    </div>
  )
}
