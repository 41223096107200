import React, { useEffect, useState } from "react"
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import AddIcon from '@mui/icons-material/Add'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconLabelListSkeleton from "../../../Components/IconLabelListSkeleton"
import EditDeleteMenu from "../../../Components/EditDeleteMenu"
import SubjectAddUpdateDialog from "./SubjectAddUpdateDialog"
import SimpleDeleteModal from "../../../Components/SimpleDeleteModal"
import { useSnackbar } from "notistack"

const TAG = "SubjectList"
const generateKey = (pre) => {
    return `${TAG}_${ pre }_${ new Date().getTime() }`
}

export default function SubjectList(props) {
    const [subjects, setSubjects] = useState([])
    const [subjectLoading, setSubjectLoading] = useState(false)

    const [dialogSubjectId, setDialogSubjectId] = useState('')
    const [openSubjectDialog, setOpenSubjectDialog] = useState(false)

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deleteModalSubjectId, setDeleteModalSubjectId] = useState('')

    const [refreshKey, setRefreshKey] = useState(0)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user")
        }
    }

    const refresh = () => {
        props.handleSubjectSelection('')
        setRefreshKey(prevKey => prevKey + 1)
    }
 
    const handleSubjectSelection = (event) => {
        props.handleSubjectSelection(event.currentTarget.getAttribute('value'))
    }

    const handleAddOrEditPressed = (subjectId) => {
        setDialogSubjectId(subjectId)
        setOpenSubjectDialog(true)
    }

    const handleDeletePressed = (subjectId) => {
        setDeleteModalSubjectId(subjectId)
        setOpenDeleteModal(true)
    }

    async function getSubjectsByCourse(courseId) {
        setSubjectLoading(true)
        axios
            .get(api.getFilterSubjects + courseId, config)
            .then((response) => {
                setSubjects(response.data)
                setSubjectLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Subject fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function deleteSubject(subjectId) {
        axios
            .delete(api.deleteSubject + subjectId, config)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
                refresh()
            })
            .catch((error) => {
                enqueueSnackbar("Course delete failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        getSubjectsByCourse(props.courseId)
    },[props.courseId, refreshKey])

    const subjectItems = (item) => {
        return (
            <ListItem
                key={item._id}
                secondaryAction={
                    <EditDeleteMenu
                        value={item._id}
                        editAction={handleAddOrEditPressed}
                        deleteAction={handleDeletePressed}
                    />
                }
                disablePadding
            >
                <ListItemButton
                    selected={props.selectedSubject === item._id}
                    value={item._id}
                    onClick={handleSubjectSelection}
                >
                    <ListItemIcon>
                        <Avatar
                            sx={{ width: 20, height: 20, fontSize: 12 }}
                        >
                            {item.order}
                        </Avatar>
                    </ListItemIcon>
                    <ListItemText
                        primary={item.title}
                    />
                </ListItemButton>
            </ListItem>
        )
    }

    const subjectItemsSkeleton = [1, 2, 3].map((value) => <IconLabelListSkeleton key={value} />)

    return (
        <Card sx={{ width: "20vw" }}>
            <CardHeader
                title="Subjects"
                action={
                    <Tooltip title="Add New Subject">
                        <IconButton aria-label="add" onClick={(_event) => handleAddOrEditPressed('')}>
                            <AddIcon color="primary" sx={{ width: 30, height: 30 }} />
                        </IconButton>
                    </Tooltip>
                }
            />
            <CardContent>
                <List>
                    {subjectLoading ? subjectItemsSkeleton : subjects.map(subjectItems)}
                </List>
            </CardContent>
            <SubjectAddUpdateDialog
                key={generateKey("subject-dialog")}
                courseId={props.courseId}
                subjectId={dialogSubjectId}
                open={openSubjectDialog}
                handleClose={() => setOpenSubjectDialog(false)}
                handleSuccess={refresh}
            />
            <SimpleDeleteModal
                title="Delete Subject"
                open={openDeleteModal}
                handleClose={() => {
                    setDeleteModalSubjectId('')
                    setOpenDeleteModal(false)
                }}
                handleDelete={() => deleteSubject(deleteModalSubjectId)}
            />
        </Card>
    )
}