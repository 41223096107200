import React from "react";
import "../../Css/root.css";
import { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import axios from "../../axios";
import api from "../../api";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "../../Components/Toaster/ToastReducer";
import moment from "moment";

export default function EditCoupon(state) {
	const dispatch=useDispatch();
	const [Sub, setSub] = useState([]);
	const [Courses, setCourses] = useState([]);
	const [Datas, setDatas] = useState([]);
	const [coursesData, setcoursesData] = useState([]);
	const [name, setName] = React.useState(state.history.location.state.code);
	const [Error, setError] = React.useState("");
	const [token, settoken] = React.useState(localStorage.getItem("user"));
	const [tohome, setTohome] = React.useState(false);
	const [isActive, setisActive] = useState(
		state.history.location.state.isActive
	);
	const stateHistory = state.history.location.state;

	const [expiredDate, setexpiredDate] = useState(formatDate(state.history.location.state.expiredDate));
	const [flatDiscount, setflatDiscount] = useState(
		state.history.location.state.flatDiscount
	);
	const [percentDiscount, setpercentDiscount] = useState(
		state.history.location.state.percentDiscount
	);

	const options = [];
	const courseOptions = [];

	const SubscriptionArray = [];
	const CourseArray = [];

	const data = {
		headers: {
			"x-auth-token": token,
		},
	};
	function formatDate(date) {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	}

	async function fetchSubscriptionOrCourse() {
		const subs_response = await axios.get(api.getsubcoupon, data);
		setDatas(subs_response.data);

		const course_response = await axios.get(api.getCatalogCourseCoupon, data);
		setcoursesData(course_response.data);
	}

	function sortData() {
		Datas.map((data) => options.push({ label: data.title, value: data._id }));
		coursesData.map((data) => courseOptions.push({ label: data.title, value: data._id }));

	}
	function modifyData() {
		Sub.map((data) => {if(data.value){SubscriptionArray.push(data.value)}});
		Courses.map((data) =>{if(data.value){CourseArray.push(data.value)}});
	}

	function getData() {
		let state = []
		let course_state = []


		stateHistory.subscriptions.map((data) =>
			state.push({ label: data.title, value: data._id })
		);

		stateHistory.catalogCourses.map((data) =>
		course_state.push({ label: data.title, value: data._id })
		);

		setSub(state)
		setCourses(course_state)
	}

	useEffect(() => {
		getData();
		fetchSubscriptionOrCourse();
	}, []);

	if (tohome === true) {
		return <Redirect to="/coupon" />;
	}
	sortData();
	
	function handleSubmit() {
		modifyData();
		let body = {
			code: name,
			percentDiscount: percentDiscount,
			flatDiscount: flatDiscount,
			expiredDate: moment(expiredDate).endOf('day').utc(),
			isActive: isActive,
			subscriptions : SubscriptionArray,
			catalogCourses : CourseArray
		}
		
		axios
			.put(api.updateCoupon + state.history.location.state._id, body, data)
			.then(async (resp) => {
				if (resp.status === 200) {
					dispatch(actions.Success("Coupon updated Succssfully"));
					setTohome(true);
				}
			})
			.catch((error) => {
				dispatch(actions.Danger("Something went wrong"));
				setError(error.response.data);
			});
	}
	return (
		<div>
			<div className="d-flex flex-column-fluid">
				<div className=" container ">
					<div className="row">
						<div className="col-md-12">
							<div className="card card-custom">
								<div className="card-header">
										<h3 className="card-title"> Edit Coupon</h3>
									<div className="card-toolbar">
										<div className="example-tools justify-content-center">
											<Link
												className="btn btn-success font-weight-bolder font-size-sm"
												to="/coupon"
											>
												<i className="flaticon2-list"></i>
												All Coupon
											</Link>
										</div>
									</div>
								</div>
								<form className="form">
									<input type="hidden" />
									<div className="card-body">
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Code:
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													placeholder="Enter Coupon"
													value={name}
													onChange={(e) => {
														setName(e.target.value);
													}}
												/>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Subscriptions :
											</label>
											<div className="col-lg-3">
												<MultiSelect
													options={options}
													value={Sub}
													onChange={setSub}
													labelledBy="Select"
												/>
											</div>
											<label className="col-lg-2 col-form-label text-left">
												Course :
											</label>
											<div className="col-lg-3">
												<MultiSelect
													options={courseOptions}
													value={Courses}
													onChange={setCourses}
													labelledBy="Select"
												/>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Percent Discount:
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													value={percentDiscount}
													className="form-control "
													placeholder="Enter Percent discount"
													onChange={(e) => {
														setpercentDiscount(e.target.value);
													}}
												/>
											</div>
											<label className="col-lg-2 col-form-label text-left">
												Flat Discount:
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													value={flatDiscount}
													className="form-control "
													placeholder="Enter Flat Discount"
													onChange={(e) => {
														setflatDiscount(e.target.value);
													}}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Expire Date
											</label>
											<div className="col-lg-3">
												<input
													type="date"
													className="form-control "
													onChange={(e) => {
														setexpiredDate(e.target.value);
													}}
													value={expiredDate}
												/>
											</div>
											<label className="col-lg-2 col-form-label text-left">
												Is Active:
											</label>
											<div className="col-lg-3">
												<span className="switch switch-icon switch-success ">
													<label>
														<input
															type="checkbox"
															checked={isActive}
															onChange={(e) => setisActive(e.target.checked)}
														/>

														<span></span>
													</label>
												</span>
											</div>
										</div>
										<div className="form-group row">
											<p>{Error}</p>
											<label
												n
												className="col-lg-2 col-form-label text-left"
												style={{ color: "red", fontSize: "22px" }}
											></label>
											<div className="col-lg-3" hidden>
												<span className="switch switch-icon switch-success ">
													<label>
														<input type="hidden" value="0" name="is_active" />
														<input type="checkbox" value="1" name="is_active" />

														<span></span>
													</label>
												</span>
											</div>
										</div>
										<div className="form-group row"></div>
									</div>
									<div className="card-footer">
										<div className="row">
											<div className="col-lg-2"></div>
											<div className="col-lg-10">
												<button
													type="button"
													className="btn btn-success mr-2"
													onClick={handleSubmit}
												>
													Submit
												</button>
												<button type="reset" className="btn btn-secondary">
													Reset
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
