import React, { useState } from 'react'
import moment from 'moment';
import { useSnackbar } from "notistack";

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import axios from "../../../axios";
import DoubtStatusSkeleton from "./DoubtStatusSkeleton"

export default function DoubtStatusCount() {

    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [data, setData] = useState({ show: false, totalSolved: 0, totalUnAssigned: 0 });
    const { enqueueSnackbar } = useSnackbar()
    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }
    const handleChangeStartDate = (newValue) => { setStartDate(newValue); setData({ show: false }) }
    const handleChangeEndDate = (newValue) => { setEndDate(newValue); setData({ show: false }) }

    const handleSearch = async () => {
        setLoading(true)
        let url = `/admin/doubts/doubtStatus-count`;
        if (startDate && endDate) {
            url += `?fromDate=${moment(startDate).startOf('day').utc()}&toDate=${moment(endDate).endOf('day').utc()}`
        }else{
            setStartDate(null)
            setEndDate(null)
        }
        try {
            const response = await axios.get(url, config)
            const { totalSolved, totalUnAssigned } = response?.data
            setData({ show: true, totalSolved, totalUnAssigned })
            setLoading(false)
        } catch (error) {
            enqueueSnackbar(error?.response?.data, { variant: 'error' });
            setLoading(false)
        }
    }

    const dateFilter = (
        <Card sx={{ minWidth: 350 }}>
            <CardHeader title="Doubt Status Count" />
            <CardContent>
                <Grid sx={{ mt: 2 }} container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack spacing={3}>
                                <DesktopDatePicker
                                    label="Start Date"
                                    inputFormat="MM/dd/yyyy"
                                    value={startDate}
                                    onChange={handleChangeStartDate}
                                    renderInput={(params) => <TextField {...params} />}
                                />

                                <DesktopDatePicker
                                    label="End Date"
                                    inputFormat="MM/dd/yyyy"
                                    value={endDate}
                                    onChange={handleChangeEndDate}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <Button onClick={handleSearch} variant="contained">Search</Button>
                            </Stack>
                            {data?.show &&
                                <Stack sx={{ mt: 2 }} spacing={3}>
                                    <Divider></Divider>
                                    <Typography fontWeight={650} variant='h6'>
                                        Total Solved: {data?.totalSolved}
                                    </Typography>
                                    <Typography fontWeight={650} variant='h6'>
                                        Total Unassigned: {data?.totalUnAssigned}
                                    </Typography>
                                </Stack>}
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
    return (
        <div>
            {loading && <DoubtStatusSkeleton />}
            {!loading && dateFilter}
        </div>
    )
}
