import React from "react";
import "../../Css/root.css";
import { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "../../axios";
import api from "../../api";
import Form from "react-bootstrap/Form";
import {actions} from "../../Components/Toaster/ToastReducer";
import {useDispatch } from "react-redux";
import {DesktopDatePicker} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import moment from 'moment'

export default function SendSmsAllUser() {
    const dispatch = useDispatch();
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const [tohome, setTohome] = React.useState(false);
    const [maskID, setMaskID]=useState();
    const [message, setMessage]=useState();
    const [isActive, setisActive]=useState(false);
    const [goals, setGoals] = useState();
    const [subscription, setSubsCription] = useState();
    const [startTime, setStartTime] = React.useState("");
	const [endTime, setEndTime] = React.useState("");
  
    let data = {
        headers: {
            "x-auth-token": token,
        },
    };
    const handleSubmit =()=>{
        const url = `${api.SmsSendToAllUser}sId=${maskID}&parentSms=${isActive ? 1 : 0}`
        let body={
            smsBody : message
        }
        if(startTime && endTime){
            body.startTime =  startTime;
            body.endTime = endTime;
        }
        if (maskID && message) {
            axios
                .post(url, body, data)
                .then(async (resp) => {
                    const {
                        status,
                    } = resp;
                    if (status === 200 ) {
                        dispatch(actions.Success("Sms send to all user"));
                        setTohome(true);
                    }
                })
                .catch((error) => {
                    dispatch(actions.Danger("Something went wrong"));
                });
        }
    }
    if (tohome === true) {
        return <Redirect to="/sms" />;
    }
    return (
        <div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-header">
                                    <h3 className="card-title"> SEND SMS</h3>
                                    <div className="card-toolbar">
                                        <div className="example-tools justify-content-center">
                                            <Link
                                                className="btn btn-success font-weight-bolder font-size-sm"
                                                to="/subjects"
                                            >
                                                <i className="flaticon2-list"></i>
                                                 SEND SMS
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <form className="form">
                                    <input type="hidden" />
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Masking
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => {
                                                        setMaskID(e.target.value)
                                                    }}
                                                >
                                                    <option value="" defaultChecked>
                                                        Masking Type
                                                    </option>
                                                    <option value="sIdNonMasking">
                                                       sIdNonMasking
                                                    </option>
                                                    <option value="sIdMasking">
                                                      sIdMasking
                                                    </option>

                                                </Form.Control>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                        <div style={{ marginTop: '10px' }} className="col-md-2">
												<LocalizationProvider dateAdapter={AdapterDateFns}>
													<Stack spacing={3}>
														<DesktopDatePicker
															label="Start Date"
															inputFormat="MM/dd/yyyy"
															value={startTime}
															onChange={(newValue) => setStartTime(newValue)}
															renderInput={(params) => <TextField {...params} />}
														/>
													</Stack>
												</LocalizationProvider>
											</div>
											<div style={{ marginTop: '10px' }} className="col-md-2">
												<LocalizationProvider dateAdapter={AdapterDateFns}>
													<Stack spacing={3}>
														<DesktopDatePicker
															label="End Date"
															inputFormat="MM/dd/yyyy"
															value={endTime}
															onChange={(newValue) => setEndTime(newValue)}
															renderInput={(params) => <TextField {...params} />}
														/>
													</Stack>
												</LocalizationProvider>
											</div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Parents SMS:
                                            </label>
                                            <div className="col-lg-3">
                                                <span className="switch switch-icon switch-success ">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isActive}
                                                            onChange={(e) => setisActive(e.target.checked)}
                                                        />
                                                        <span></span>
                                                    </label>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-6">
                                                <div className="form-group green-border-focus">
                                                    <label style={{fontSize:'12px'}} for="exampleFormControlTextarea5">write message</label>
                                                    <textarea onChange={(e)=> setMessage(e.target.value)} className="form-control" id="exampleFormControlTextarea5" rows="5"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row"></div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-lg-2"></div>
                                            <div className="col-lg-10">
                                                <button
                                                    type="button"
                                                    className="btn btn-success mr-2"
                                                    onClick={handleSubmit}
                                                >
                                                    Send
                                                </button>
                                                <button type="reset" className="btn btn-secondary">
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
