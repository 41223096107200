import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';

export default function DoubtStatusSelector({selected, handleChangeStatus}) {
  const handleChange = (event) => {
    handleChangeStatus(event.target.value)
  };
  return (
    <Box display={'flex'} justifyContent={'center'}>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={selected}
          onChange={handleChange}
          label="Status"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"Assigned"}>Assigned</MenuItem>
          <MenuItem value={"Unassigned"}>Unassigned</MenuItem>
          <MenuItem value={"Replied"}>Replied</MenuItem>
          <MenuItem value={"Solved"}>Solved</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
