import React, { useEffect, useRef, useState } from "react"

/* @MUI COMPONENT */
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import MultiSelectorChip from "../../../Components/MultiSelectorChip"
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import RestoreIcon from '@mui/icons-material/Restore'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ListItemText from '@mui/material/ListItemText'
import Skeleton from '@mui/material/Skeleton'
import EditIcon from '@mui/icons-material/Edit';
import Divider from "@mui/material/Divider"
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

import { useSnackbar } from "notistack"

/* @CUSTOM COMPONENT */
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import RichTextEditor from "../../../Components/RichTextEditor"
import BasicCircularProgress from "../../../Components/BasicCircularProgress"
import DoubtSolverDialog from "./DoubtSolverDialog"
import ExamReviewerDialog from "./ExamReviewerDialog"
import UpdateEbook from "./UpdateEbook"
import SimpleDeleteModal from "../../../Components/SimpleDeleteModal"

const Input = styled('input')({
    display: 'none',
})

const courseServices = [
    {
        name: "Recorded Class",
        _id: "recorded"
        
    },
    {
        name: "Live Class",
        _id: "live"
        
    },
    {
        name: "Quiz",
        _id: "quiz"
    },
    {
        name: "Exam",
        _id: "exam"
    },
    {
        name: "Ebook",
        _id: "ebook"
    },
    {
        name: "Doubt",
        _id: "doubt"
    },
    {
        name: "Written Exam",
        _id: "written-exam"
    }
]

const TAG = "CourseAddUpdateDialog"
const generateKey = (pre) => {
    return `${TAG}_${ pre }_${ new Date().getTime() }`
}

export default function CourseAddUpdateDialog(props) {
    const [courseLoading, setCourseLoading] = useState(false)
    const [educatorLoading, setEducatorLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [ebookLoading, setEbookLoading] = useState(false)
    const [educators, setEducators] = useState([])

    const [title, setTitle] = useState('')
    const [introVideo , setIntroVideo] = useState("")
    const [order, setOrder] = useState('')
    const [isAppOnly, setIsAppOnly] = useState(false)
    const [isActive, setIsActive] = useState(true)
    const [isFree, setIsFree] = useState(false)
    const [description, setDescription] = useState('')
    const [banner, setBanner] = useState('')
    const [selectedEducators, setSelectedEducators] = useState([])
    const [selectedServices, setSelectedServices] = useState([])
    const [subjects, setSubjects] = useState([])

    const [ebookFile, setEbookFile] = useState({isShow : false , pdf :{}, thumbnai :{}, title :'' })
    const [bannerFile, setBannerFile] = useState('')
    const [selectedEbook, setSelectedEbook]=useState([])
    const [updateEbook, setUpdateEbook]=useState('')
    const [errorMessage, setErrorMessage] = useState({})
    const editorRef = useRef(null)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [openSolverDialog, setOpenSolverDialog] = useState(false)
    const [openExamReviewerDialog, setOpenExamReviewerDialog] = useState(false)

    const [deleteModalEbookId, setDeleteModalEbookId] = useState('');

    const subscriptionId = props.subscriptionId

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    const fileUploadconfig = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
            "Content-Type" : "multipart/form-data"
        }
    }

    const educatorData = {
        title: "Educator",
        items: educators,
        index: "name",
        selected: selectedEducators
    }

    const servicesData = {
        title: "Services",
        items: courseServices,
        index: "name",
        selected: selectedServices
    }

    function resetFormData() {
        setTitle('')
        setIntroVideo('')
        setOrder('')
        setIsActive(true)
        setIsFree(false)
        setDescription('')
        setSelectedEducators([])
        setSelectedServices([])
        setErrorMessage({})
    }

    const handleEducatorSelection = (value) => {
        if(!value.length) {
            setErrorMessage(message => {
                return {...message, educator: 'Select at least one teacher.'}
            })
        } else {
            setErrorMessage(message => {
                return {...message, educator: ''}
            })
        }
        setSelectedEducators(value)
    }

    const handleServiceSelection = (value) => {
        if(!value.length) {
            setErrorMessage(message => {
                return {...message, service: 'Select at least one service.'}
            })
        } else {
            setErrorMessage(message => {
                return {...message, service: ''}
            })
        }
        setSelectedServices(value)

        if(value.includes('ebook')){
            setEbookFile({isShow : true, pdf : {}, thumbnai : {}})
        }else{
            setEbookFile({isShow : false, pdf : {}, thumbnai : {}})
        }
    }

    const onUploadBanner = ({ target }) => {
        if(target.files[0]) {
            setBannerFile(target.files[0])
        }
    }

    const handleClose = () => {
        props.handleClose()
    }

    const handleReset = () => (
        resetFormData()
    )

    const handleSubmit = () => {
        if(!isFormDataValid()) return
        props.courseId ? updateCourse() : addNewCourse()
    }

    const isFormDataValid = () => {
        var isValid = true
        if(!title.length) {
            isValid = false
            setErrorMessage(message => {
                return {...message, title: 'Title must not be empty.'}
            })
        }
        if(introVideo) {
            if(introVideo.includes("watch?v")){
                isValid = false
                setErrorMessage(message => {
                    return {...message, introVideo: 'Please enter valid youtube video.'}
                })
            }
        }
        if(!order) {
            isValid = false
            setErrorMessage(message => {
                return {...message, order: 'Order must be a valid number'}
            })
        }
        if(!selectedEducators.length) {
            isValid = false
            setErrorMessage(message => {
                return {...message, educator: 'Select at least one teacher.'}
            })
        }
        if(!selectedServices.length) {
            isValid = false
            setErrorMessage(message => {
                return {...message, service: 'Select at least one service.'}
            })
        }
        if(editorRef.current && editorRef.current.getContent() === '') {
            isValid = false
            enqueueSnackbar("Description can not be empty", { variant: 'error' })
        }
        return isValid
    }

    const deleteEbook = async(ebookId)=>{
        if(!ebookId) return enqueueSnackbar("Invalid ebook", {variant: 'error'})
        setEbookLoading(true)
        try{
            const response = await axios.delete(api.deleteEbook + `?ebookId=${ebookId}&courseId=${props.courseId}`, config)
            enqueueSnackbar(response.data, {variant: 'success'})
            setEbookLoading(false)
            setDeleteModalEbookId('')
            fetchCourseData(props.courseId)
        }catch(error){
            enqueueSnackbar(error.response?.data, {variant: 'error'})
            setEbookLoading(false)
            setDeleteModalEbookId('')
        }
    }
    async function fetchCourseData(courseId) {
        setCourseLoading(true)
        axios
            .get(api.getCourseById + courseId, config)
            .then((response) => {
                setTitle(response.data.title)
                setIntroVideo(response?.data?.introVideo)
                setIsActive(response.data.isActive)
                setIsFree(response.data.isFree)
                setDescription(response.data.description)
                setSelectedEducators(response.data.teachers)
                setSelectedServices(response.data.services)
                if(response.data.services.includes('ebook')){
                    setEbookFile({...ebookFile, isShow: true});
                }
                setSelectedEbook(response.data.ebooks);
                setSubjects(response?.data?.subjects)
                if(response?.data?.isAppOnly) setIsAppOnly(response?.data?.isAppOnly)
                if(response.data.order) setOrder(response.data.order)
                if(response.data.banner) setBanner(response.data.banner)
                setCourseLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Course data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function fetchEducators() {
        setEducatorLoading(true)
        axios
            .get(api.getCourseExplorerEducators, config)
            .then((response) => {
                setEducators(response.data)
                setEducatorLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Educator fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function addNewCourse() {
        setSubmitLoading(true)
        const coursePayload = {
            title: title,
            description: editorRef.current.getContent(),
            introVideo: introVideo,
            isAppOnly: isAppOnly,
            category: {
                value: 'subscription',
                parent: props.subscriptionId
            },
            subjects: [],
            order: order,
            isFree: isFree,
            isActive: isActive,
            teachers: selectedEducators,
            services: selectedServices
        }
        axios
            .post(api.createCourse, coursePayload, config)
            .then((response) => {
                let courseId = response.data
                axios
                    .post(
                        api.addCourseToSubscription,
                        {
                            courseId: courseId,
                            subscriptionId: subscriptionId
                        },
                        config
                    )
                    .then((response) => {
                        enqueueSnackbar(response.data, { variant: 'success' })
                        addNewEbook(courseId)
                        uploadCourseBanner(courseId)
                        setSubmitLoading(false)
                        handleClose()
                        props.handleSuccess()
                    })
                    .catch((error) => {
                        enqueueSnackbar("Add new course failed: " + error.response.data, { variant: 'error' })
                    })
            })
            .catch((error) => {
                enqueueSnackbar("Add new course failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function updateCourse() {
        const updatePayload = {
            title: title,
            introVideo: introVideo,
            description: editorRef.current.getContent(),
            order: order,
            isFree: isFree,
            isActive: isActive,
            teachers: selectedEducators,
            services: selectedServices,
            isAppOnly: isAppOnly
        }
        axios
            .put(api.updateCourse + props.courseId, updatePayload, config)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
                if(ebookFile.isShow && ebookFile.pdf && ebookFile.thumbnai && ebookFile.title){
                    addNewEbook(props.courseId)
                }
                uploadCourseBanner(props.courseId)
                handleClose()
                props.handleSuccess()
            })
            .catch((error) => {
                enqueueSnackbar("Update course failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function addNewEbook(courseId) {
        if(!ebookFile.pdf && !ebookFile.title) return enqueueSnackbar("Ebook file pdf and title required", { variant: 'error' })
        let formData = new FormData();
        formData.append("ebook", ebookFile.pdf);
        formData.append("thumbnail", ebookFile.thumbnai);
        formData.append("title", ebookFile.title);
        axios
            .post(api.AddNewEbook + courseId, formData, fileUploadconfig)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
            })
            .catch((error) => {
                enqueueSnackbar("Upload ebook failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function uploadCourseBanner(courseId) {
        if (!bannerFile) return
        let formData = new FormData()
        formData.append("banner", bannerFile)

        axios.post(api.uploadCourseBanner + courseId, formData, fileUploadconfig)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
            })
            .catch((error) => {
                enqueueSnackbar("Upload course banner failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        // TODO: Stop initial loading
        if(!props.open) {
            return
        }
        props.courseId ? fetchCourseData(props.courseId) : setCourseLoading(false)
        fetchEducators()
    }, [props.open])

    const appBar = (
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Add or Update Course
                </Typography>
                <LoadingButton
                    color="secondary"
                    onClick={handleSubmit}
                    loading={courseLoading || educatorLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
                <LoadingButton
                    color="inherit"
                    sx={{ ml: 2 }}
                    onClick={handleReset}
                    loading={courseLoading || educatorLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<RestoreIcon />}
                    variant="contained"
                >
                    Reset
                </LoadingButton>
            </Toolbar>
        </AppBar>
    )

    const titleField = (
        <TextField
            required
            error={!!errorMessage.title}
            variant="outlined"
            label="Title"
            helperText={errorMessage.title}
            sx={{ minWidth: 300 }}
            value={title}
            onChange={(event) => {
                const text = event.target.value
                if(!text.length) {
                    setErrorMessage(message => {
                        return {...message, title: 'Title must not be empty.'}
                    })
                } else {
                    setErrorMessage(message => {
                        return {...message, title: ''}
                    })
                }
                setTitle(event.target.value)
            }}
        />
    )

    const introField = (
        <TextField
            required
            error={!!errorMessage.introVideo}
            variant="outlined"
            label="Intro Video"
            helperText={errorMessage.introVideo}
            sx={{ minWidth: 300 }}
            value={introVideo}
            onChange={(event) => {
                setIntroVideo(event.target.value)
            }}
        />
    )

    const appOnlyField = (
        <FormGroup>
            <FormControlLabel
                onChange={(event) => setIsAppOnly(event.target.checked)}
                control={<Checkbox checked={isAppOnly} />}
                label="Available only for app"
            />
        </FormGroup>
    )

    const orderField = (
        <TextField
            required
            error={!!errorMessage.order}
            variant="outlined"
            type="number"
            label="Order"
            helperText={errorMessage.order}
            sx={{ minWidth: 300 }}
            value={order}
            onChange={(event) => {
                const order = event.target.value
                if(!order) {
                    setErrorMessage(message => {
                        return {...message, order: 'Order must be a valid number.'}
                    })
                } else {
                    setErrorMessage(message => {
                        return {...message, order: ''}
                    })
                }
                setOrder(event.target.value)
            }}
        />
    )

    const isActiveSwitch = (
        <FormControlLabel
            label="Is Active"
            control={
                <Switch
                    checked={isActive}
                    onChange={(event) => setIsActive(event.target.checked)}
                />
            }
        />
    )

    const isFreeSwitch = (
        <FormControlLabel
            label="Is Free"
            control={
                <Switch
                    checked={isFree}
                    onChange={(event) => setIsFree(event.target.checked)}
                />
            }
        />
    )

    const educatorSelector = (
        <Box sx={{ maxHeight: 400, overflow: 'auto', p: 2 }}>
            <MultiSelectorChip
                required
                error={!!errorMessage.educator}
                helperText={errorMessage.educator}
                data={educatorData}
                handleSelection={handleEducatorSelection}
                sx={{ width: 400 }}
            />
        </Box>
    )

    const serviceSelector = (
        <Box sx={{ maxHeight: 400, overflow: 'auto', p: 2 }}>
            <MultiSelectorChip
                required
                error={!!errorMessage.service}
                helperText={errorMessage.service}
                data={servicesData}
                handleSelection={handleServiceSelection}
                sx={{ width: 400 }}
            />
        </Box>
    )

    const richTextEditor = (
        <RichTextEditor
            sx={{ width: 600, height: 600 }}
            initialValue={description}
            onInit={(_evt, editor) => {
                editorRef.current = editor
            }}
        />
    )

    const bannerUploadCard = (
        <Card sx={{ width: 200 }}>
            {
                bannerFile ?
                    <CardMedia
                        component="img"
                        height="118"
                        image={URL.createObjectURL(bannerFile)}
                    /> : (
                        banner ?
                            <CardMedia
                                component="img"
                                height="118"
                                image={"https://cdn.rootsedulive.com/" + banner}
                            /> : <Skeleton variant="rectangular" width={200} height={118} />
                    )
            }
            <CardActions disableSpacing>
                <label htmlFor="subject-banner">
                    <Input onChange={onUploadBanner} accept="image/*" id="subject-banner" type="file" />
                    <Button component="span" size="small">Upload Banner</Button>
                </label>
            </CardActions>
        </Card>
    )

    const onUploadEbookThumbnail = ({ target }) =>{
        setEbookFile({...ebookFile, thumbnai : target.files[0]})
    }

    const onUploadEbookPdf = ({ target }) =>{
        setEbookFile({...ebookFile, pdf : target.files[0]})
    }
    
    const fileUploadCard = (
        <Card elevation={2} sx={{ width: 400, p: 2 }}>
            <Typography align="center" gutterBottom variant="h5" component="div">
                Upload Ebook
            </Typography>
            <TextField
                sx={{ width: '100%' }}
                required
                id="outlined-required"
                label="Ebook title"
                defaultValue=""
                onChange={(e) => setEbookFile({ ...ebookFile, title: e.target.value })}
            />
            <Grid sx={{ width: '100%' }}>
                <label htmlFor="contained-button-file-thumbnail">
                    <Input onChange={onUploadEbookThumbnail} accept="image/*" id="contained-button-file-thumbnail" type="file" />
                    <Button sx={{ mt: 2, width: '100%' }} variant="contained" component="span">
                        Upload ebook thumbnail
                    </Button>
                </label>

                {ebookFile.thumbnai.name ?
                    <Typography sx={{ mt: 2, width: '100%' }} gutterBottom variant="body" component="div">
                        Uploaded thumbnail : {ebookFile.thumbnai.name}
                    </Typography> : null
                }
            </Grid>
            <Grid sx={{ width: '100%' }}>
                <label htmlFor="contained-button-file-pdf">
                    <Input onChange={onUploadEbookPdf} accept=".pdf" id="contained-button-file-pdf" type="file" />
                    <Button sx={{ mt: 2 }} variant="contained" component="span">
                        Upload ebook pdf
                    </Button>
                </label>

                {ebookFile.pdf.name ?
                    <Typography sx={{ mt: 2 }} gutterBottom variant="body" component="div">
                        Uploaded pdf  : {ebookFile.pdf.name}
                    </Typography> : null
                }

            </Grid>
        </Card>
    )

    const selectedEbookList = (
        <Card elevation={2} sx={{ width: 400, p: 2 }}>
            <Typography variant="body1" gutterBottom align="center">
                Uploaded Ebook
            </Typography>
            <Divider/>
            <Box sx={{ p: 2 }}>
                {selectedEbook.map((value, index) => {
                    return (
                        <div key={index}>
                            <Stack sx={{ p: 1 }} direction={'row'} alignItems={'center'} spacing={3}>
                                <ListItemText id={value._id} primary={`${value.title}`} />
                                <IconButton onClick={() => window.open(`https://api.rootsedulive.com/bucket/${value.fileUrl}`)}>
                                    <OpenInNewIcon />
                                </IconButton>
                                <IconButton onClick={() => setUpdateEbook(value)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => setDeleteModalEbookId(value._id)}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Stack>
                            <Divider />
                        </div>
                    );
                })}
            </Box>
        </Card>
    )
    return (
        <Dialog fullScreen disableEnforceFocus open={Boolean(props.open)} onClose={handleClose}>
            {appBar}
            {(courseLoading || educatorLoading || ebookLoading) ? <BasicCircularProgress /> :
                <Box sx={{ m: 2 }}>
                    <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={8}>
                        <Stack direction="column" spacing={4}>
                            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                                {titleField}
                                {orderField}
                            </Stack>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                {introField}
                                {isActiveSwitch}
                                {isFreeSwitch}
                            </Stack>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                {appOnlyField}
                            </Stack>
                            <Stack direction="column" justifyContent="center" alignItems="center" spacing={4}>
                                {educatorSelector}
                                {serviceSelector}
                                {selectedEbook.length > 0 ? selectedEbookList : null}
                                {ebookFile.isShow ? fileUploadCard : null }
                            </Stack>
                        </Stack>
                        {richTextEditor}
                        <Stack direction="column" spacing={4}>
                            {bannerUploadCard}
                            {
                                selectedServices.includes("doubt") &&
                                    <Button
                                        variant="contained"
                                        component="span"
                                        onClick={(_event) => setOpenSolverDialog(true)}
                                    >
                                        Open Doubt Solver Menu
                                    </Button>
                            }
                            {
                                selectedServices.includes("written-exam") &&
                                    <Button
                                        variant="contained"
                                        component="span"
                                        onClick={(_event) => setOpenExamReviewerDialog(true)}
                                    >
                                        Open Exam Reviewer Menu
                                    </Button>
                            }
                        </Stack>
                    </Stack>
                </Box>}
            <DoubtSolverDialog
                key={generateKey("solver-dialog")}
                open={openSolverDialog}
                courseId={props.courseId}
                subjects={subjects}
                handleClose={() => setOpenSolverDialog(false)}
            />

            <ExamReviewerDialog
                key={generateKey("Exam Reviewer dialog")}
                open={openExamReviewerDialog}
                courseId={props.courseId}
                subjects={subjects}
                handleClose={() => setOpenExamReviewerDialog(false)}
            />
            {updateEbook && <UpdateEbook
                onCloseEbook={() => setUpdateEbook(null)}
                handleSuccess={()=>{setUpdateEbook(null);fetchCourseData(props.courseId)}}
                ebookId={updateEbook._id}
                title={updateEbook.title}
                open={updateEbook ? true : false}
            />}
            <SimpleDeleteModal
                title="Delete Ebook"
                open={deleteModalEbookId ? true : false}
                handleClose={() => {
                    setDeleteModalEbookId('')
                }}
                handleDelete={() => deleteEbook(deleteModalEbookId)}
            />
        </Dialog>
    )
}