import React from 'react';
import Button from '@mui/material/Button';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from "../../axios";
import moment from 'moment';

export default function AffiliateEducatorPaymentExport({ selectedCode, courseId, subscriptionId, payment, fileName, startTime, endTime }) {
    const token = localStorage.getItem("user");
    const fetchData = async(selectedCode)=>{
        setloading(true)
        try{
            let body={
                coupon : selectedCode,
            }
            if(startTime && endTime){
              body.startTime = moment(startTime).startOf('day').utc()
              body.endTime = moment(endTime).endOf('day').utc()
            }
            if(courseId){
                body.courseId = courseId
            }
            if(subscriptionId){
                body.subscriptionId = subscriptionId
            }
            const response = await axios.post('/admin/affiliate-payments/get-educator-payments', body, {headers: {
                "x-auth-token": token,
              }})
          
            setloading(false);
            return response.data.result;
        }catch(error){

        }
    }
    const [loading, setloading] = React.useState(false);
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = async(selectedCode, payment, fileName, startTime, endTime) => {
        const apiData = []
        var couponInterval = 0;
        while(couponInterval <= selectedCode.length){
            const apiFetchData = await fetchData(selectedCode.slice(couponInterval, couponInterval+100));
            couponInterval = couponInterval + 100;
            apiData.push(...apiFetchData)
        }
        let excelData = []

        for(var index in apiData){
            let subscriptiontotalStoreAmount = 0;
            let subscriptiontotalReferralAmount = 0;
            let catalogCourseStoreAmount = 0;
            let catalogCourseReferralAmount = 0;
        
            if(apiData[index]?.subscriptiontotalStoreAmount){
                subscriptiontotalStoreAmount = Number(apiData[index]?.subscriptiontotalStoreAmount?.toFixed(2))
            }
            if(apiData[index]?.subscriptiontotalReferralAmount){
                subscriptiontotalReferralAmount = Number(apiData[index]?.subscriptiontotalReferralAmount?.toFixed(2))
            }
            if(apiData[index]?.catalogCourseStoreAmount){
                catalogCourseStoreAmount = Number(apiData[index]?.catalogCourseStoreAmount?.toFixed(2))
            }
            if(apiData[index]?.catalogCourseReferralAmount){
                catalogCourseReferralAmount = Number(apiData[index]?.catalogCourseReferralAmount?.toFixed(2))
            }
           
            let item = {
                Coupon: apiData[index].code,
                Name: apiData[index].userName,
                Email: apiData[index].userEmail,
                Phone: apiData[index].phone,
                "Subscription Sale": subscriptiontotalStoreAmount,
                "Subscription Referral": subscriptiontotalReferralAmount,
                "Personal Course Sale": catalogCourseStoreAmount,
                "Personal Course Referral": catalogCourseReferralAmount,
                "Total Referral": subscriptiontotalReferralAmount + catalogCourseReferralAmount
            };

            if(startTime && endTime){
                item.StartDate = startTime
                item.EndDate = endTime
            }
            excelData.push(item);
        }
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return <Button onClick={() => exportToCSV(selectedCode, payment, fileName, startTime, endTime)} color="secondary" variant="contained"> {loading ?<>loading</>:<>Export to excel</>}</Button>
}
