import React from "react";
import "../../Css/root.css";
import { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import axios from "../../axios";
import api from "../../api";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import {actions} from "../../Components/Toaster/ToastReducer";
import { StaticTimePicker } from "@mui/lab";

export default function EditAffiliatePayment(state) {
	const dispatch=useDispatch();
	const [Sub, setSub] = useState([]);
	const [Datas, setDatas] = useState([]);
	const [name, setName] = React.useState(state.history.location.state.code);
	const [Error, setError] = React.useState("");
	const [token, settoken] = React.useState(localStorage.getItem("user"));
	const [tohome, setTohome] = React.useState(false);

	const [disbursedAmount, setDisBurseAmount] = React.useState(state.location.state.disbursedAmount)
	const [disburseDate, setDisBurseDate] = React.useState(formatDate(state.location.state.disburseDate))
	
	const data = {
		headers: {
			"x-auth-token": token,
		},
	};
	function formatDate(date) {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	}
	async function my_async_fn() {
		const response = await axios.get(api.getsubcoupon, data);
		setDatas(response.data);
	}
	
	if (tohome === true) {
		return <Redirect to="/affiliate-payment" />;
	}
	
	function handleSubmit() {
		if(!disburseDate && !disbursedAmount) return actions.Danger("Please select date and amount");
		const body = {
			user : state.location.state.user._id,
			disbursedAmount : disbursedAmount,
			disburseDate : disburseDate
		};
		axios
			.put(api.affiliateUpdateAmount + state.history.location.state._id, body, data)
			.then(async (resp) => {
				if (resp.status === 200) {
					dispatch(actions.Success("Affiliate payment updated Succssfully"));
					setTohome(true);
				}
			})
			.catch((error) => {
				dispatch(actions.Danger("Something went wrong"));
				setError(error.response.data);
			});
	}
	return (
		<div>
			<div className="d-flex flex-column-fluid">
				<div className=" container ">
					<div className="row">
						<div className="col-md-12">
							<div className="card card-custom">
								<div className="card-header">
									<h3 className="card-title"> Edit Coupon</h3>
									<div className="card-toolbar">
										<div className="example-tools justify-content-center">
											<Link
												className="btn btn-success font-weight-bolder font-size-sm"
												to="/affiliate-payment"
											>
												<i className="flaticon2-list"></i>
												All Affiliate Payment
											</Link>
										</div>
									</div>
								</div>
								<form className="form">
									<input type="hidden" />
									<div className="card-body">

									<div className="form-group row">
											<h2 className="col-lg-4 col-form-label text-left ltb">
												Name : {state.location.state.user.name}
											</h2>
											
									 </div>

									 <div className="form-group row">
											<h2 className="col-lg-4 col-form-label text-left ltb">
												Email : {state.location.state.user.email}
											</h2>
											
									 </div>

									 <div className="form-group row">
											<h2 className="col-lg-4 col-form-label text-left ltb">
												Phone : {state.location.state.user.phone}
											</h2>
											
									 </div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												DisBurse Amount:
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													placeholder="Enter Amount"
													value={disbursedAmount}
													onChange={(e) => {
														setDisBurseAmount(e.target.value);
													}}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Disburse Date
											</label>
											<div className="col-lg-3">
												<input
													type="date"
													className="form-control "
													onChange={(e) => {
														//setexpiredDate(e.target.value);
													    setDisBurseDate(e.target.value);
													}}
													value={disburseDate}
												/>
											</div>
										</div>
									</div>
									<div className="card-footer">
										<div className="row">
											<div className="col-lg-2"></div>
											<div className="col-lg-10">
												<button
													type="button"
													className="btn btn-success mr-2"
													onClick={handleSubmit}
												>
													Submit
												</button>
												<button type="reset" className="btn btn-secondary">
													Reset
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
