import React, { useState } from 'react'
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import Stack from "@mui/material/Stack"
import Card from "@mui/material/Card"
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'

import BasicCircularProgress from '../../../Components/BasicCircularProgress';
import MultiSelectorChip from '../../../Components/MultiSelectorChip'
import axios from '../../../axios';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const roles = [
    { _id: "superAdmin", title: "Super Admin" },
    { _id: "admin", title: "Admin" },
    { _id: "contentAdmin", title: "Content Admin" },
    { _id: "smsAdmin", title: "Sms Admin" },
    { _id: "student", title: "Student" },
    { _id: "educator", title: "Educator" },
    { _id: "brandPartner", title: "Brand Partner" },
    { _id: "doubtSolver", title: "Doubt Solver" },
    { _id: "orderAdmin", title: "Order Admin" },
    { _id: "affiliateAdmin", title: "Affiliate Admin" },
    { _id: "examReviewer", title: "Exam Reviewer" }
]

export default function RolseWiseUserLogout() {
    const [loading, setLoading] = useState(false)
    const [removeRole, setRemoveRole] = useState(false)
    const [selectedRoles, setSelectedRoles] = useState([]);
    const history = useHistory()

    const { enqueueSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        },
    };

    const solverData = {
        title: "User Roles",
        index: "title",
        items: roles,
        selected: selectedRoles,
    };

    const handleChange = (event) => {
        setRemoveRole(event.target.checked);
    };

    const handleClear = () => {
        setRemoveRole(false)
        setSelectedRoles([])
    }

    const handleSubmit = async () => {
        if (selectedRoles.length <= 0) return enqueueSnackbar("Empty role list", { variant: "warning" })

        let body = {
            roles: selectedRoles
        }
        if (removeRole) {
            body.removeRole = removeRole
        }
        try {
            setLoading(true)
            const response = await axios.post('/admin/users/logout-by-roles', body, config)
            enqueueSnackbar(response?.data, { variant: "success" })
            setSelectedRoles([])
            setRemoveRole(false);
            setLoading(false)
            history.push('/users')
        } catch (error) {
            enqueueSnackbar(error?.response?.data, { variant: "error" })
            setLoading(false)
        }
    }

    return (
        <Card>
            {loading && <BasicCircularProgress />}
            <CardHeader
                title={"Bulk User Logout by Role"}
            />
            <CardContent>
                <Stack width={"100%"} justifyContent={'center'} alignItems={'center'} sx={{ p: 2 }} spacing={4}>

                    <Paper variant='outlined' sx={{ p: 2 }}>
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            minWidth={450}
                        >
                            <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                <MultiSelectorChip
                                    data={solverData}
                                    handleSelection={(roles) => setSelectedRoles(roles)}
                                    sx={{ width: 400 }}
                                />
                                <FormGroup>
                                    <FormControlLabel sx={{ maxWidth: '200px' }} control={<Checkbox checked={removeRole} onChange={handleChange} />} label="Remove Role" />
                                </FormGroup>
                            </Stack>
                            <Stack spacing={3} justifyContent={'center'} sx={{ mt: 2 }} alignItems={'center'} direction={'row'}>
                                <Button onClick={handleSubmit} variant="contained">Submit</Button>
                                <Button onClick={handleClear} variant="outlined">Clear</Button>
                            </Stack>
                        </Box>
                    </Paper>
                </Stack>
            </CardContent>
        </Card>
    )
}
