import React, { useState } from 'react'
import axios from "../../../axios"
import api from "../../../api"
import { useSnackbar } from "notistack"
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from "@mui/material/Button"
import TextField from '@mui/material/TextField';
import ExcelToUploadPhone from './ExcelToUploadPhone';

export default function SendDynamicSms(props) {
    const [users, setUsers] = useState([])
    const [message, setMessage] = useState("")
    const [walletAmount, setWalletAmount] = useState()
    const [maskID, setMaskID] = useState("");
    const { enqueueSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        },
    };

    const handleSubmit = async () => {
        if (!users.length) return enqueueSnackbar("User list is not allowed to be empty", { variant: 'error' })
        if (!maskID) return enqueueSnackbar("Masking Id is not allowed to be empty", { variant: 'error' })
        if (!message) return enqueueSnackbar("Message is not allowed to be empty", { variant: 'error' })
        try {
            const response = await axios.post(api.smsSendDynamic, { users: users ,sId : maskID }, config)
            setMessage("")
            enqueueSnackbar("" + response?.data, { variant: 'success' })
        } catch (error) {
            enqueueSnackbar("Dynamic sms send failed : " + error.response.data, { variant: 'error' })
        }
    }

    const form = (
        <Paper variant="outlined" sx={{ mt: 2, p: 2, width: { md: 400, xs: 300 } }} square>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={'4'}
            >
                <FormControl fullWidth sx={{ mt: 2, mr: 2, p: 1, }}>
                    <InputLabel id="demo-simple-select-label">Sid</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={maskID}
                        label="type"
                        onChange={(e) => setMaskID(e.target.value)}
                    >
                        <MenuItem defaultChecked disable value={""}>Select Mask</MenuItem>
                        <MenuItem value={"sIdNonMasking"}>Non Masking</MenuItem>
                        <MenuItem value={"sIdMasking"}>Masking</MenuItem>
                    </Select>

                    <TextField
                        id="outlined-multiline-static"
                        label="Write Message"
                        multiline
                        value={message}
                        rows={5}
                        defaultValue=""
                        onChange={(e)=>setMessage(e.target.value)}
                        sx={{width : '100%', mt : 2, mb : 1}}
                    />

                    {message && <ExcelToUploadPhone message={message} handleMergeUser={(user) => setUsers(user)} />}
                    <Button onClick={handleSubmit} sx={{ w: '100%', color: 'green', mt: 2, color: 'white' }} variant="contained">Submit</Button>
                </FormControl>
            </Grid>
        </Paper>
    )
    return (
        <React.Fragment>
            <Box sx={{ p: 2, mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} component={Paper} elevation={4}>
                <Typography fontWeight={800} variant='h5' gutterBottom align='center'>Dynamic bulk SMS</Typography>
                {form}
            </Box>
        </React.Fragment>
    )
}
