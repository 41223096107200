import React, { useEffect, useState } from "react"
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import PreviewIcon from '@mui/icons-material/Preview'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Stack from '@mui/material/Stack'
import { useSnackbar } from "notistack"
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import CardContent from '@mui/material/CardContent'
import IconLabelListSkeleton from "../../../Components/IconLabelListSkeleton"
import ExamPreview from "../../../Components/ExamPreview"

export default function QuizAddDialog(props) {
    const [quizzes, setQuizzes] = useState([])
    const [selectedQuiz, setSelectedQuiz] = useState('')
    const [previewQuiz, setPreviewQuiz] = useState('')

    const [quizLoading, setQuizLoading] = useState(false)
    const [previewLoading, setPreviewLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user")
        }
    }

    const handleQuizSelection = (quizId) => {
        setSelectedQuiz(quizId)
        setPreviewQuiz('')
    }

    const handlePreview = (quizId) => {
        fetchQuizById(quizId)
    }

    const handleSubmit = () => {
        if(!selectedQuiz) {
            enqueueSnackbar("Please select a quiz", { variant: 'error' })
            return
        }
        addQuizToClass()
    }

    const handleClose = () => {
        props.handleClose()
    }

    async function fetchQuizBySubject(subjectId) {
        setQuizLoading(true)
        axios
            .get(api.getQuizByOldSubject + subjectId, config)
            .then((response) => {
                setQuizLoading(false)
                setQuizzes(response.data)
            })
            .catch((error) => {
                enqueueSnackbar("Quiz data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function fetchQuizById(quizId) {
        setPreviewLoading(true)
        axios
            .get(api.getQuizById + quizId, config)
            .then((response) => {
                setPreviewLoading(false)
                setPreviewQuiz(response.data)
            })
            .catch((error) => {
                enqueueSnackbar("Quiz preview failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function addQuizToClass() {
        setSubmitLoading(true)
        axios
            .post(
                api.addQuizToClass,
                {
                    classId: props.classId,
                    quizId: selectedQuiz
                },
                config
            )
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
                setSubmitLoading(false)
                handleClose()
            }).catch((error) => {
                enqueueSnackbar("Add quiz to class failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        if(!props.open) return
        fetchQuizBySubject(props.subjectId)
    }, [props.open])

    const appBar = (
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Add Quiz to Class
                </Typography>
                <LoadingButton
                    color="secondary"
                    onClick={handleSubmit}
                    loading={quizLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
            </Toolbar>
        </AppBar>
    )

    const quizItemsSkeleton = [1, 2, 3].map((value) => <IconLabelListSkeleton key={value} />)

    const quizItem = (item) => {
        return (
            <ListItem
                key={item._id}
                disablePadding
                secondaryAction={
                    <Tooltip title="Preview">
                        <IconButton edge="end" aria-label="preview" onClick={(_event) => handlePreview(item._id)}>
                            <PreviewIcon />
                        </IconButton>
                    </Tooltip>
                }
            >
                <ListItemButton onClick={(_event) => handleQuizSelection(item._id)}>
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={selectedQuiz === item._id}
                            tabIndex={-1}
                            disableRipple
                        />
                    </ListItemIcon>
                    <ListItemText>{item.title}</ListItemText>
                </ListItemButton>
            </ListItem>
        )
    }

    return (
        <Dialog fullScreen disableEnforceFocus open={Boolean(props.open)} onClose={handleClose}>
            {appBar}
            <Box sx={{ m: 2 }}>
                <Stack direction="row" spacing={4}>
                    <Stack direction="column">
                        <Card sx={{ width: "20vw" }}>
                            <CardHeader title="Quizzes" />
                            <CardContent>
                                <List>
                                    {quizLoading ? quizItemsSkeleton : quizzes.map(quizItem)}
                                </List>
                            </CardContent>
                        </Card>
                    </Stack>
                    <Stack direction="column">
                        {previewLoading ? null : (previewQuiz && <ExamPreview sx={{ width: "60vw" }} exam={previewQuiz} />)}
                    </Stack>
                </Stack>
            </Box>
        </Dialog>
    )
}