import React, { useState } from 'react'
import * as XLSX from 'xlsx';
import axios from "../../../axios"
import api from "../../../api"
import { useSnackbar } from "notistack"
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Skeleton from '@mui/material/Skeleton'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button"
import { styled } from '@mui/material/styles'
import { green } from "@mui/material/colors"
import {DatePicker} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
const Input = styled('input')({
    display: 'none',
})
export default function BulkCouponUpdate() {
    const [coupons, setCoupons] = useState([])
    const [fileInfo, setFileInfo] = useState({ fileName: '', fileSize: '', totalCoupon: 0 })
    const [formData, setFormData] = useState({ percentDiscount: null, flatDiscount: null, expiredDate: null, isActive: true })

    const { enqueueSnackbar } = useSnackbar()

    const handleSubmit = async () => {
        if (!coupons.length) return enqueueSnackbar("Coupon list is not allowed to be empty", { variant: 'warning' })
        let couponPayload = {}
        if (formData.percentDiscount) {
            couponPayload.percentDiscount = formData.percentDiscount
        }
        if (formData.flatDiscount) {
            couponPayload.flatDiscount = formData.flatDiscount
        }
        if (formData.expiredDate) {
            couponPayload.expiredDate = moment(formData.expiredDate).endOf('day').utc()
        }
        couponPayload.isActive = formData.isActive

        try {
            const response = await axios.put(api.bulkCouponUpdate, { coupons: coupons, couponPayload }, {
                headers: { "x-auth-token": localStorage.getItem("user") }
            })
            enqueueSnackbar("" + response.data, { variant: 'success' })
        } catch (error) {
            enqueueSnackbar("coupons update failed : " + error.response.data, { variant: 'error' })
        }
    }

    const onUploadExcel = (e) => {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var fileName = e.target.files[0]?.name
        var fileSize = e.target.files[0]?.size
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 0 });
            const dataParseCol = XLSX.utils.sheet_to_json(ws, { header: 1 });
            if (!dataParseCol[0].includes("Coupon")) {
                return enqueueSnackbar("Coupon column not found", { variant: 'warning' })
            }
            var coupons = []
            dataParse.filter(function (item) { coupons.push(item.Coupon) })
            setCoupons(coupons)
            setFileInfo({ fileName: fileName, totalCoupon: coupons.length, fileSize: fileSize })
        };
        reader.readAsBinaryString(f)
    }


    const fileInfoBox = (
        <Box component={Paper} elevation={2} sx={{ p: 1 }}>
            <Typography fontWeight={650} color={green[300]} variant='subtitle1' gutterBottom align='center'>
                File Name : {fileInfo.fileName}
            </Typography>
            <Typography fontWeight={650} color={green[300]} variant='subtitle1' gutterBottom align='center'>
                File Size  : {(fileInfo.fileSize / 1024).toFixed(2)} kb
            </Typography>
            <Typography fontWeight={650} color={green[300]} variant='subtitle1' gutterBottom align='center'>
                Total coupons  : {fileInfo.totalCoupon}
            </Typography>
        </Box>
    )

    const userFileUpload = (
        <Card sx={{ width: '100%', mt: 2, mr: 2, pr: 2 }}>
            <Typography sx={{ m: 1 }} fontWeight={450} variant='h6'>Column : [Coupon] </Typography>
            {
                coupons.length > 0 ?
                    fileInfoBox : <Skeleton variant="rectangular" width={'100%'} height={218} />
            }
            <CardActions disableSpacing>
                <label htmlFor="subject-banner">
                    <Input onChange={onUploadExcel} accept="*" id="subject-banner" type="file" />
                    <Button component="span" size="small">Upload Excel</Button>
                </label>
            </CardActions>
        </Card>
    )

    const form = (
        <Paper variant="outlined" sx={{ m: 2, p: 2 }} square>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={'4'}
            >  <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                    <div>
                        <TextField
                            required
                            id="outlined-required"
                            label="Percent Discount"
                            value={formData.percentDiscount}
                            onChange={(e) => setFormData({ ...formData, percentDiscount: e.target.value })}
                        />

                        <TextField
                            required
                            id="outlined-required"
                            label="Flat Discount"
                            value={formData.flatDiscount}
                            onChange={(e) => setFormData({ ...formData, flatDiscount: e.target.value })}
                        />
                    </div>

                    <div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Expiry Date"
                                value={formData.expiredDate}
                                onChange={(newValue) => {
                                    setFormData({ ...formData, expiredDate: newValue })
                                }} 
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                        <FormControlLabel
                            sx={{ m: 2 }}
                            control={<Checkbox checked={formData.isActive ? true : false}
                                onChange={(event) => setFormData({ ...formData, isActive: event.target.checked })}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />}
                            label="Is Active"
                        />
                    </div>
                </Box>
                <FormControl fullWidth sx={{ m: 1 }}>
                    {userFileUpload}
                    <Button onClick={handleSubmit} sx={{ w: '100%', color: 'green', mt: 2, mr: 2, color: 'white' }}
                        variant="contained">
                        Submit
                    </Button>
                </FormControl>
            </Grid>
        </Paper>
    )

    return (
        <React.Fragment>
            <Box sx={{ p: 2, mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} component={Paper} elevation={4}>
                <Typography fontWeight={800} variant='h5' gutterBottom align='center'>
                    Bulk coupon Update
                </Typography>
                {form}
            </Box>
        </React.Fragment>
    )
}
