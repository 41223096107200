/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Stack from '@mui/material/Stack';
import UserPayment from "./UserPayment";
import PaymentVisualize from "./PaymentVisualize"
import PaymentMode from "./ExportPayment/index"
export default function Payment(props) {
  return (
    <div className="col-lg-12">
      <Stack spacing={3}>
        <Box>
          <UserPayment />
        </Box>
        <Box component={Paper} sx={{ mt: 2, mb: 2, p: 2 }}>
          <PaymentMode />
        </Box>
        <Box>
          <PaymentVisualize />
        </Box>
      </Stack>
    </div>
  );
}
