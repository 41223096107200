/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import "../../Css/root.css";

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import api from "../../api";
import TablePagination from "@mui/material/TablePagination";
import DeleteModal from "../../Components/DeleteModal";
import { useDispatch, useReducer } from "react-redux";
import {OrderDetailsModal} from "./OrderDetailsModal";
import OrderDeleteModal from "./OrderDeleteModal";
import ExplorerModeMenu from "../../Components/ExplorerModeMenu"
import SubscriptionFilter from "../../Components/Filters/SubscriptionFilter"
import CatalogCourseFilter from "../../Components/Filters/CatalogCourseFilter";

import OrderExportExcel from "./OrderExportExcel"
import UserPaymentdetails from "../Payment/UserPaymentdetails";
import { useSnackbar } from "notistack";

const ExplorerMode = {
  SubscriptionCourse: "SubscriptionCourse",
  CatalogCourse: "CatalogCourse",
}

const ExplorerModeMenuItem = {
  SubscriptionCourse: "Subscription Course",
  CatalogCourse: "CatalogCourse"
}

const FilterTitleByMode = {
  SubscriptionCourse: "Select Subscription",
  CatalogCourse: "Select Catalog Course",
}

export default function Order(props) {

  const [phoneOrEmail, setPhoneOrEmail] = useState('')
  const [isShowUserDetails, setIsShowUserDetails] = React.useState(false)
  const [userPaymentDetails, setUserPaymentDetails] = React.useState([])

  const [token, settoken] = useState(localStorage.getItem("user"));
  const [Datas, setData] = useState([]);
  const [Count, setCount] = useState();
  const [row, setRow] = useState(10);
  const [page, setpage] = useState(0);
  const dispatch = useDispatch();
  const [modal, setModal] = useState({});
  const handleClose = () => setShow(false);
  const [show, setShow] = React.useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  const [sub, setsub] = useState([]);
  const [cors, setcors] = useState([]);
  const [searchSub, setsearchSub] = useState("");
  const [Datass, setDatass] = useState([]);
  const [url, seturl] = useState(api.orderListGet);
  const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const [subj, setsubj] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})


    const [explorerMode, setExplorerMode] = useState(ExplorerMode.SubscriptionCourse)
    const [selectedGoal, setSelectedGoal] = useState('')
    const [selectedSubscription, setSelectedSubscription] = useState('')
    const [selectedCourse, setSelectedCourse] = useState('')
     
    const { enqueueSnackbar } = useSnackbar()
  var i = (page * row) + 1;
  let data = {
    headers: {
      "x-auth-token": token,
    },
  };

  const handleModeChange = (mode) => {
    setExplorerMode(ExplorerMode[mode])
    setSelectedCourse('')
    setSelectedSubscription('')
    setSelectedGoal('')
}


const handleSearchOrderByPhone = async () => {
  if(!phoneOrEmail.length) return enqueueSnackbar('Invalid input', { variant: 'error'});
  axios
      .get(`/admin/orders/payment-searchByEmailOrPhone?PhoneOrEmail=${phoneOrEmail}`, data)
      .then((resp) => {
         if(resp.status == 200){
          setUserPaymentDetails(resp.data.result)
          setIsShowUserDetails(true)
         }
      })
      .catch((error) => {
          return enqueueSnackbar(`${error.response.data}`, { variant: 'error'});
      });
}

const handlecloseUserDetails = ()=>{
  setIsShowUserDetails(false)
}

  async function my_async_fn(row, page, url) {
    axios
      .get(url + "pageNo=" + page + "&row=" + row, data)
      .then((resp) => {
        setData(resp.data.result);
        setCount(resp.data.count);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          return dispatch(props.logout());
        } else {
          return error.response.status;
        }
      });
  }
  const handleChangePage = (event, newPage) => {
    setpage(newPage);
    my_async_fn(row, newPage, url);
  };

  const handleChangeRowsPerPage = (event) => {
    setRow(event.target.value);
    setpage(0);
    forceUpdate();
    my_async_fn(event.target.value, 0, url);
  };

  
  async function goals() {
    settoken(localStorage.getItem("user"));
    const response = await axios.get(api.getGoalChap, data);
    setDatass(response.data);
  }


  useEffect(() => {
    my_async_fn(row, page, url);
    goals();
  }, []);


  function handleSearch() {
    seturl(api.orderSearch + "nameOrEmailOrPhone=" + searchSub + "&");
    let url = api.orderSearch + "nameOrEmailOrPhone=" + searchSub + "&";
    my_async_fn(row, page, url);
  }
  async function getSubs(selected) {
    const response = await axios.get(api.getSubChap + selected, data);
    setsub(response.data.subscriptions);
  }
  async function getcourses(selected) {
    const response = await axios.get(api.getCorsChap + selected, data);
    setcors(response.data.courses);
  }
  async function getsubject(selected) {
    const response = await axios.get(api.getSubjChap + selected, data);
    setsubj(response.data.subjects);
  }
  
const subscriptionSelector = (
  <SubscriptionFilter
      selectedSubscription={selectedSubscription}
      handleSubscriptionSelection={(subscriptionId) => {
          setSelectedSubscription(subscriptionId)
      }}
  />
)

const catalogCourseSelector = (
  <CatalogCourseFilter
      selectedCourse={selectedCourse}
      handleCourseSelection={(courseId) => {
          setSelectedCourse(courseId)
      }}
  />
)
  const filterBox = (
    <Card sx={{mb : 2, mt: 2}}>
        <CardHeader
                title={FilterTitleByMode[explorerMode]}
                action={
                    <ExplorerModeMenu
                        currentMode={explorerMode}
                        explorerModes={ExplorerModeMenuItem}
                        handleModeSelection={handleModeChange}
                    />
                }
        />
      <CardContent>
        {explorerMode === "SubscriptionCourse" && subscriptionSelector}
        {explorerMode === "CatalogCourse" && catalogCourseSelector}
        <Stack justifyContent={'center'} alignItems={'center'}>
          {selectedSubscription && <OrderExportExcel selectedSubscription={selectedSubscription} selectedGoal={selectedGoal} />}
          {selectedCourse && <OrderExportExcel selectedCourse={selectedCourse} />}
        </Stack>
      </CardContent>
    </Card>
)

  return (
    <div>
      <div>
        <OrderDeleteModal 
        showDeleteModal={showDeleteModal} 
        setShowDeleteModal={(showDeleteModal)=>setShowDeleteModal(showDeleteModal)} 
        selectedUser={selectedUser}
        reset={() => {
          my_async_fn(row, page, url);
        }}
        />
        {isShowUserDetails && userPaymentDetails &&
                <UserPaymentdetails
                    userPaymentDetails={userPaymentDetails}
                    isShowUserDetails={isShowUserDetails}
                    handlecloseUserDetails={handlecloseUserDetails}
                />
            }
        <div className="card card-custom">
          <div className="card-header border-0 py-5 cent">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                 Search Order
              </span>
            </h3>
          </div>
          <div></div>
          <div className="card-body pt-0 pb-3 ">
            <div className="form-group row">
            <div className="col-lg-12">
               {filterBox}
               </div>
              <div className="col-lg-12">
                <form className="custom-form">
                  <div className="input-group col-lg-6 col-md-6 col-sm-6 cent">
                    <input
                      type="text"
                      id="search"
                      value={searchSub}
                      className="form-control"
                      placeholder="Search By title"
                      onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                      onChange={(e) => {
                        setsearchSub(e.target.value);
                      }}
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="btn btn-sm btn-success"
                        type="button"
                        onClick={handleSearch}
                      >
                        Search!
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        {/* Search Ends */}
      </div>


      <DeleteModal
        detail={modal}
        url={api.liveClassDelete}
        showDelete={showDelete}
        handleCloseDelete={handleCloseDelete}
        reset={() => {
          my_async_fn(row, page);
        }}
      />

      <OrderDetailsModal
				show={show}
				OrderDetail={modal}
				handleClose={handleClose}
			/>

      {/* <LiveClassSearch/> */}
      <div className={`card card-custom `}>
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Order List
            </span>
          </h3>
        
          <div className="card-toolbar">
            <div style={{ marginRight: '10px' }}>
              <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 200, m: {md: 0, xs: 2} }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Phonenumber or email"
                  onChange={(e) => setPhoneOrEmail(e.target.value)}
                  value={phoneOrEmail}
                  inputProps={{ 'aria-label': 'Phonenumber or email' }}
                />
                <IconButton onClick={handleSearchOrderByPhone} type="button" sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>

          <div style={{marginRight : '10px'}}>
              <Link
                to="/bulk-order"
                className="btn btn-success font-weight-bolder font-size-sm"
              >
                <span className="svg-icon svg-icon-md svg-icon-white">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Add-user.svg"
                    )}
                    className="h-50 align-self-center"
                  ></SVG>
                </span>
                 Bulk Order
              </Link>
            </div>
            <div>
              <Link
                to="/add-order"
                className="btn btn-success font-weight-bolder font-size-sm"
              >
                <span className="svg-icon svg-icon-md svg-icon-white">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Add-user.svg"
                    )}
                    className="h-50 align-self-center"
                  ></SVG>
                </span>
                Add Order
              </Link>
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="pl-0" style={{ width: "30px" }}>
                    Serial
                  </th>
                  <th className="pr-0 " style={{ width: "150px" }}>
                    Name
                  </th>
                  <th style={{ minWidth: "100px" }}>Email</th>
                  <th style={{ minWidth: "1o0px" }}>Phone</th>
                  <th style={{ minWidth: "80px" }}>Wallet Amount</th>
                </tr>
              </thead>
              {
               Datas.map((item, index) =>
               <tbody>
                  <tr>

                    <td className="pl-0">

                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {i++}
                      </span>
                    </td>

                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.name}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      {item.email}
                      </span>

                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.phone}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                         {item.walletAmount}
                      </span>
                    </td>
                    <td>

                      
                     
                      {/* <div class="btn btn-sm font-weight-bold btn-light-warning">Due</div> */}
                    </td>
                    <td className="pr-0 text-center">
                      <a
												onClick={() => setModal(item) & setShow(true)}
												className="btn btn-icon btn-light btn-hover-primary btn-sm"
											>
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Settings-1.svg"
                            )}
                          ></SVG>
                        </span>
                      </a>


                      <Link
                        to={{
                          pathname: "/edit-payment",
                          state: item,
                        }}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-warning">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          ></SVG>
                        </span>
                      </Link>


                      <a
                        onClick={(e)=> {e.preventDefault();setSelectedUser(item);setShowDeleteModal(true)}}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                          ></SVG>
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>

               )
              }
                

      
            </table>
            <div className="row align-items-center">
              <div className="col-md-8 col-sm-8">
                <div className="d-flex flex-wrp py-2 mr-3">
                  <TablePagination
                    component="div"
                    count={Count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={row}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
              <br></br> <br></br>
              <br></br>
              <br></br>
            </div>

          </div>
          {/* end::Table */}
        </div>
      </div>
    </div>
  );
}
