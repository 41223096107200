import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
const Item = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function EducatorSkeleton() {
  return (
    <Box sx={{ maxWidth: "500px" }}>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={3}
      >
        <Skeleton variant="rectangular" width={250} height={40} />
        <Skeleton variant="rectangular" width={250} height={40} />
      </Stack>
      <Stack
        sx={{mt:2}}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={3}
      >
        <Skeleton variant="rectangular" width={250} height={40} />
        <Skeleton variant="rectangular" width={250} height={40} />
      </Stack>
      <Grid
        sx={{ mt: 2 }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={12}>
          <Item>
            <Skeleton variant="rectangular" width={"100%"} height={40} />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <Skeleton variant="rectangular" width={150} height={40} />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <Skeleton variant="rectangular" width={150} height={40} />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <Skeleton variant="rectangular" width={50} height={20} />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <Skeleton variant="rectangular" width={50} height={20} />
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Skeleton width={100} variant="text" sx={{ fontSize: "1rem" }} />
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Skeleton variant="rectangular" width={50} height={20} />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
