import * as React from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import MoreIcon from "@mui/icons-material/More";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";

export default function OrderMenu({ selectedItem, pages, handleChange }) {
  return (
    <Paper>
      <MenuList>
        <MenuItem onClick={()=>handleChange(pages[0])} selected={selectedItem === pages[0] ? true : false }>
          <ListItemIcon>
            <SubscriptionsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Subscription Order</ListItemText>
        </MenuItem>
        <MenuItem onClick={()=>handleChange(pages[1])} selected={selectedItem === pages[1] ? true : false }>
          <ListItemIcon>
            <PlaylistAddCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Subscription Limit</ListItemText>
        </MenuItem>
        <MenuItem onClick={()=>handleChange(pages[2])} selected={selectedItem === pages[2] ? true : false }>
          <ListItemIcon>
            <LoyaltyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Catalog Course Order</ListItemText>
        </MenuItem>
        <MenuItem onClick={()=>handleChange(pages[3])} selected={selectedItem === pages[3] ? true : false }>
          <ListItemIcon>
            <PlaylistAddCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Catalog Course Limit</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem disabled>
          <ListItemIcon>
            <MoreIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Bulk Order</ListItemText>
        </MenuItem>
      </MenuList>
    </Paper>
  );
}
