import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { useSnackbar } from "notistack"
import Stack from '@mui/material/Stack';
import axios from "../../../axios"
import api from "../../../api"

export default function EditDialog({ editOpen, handleCloseEdit, handleRefreshData, data }) {

    const [formData, setFormData] = useState({
        title: data?.title,
        validity: data?.validity,
        price: data?.price,
        flatDiscount: data?.flatDiscount,
        percentDiscount: data?.percentDiscount,
        serviceCharge: data?.serviceCharge
    })
    const { enqueueSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    const handleClear = async () => {
        setFormData({
            title: '',
            validity: '',
            price: '',
            flatDiscount: '',
            percentDiscount: '',
            serviceCharge: ''
        })
    }

    const handleSubmit = async () => {
        try {
            if (!formData.title) return enqueueSnackbar("Title is not allowed to be empty", { variant: 'warning' })
            if (!formData.validity) return enqueueSnackbar("Validity is not allowed to be empty", { variant: 'warning' })
            if (!formData.price) return enqueueSnackbar("Price is not allowed to be empty", { variant: 'warning' })

            const response = await axios.put(`/admin/paymentPlan/${data._id}`, {...formData}, config);
            enqueueSnackbar(response.data, { variant: 'success' })

            handleCloseEdit()
            handleRefreshData()
        } catch (error) {
            enqueueSnackbar(error?.response?.data, { variant: 'error' })
        }
    }

    return (
        <div>
            <Dialog open={editOpen} onClose={handleCloseEdit}>
                <DialogTitle>Edit Payment Plan</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        sx={{
                            ml : '15%',
                            '& .MuiTextField-root': { m: { xs: 1, md: 2 }, width: '40ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                required
                                id="outlined-required"
                                label="Title"
                                value={formData.title}
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                            <TextField
                                required
                                id="outlined-number"
                                label="Validity"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.validity}
                                onChange={(e) => setFormData({ ...formData, validity: e.target.value })}
                            />
                        </div>

                        <div>
                            <TextField
                                required
                                id="outlined-number"
                                label="Price"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.price}
                                onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                            />
                            <TextField
                                id="outlined-number"
                                label="Flat Discount"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.flatDiscount}
                                onChange={(e) => setFormData({ ...formData, flatDiscount: e.target.value })}
                            />
                        </div>

                        <div>
                            <TextField
                                id="outlined-number"
                                label="Percent Discount"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.percentDiscount}
                                onChange={(e) => setFormData({ ...formData, percentDiscount: e.target.value })}
                            />
                            <TextField
                                id="outlined-number"
                                label="Service Charge"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.serviceCharge}
                                onChange={(e) => setFormData({ ...formData, serviceCharge: e.target.value })}
                            />
                        </div>

                        {/* <Stack justifyContent={'center'} spacing={2} direction="row">
                            <Button onClick={handleSubmit} variant="contained">Submit</Button>
                            <Button onClick={handleClear} variant="outlined">Clear</Button>
                        </Stack> */}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit}>Update</Button>
                    <Button onClick={handleCloseEdit}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
