import React, { useState, useEffect } from 'react';
import { useSnackbar } from "notistack"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SimpleBackDrop from "../../../Components/SimpleBackDrop"
import DetailsModal from "./DetailsModal"
import EditDialog from "./EditDialog"
import SimpleDeleteModal from '../../../Components/SimpleDeleteModal'
import axios from "../../../axios"

const columns = [
    { id: '0', label: 'Serial', minWidth: 50, align: 'center'},
    { id: '1', label: 'Title', minWidth: 270, align: 'center'},
    { id: '2', label: 'Validity', minWidth: 100, align: 'center' },
    {
        id: '3',
        label: 'Price',
        minWidth: 100,
        align: 'center'
    },
    {
        id: '4',
        label: 'Edit',
        minWidth: 100,
        align: 'center'
    },
    {
        id: '3',
        label: 'Delete',
        minWidth: 100,
        align: 'center'
    },
    {
        id: '4',
        label: 'details',
        minWidth: 100,
        align: 'center'
    },
];

export default function PaymentPlanTableList() {
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0)
    const [refresh, setRefresh] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paymentPlans, setPaymentplans] = useState([])

    const [deleteOpen, setDeleteOpen] = React.useState(false)
    const [deletePaymentPlanId, setDeletePaymentPlanId] = React.useState('')
    const [editOpen, setEditOpen] = React.useState(false)
    const [editPaymentPlan, setEditPaymentPlan] = React.useState('')

    const [loading, setLoading] = useState(true)
    const [paymentPlanDetails, setPaymentPlanDetails] = useState({isShow: false, data: {}})
    const { enqueueSnackbar } = useSnackbar()
    const config = { headers: { "x-auth-token": localStorage.getItem("user") } }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchPaymentPlans = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`/admin/paymentPlan?pageNo=${page}&row=${rowsPerPage}`, config);
            setPaymentplans(response.data?.result?.paymentPlans)
            setCount(response.data?.count)
            setLoading(false)
        } catch (error) {

        }
    }

    const handleDeletePaymentPlan = async()=>{
        try{
            const response = await axios.delete(`/admin/paymentPlan/${deletePaymentPlanId}`, config);
            enqueueSnackbar(response.data, { variant: 'success' })
            setDeletePaymentPlanId('')
            setRefresh(refresh+1)
        }catch(error){
            return enqueueSnackbar(error.response.data, { variant: 'error' })
        }
    }

    useEffect(() => {
        fetchPaymentPlans()
    }, [page, rowsPerPage, refresh])

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {<DetailsModal open={paymentPlanDetails.isShow} data={paymentPlanDetails.data}
                handleDetails={(state) => setPaymentPlanDetails({ isShow: state, data: {} })}
            />}

            {editOpen && <EditDialog editOpen={editOpen}
              handleCloseEdit={()=>setEditOpen(false)} 
              handleRefreshData={()=>setRefresh(refresh+1)} 
              data={editPaymentPlan} /> }

             {deleteOpen &&
                <SimpleDeleteModal
                    title="Delete PaymentPlan"
                    open={deleteOpen}
                    handleClose={() => {
                        setDeleteOpen(false)
                    }}
                    handleDelete={handleDeletePaymentPlan}
                />
            }
            {loading && <SimpleBackDrop/>}
            <TableContainer sx={{ maxHeight: 500 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && paymentPlans?.map((item, index) =>
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>

                                 <TableCell key={index} align={'center'}>
                                    {(index+1)+rowsPerPage*page}
                                </TableCell>

                                <TableCell key={index} align={'center'}>
                                    {item?.title}
                                </TableCell>

                                <TableCell key={index} align={'center'}>
                                    {item?.validity}
                                </TableCell>

                                <TableCell key={index} align={'center'}>
                                    {item?.price}
                                </TableCell>

                                <TableCell key={index} align={'center'}>
                                    <Button onClick={()=>{setEditPaymentPlan(item);setEditOpen(true)}} variant="contained">Edit</Button>
                                </TableCell>

                                <TableCell key={index} align={'center'}>
                                    <Button onClick={() => {setDeletePaymentPlanId(item._id);setDeleteOpen(true)}} sx={{ color: 'white', backgroundColor : '#f44336' }} variant="contained">Delete</Button>
                                </TableCell>

                                <TableCell key={index} align={'center'}>
                                    <Button onClick={()=>setPaymentPlanDetails({isShow : true, data: item})} variant="outlined">Details</Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
