import React from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from "notistack"
import axios from "../../axios";
import api from "../../api";
import moment from "moment"

export default function DoubtFilterDataExport({formData}) {
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const [loading, setLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar()
    const data = {
        headers: {
            "x-auth-token": token,
        },
    };
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "Doubt Filter"

    const fetchData = async () => {
        setLoading(true);
        let url = ''
        if(formData.fromDate && formData.toDate && formData.doubtType){
            url = api.getDoubtFilterWise+`status=${formData.doubtType}&fromDate=${moment(formData.fromDate).startOf('day').utc()}&toDate=${moment(formData.toDate).endOf('day').utc()}`
        }
        else if(!formData.fromDate && !formData.toDate && formData.doubtType){
            url = api.getDoubtFilterWise+`status=${formData.doubtType}` 
        }else{
            url = api.getDoubtFilterWise
        }
        try{
            const response = await axios.get(url, data)
            return response.data.result;
        }catch(error){
            setLoading(false)
            return enqueueSnackbar(" "+ error.response.data, { variant: 'warning' })
        }
    }

    const exportToCSV = async () => {
        if(!formData.doubtType) return enqueueSnackbar("Doubt type is not allowed to be empty", { variant: 'warning' })
        var importData = await fetchData();
        if(importData.length <= 0) {
            setLoading(false)
            return enqueueSnackbar("No records found ", { variant: 'warning' })
        }
        var excelData = [];
        //return null
        for (var index in importData) {
             let obj ={}
             if(importData[index].name){
                 obj.Solver_Name = importData[index].name[0]
             }
             if(importData[index].phone){
                obj.Solver_Phone = importData[index].phone[0]
            }
            if(importData[index].count){
                obj.Doubt_count = importData[index].count
            }
            if(formData.doubtType){
                obj.Status = importData[index].status[0]
            }
            if(formData.fromDate && formData.toDate){
                obj.From_Date = moment(formData.fromDate).format("YYYY-MM-DD")
                obj.To_Date =  moment(formData.toDate).format("YYYY-MM-DD")
            }
            excelData.push(obj);
        }
        setLoading(false);
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return <LoadingButton
        style={{ marginLeft: '10px' }}
        color="secondary"
        onClick={() => exportToCSV()}
        loading={loading}
        loadingPosition="start"
        variant="contained"
    >
        EXPORT TO EXCEL
    </LoadingButton>
}
