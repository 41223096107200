import React, { useEffect, useRef, useState } from "react"

/* @MUI COMPONENT */
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import RestoreIcon from '@mui/icons-material/Restore'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardHeader from '@mui/material/CardHeader'
import CardContent from "@mui/material/CardContent"
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import Skeleton from '@mui/material/Skeleton'
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import { useSnackbar } from "notistack"

/* @CUSTOM COMPONENT */
import "../../Css/root.css"
import axios from "../../axios"
import api from "../../api"
import RichTextEditor from "../../Components/RichTextEditor"
import MultiSelectorChip from "../../Components/MultiSelectorChip"
import BasicCircularProgress from "../../Components/BasicCircularProgress"

const Input = styled('input')({
    display: 'none',
})

export default function DoubtSolveDialog(props) {
    const [doubt, setDoubt] = useState('')
    const [doubtImage, setDoubtImage] = useState(new Blob())
    const [doubtAnswerImage, setDoubtAnswerImage] = useState(new Blob())
    const [answerFile, setAnswerFile] = useState('')
    const [comment, setComment] = useState('')
    const [doubtLoading, setDoubtLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    const fileUploadconfig = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
            "Content-Type": "multipart/form-data"
        }
    }

    const handleClose = () => {
        props.handleCloseDialouge()
    }

    // const handleSubmit = async () => {
    //     if (!answerFile) {
    //         updateDoubt('')
    //         return
    //     }

    //     let formData = new FormData()
    //     formData.append("doubtImage", answerFile)
    //     try {
    //         setSubmitLoading(true)
    //         const response = await axios.post(api.uploadDoubtAnswerImage, formData, fileUploadconfig)
    //         setSubmitLoading(false)
    //         updateDoubt(response.data)
    //     } catch (error) {
    //         enqueueSnackbar("Doubt image upload failed: " + error.response, { variant: 'error' })
    //     }
    // }

    const onUploadAnswer = ({ target }) => {
        if (target.files[0]) {
            setAnswerFile(target.files[0])
        }
    }

    async function fetchDoubt(doubtId) {
        setDoubtLoading(true)
        axios
            .get(api.getDoubtById + doubtId, config)
            .then((response) => {
                setDoubt(response.data)
                fetchDoubtImage(response.data.questionImage)
                if(response.data?.answerImage){
                    fetchDoubtAnswerImage(response.data.answerImage)
                }
            })
            .catch((error) => {
                enqueueSnackbar("Doubt data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function fetchDoubtImage(imageLink) {
        axios
            .get(`${process.env.REACT_APP_API_URL}/bucket/` + imageLink, { responseType: 'blob' })
            .then((response) => {
                setDoubtLoading(false)
                if (response.data.statusCode) {
                    enqueueSnackbar(response.data.message, { variant: 'error' })
                    return
                }
                setDoubtImage(response.data)
            })
            .catch((error) => {
                enqueueSnackbar("Doubt data fetch failed: " + error.response, { variant: 'error' })
            })
    }

    async function fetchDoubtAnswerImage(imageLink) {
        axios
            .get(`${process.env.REACT_APP_API_URL}/bucket/` + imageLink, { responseType: 'blob' })
            .then((response) => {
                setDoubtLoading(false)
                if (response.data.statusCode) {
                    enqueueSnackbar(response.data.message, { variant: 'error' })
                    return
                }
                setDoubtAnswerImage(response.data)
            })
            .catch((error) => {
                enqueueSnackbar("Doubt data fetch failed: " + error.response, { variant: 'error' })
            })
    }

    async function updateDoubt(image) {
        if (image === '' && comment === '') {
            enqueueSnackbar("Image or comment required", { variant: 'error' })
            return
        }
        var updatePayload = {}
        if (image !== '') {
            updatePayload = { ...updatePayload, answerImage: image }
        }

        if (comment !== '') {
            updatePayload = { ...updatePayload, comment: comment }
        }

        setSubmitLoading(true)

        axios
            .put(api.updateDoubtById + props.doubtId, updatePayload, config)
            .then((response) => {
                setSubmitLoading(false)
                enqueueSnackbar(response.data, { variant: 'success' })
                handleClose()
                props.handleSuccess()
            })
            .catch((error) => {
                enqueueSnackbar("Doubt solve failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        if (!props.open) return
        fetchDoubt(props.doubtId)
    }, [props.open])

    const appBar = (
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Doubt Solve
                </Typography>
                {/* <LoadingButton
                    color="secondary"
                    disabled
                    // onClick={handleSubmit}
                    loading={doubtLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton> */}
            </Toolbar>
        </AppBar>
    )

    const doubtImageCard = (
        <Card sx={{ width: 400 }}>
            <CardHeader title="Question Image" />
            <CardMedia
                component="img"
                image={URL.createObjectURL(doubtImage)}
            />
        </Card>
    )

    const answerUploadCard = (
        <Card sx={{ width: 400 }}>
            {
                answerFile ?
                    <CardMedia
                        component="img"
                        image={URL.createObjectURL(answerFile)}
                    /> : <Skeleton variant="rectangular" width={400} height={236} />
            }
            <CardActions disableSpacing>
                <label htmlFor="doubt-answer">
                    <Input onChange={onUploadAnswer} accept="image/*" id="doubt-answer" type="file" />
                    <Button component="span" size="small">Upload Answer Image</Button>
                </label>
            </CardActions>
        </Card>
    )

    const commentsCard = (
        <Card sx={{ width: 400 }}>
            <CardHeader title="Comments" />
            <CardContent>
                <List>
                    {doubt ?
                        doubt.comments.map((item, index) => {
                            return (
                                <div key={index}>
                                <Divider />
                                <ListItem sx={{m : 2}} alignItems="flex-start">
                                    <ListItemAvatar sx={{ width: '25px', height: '25px', mt : -1 }}>
                                        {item?.user?.role === "doubtSolver" ?
                                            <Avatar>DS</Avatar> :
                                            <Avatar>ST</Avatar>}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item?.comment}
                                    />
                                </ListItem>
                                </div>
                            )
                        }) : null
                    }
                </List>
            </CardContent>
        </Card>
    )

    const answeredImage = (
        <Card sx={{ width: 400 }}>
            {
                doubtAnswerImage ?
                    <CardMedia
                        component="img"
                        image={URL.createObjectURL(doubtAnswerImage)}
                    /> : <Skeleton variant="rectangular" width={400} height={236} />
            }
            <CardActions disableSpacing>
                <label htmlFor="doubt-answer">
                    <Typography variant="h6" gutterBottom align="center">Answer Image</Typography>
                </label>
            </CardActions>
        </Card>
    )

    const addCommentField = (
        <TextField
            label="Add a comment"
            variant="standard"
            sx={{ width: 400 }}
            multiline
            maxRows={20}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
        />
    )

    return (
        <Dialog fullScreen disableEnforceFocus open={Boolean(props.open)} onClose={handleClose}>
            {appBar}
            {doubtLoading ? <BasicCircularProgress /> :
                <Box sx={{ m: 2 }}>
                    <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={8}>
                        <Stack direction="column" spacing={4}>
                            {doubtImageCard}
                            {commentsCard}
                            {doubt?.answerImage && answeredImage}
                        </Stack>
                        {answerUploadCard}
                        {addCommentField}
                    </Stack>
                </Box>}
        </Dialog>
    )
}