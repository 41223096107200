import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";

export const actionTypes = {
  Success: "Success",
  Danger: "Danger",
  Warning: "Warning",
  Info: "Info",
  Close : "Close"
};

const initialToastState = {
  show: false,
  message: "",
  type : ""
};

export const reducer = persistReducer(
    { storage, key: "toast", whitelist: ["toast"] },
    (state = initialToastState, action) => {
        switch (action.type) {
            case actionTypes.Success: {
                const { message } = action.payload;
                return { show: true, message: message, type: actionTypes.Success };
            }
            case actionTypes.Danger: {
                const { message } = action.payload;
                return { show: true, message: message, type: actionTypes.Danger };
            }
            case actionTypes.Info: {
                const { message } = action.payload;
                return { show: true, message: message, type: actionTypes.Info };
            }
            case actionTypes.Close: {
                const { message } = action.payload;
                return { show: false, message: message, type: actionTypes.Info };
            }
            default:
                return state;
        }
    }
);

export const actions = {
    Success : (message)=>({ type: actionTypes.Success, payload: { message } }),
    Danger : (message)=>({ type: actionTypes.Danger, payload: { message } }),
    Info : (message)=>({ type: actionTypes.Info, payload: { message } }),
    Close : ()=>({type: actionTypes.Close, payload:  {message:""} })
  };