import { Modal,Button } from "react-bootstrap"
import React from "react"
import { useSnackbar } from "notistack"
import LogoutIcon from '@mui/icons-material/Logout';
import axios from '../axios'
import api from "../api";

export function UserDetailsModal({UserDetail,show,handleClose}){
 
  const { enqueueSnackbar } = useSnackbar()
  let config = { headers: { "x-auth-token": localStorage.getItem("user") } }

  const handleLogout = async () => {
    try {
      const response = await axios.get(api.logoutUser + UserDetail._id, config)
      enqueueSnackbar(response.data, { variant: 'success' })
      handleClose()
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: 'error' })
    }
  }
    return(
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
      
        <Modal.Body>
          <p>Name :  <span style={{fontSize:"18px",fontWeight:'400',color:'#1BC5BD'}}> {UserDetail?.name}     </span> </p>
          {/* <p>Father's Name  :  <span style={{fontSize:"18px",fontWeight:'400',color:'#1BC5BD'}}>   {UserDetail.fatherName}   </span> </p> */}
          {/* <p>Mother's Name  :  <span style={{fontSize:"18px",fontWeight:'400',color:'#1BC5BD'}}> {UserDetail.motherName}     </span> </p> */}
          <p>Email   : <span style={{fontSize:"18px",fontWeight:'400', color:'#1BC5BD'}}>    {UserDetail?.email}  </span> </p>
          <p>Phone  : <span style={{fontSize:"18px",fontWeight:'400',color:'#1BC5BD'}}>    {UserDetail?.phone}  </span> </p>
          {/* <p>Father's Phone   : <span style={{fontSize:"18px",fontWeight:'400',color:'#1BC5BD'}}>  {UserDetail.fatherPhone}    </span> </p>
          <p>Mother's Phone  :  <span style={{fontSize:"18px",fontWeight:'400',color:'#1BC5BD'}}>  {UserDetail.motherPhone}    </span> </p>
          <p>Gender  : <span style={{fontSize:"18px",fontWeight:'400',color:'#1BC5BD'}}>     {UserDetail.gender} </span> </p> */}
          <p>Class :  <span style={{fontSize:"18px",fontWeight:'400',color:'#1BC5BD'}}>    {UserDetail?.class}  </span> </p>
          <p>Study Group  : <span style={{fontSize:"18px",fontWeight:'400',color:'#1BC5BD'}}>    {UserDetail?.studyGroup}   </span></p>
          <p>Study Version  : <span style={{fontSize:"18px",fontWeight:'400',color:'#1BC5BD'}}>    {UserDetail?.studyVersion}   </span></p>
          <p>Batch year  : <span style={{fontSize:"18px",fontWeight:'400',color:'#1BC5BD'}}>    {UserDetail?.batchYear}   </span></p>
          <p>Institution  : <span style={{fontSize:"18px",fontWeight:'400',color:'#1BC5BD'}}>    {UserDetail?.institution}   </span></p>
          <p>Role  : <span style={{fontSize:"18px",fontWeight:'400',color:'#1BC5BD'}}>    {UserDetail?.role}   </span></p>
          <p>Wallet Amount  : <span style={{fontSize:"18px",fontWeight:'400',color:'#1BC5BD'}}>    {UserDetail?.walletAmount}   </span></p>
          <Button variant="primary" onClick={handleLogout}>
           <LogoutIcon />&nbsp; Logout
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
}