import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export default function DetailsModal({ open, handleDetails, data }) {
    
    const handleClose = () => {
        handleDetails(false);
    };

    return (
        <div>
            <Dialog sx={{p : {md : 8, xs: 2}}} open={open} onClose={handleClose}>
                <DialogTitle>Payment Plan details</DialogTitle>
                <DialogContent sx={{ p: {md : 4, xs : 2} , minWidth : '400px'}}>
                    <Typography sx={{ m: 2 }} variant="subtitle1" gutterBottom component="div">
                        Title {data?.title}
                    </Typography>


                    <Typography sx={{ m: 2 }} variant="subtitle1" gutterBottom component="div">
                        Validity {data?.validity}
                    </Typography>


                    <Typography sx={{ m: 2 }} variant="subtitle1" gutterBottom component="div">
                        Price {data?.price}
                    </Typography>


                    <Typography sx={{ m: 2 }} variant="subtitle1" gutterBottom component="div">
                        Percent Discount {data?.percentDiscount}
                    </Typography>


                    <Typography sx={{ m: 2 }} variant="subtitle1" gutterBottom component="div">
                        Flat Discount {data?.flatDiscount}
                    </Typography>

                    <Typography sx={{ m: 2 }} variant="subtitle1" gutterBottom component="div">
                        Service Charge {data?.serviceCharge}
                    </Typography>
                    <Divider />
                    {
                        data?.subscriptionPlan?.subscriptions?.length > 0 &&
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Subscriptions
                                </ListSubheader>
                            }
                        >
                            {data?.subscriptionPlan?.subscriptions?.map((item, index) =>
                                <ListItemButton key={index}>
                                    <ListItemIcon>
                                        <LoyaltyIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={item?.title} />
                                </ListItemButton>
                            )}
                        </List>

                    }

                    {
                        data?.catalogPlan?.courses?.length > 0 &&
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Courses
                                </ListSubheader>
                            }
                        >
                            {data?.catalogPlan?.courses?.map((item, index) =>
                                <ListItemButton key={index}>
                                    <ListItemIcon>
                                        <CollectionsBookmarkIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={item?.title} />
                                </ListItemButton>
                            )}
                        </List>

                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
