import React from 'react'
import Grid from "@mui/material/Grid"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import {DesktopDatePicker} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField'
import Stack from "@mui/material/Stack"
import WrittenExamFilterDataExport from './WrittenExamFilterDataExport'

export default function WrittenExamFilter() {
    const [formData, setFormData] = React.useState({ status: '', name: "", fromDate: '', toDate: '' })
    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid sx={{ m: 1 }} md={3} xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.status}
                            label="Select Type"
                            onChange={(event) => setFormData({ ...formData, status: event.target.value })}
                        >
                            <MenuItem value={"Reviewed"}>Reviewed</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid sx={{ m: 1 }} md={3} xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="From Date"
                                inputFormat="MM/dd/yyyy"
                                value={formData.fromDate}
                                onChange={(newValue) => setFormData({ ...formData, fromDate: newValue })}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
                <Grid sx={{ m: 1 }} md={3} xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="To Date"
                                inputFormat="MM/dd/yyyy"
                                value={formData.toDate}
                                onChange={(newValue) => setFormData({ ...formData, toDate: newValue })}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
                <Grid sx={{ m: 1 }} md={3} xs={6}>
                    <WrittenExamFilterDataExport formData={formData} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
