import React from "react";
import "../../Css/root.css";
import { useState, useEffect } from "react";
import axios from "../../axios";
import api from "../../api";
import { Link, Redirect } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {actions} from "../../Components/Toaster/ToastReducer";

export default function UpdatePayment(props) {

    const dispatch = useDispatch();
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const [tohome, setTohome] = React.useState(false);

    const [startTime, setStartTime] = useState(new Date());
    const [phoneNumber, setPhoneNumber] = useState();
    const [user, setUser] = useState();
    const [message, setMessage] = useState();

    const [paymentamount, setPaymentAmount] = useState();
    const [selectedSubsCriptionID, setSelectedSubsCriptionID] = useState();
    const [selectedSubscriptionPlanID, setSelectedSubsCriptionPlanID] = useState();
    const [selectedCourseId, setSelectedCourseId] = useState('')
    const [selectedCoursePlanId, setSelectedCoursePlanId] = useState('')
    const [transactionid, setTransactionID] = useState();
    const [storeamount, setStoreAmount] = useState();
    const [status, setStatus] = useState();
    const [coupon, setCoupon] = useState();
    const [paymentdate, setPaymentDate] = useState();
    const [walletFromAmount, setWalletFromAmount] = useState();

    const [paymentType, setPaymentType] = useState('')
    const [goals, setGoals] = useState();
    const [subs, setSubs] = useState();
    const [subsPlan, setSubsPlan] = useState();
    const [courses, setCourses] = useState()
    const [coursePlan, setCoursePlan] = useState()

    const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

    useEffect(() => {
        goal();
    }, []);

    async function goal() {
        settoken(localStorage.getItem("user"));
        const response = await axios.get(api.orderGetGoals, data);
        setGoals(response.data);
    }

    async function getSubs(selected) {
        const response = await axios.get(api.orderGetSubscriptions+`?goalId=${selected}`, data);
        setSubs(response.data.subscriptions);
    }
    async function getSubsPlan(selected) {
      const response = await axios.get(`/admin/paymentPlan/subscription?subscriptionId=${selected}&pageNo=1&row=100`, data);
      setSubsPlan(response.data.result.paymentPlans)
    }
    async function getCourses(selected) {
        const response = await axios.get(api.orderGetCourses + `?goalId=${selected}`, data)
        setCourses(response.data.catalogCourses)
    }
    async function getCoursePlan(selected) {
        const response = await axios.get(`/admin/paymentPlan/course?courseId=${selected}&pageNo=1&row=100`, data)
        setCoursePlan(response.data.result.paymentPlans)
    }
    const data = {
        headers: {
            "x-auth-token": token,
        },
    };
    if (tohome === true) {
        return <Redirect to="/payment" />;
    }
    const handleSubmit = () => {
        const body = {
            userId: user ? user._id : '',
            paymentPlanId: paymentType === 'subscription' ? selectedSubscriptionPlanID : selectedCoursePlanId,
            transactionId: transactionid,
            paymentAmount: paymentamount,
            storeAmount: storeamount,
            coupon: coupon,
            amountFromWallet: walletFromAmount,
            paymentDate: startTime,
            paymentType: paymentType,
        }
        axios
            .post(api.createPayment, body, data)
            .then(async (resp) => {
                const { status } = resp;
                if (status === 200) {
                    dispatch(actions.Success("Payment updated Succssfully"));
                    setIsPaymentSuccess(true)
                }
            })
            .catch((error) => {
                dispatch(actions.Danger("Something went wrong"));
            });
    }

    const handleSeacrhUser = () => {
        const url = `${api.getuserByphone}/${phoneNumber}`;

        axios
            .get(url, data)
            .then((resp) => {
                
                const { user, message } = resp.data;
                if(!user){
                    dispatch(actions.Danger("user not found"));
                }
                setUser(user);
                setMessage(message);
            })
            .catch((error) => {
                dispatch(actions.Danger("Something went wrong"));
                if (error.response.status == 401) {
                    return dispatch(props.logout());
                } else {
                    return error.response.status;
                }
            });
    }
    const handleSubmitPaymentAddToUser = async () => {
        const body = {
            userId: user ? user._id : '',
            transactionId: transactionid,
            amountFromWallet: walletFromAmount,
        }
        axios
            .post(api.paymentAddUser, body, data)
            .then(async (resp) => {
                const { status } = resp;

                if (status == 200) {
                    dispatch(actions.Success("Payment add to user success"));
                    setTohome(true)
                }
            })
            .catch((error) => {
                dispatch(actions.Danger("something went wrong"));
            });
    }

    return (
        <div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-header">
                                    <h3 className="card-title"> Create Payment</h3>
                                    <div className="card-toolbar">
                                        <div className="example-tools justify-content-center">
                                            <Link
                                                className="btn btn-success font-weight-bolder font-size-sm"
                                                to="/coupon"
                                            >
                                                <i className="flaticon2-list"></i>
                                                Update Payment
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <form className="form">
                                    <input type="hidden" />
                                    <div className="card-body">

                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Phone Number :
                                            </label>
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    placeholder="01xxxxxxxxx"
                                                    onChange={(e) => {
                                                        setPhoneNumber(`88${e.target.value}`)
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-success mr-2"
                                                    onClick={handleSeacrhUser}
                                                >
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            user ?
                                                <div className="form-group row">
                                                    <label className="col-lg-10 col-form-label text-left">
                                                        {message}
                                                    </label>

                                                </div> : null
                                        }
                                        {
                                            user ?

                                                <>
                                                    <div className="form-group row">
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Selected User :
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                value={user ? user.email : ''}
                                                                placeholder="User Email"
                                                                onChange={(e) => {
                                                                    //setName(e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Payment Amount :
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                placeholder="Enter Amount"
                                                                onChange={(e) => {
                                                                    //setName(e.target.value);
                                                                    setPaymentAmount(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Type :
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <Form.Control
                                                                as="select"
                                                                onChange={(e) => {
                                                                    const selected = e.target.value;
                                                                    setPaymentType(selected)
                                                                }}
                                                            >
                                                                <option value="" defaultChecked>
                                                                    Select Payment Type
                                                                </option>
                                                                <option value="subscription">
                                                                    Subscription
                                                                </option>
                                                                <option value="course">
                                                                    Course
                                                                </option>
                                                            </Form.Control>
                                                        </div>
                                                    </div>

                                                    {
                                                        paymentType === 'subscription' ?
                                                        (<>
                                                            <div className="form-group row">
                                                                <label className="col-lg-2 col-form-label text-left">
                                                                    Goal :
                                                                </label>
                                                                <div className="col-lg-3">
                                                                    <Form.Control
                                                                        as="select"
                                                                        onChange={(e) => {
                                                                            const selected = e.target.value;
                                                                            getSubs(selected)
                                                                        }}
                                                                    >
                                                                        <option value="" defaultChecked>
                                                                            Select Goal
                                                                        </option>
                                                                        {
                                                                            goals ?
                                                                                goals.map(item =>
                                                                                    <option value={item._id}>
                                                                                        {item.title}
                                                                                    </option>
                                                                                ) : null
                                                                        }

                                                                    </Form.Control>
                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-lg-2 col-form-label text-left">
                                                                    Subscription :
                                                                </label>
                                                                <div className="col-lg-3">
                                                                    <Form.Control
                                                                        as="select"
                                                                        onChange={(e) => {
                                                                            const selected = e.target.value;
                                                                            getSubsPlan(selected);
                                                                            setSelectedSubsCriptionID(selected)
                                                                        }}
                                                                    >
                                                                        <option value="" defaultChecked>
                                                                            Select Subscription
                                                                        </option>
                                                                        {
                                                                            subs ?
                                                                                subs.map(item =>

                                                                                    <option value={item._id}>
                                                                                        {item.title}
                                                                                    </option>
                                                                                ) : null
                                                                        }
                                                                    </Form.Control>
                                                                </div>
                                                                <label className="col-lg-2 col-form-label text-left">
                                                                    Subscription Plan :
                                                                </label>
                                                                <div className="col-lg-3">
                                                                    <Form.Control
                                                                        as="select"
                                                                        onChange={(e) => {
                                                                            const selected = e.target.value;
                                                                            setSelectedSubsCriptionPlanID(selected);
                                                                        }}
                                                                    >
                                                                        <option value="" defaultChecked>
                                                                            Select SubscriptionPlan
                                                                        </option>
                                                                        {
                                                                            subsPlan ?
                                                                                subsPlan.map(item =>

                                                                                    <option value={item._id}>
                                                                                        {item.title}
                                                                                    </option>
                                                                                ) : null
                                                                        }
                                                                    </Form.Control>
                                                                </div>
                                                            </div>
                                                        </>) : null
                                                    }

                                                    {
                                                        paymentType === 'course' ?
                                                        (<>
                                                            <div className="form-group row">
                                                                <label className="col-lg-2 col-form-label text-left">
                                                                    Goal :
                                                                </label>
                                                                <div className="col-lg-3">
                                                                    <Form.Control
                                                                        as="select"
                                                                        onChange={(e) => {
                                                                            const selected = e.target.value;
                                                                            getCourses(selected)
                                                                        }}
                                                                    >
                                                                        <option value="" defaultChecked>
                                                                            Select Goal
                                                                        </option>
                                                                        {
                                                                            goals ?
                                                                                goals.map(item =>
                                                                                    <option value={item._id}>
                                                                                        {item.title}
                                                                                    </option>
                                                                                ) : null
                                                                        }

                                                                    </Form.Control>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-lg-2 col-form-label text-left">
                                                                    Course :
                                                                </label>
                                                                <div className="col-lg-3">
                                                                    <Form.Control
                                                                        as="select"
                                                                        onChange={(e) => {
                                                                            const selected = e.target.value;
                                                                            getCoursePlan(selected);
                                                                            setSelectedCourseId(selected)
                                                                        }}
                                                                    >
                                                                        <option value="" defaultChecked>
                                                                            Select Course
                                                                        </option>
                                                                        {
                                                                            courses ?
                                                                                courses.map(item =>

                                                                                    <option value={item._id}>
                                                                                        {item.title}
                                                                                    </option>
                                                                                ) : null
                                                                        }
                                                                    </Form.Control>
                                                                </div>
                                                                <label className="col-lg-2 col-form-label text-left">
                                                                    Course Plan :
                                                                </label>
                                                                <div className="col-lg-3">
                                                                    <Form.Control
                                                                        as="select"
                                                                        onChange={(e) => {
                                                                            const selected = e.target.value;
                                                                            setSelectedCoursePlanId(selected);
                                                                        }}
                                                                    >
                                                                        <option value="" defaultChecked>
                                                                            Select CoursePlan
                                                                        </option>
                                                                        {
                                                                            coursePlan ?
                                                                                coursePlan.map(item =>

                                                                                    <option value={item._id}>
                                                                                        {item.title}
                                                                                    </option>
                                                                                ) : null
                                                                        }
                                                                    </Form.Control>
                                                                </div>
                                                            </div>
                                                        </>) : null
                                                    }

                                                    <div className="form-group row">
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Amount From Wallet
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                placeholder="Enter Amount"
                                                                onChange={(e) => {
                                                                    setWalletFromAmount(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Transaction ID :
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                placeholder="Enter TransactionID"
                                                                onChange={(e) => {
                                                                    setTransactionID(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Store Amount :
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                placeholder="Enter Amount"
                                                                onChange={(e) => {
                                                                    setStoreAmount(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>



                                                    <div className="form-group row">
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Status :
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                placeholder="Enter Status"
                                                                onChange={(e) => {                                                                 
                                                                    setStatus(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Coupon :
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                placeholder="Enter Coupon"
                                                                onChange={(e) => {                                                                 
                                                                    setCoupon(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Payment Date :
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <DatePicker timeIntervals ={1} showTimeSelect selected={startTime} onChange={(date) => setStartTime(date)} />
                                                        </div>
                                                    </div>

                                                </> : null
                                        }


                                        <div className="form-group row"></div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-lg-2"></div>
                                            <div className="col-lg-10">
                                                <button
                                                    type="button"
                                                    className="btn btn-success mr-2"
                                                    onClick={handleSubmit}
                                                >
                                                    Submit
                                                </button>
                                                {
                                                    isPaymentSuccess ?
                                                        <button onClick={handleSubmitPaymentAddToUser} type="button" className="btn btn-secondary">
                                                            Add to User
                                                        </button> : null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
