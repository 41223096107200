import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import Button from '@mui/material/Button';
import CardMenuItem from './CardMenuItem';
import AvatarGroup from '@mui/material/AvatarGroup';
import SimpleBackdrop from "../../../../Components/SimpleBackDrop";
import RoomDetails from "./RoomDetails"
import RecordingDetails from './RecordingDetails';

export default function LiveClassCard({ name, roomId, type, hosts, isActive, isLive, expiration, handleRefreshData }) {
    const [loading, setLoading] = React.useState(false)
    const [showdetails, setShowdetails] = React.useState(false)
    const [showRecordingDetails, setShowRecordingDetails] = React.useState(false)

    return (
        <Card component={Paper} variant="outlined" sx={{ maxWidth: 345, padding: 1 }}>
            {loading && <SimpleBackdrop />}
            {
                showRecordingDetails &&
                <RecordingDetails
                    roomId={roomId}
                    showdetail={showRecordingDetails}
                    handleToggleDetails={(state) => setShowRecordingDetails(state)}
                />
            }
            {showdetails &&
                <RoomDetails
                    handleToggleDetails={(state) => setShowdetails(state)}
                    showdetail={showdetails}
                    name={name}
                    roomId={roomId}
                    hosts={hosts}
                    isActive={isActive}
                    type={type}
                    isLive={isLive}
                    expiration={expiration}
                />}
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        {hosts[0]?.name[0]}
                    </Avatar>
                }
                action={
                    <CardMenuItem
                        name={name}
                        roomId={roomId}
                        hosts={hosts}
                        isActive={isActive}
                        isLive={isLive}
                        expiration={expiration}
                        type={type}
                        handleRefreshData={handleRefreshData}
                    />
                }
                title={name}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
                <AvatarGroup max={3}>
                    {
                        hosts?.map((item, index) =>
                            <Avatar
                                key={index}
                                component={Paper}
                                elevation={4}
                                sx={{ height: '50px', width: '50px', display: 'flex', justifyItems: 'center' }}
                                alt="teacher"
                                src={`https://cdn.rootsedulive.com/${item?.avatar}`}
                            />
                        )
                    }
                </AvatarGroup>
            </Box>
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {hosts[0]?.name && <>Host : {hosts[0]?.name} </>} <br />
                    {hosts[1]?.name && <>Host : {hosts[1]?.name} </>} <br />
                    {hosts.length > 2 && <>+{Math.abs(hosts.length - 2)}&nbsp;more</>}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                {/* <Button onClick={handleJoinMeeting} sx={{ m: 1 }} variant="contained">Join now</Button> */}
                <Button onClick={()=>setShowRecordingDetails(true)} sx={{ m: 1, width: '100%' }} variant="contained">Recordings</Button>
                <Button onClick={() => setShowdetails(true)} sx={{ m: 1, width: '100%' }} variant="outlined">Details</Button>
            </CardActions>
        </Card>
    );
}
