import React, { useEffect, useState } from "react"
import "../Css/root.css"
import { Editor } from "@tinymce/tinymce-react"

export default function RichTextEditor(props) {
    return (
        <Editor
            tinymceScriptSrc="https://cdn.rootsedulive.com/scripts/js/tinymce/tinymce.min.js"
            onInit={props.onInit}
            initialValue={props.initialValue ? props.initialValue : ""}
            init={{
                height: props.sx.height,
                width: props.sx.width,
                menubar: false,
                plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                ],
                external_plugins: {
                    tiny_mce_wiris:
                        "https://cdn.jsdelivr.net/npm/@wiris/mathtype-tinymce4@7.24.0/plugin.min.js",
                },
                toolbar:
                    "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
            scriptLoading={{ async: true }}
        />
    )
}
