import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import CatalogCourseSelect from "./CatalogCourseSelect";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import axios from "../../../axios";
import { useHistory } from "react-router-dom";

export default function AddUpdateCatalogCourses({ userId, personalCourses }) {
  const history = useHistory();
  const [formValues, setFormValues] = useState([
    { goal: "", course: "", referralPercentage: null },
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const config = {
    headers: {
      "x-auth-token": localStorage.getItem("user"),
    },
  };
  const addFormFields = () => {
    setFormValues([
      ...formValues,
      { goal: "", course: "", referralPercentage: null },
    ]);
  };

  const handleChangeGoal = (i, goalId) => {
    let newFormValues = [...formValues];
    newFormValues[i]["goal"] = goalId;
    setFormValues(newFormValues);
  };

  const handleChangeCourse = (i, courseId) => {
    let newFormValues = [...formValues];
    newFormValues[i]["course"] = courseId;
    setFormValues(newFormValues);
  };

  const handleChangeReferral = (i, referralPercentage) => {
    let newFormValues = [...formValues];
    newFormValues[i]["referralPercentage"] = referralPercentage;
    setFormValues(newFormValues);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const handleSubmit = async () => {
    // if (formValues.length <= 0)
    //   return enqueueSnackbar("Course is not allowed to be empty", {
    //     variant: "warning",
    //   });

    const uniqueValues = new Set(formValues.map((v) => v.course));

    if (uniqueValues.size < formValues.length)
      return enqueueSnackbar("Duplicate course found.", {
        variant: "warning",
      });

    try {
      let body = { personalCourses: formValues, userId };
      const response = await axios.post(
        "/admin/educators/add-catalogCourse-referral",
        body,
        config
      );
      enqueueSnackbar(response.data, { variant: "success" });
      history.push("/educator");
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: "error" });
    }
  };

  const handleClear = () => {
    setFormValues([]);
  };

  useEffect(() => {
    setFormValues(personalCourses);
  }, [personalCourses]);

  return (
    <Stack spacing={3} sx={{ p: 4, width: "100%" }}>
      <Divider sx={{ width: "100%" }} />
      <Typography variant="h6">
        <AddCircleIcon />
        &nbsp;Add Personal Courses
      </Typography>
      <Divider sx={{ width: "100%" }} />
      {formValues.map((element, index) => (
        <Stack spacing={3} direction={"row"} key={index}>
          <CatalogCourseSelect
            selectedCourse={element.course}
            selectedGoal={element.goal}
            handleCourseSelection={(courseId) => {
              handleChangeCourse(index, courseId);
            }}
            handleGoalSelection={(goalId) => {
              handleChangeGoal(index, goalId);
            }}
          />
          <FormControl sx={{ maxWidth: 300 }}>
            <InputLabel htmlFor="outlined-adornment-amount">
              Referral Percentage
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={element.referralPercentage}
              onChange={(event) =>
                handleChangeReferral(index, event.target.value)
              }
              type="number"
              startAdornment={
                <InputAdornment position="start">%</InputAdornment>
              }
              label="Referral Percentage"
            />
          </FormControl>
          <IconButton
            onClick={() => removeFormFields(index)}
            aria-label="remove"
            sx={{ fontSize: "1rem" }}
          >
            <RemoveCircleIcon />
            &nbsp;Remove
          </IconButton>
        </Stack>
      ))}
      <IconButton
        sx={{ fontSize: "1rem" }}
        onClick={addFormFields}
        aria-label="add"
      >
        <AddCircleIcon />
        &nbsp;Add More
      </IconButton>
      <Divider sx={{ width: "100%" }} />
      <Stack spacing={3} direction="row">
        <Button onClick={handleSubmit} variant="contained">
          Submit
        </Button>
        <Button onClick={handleClear} variant="outlined">
          Clear
        </Button>
      </Stack>
    </Stack>
  );
}
