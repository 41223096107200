import React from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from "notistack"
import axios from "../../axios";

export default function BrandPartnerExportExcel() {

    const [loading, setLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar()

    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "Brand Partner"

    const fetchData = async () => {
        setLoading(true);
        const url = `/admin/brand-partners/all-brandPartner`
        try {
            const response = await axios.get(url, {
                headers: {
                    "x-auth-token": localStorage.getItem("user"),
                }
            })
            return response.data.result;
        } catch (error) {
            enqueueSnackbar(" " + error.response.data, { variant: 'error' })
        }
    }

    const exportToCSV = async () => {

        var importData = await fetchData();
        var excelData = [];
        for (var index in importData) {
            excelData.push({
                Name: importData[index]?.name,
                Email: importData[index]?.email,
                Phone: importData[index]?.phone,
                Coupon: importData[index]?.referral?.code?.code ? importData[index]?.referral?.code?.code : "",
                ReferralPercentage: importData[index]?.referral?.referralPercentage,
            });
        }
        setLoading(false);
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return <LoadingButton
        color="secondary"
        onClick={() => exportToCSV()}
        loading={loading}
        loadingPosition="start"
        variant="contained"
    >
        EXPORT TO EXCEL
    </LoadingButton>
}
