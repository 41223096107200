import React from 'react'
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Logo from "../images/logo.png"
import { Typography } from '@mui/material'

export default function EmptyComponent({title}) {
  return (
    <Box alignItems={'center'} justifyContent={'center'}>
        <Stack>
         <img style={{width : '40%', marginLeft : '30%'}} src={Logo} alt="logo" />
        </Stack>
        <Stack>
          <Typography align='center' variant='h3' sx={{fontWeight : 550, opacity : '0.4'}}>{title}</Typography>
        </Stack>
    </Box>
  )
}
