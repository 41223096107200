import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export default function SimpleDeleteModal(props) {
    const handleClose = () => {
        props.handleClose()
    }

    const handleDelete = () => {
        props.handleClose()
        props.handleDelete()
    }

    return (
        <Dialog
            open={Boolean(props.open)}
            onClose={handleClose}
            aria-labelledby="simple-delete-dialog-title"
            aria-describedby="simple-delete-dialog-description"
        >
            <DialogTitle id="simple-delete-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="simple-delete-dialog-description">
                    {props.description ? props.description : "Are you sure you want to delete this item? Once you delete this you can not change it back."}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button color="error" onClick={handleDelete}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}