/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import "../../Css/root.css";
import TablePagination from "@mui/material/TablePagination";
import DeleteModal from "../../Components/DeleteModal";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserDetailsModal } from "../../Components/UserDetailsModal";
import axios from "../../axios";
import api from "../../api";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import * as auth from "../../../app/modules/Auth/_redux/authRedux";
import SearchInstitutions from "../../Components/Institutions/SearchInstitutions"
import UserDataExport from "./UserDataExport/UserDataExport";
import {DesktopDatePicker} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import moment from "moment"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import UserDataVisualize from "./UserDataExport/UserDataVisualize"

function Users(props) {
	const [token, settoken] = useState(localStorage.getItem("user"));
	const [Datas, setData] = useState([]);
	const [row, setRow] = useState(5);
	const [page, setpage] = useState(0);
	const [show, setShow] = React.useState(false);
	const [modal, setModal] = React.useState({});
	const handleClose = () => setShow(false);
	const [Count, setCount] = useState(0);
	const [Datass, setDatass] = React.useState([]);
	const optionsC = [];
	const [showDelete, setShowDelete] = useState(false);
	const handleCloseDelete = () => setShowDelete(false);
	const handleShowDelete = () => setShowDelete(true);
	const dispatch = useDispatch();
	const [clas, setclas] = useState("");
	const [version, setversion] = useState("");
	const [year, setyear] = useState("");
	const [group, setgroup] = useState("");
	const [gender, setGender] = useState("");
	const [institution, setInstitution] = useState("");
	const [goal, setgoal] = useState("");
	const [startTime, setStartTime] = React.useState("");
	const [endTime, setEndTime] = React.useState("");
	const [role, setRole] = React.useState("");
	const [visualizeData, setVisualizeData] = React.useState([])

	const [url, seturl] = useState(api.getUser);
	const [nameOrEmailOrPhone, setnameOrEmailOrPhone] = useState("");
	const data = {
		headers: {
			"x-auth-token": token,
		},
	};
	async function goals() {
		settoken(localStorage.getItem("user"));
		const response = await axios.get(api.getgoalUser, data);
		setDatass(response.data);
	}
	const handleChangePage = (event, newPage) => {
		setpage(newPage);
		my_async_fn(row, newPage, url);
	};

	const handleChangeRowsPerPage = (event) => {
		setRow(parseInt(event.target.value));
		setpage(0);
		my_async_fn(event.target.value, 0, url);
	};
	var i = (page * row) + 1;

	async function my_async_fn(row, page, url) {
		axios
			.get(url + "pageNo=" + page + "&row=" + row, data)
			.then((resp) => {
				setData(resp.data.result);
				setCount(resp.data.count);
			})
			.catch((error) => {
				if (error.response.status == 401) {
					return dispatch(props.logout());
				} else {
					return error.response.status;
				}
			});
	}
	function sortData() {
		Datass.map((data) => optionsC.push({ label: data.title, value: data._id }));
	}
	function handleSearch() {
		seturl(api.searchUser + "nameOrEmailOrPhone=" + nameOrEmailOrPhone + "&");
		let url = api.searchUser + "nameOrEmailOrPhone=" + nameOrEmailOrPhone + "&";
		my_async_fn(row, page, url);
	}
	function check(value, addVal, url) {
		if (value !== "") {
			return (urls = url + addVal + value);
		}
	}
	let urls = api.filterSearchUser;

	function handleFilterSearch() {
		fetchData()
	}

	const fetchData = async () => {
		const response = await axios.get(api.filterUserAndExport + `class=${clas}&studyVersion=${version}&goal=${goal}&batchYear=${year}&studyGroup=${group}&gender=${gender}&institution=${institution}&startTime=${moment(startTime).format("YYYY-MM-DD")}&endTime=${moment(endTime).format("YYYY-MM-DD")}&role=${role}`, data)
		setData(response.data.result);
		setCount(response.data.count);
		setRow(response.data.count)
	}

	useEffect(() => {
		my_async_fn(row, page, url);
		goals();
	}, []);
	sortData();

	const onSelectInstitute = (selectedInstitution) => {
		if (selectedInstitution) {
			setInstitution(selectedInstitution.title)
		} else {
			setInstitution("")
		}
	}

	return (
		<div>
			<div>
				<div className="card card-custom">
					<div className="card-header border-0 py-5 cent">
						<h3 className="card-title align-items-start flex-column">
							<span className="card-label font-weight-bolder text-dark">
								User Search
							</span>
						</h3>
					</div>
					<div></div>
					<div className="card-body pt-0 pb-3 ">
						<div className="form-group row">
							<div className="col-lg-12">
								<form>
									<div className="col-lg-12 col-md-12 col-sm-12">
										<div
											className="form-group row"
											style={{ marginBottom: "0px !important" }}
										>
											<div className="col-md-1"></div>

											<div className="col-md-2">
												<select
													className="form-control soption"
													defaultValue=""
													onChange={(e) => {
														setclas(e.target.value);
													}}
												>
													<option value="" disabled>
														Class
													</option>
													<option value="VIII">Eight</option>
													<option value="IX">Nine</option>
													<option value="X">Ten</option>
													<option value="XI">Eleven</option>
													<option value="XII">Twelve</option>
													<option value="undergrad">Undergrad</option>
													<option value="postgrad">Postgrad</option>
												</select>
											</div>
											<div className="col-md-2">
												<select
													className="form-control soption"
													defaultValue=""
													onChange={(e) => {
														setversion(e.target.value);
													}}
												>
													<option value="" disabled >
														Study Version
													</option>
													<option value="Bangla">Bangla</option>
													<option value="English">English</option>
												</select>
											</div>
											<div className="col-md-2">
												<select
													className="form-control soption"
													defaultValue=""
													onChange={(e) => {
														setyear(e.target.value);
													}}
												>
													<option value="" disabled >
														Batch Year
													</option>
													<option value="2020">2020</option>
													<option value="2021">2021</option>
													<option value="2022">2022</option>
													<option value="2023">2023</option>
												</select>
											</div>
											<div className="col-md-2">
												<select
													className="form-control soption"
													defaultValue=""
													onChange={(e) => {
														setgoal(e.target.value);
													}}
												>
													<option value="" disabled >
														Select Goal
													</option>
													{optionsC.map((details) => (
														<option value={details.value}>
															{details.label}
														</option>
													))}
												</select>
											</div>
											<div className="col-md-2">
												<select
													defaultValue=""
													className="form-control soption"
													onChange={(e) => {
														setgroup(e.target.value);
													}}
												>
													<option value="" disabled>
														Study Group
													</option>
													<option value="Science">Science</option>
													<option value="Commerce">Humanities</option>
													<option value="Humanities">Humanities</option>
												</select>
											</div>

											<div style={{ marginTop: '10px', marginLeft: '100px' }} className="col-md-2">
												<select
													defaultValue=""
													className="form-control soption"
													onChange={(e) => {
														setGender(e.target.value);
													}}
												>
													<option value="" disabled>
														Gender
													</option>
													<option value="Male">Male</option>
													<option value="Female">Female</option>
													<option value="Others">Others</option>
												</select>
											</div>
											<div style={{ marginTop: '10px' }} className="col-md-3">
												<SearchInstitutions onSelectInstitute={(selectedInstitution) => onSelectInstitute(selectedInstitution)} />
											</div>
											<div style={{ marginTop: '10px' }} className="col-md-2">
												<LocalizationProvider dateAdapter={AdapterDateFns}>
													<Stack spacing={3}>
														<DesktopDatePicker
															label="Start Date"
															inputFormat="MM/dd/yyyy"
															value={startTime}
															onChange={(newValue) => setStartTime(newValue)}
															renderInput={(params) => <TextField {...params} />}
														/>
													</Stack>
												</LocalizationProvider>
											</div>
											<div style={{ marginTop: '10px' }} className="col-md-2">
												<LocalizationProvider dateAdapter={AdapterDateFns}>
													<Stack spacing={3}>
														<DesktopDatePicker
															label="End Date"
															inputFormat="MM/dd/yyyy"
															value={endTime}
															onChange={(newValue) => setEndTime(newValue)}
															renderInput={(params) => <TextField {...params} />}
														/>
													</Stack>
												</LocalizationProvider>
											</div>

											<div style={{ marginTop: '10px' }} className="col-md-2">
												<select
													defaultValue=""
													className="form-control soption"
													onChange={(e) => {
														setRole(e.target.value);
													}}
												>
													<option value="" disabled>
														Role
													</option>
													<option value="superAdmin">superAdmin</option>
													<option value="admin">admin</option>
													<option value="contentAdmin">contentAdmin</option>
													<option value="questionAdmin">questionAdmin</option>
													<option value="smsAdmin">smsAdmin</option>
													<option value="programDirector">programDirector</option>
													<option value="student">student</option>
													<option value="educator">educator</option>
													<option value="brandPartner">brandPartner</option>
													<option value="doubtSolver">doubtSolver</option>
													<option value="orderAdmin">orderAdmin</option>
													<option value="affiliateAdmin">affiliateAdmin</option>
												</select>
											</div>

											<div className="col-md-1"></div>
											<div className="col-md-12 my-4 text-center">
												<button
													type="button"
													className="btn btn-outline-success"
													onClick={handleFilterSearch}
												>
													Submit
												</button>
												<UserDataExport role={role} startTime={startTime} endTime={endTime} clas={clas} version={version} goal={goal} year={year} group={group} gender={gender} institution={institution} />
											</div>
										</div>
									</div>
								</form>
							</div>
							<div className="col-lg-12">
								<form className="custom-form">
									<div className="input-group col-lg-6 col-md-6 col-sm-6 cent">
										<input
											type="text"
											id="search"
											value={nameOrEmailOrPhone}
											className="form-control"
											placeholder="Search By nameOrEmailOrPhone"
											onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
											onChange={(e) => {
												setnameOrEmailOrPhone(e.target.value);
											}}
										></input>
										<div className="input-group-append">
											<button
												className="btn btn-sm btn-success"
												type="button"
												onClick={handleSearch}
											>
												Search!
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<br></br>
				<br></br>
				{/* Search Ends */}
			</div>
			<DeleteModal
				detail={modal}
				url={api.deleteUser}
				showDelete={showDelete}
				handleCloseDelete={handleCloseDelete}
				reset={() => {
					my_async_fn(row, page, url);
				}}
			/>

			<UserDetailsModal
				show={show}
				UserDetail={modal}
				handleClose={handleClose}
			/>

			<div className={`card card-custom `}>
				<div className="card-header border-0 py-5">
					<h3 className="card-title align-items-start flex-column">
						<span className="card-label font-weight-bolder text-dark">
						 Total registered user: {Count}
						</span>
					</h3>
					<div className="card-toolbar">
					    <div style={{marginRight : '10px'}}>
							<Link
								className="btn btn-success font-weight-bolder font-size-sm"
								to="/bulk-user-logout"
							>
								<span className="svg-icon svg-icon-md svg-icon-white"></span>
								Bulk User Logout
							</Link>
						</div>

						<div style={{marginRight : '10px'}}>
							<Link
								className="btn btn-success font-weight-bolder font-size-sm"
								to="/bulk-user-role"
							>
								<span className="svg-icon svg-icon-md svg-icon-white"></span>
								Update bulk user role
							</Link>
						</div>
						<div>
							<Link
								className="btn btn-success font-weight-bolder font-size-sm"
								to="/create_user"
							>
								<span className="svg-icon svg-icon-md svg-icon-white"></span>
								Add New User
							</Link>
						</div>
						
					</div>
				</div>
				{/* end::Header */}

				{/* begin::Body */}
				<div className="card-body py-0">
					{/* begin::Table */}
					<div className="table-responsive">
						<table
							className="table table-head-custom table-vertical-center"
							id="kt_advance_table_widget_1"
						>
							<thead>
								<tr className="text-left">
									<th className="pl-0" style={{ width: "30px" }}>
										Serial
									</th>
									<th style={{ minWidth: "100px" }}/>
									<th className="pr-0" style={{ width: "80px" }}>
										Name
									</th>
									<th style={{ minWidth: "100px" }}>Email</th>
									<th style={{ minWidth: "140px" }}>Phone</th>
									<th style={{ minWidth: "80px" }}>Class</th>
									<th
										className="pr-0 text-center"
										style={{ minWidth: "150px" }}
									>
										Action
									</th>
								</tr>
							</thead>
							<tbody >
								{Datas.map((detail) => (

									<tr key={detail._id}>
										<td className="pl-0">
											<span className="text-dark-75 font-weight-bolder d-block font-size-lg">
												{i++}
											</span>
										</td>
										<td className="pr-0">
											<div className="symbol symbol-50 symbol-lightv mt-1">
												<span className="symbol-label">
													<AccountCircleIcon fontSize="large"/>
												</span>
											</div>
										</td>
										<td>
											<span className="text-dark-75 font-weight-bolder d-block font-size-lg">
												{detail.name}
											</span>
										</td>
										<td>
											<span className="text-dark-75 font-weight-bolder d-block font-size-lg">
												{detail.email}
											</span>
										</td>
										<td>
											<span className="text-dark-75 font-weight-bolder d-block font-size-lg">
												{detail.phone}
											</span>
										</td>
										<td>
											<span className="text-dark-75 font-weight-bolder d-block font-size-lg">
												{detail.class}
											</span>
										</td>
										<td className="pr-0 text-center">
											<a
												onClick={() => setModal(detail) & setShow(true)}
												className="btn btn-icon btn-light btn-hover-primary btn-sm"
											>
												<span className="svg-icon svg-icon-md svg-icon-primary">
													<SVG
														src={toAbsoluteUrl(
															"/media/svg/icons/General/Settings-1.svg"
														)}
													></SVG>
												</span>
											</a>
											<Link
												to={{
													pathname: "/update_user",
													state: detail,
												}}
												className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
											>
												<span className="svg-icon svg-icon-md svg-icon-warning">
													<SVG
														src={toAbsoluteUrl(
															"/media/svg/icons/Communication/Write.svg"
														)}
													></SVG>
												</span>
											</Link>
											<div
												onClick={() => setModal(detail) & setShowDelete(true)}
												className="btn btn-icon btn-light btn-hover-primary btn-sm"
											>
												<span className="svg-icon svg-icon-md svg-icon-danger">
													<SVG
														src={toAbsoluteUrl(
															"/media/svg/icons/General/Trash.svg"
														)}
													></SVG>
												</span>
											</div>
										</td>
									</tr>

								))}
							</tbody>
						</table>
						<div className="row align-items-center">
							<div className="col-md-8 col-sm-8">
								<div className="d-flex flex-wrp py-2 mr-3">
									<TablePagination
										component="div"
										count={Count}
										page={page}
										onPageChange={handleChangePage}
										rowsPerPage={row}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</div>
							</div>
							<br></br> <br></br>
							<br></br>
							<br></br>
						</div>
					</div>
					{/* end::Table */}


					{visualizeData && <div className={`card card-custom `}>
						<div className="card-header border-0 py-5">
							<UserDataVisualize data={visualizeData} />
						</div>
					</div>}

				</div>
			</div>
		</div>
	);
}
export default connect(null, auth.actions)(Users);
