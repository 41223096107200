import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

export default function LiveClassSkeleton() {

    return (
        <Card component={Paper} variant="outlined" sx={{ maxWidth: 345, padding: 1 }}>
            <CardHeader
                avatar={
                    <Skeleton animation="wave" variant="circular" width={30} height={30} />
                }
                title={<> <Skeleton animation="wave" variant="text" /> </>}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
                <Skeleton sx={{ height: '100px', width: '100px', display: 'flex', justifyItems: 'center' }} animation="wave" variant="circular" />
            </Box>
            <CardContent>
                <Skeleton sx={{width : '30%'}} animation="wave" variant="text" />
            </CardContent>
            <CardActions disableSpacing>
                <Skeleton sx={{ m: 1 }} variant="rectangular" width={80} height={30} />
                <Skeleton sx={{ m: 1 }} variant="rectangular" width={80} height={30} />
            </CardActions>
            <CardActions disableSpacing>
                <Skeleton sx={{ m: 1 }} variant="rectangular" width={200} height={30} />
            </CardActions>
        </Card>
    );
}
