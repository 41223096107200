import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import TextField from '@mui/material/TextField';
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { red } from "@mui/material/colors";

export default function AddUpdateMcqExamSections({formValues, setFormValues}) {

  const addFormFields = () => {
    setFormValues([
      ...formValues,
      { title: "", start: null, end: null },
    ]);
  };

  const handleChangeTitle = (i, title) => {
    let newFormValues = [...formValues];
    newFormValues[i]["title"] = title;
    setFormValues(newFormValues);
  };

  const handleChangeStartIndex = (i, startIndex) => {
    let newFormValues = [...formValues];
    newFormValues[i]["start"] = Number(startIndex);
    setFormValues(newFormValues);
  };

  const handleChangeEndIndex = (i, endIndex) => {
    let newFormValues = [...formValues];
    newFormValues[i]["end"] = Number(endIndex);
    setFormValues(newFormValues);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  return (
    <Stack spacing={3} sx={{ p: 4, width: "100%" }}>
      <Typography fontWeight={650} variant="h5">
      Sections
      </Typography>
      {/* <Divider sx={{ width: "100%" }} /> */}
      {formValues?.map((element, index) => (
        <Stack spacing={3} direction={"row"} key={index}>
          <FormControl sx={{ maxWidth: 300 }}>
            <TextField
              required
              id="outlined-required"
              label="Title"
              value={element.title}
              onChange={(event) =>
                handleChangeTitle(index, event.target.value)
              }
            />
          </FormControl>
          <FormControl sx={{ maxWidth: 300 }}>
            <InputLabel htmlFor="outlined-adornment-amount">
              From
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={element.start}
              onChange={(event) =>
                handleChangeStartIndex(index, event.target.value)
              }
              type="number"
              label="From"
            />
          </FormControl>
          <FormControl sx={{ maxWidth: 300 }}>
            <InputLabel htmlFor="outlined-adornment-amount">
              To
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={element.end}
              onChange={(event) =>
                handleChangeEndIndex(index, event.target.value)
              }
              type="number"
              label="To"
            />
          </FormControl>
          <IconButton
            onClick={() => removeFormFields(index)}
            aria-label="remove"
            sx={{ fontSize: "1rem", color: red[500] }}
          >
            <RemoveCircleIcon />
          </IconButton>
        </Stack>
      ))}
      <Stack display={'flex'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <IconButton
          sx={{ fontSize: "1rem" }}
          onClick={addFormFields}
          aria-label="add"
          color="primary"
        >
          <AddCircleIcon />
          &nbsp;Add More
        </IconButton>
      </Stack>
    </Stack>
  );
}
