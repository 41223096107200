import React, { useState } from "react"
import "../Css/root.css"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

// TODO: Make it more reusable like MultiSelectorChip

export default function BasicSelector(props) {
    const index = props.data.index ? props.data.index : "title"
    
    return (
        <FormControl variant="standard" sx={props.sx}>
            <InputLabel>{props.data.title}</InputLabel>
            <Select
                value={props.data.selected}
                label={props.data.title}
                onChange={(event) => {
                    event.preventDefault()
                    props.handleSelection(event.target.value)
                }}
            >
                {
                    props.data.items.map((item) =>
                        <MenuItem key={item._id} value={item._id}>{item[index]}</MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}