import React, { useEffect, useRef, useState } from "react"
import axiosStatic from "axios"

/* @MUI COMPONENT */
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import MultiSelectorChip from "../../../Components/MultiSelectorChip"
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import RestoreIcon from '@mui/icons-material/Restore'
import Stack from '@mui/material/Stack'

import { useSnackbar } from "notistack"

/* @CUSTOM COMPONENT */
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import BasicSelector from "../../../Components/BasicSelector"

export default function DoubtSolverDialog(props) {
    const [subjectsLoading, setSubjectsLoading] = useState(false)
    const [solversLoading, setSolversLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [doubtSolvers, setDoubtSolvers] = useState([])

    const [selectedSubject, setSelectedSubject] = useState('')
    const [previousSolvers, setPreviousSolvers] = useState('')
    const [selectedSolvers, setSelectedSolvers] = useState('')

    const { enqueueSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    const subjectData = {
        title: "Subject",
        items: props.subjects,
        selected: selectedSubject
    }

    const solverData = {
        title: "Solvers",
        index: "name",
        items: doubtSolvers,
        selected: selectedSolvers
    }

    const handleSubjectSelection = (subjectId) => {
        setSelectedSubject(subjectId)
    }

    const handleSolverSelection = (value) => {
        setSelectedSolvers(value)
    }

    const handleRemoveSubjectfromSolver = async (solvers) => {
        axiosStatic.all(solvers.map(x => axios.delete(
            api.deleteDoubtSolverFromSubject,
            {
                data: {
                    subjectId: selectedSubject,
                    solverId: x,
                    courseId: props.courseId
                }, headers: {
                    "x-auth-token": localStorage.getItem("user"),
                },
            }
        )))
            .then(axiosStatic.spread(function (...response) {
                setSubmitLoading(false)
                enqueueSnackbar("Doubt solver delete from subject successfully", { variant: 'success' })
                props.handleClose()
            }))
            .catch((error) => {
                enqueueSnackbar("Doubt solver delete failed: " + error.response.data, { variant: 'error' })
            })
    }

    const handleAddSubjectToSolver = async (newSolverIds) => {
        axiosStatic.all(newSolverIds.map(x => axios.post(
            api.addSubjectToDoubtSolver,
            {
                courseId: props.courseId,
                subjectId: selectedSubject,
                solverId: x
            },
            config
        )))
            .then(axiosStatic.spread(function (...response) {
                setSubmitLoading(false)
                enqueueSnackbar(response[0]?.data, { variant: 'success' })
                props.handleClose()
            }))
            .catch((error) => {
                enqueueSnackbar("Doubt solver add failed: " + error.response.data, { variant: 'error' })
            })
    }


    const handleSubmit = () => {
        
        const newSolverIds = selectedSolvers
            .filter((x) => !previousSolvers.includes(x))

        const isRemovedSolver = previousSolvers.filter(n => !selectedSolvers.includes(n))
        
        if(newSolverIds?.length === 0 && isRemovedSolver?.length === 0) {
            return enqueueSnackbar("Please add or remove a doubt solver.", { variant: 'warning'})
        }
        if (newSolverIds.length) {
            handleAddSubjectToSolver(newSolverIds)
        }

        if (isRemovedSolver?.length > 0) {
            handleRemoveSubjectfromSolver(isRemovedSolver)
        }
        setSubmitLoading(true)
    }

    const handleClose = () => {
        props.handleClose()
    }

    async function fetchDoubtSolverForCourseSubject(courseId, subjectId) {
        setSubjectsLoading(true)
        axios
            .get(api.getCourseExplorerDoubtSolversByCourseSubject + `?courseId=${courseId}&subjectId=${subjectId}`, config)
            .then((response) => {
                setSubjectsLoading(false)
                const solvers = response?.data?.map(function (item) { return item._id })
                setSelectedSolvers(solvers)
                setPreviousSolvers(solvers)
                setSubjectsLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Solver data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function fetchDoubtSolvers() {
        setSolversLoading(true)
        axios
            .get(api.getCourseExplorerDoubtSolvers, config)
            .then((response) => {
                setSolversLoading(false)
                setDoubtSolvers(response.data)
            })
            .catch((error) => {
                enqueueSnackbar("Solver data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        if (!props.open || !props.courseId) return
        fetchDoubtSolvers(props.courseId)
    }, [props.open])

    useEffect(()=>{
        if (!props.open || !props.courseId || !selectedSubject) return
        fetchDoubtSolverForCourseSubject(props.courseId, selectedSubject)
    }, [props.open, selectedSubject])

    const subjectSelector = (
        <BasicSelector
            data={subjectData}
            sx={{ width: 200 }}
            handleSelection={handleSubjectSelection}
        />
    )

    const solverSelector = (
        <MultiSelectorChip
            data={solverData}
            handleSelection={handleSolverSelection}
            sx={{ width: 400 }}
        />
    )

    return (
        <Dialog open={Boolean(props.open)} onClose={handleClose}>
            <DialogTitle>Doubt Solvers</DialogTitle>
            <DialogContent sx={{ width: 600 }}>
                <Stack direction="column" spacing={4}>
                    {subjectSelector}
                    {selectedSubject && solverSelector}
                </Stack>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    color="secondary"
                    onClick={handleSubmit}
                    loading={subjectsLoading || solversLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
                <LoadingButton
                    color="inherit"
                    sx={{ ml: 2 }}
                    onClick={handleClose}
                    loading={subjectsLoading || solversLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<RestoreIcon />}
                    variant="contained"
                >
                    Cancel
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
