/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import "../../Css/root.css";
import "react-telephone-input/css/default.css";
import { InputGroup, Col, FormControl } from "react-bootstrap";
import { Form } from "react-bootstrap";
import axios from "../../axios";
import api from "../../api";
import Avatar from "../../images/avatar.jpg";
import { Link, Redirect } from "react-router-dom";
import {useDispatch } from "react-redux";
import {actions} from "../../Components/Toaster/ToastReducer";
import { useSnackbar } from "notistack"

export default function UpdateUser(state) {
	const dispatch = useDispatch();
	const [token, settoken] = React.useState(localStorage.getItem("user"));
	const [name, setName] = React.useState(state.history.location.state.name);
	const [role, setrole] = React.useState(state.history.location.state.role);
	const [fname, setFname] = React.useState(
		state.history.location.state.fatherName
	);
	const [mname, setMname] = React.useState(
		state.history.location.state.motherName
	);

	const [password, setPassword] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [walletAmount, setWalletAmount] = React.useState(state?.history?.location?.state?.walletAmount || 0)
	const [batchyear, setBatchyear] = React.useState(
		state.history.location.state.batchYear
	);
	const [phone, setPhone] = React.useState("");
	const [date, setDate] = React.useState("");
	const [gender, setGender] = React.useState(
		state.history.location.state.gender
	);
	const [address, setAddress] = React.useState(
		state.history.location.state.address
	);
	const [institution, setInstitution] = React.useState(
		state.history.location.state.institution
	);
	const [fphone, setFphone] = React.useState("");
	const [mphone, setMphone] = React.useState("");
	const [clas, setClas] = React.useState(state.history.location.state.class);
	const [sgroup, setSgroup] = React.useState(
		state.history.location.state.studyGroup
	);
	const [sversion, setSversion] = React.useState(
		state.history.location.state.studyVersion
	);
	const [errr, setErrr] = React.useState("");
	const [avatar, setAvatar] = React.useState('');
	const [avatarD, setAvatarD] = React.useState();
	const [tohome, setTohome] = React.useState(false);
	const { enqueueSnackbar } = useSnackbar()
	// const handleChange = (event) => {
	//   setGoal(event.target.value)
	// };
	const data = {
		headers: {
			"x-auth-token": token,
		},
	};
	function checkF() {
		if (state.history.location.state.fatherPhone !== undefined) {
			setFphone(state.history.location.state.fatherPhone.substring(2));
		} else {
			setFphone("");
		}
	}
	function checkM() {
		if (state.history.location.state.motherPhone !== undefined) {
			setMphone(state.history.location.state.motherPhone.substring(2));
		} else {
			setMphone("");
		}
	}
	function checkA() {
		if (state.history.location.state.avatar !== undefined) {
			fetchUserAvatar(state.history.location.state.avatar)
		} else {
			setAvatar(Avatar);
		}
	}
	React.useEffect(() => {
		checkF();
		checkM();
		checkA();
	}, []);
	if (tohome === true) {
		return <Redirect to="/" />;
	}
	const body = {
		name: name,
		gender: gender,
		address: address,

		fatherName: fname,
		motherName: mname,
	
        walletAmount : walletAmount,
		institution: institution,
		class: clas,
		studyVersion: sversion,
		studyGroup: sgroup,
		batchYear: batchyear,
		role: role,
	};

	function checkPhone() {
		if (phone !== "") {
			body["phone"] = "88" + phone;
		}
	}
	function checkEmail() {
		if (email !== "") {
			body["email"] = email;
		}
	}
	function checkPassword() {
		if (password !== "") {
			body["password"] = password;
		}
	}
	function checkDate() {
		if (date !== "") {
			body["dateOfBirth"] = date;
		}
	}
	function checkMPhone() {
		if (mphone !== "") {
			body["motherPhone"] = "88" + phone;
		}
	}
	function checkFPhone() {
		if (fphone !== "") {
			body["fatherPhone"] = "88" + phone;
		}
	}
	function handleSubmit() {
		checkDate();
		checkPhone();
		checkEmail();
		checkPassword();
		axios
			.put(api.updateUser + state.history.location.state._id, body, data)
			.then(async (resp) => {
				if (resp.status === 200) {
					dispatch(actions.Success("User updated Succssfully"));
					setTohome(true);
				}
			}) 
			.catch((error) => {
				setErrr(error.response.data);
			});
	}
	function onImageChange(e) {
		let image_as_base64 = URL.createObjectURL(e.target.files[0]);
		let image_as_files = e.target.files[0];
		setAvatar(image_as_base64);
		setAvatarD(image_as_files);
	}
	let formData = new FormData();
	formData.append("avatar", avatarD);
	function ChangeProfilePic() {
		axios
			.post(api.avatarUser + state.history.location.state._id, formData, data)
			.then((response) => {
				const {status}=response;
				if(status == 200)
				{
					dispatch(actions.Success("Profile pic updated Succssfully"));

				}
				setErrr(response.data);
			});
	}
	async function fetchUserAvatar(imageLink) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/admin/bucket/` + imageLink,
		{
			responseType: "blob",
			headers: {
			  "x-auth-token": token,
			},
		  },
      )
      .then(async(response) => {
        if (response.data.statusCode) {
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
        setAvatar(URL.createObjectURL(response.data));
      })
      .catch((error) => {
        enqueueSnackbar("User image fetch failed: " + error?.response?.data, {
          variant: "error",
        });
      });
  }
	return (
		<div>
			<div className="d-flex flex-column-fluid">
				<div className=" container ">
					<div className="row">
						<div className="col-md-12">
							<div className="card card-custom">
								<div className="card-header">
									<h3 className="card-title"> Update User</h3>
									<div className="card-toolbar">
										<div className="example-tools justify-content-center">
											<Link
												className="btn btn-success font-weight-bolder font-size-sm"
												to="/"
											>
												<i className="flaticon2-list"></i>
												All User
											</Link>
										</div>
									</div>
								</div>
								<form className="form">
									<input type="hidden" />
									<div className="card-body">
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Name:
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													placeholder="Enter Name"
													value={name}
													onChange={(e) => {
														setName(e.target.value);
													}}
												/>
											</div>

											<label className="col-lg-2 col-form-label text-left">
												Avatar:
											</label>
											<div className="col-lg-3">
												<div
													className="image-input image-input-outline "
													id="image_1"
												>
													<img
														className="image-input-wrapper"
														src={avatar}
														style={{
															width: "120px",
															height: "120px"
														}}
													/>

													<label
														className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
														data-action="change"
														data-toggle="tooltip"
														title=""
														data-original-title="Change Avatar:"
													>
														<i className="fa fa-pen icon-sm text-muted"></i>

														<input
															type="file"
															accept=".png, .jpg, .jpeg"
															name="avatar"
															onChange={onImageChange}
														/>
													</label>

													<span
														className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
														data-action="cancel"
														data-toggle="tooltip"
														title=""
														data-original-title="Cancel Avatar:"
													>
														<i className="ki ki-bold-close icon-xs text-muted"></i>
													</span>
												</div>
												<button
													type="button"
													onClick={ChangeProfilePic}
													className="btn btn-success mr-2"
												>
													Change
												</button>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Password:
											</label>
											<div className="col-lg-3">
												<input
													type="password"
													className="form-control "
													placeholder="Enter Password"
													onChange={(e) => {
														setPassword(e.target.value);
													}}
												/>
											</div>

											<label className="col-lg-2 col-form-label text-left">
												Email:
											</label>
											<div className="col-lg-3">
												<input
													type="email"
													className="form-control "
													placeholder="Enter Email"
													value={email}
													onChange={(e) => {
														setEmail(e.target.value);
													}}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Batch Year :
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													placeholder="Enter Batch Year"
													value={batchyear}
													onChange={(e) => {
														setBatchyear(e.target.value);
													}}
												/>
											</div>

											<label className="col-lg-2 col-form-label text-left">
												Phone:
											</label>
											<InputGroup className="col-lg-3">
												<InputGroup.Text>88</InputGroup.Text>
												<FormControl
													id="inlineFormInputGroup"
													name="telephone"
													placeholder="Phone Number"
													value={phone}
													type="tel"
													onChange={(e) => {
														setPhone(e.target.value);
													}}
												/>
											</InputGroup>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Date Of Birth :
											</label>
											<div className="col-lg-3">
												<input
													type="date"
													className="form-control "
													value={date}
													onChange={(e) => {
														setDate(e.target.value);
													}}
												/>
											</div>

											<label className="col-lg-2 col-form-label text-left">
												Gender :
											</label>
											<div className="col-lg-3">
												<Form.Control
													as="select"
													onChange={(e) => {
														setGender(e.target.value);
													}}
													value={gender}
												>
													<option value="" disabled selected>
														Gender
													</option>
													<option value="Male">Male</option>
													<option value="Female">Female</option>
													<option value="Others">Others</option>
												</Form.Control>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Address :
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													value={address}
													placeholder="Enter Address"
													onChange={(e) => {
														setAddress(e.target.value);
													}}
												/>
											</div>

											<label className="col-lg-2 col-form-label text-left">
												Institution:
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													value={institution}
													placeholder="Enter Institution"
													onChange={(e) => {
														setInstitution(e.target.value);
													}}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Father's Name :
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													value={fname}
													placeholder="Enter Father's Name"
													onChange={(e) => {
														setFname(e.target.value);
													}}
												/>
											</div>

											<label className="col-lg-2 col-form-label text-left">
												Mother's Name :
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													className="form-control "
													value={mname}
													placeholder="Enter Mother's Name"
													onChange={(e) => {
														setMname(e.target.value);
													}}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Father's Phone:
											</label>
											<InputGroup className="col-lg-3">
												<InputGroup.Text>88</InputGroup.Text>
												<FormControl
													id="inlineFormInputGroup"
													value={fphone}
													placeholder="Phone Number"
													type="tel"
													onChange={(e) => {
														setFphone(e.target.value);
													}}
												/>
											</InputGroup>

											<label className="col-lg-2 col-form-label text-left">
												Mother's Phone:
											</label>
											<InputGroup className="col-lg-3">
												<InputGroup.Text>88</InputGroup.Text>
												<FormControl
													id="inlineFormInputGroup"
													value={mphone}
													placeholder="Phone Number"
													type="tel"
													onChange={(e) => {
														setMphone(e.target.value);
													}}
												/>
											</InputGroup>
										</div>

										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Class :
											</label>
											<div className="col-lg-3">
												<Form.Control
													as="select"
													onChange={(e) => {
														setClas(e.target.value);
													}}
													value={clas}
												>
													<option value="" disabled selected>
														Class
													</option>
													<option value="VIII">Eight</option>
													<option value="IX">Nine</option>
													<option value="X">Ten</option>
													<option value="XI">Eleven</option>
													<option value="XII">Twelve</option>
													<option value="undergrad">Undergrad</option>
													<option value="postgrad">Postgrad</option>
												</Form.Control>
											</div>

											<label className="col-lg-2 col-form-label text-left">
												Study Group :
											</label>
											<div className="col-lg-3">
												<Form.Control
													as="select"
													onChange={(e) => {
														setSgroup(e.target.value);
													}}
													value={sgroup}
												>
													<option value="" disabled selected>
														Study Group
													</option>
													<option value="Science">Science</option>
													<option value="Commerce">Humanities</option>
													<option value="Humanities">Humanities</option>
												</Form.Control>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Study Version :
											</label>
											<div className="col-lg-3">
												<Form.Control
													as="select"
													onChange={(e) => {
														setSversion(e.target.value);
													}}
													value={sversion}
												>
													<option value="" disabled selected>
														Study Version
													</option>
													<option value="Bangla">Bangla</option>
													<option value="English">English</option>
												</Form.Control>
											</div>

											<label className="col-lg-2 col-form-label text-left">
												Role :
											</label>
											<div className="col-lg-3">
												<Form.Control
													as="select"
													onChange={(e) => {
														setrole(e.target.value);
													}}
													value={role}
												>
													<option value="" disabled selected>
														Role
													</option>
													<option value="superAdmin">Super Admin</option>
													<option value="admin">Admin</option>
													<option value="contentAdmin">Content Admin</option>
													<option value="questionAdmin">Question Admin</option>
													<option value="programDirector">
														Program Director
													</option>
													<option value="student">Student</option>
													<option value="educator">Educator</option>
													<option value="brandPartner">Brand Partner</option>
													<option value="doubtSolver">Doubt Solver</option>
													<option value="orderAdmin">orderAdmin</option>
													<option value="affiliateAdmin">affiliateAdmin</option>
													<option value="examReviewer">Exam Reviewer</option>
												</Form.Control>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Wallet :
											</label>
											<div className="col-lg-3">
												<input
													type="number"
													className="form-control "
													placeholder="Enter wallet amount"
													value={walletAmount}
													onChange={(e) => {
														setWalletAmount(e.target.value);
													}}
												/>
											</div>
										</div>

										<div className="form-group row">
											<label
												n
												className="col-lg-2 col-form-label text-left"
												style={{ color: "red", fontSize: "22px" }}
											>
												{errr}
											</label>
											<div className="col-lg-3" hidden>
												<span className="switch switch-icon switch-success ">
													<label>
														<input type="hidden" value="0" name="is_active" />
														<input type="checkbox" value="1" name="is_active" />

														<span></span>
													</label>
												</span>
											</div>
										</div>
										<div className="form-group row"></div>
									</div>
									<div className="card-footer">
										<div className="row">
											<div className="col-lg-2"></div>
											<div className="col-lg-10">
												<button
													type="button"
													onClick={handleSubmit}
													className="btn btn-success mr-2"
												>
													Submit
												</button>
												<button type="reset" className="btn btn-secondary">
													Reset
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
