import React, { useEffect, useState } from "react"
import "../../Css/root.css"
import axios from "../../axios"
import api from "../../api"
import BasicSelector from "../BasicSelector"

export default function GoalFilter(props) {
    const [goals, setGoals] = useState([])

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    const goalData = {
        title: "Goals",
        items: goals,
        selected: props.selectedGoal
    }

    useEffect(() => {
        getGoalsForFilter();
    }, []);

    async function getGoalsForFilter() {
        axios
            .get(api.getFilterGoals, config)
            .then((response) => {
                setGoals(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }


    function handleGoalSelection(goalId) {
        props.handleGoalSelection(goalId)
    }

    return (
        <BasicSelector data={goalData} sx={{ width: 200 }} handleSelection={handleGoalSelection} />
    )
}