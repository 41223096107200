import React from "react";
import "../../Css/root.css";
import { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "../../axios";
import api from "../../api";
import Form from "react-bootstrap/Form";

import Chip from "@mui/material/Chip";
import { MuiChipsInput } from 'mui-chips-input'
import {actions} from "../../Components/Toaster/ToastReducer";
import {useDispatch } from "react-redux";
import ExcelToUploadPhone from "./ExcelToUploadPhone"
export default function CustomNumberSms() {
    const dispatch = useDispatch();
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const [tohome, setTohome] = React.useState(false);
    const [maskID, setMaskID]=useState();
    const [message, setMessage]=useState();
    const [excelfileUser, setExcelFileUser] = useState([])
    const [excelfileColumns, setExcelFileColumns] = useState([])
    const [phoneNumbers, setPhoneNumbers]=useState([]);
    let data = {
        headers: {
            "x-auth-token": token,
        },
    };

    const handleChangeChips = (phoneNumbers) => {
        const validPhoneNumbers = phoneNumbers.filter(item=>item.length === 11 )
        setPhoneNumbers(validPhoneNumbers)
    }

    const handleMergeUser =(users)=>{
        setExcelFileUser(users)
    }
    const dynamicMessage =()=>{

    }

    const handleSubmit =()=>{
        var phoneArr = [...phoneNumbers]
        var msisdn = []
        for (var data in phoneArr) {
            msisdn.push(`88${phoneArr[data]}`);
        }
        msisdn = [...msisdn, ...excelfileUser]
        if(excelfileUser.length){
            dynamicMessage()
        }
        const url= `${api.SmsSendCustomUser}sId=${maskID}`
        const body={
            msisdn : msisdn,
            smsBody : message
        }
        data = {
            headers: {
                "x-auth-token": token,
            },
        };
        if (msisdn && maskID && message) {
            axios
                .post(url, body, data)
                .then(async (resp) => {
                    const {
                        status,
                    } = resp;
                    if (status === 200 ) {
                        dispatch(actions.Success("Sms send successfully"));
                        setTohome(true);
                    }
                })
                .catch((error) => {
                });
        }
    }
    if (tohome === true) {
        return <Redirect to="/sms" />;
    }
    return (
        <div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-header">
                                    <h3 className="card-title"> SEND SMS</h3>
                                    <div className="card-toolbar">
                                        <div className="example-tools justify-content-center">
                                            <Link
                                                className="btn btn-success font-weight-bolder font-size-sm"
                                                to="/subjects"
                                            >
                                                <i className="flaticon2-list"></i>
                                                SEND SMS TO SELECTED USER
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <form className="form">
                                    <input type="hidden" />
                                    <div className="card-body">

                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Masking
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => {
                                                        setMaskID(e.target.value)
                                                    }}
                                                >
                                                    <option value="" defaultChecked>
                                                        Masking Type
                                                    </option>
                                                    <option value="sIdNonMasking">
                                                       sIdNonMasking
                                                    </option>
                                                    <option value="sIdMasking">
                                                      sIdMasking
                                                    </option>

                                                </Form.Control>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-lg-6">
                                                <div>
                                                    <MuiChipsInput label="Phone Number" value={phoneNumbers} onChange={handleChangeChips} />
                                                </div>
                                            </div>
                                           
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-6">
                                                <div className="form-group green-border-focus">
                                                    <label style={{fontSize:'12px'}} for="exampleFormControlTextarea5">write message</label>
                                                    <textarea onChange={(e)=> setMessage(e.target.value)} className="form-control" id="exampleFormControlTextarea5" rows="5"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <ExcelToUploadPhone handleMergeUser={(users)=>handleMergeUser(users)} />
                                            </div>
                                        </div>

                                        <div className="form-group row"></div>

                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-lg-2"></div>
                                            <div className="col-lg-10">
                                                <button
                                                    type="button"
                                                    className="btn btn-success mr-2"
                                                    onClick={handleSubmit}
                                                >
                                                    Send
                                                </button>
                                                <button type="reset" className="btn btn-secondary">
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
