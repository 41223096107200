import React, { useState } from 'react'
import { Box, Card, CardHeader, CardContent, Stack } from '@mui/material';
import SubjectFilter from '../../../Components/Filters/SubjectFilter';
import DoubSolverExportSubjectWise from "./DoubSolverExportSubjectWise"

export default function DoubtSolverSubjectFilter() {
    const [selectedCourse, setSelectedCourse] = useState('')
    const [selectedSubject, setSelectedSubject] = useState('')

    const filterBox = (
        <Card>
            <CardHeader
                title={"Doubt Solver Export Subject wise"}
            />
            <CardContent>
                <Stack spacing={3}>
                    <SubjectFilter
                        selectedSubject={selectedSubject}
                        handleCourseSelection={(courseId) => {
                            setSelectedCourse(courseId)
                        }}
                        handleSubjectSelection={(subjectId) => {
                            setSelectedSubject(subjectId)
                        }}
                    />
                </Stack>
                <Stack display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ mt: 3 }}>
                    <DoubSolverExportSubjectWise
                        courseId={selectedCourse}
                        subjectId={selectedSubject}
                    />
                </Stack>
            </CardContent>
        </Card>
    );
    return (
        <Box>
            {filterBox}
        </Box>
    )
}
