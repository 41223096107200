import * as React from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import {institutions} from "./institutions"

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export default function SearchInstitutions({onSelectInstitute}) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const [searchInstitution, setSearchInstitution]=React.useState(' ');
    const [institutionList, setInstitutionList] = React.useState([]);
    
    React.useEffect (()=>{
        if(searchInstitution.length <= 0  && searchInstitution.length % 2 == 0 ) return null
        let result = institutions && institutions.filter(o => o.title.toLowerCase().match(searchInstitution.toLowerCase())).slice(0, 30);
        setInstitutionList(result)
        setOptions(result)
    }, [searchInstitution])

    React.useEffect (()=>{
        if(searchInstitution.length >= 1 ) return null
        fetchTopInstitution()
     }, [searchInstitution])

     React.useEffect (()=>{
        fetchTopInstitution()
     }, [])

     const fetchTopInstitution = ()=>{
        if(institutions) setInstitutionList(institutions.slice(0, 30))
        if(institutions) setOptions(institutions.slice(0, 30))
    }

    React.useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        (async () => {
            await sleep(1e3); // For demo purposes.
            if (active) {
                setOptions([...institutionList]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const handleChangeInstitution = (event, newValue)=>{
        if(!newValue) fetchTopInstitution()
        onSelectInstitute(newValue)
    }
    return (
        <Autocomplete
            id="asynchronous-demo"
            sx={{ width: '100%' }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            getOptionLabel={(option) => option.title}
            options={options}
            loading={loading}
            onChange={handleChangeInstitution}
            renderInput={(params) => (
                <TextField
                    onChange={(e)=>setSearchInstitution(e.target.value)}
                    {...params}
                    label="Institution"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
