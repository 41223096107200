import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import QuestionPreview from "./QuestionPreview";
import ReactToPrint from 'react-to-print';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, examId, handleCloseDialog }) {

    const [loading, setLoading] = useState(true)
    const bodyRef = React.createRef();

    const handleClose = () => {
        handleCloseDialog(false)
    };

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Export Pdf
                        </Typography>
                        {loading ? <div>Loading...</div> : <ReactToPrint
                            trigger={() => <Button autoFocus color="inherit" onClick={handleClose}>
                                save
                            </Button>}
                            content={() => bodyRef.current}
                        />}
                    </Toolbar>
                </AppBar>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ p: 2 }}>
                    <Box maxWidth={'md'}>
                        <div ref={bodyRef}>
                            <QuestionPreview
                                examId={examId}
                                handleLoading={(loading) => setLoading(loading)}
                            />
                        </div>
                    </Box>
                </Box>
            </Dialog>
        </div>
    );
}
