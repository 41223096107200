import React from "react"
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export function OrderDetailsModal({ OrderDetail, show, handleClose }) {
    
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }

    const subscriptionsDetails = (
        <>
            <br /><br />
            {
                OrderDetail.subscriptions ?
                    <>
                        {
                            OrderDetail.subscriptions.map(item =>
                                <div key={item._id}>
                                    <p>Subscription title :  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}> {item.subscriptionId.title}</span> </p>
                                    <p>Last Payment Date :  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}> {formatDate(item.lastPaymentDate)}</span>
                                        <p>End Date :  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}> {formatDate(item.endDate)}</span> </p> </p>
                                    <br /> <br />
                                </div>
                            )
                        }

                    </>
                    : null
            }
        </>
    )

    const courseDetails = (
        <>
            <br /><br />
            {
                OrderDetail.catalogCourses ?
                    <>
                        {
                            OrderDetail?.catalogCourses?.map(item =>
                                <div key={item._id}>
                                    <p>Course title :  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}> {item.course.title}</span> </p>
                                    <p>Last Payment Date :  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}> {formatDate(item.lastPaymentDate)}</span>
                                        <p>End Date :  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}> {formatDate(item.endDate)}</span> </p> </p>
                                    <br /> <br />
                                </div>
                            )
                        }

                    </>
                    : null
            }
        </>
    )

    return (
        <Dialog
            fullScreen
            open={show}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box sx={{ p: 4 }}>
                <p>Name:  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}> {OrderDetail.name}</span> </p>
                <p>Email:  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}>{OrderDetail.email}</span> </p>
                <p>Phone:  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}> {OrderDetail.phone}</span> </p>
                <p>Wallet Amount:  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}> {OrderDetail.walletAmount}</span> </p>
            </Box>

            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Subscriptions" {...a11yProps(0)} />
                        <Tab label="Catalog Course" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {subscriptionsDetails}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {courseDetails}
                </TabPanel>
            </Box>
        </Dialog>
    )
}