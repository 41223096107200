import React, { useEffect } from "react";
import {
    styled,
    Box,
    Popper,
    Checkbox,
    TextField,
    Autocomplete,
    Divider,
    ClickAwayListener,
    AutocompleteCloseReason
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Skeleton from '@mui/material/Skeleton';
import axios from "../../../axios";
import api from "../../../api";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PopperStyledComponent = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
        }`
}));

export default function SubscriptionMultiSelect({ handleChangeSubscriptions }) {
    const [loading, setLoading] = React.useState(true)
    const [value, setValue] = React.useState([]);
    const [checkAll, setCheckAll] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [subscriptionsData, setSubscriptionsData] = React.useState([])
    const config = { headers: { "x-auth-token": localStorage.getItem("user") } }

    const checkAllChange = (event) => {
        setCheckAll(event.target.checked);
        if (event.target.checked) {
            setValue(subscriptionsData);
            handleChangeSubscriptions(subscriptionsData)
        } else {
            setValue([]);
            handleChangeSubscriptions([])
        }
    };

    const handleClickAway = (e) => {
        setOpen(false);
    };

    async function getAllSubscriptions() {
        const response = await axios.get(api.getSubsGoal, config)
        setSubscriptionsData(response.data)
        setLoading(false)
    }
    useEffect(() => {
        getAllSubscriptions()
    }, [])

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box>
                {!loading && <Autocomplete
                    multiple
                    disableCloseOnSelect
                    limitTags={3}
                    id="checkboxes-tags-demo"
                    options={subscriptionsData}
                    value={value}
                    open={open}
                    onChange={(event, newValue, reason) => {
                        if (reason === "selectOption") {
                            setValue(newValue);
                            handleChangeSubscriptions(newValue)
                        } else if (reason === "removeOption") {
                            setCheckAll(false);
                            setValue(newValue);
                            handleChangeSubscriptions(newValue)
                        } else if (reason === "clear") {
                            setValue([]);
                            handleChangeSubscriptions([])
                            setCheckAll(false);
                        }
                    }}
                    onClose={(e, reason) => {
                        if (reason === "escape") {
                            setOpen(false);
                        }
                    }}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    PopperComponent={(param) => (
                        <PopperStyledComponent {...param}>
                            <Box {...param} />
                            <Divider />
                            <Box
                                sx={{
                                    backgroundColor: "white",
                                    height: "45px",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap"
                                }}
                            >
                                <Checkbox
                                    checked={checkAll}
                                    onChange={checkAllChange}
                                    id="check-all"
                                    sx={{ marginRight: "8px" }}
                                    onMouseDown={(e) => e.preventDefault()}
                                />
                                Select All
                            </Box>
                        </PopperStyledComponent>
                    )}
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected || checkAll}
                            />
                            {option.title}
                        </li>
                    )}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                        <TextField {...params} label="Subscriptions" placeholder="Subscriptions" />
                    )}
                />}
                {loading && <Skeleton animation="wave" variant="rectangular" width={"100%"} height={60} />}
            </Box>
        </ClickAwayListener>
    );
}