import React, { useState } from "react";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import ExcelToUploadPhone from "../component/ExcelToUploadPhone";
import GoalFilter from "../../../../Components/Filters/GoalFilter";
import SubscriptionFilter from "../../../../Components/Filters/SubscriptionFilter";
import RejectedOrderUserExportExcel from "../component/RejectedOrderUserExportExcel";
import axios from "../../../../axios";
import api from "../../../../api";

const FilterTitleByMode = {
  SubscriptionCourse: "Select Subscription",
  FreeCourse: "Select Goal",
};
const ExplorerMode = {
  SubscriptionCourse: "SubscriptionCourse",
  FreeCourse: "FreeCourse",
};

export default function SubscriptionCreate() {
  const [rejectedUser, setRejectedUser] = React.useState([]);

  const [explorerMode, setExplorerMode] = useState(
    ExplorerMode.SubscriptionCourse
  );
  const [selectedGoal, setSelectedGoal] = useState("");
  const [selectedSubscription, setSelectedSubscription] = useState("");

  const [formData, setFormData] = React.useState({
    users: [],
    wallet: 0,
    lastPaymentDate: new Date(),
    endDate: new Date(),
  });

  const { enqueueSnackbar } = useSnackbar();
  const config = { headers: { "x-auth-token": localStorage.getItem("user") } };

  const handleMergeUser = (users) => {
    setFormData({ ...formData, users: users });
  };

  const handleSubmit = async () => {
    if (formData.users.length == undefined || formData.users?.length <= 0)
      return enqueueSnackbar("User is not allowed to be empty", {
        variant: "warning",
      });
    if (!formData.lastPaymentDate)
      return enqueueSnackbar("Last payment date is not allowed to be empty", {
        variant: "warning",
      });
    if (!formData.endDate)
      return enqueueSnackbar("End date is not allowed to be empty", {
        variant: "warning",
      });
    if (!selectedSubscription)
      return enqueueSnackbar("Subscription is not allowed to be empty", {
        variant: "warning",
      });
    try {
      const response = await axios.post(
        api.orderCreateForBulkUser,
        { ...formData, subscriptionId: selectedSubscription },
        config
      );
      enqueueSnackbar("" + response.data.message, { variant: "success" });
      if (response?.data?.rejectedUser) {
        setRejectedUser(response.data.rejectedUser);
      }
    } catch (error) {
      enqueueSnackbar("" + error.response.data, { variant: "error" });
    }
  };

  const goalSelector = (
    <GoalFilter
      selectedGoal={selectedGoal}
      handleGoalSelection={(goalId) => {
        setSelectedGoal(goalId);
      }}
    />
  );

  const subscriptionSelector = (
    <SubscriptionFilter
      selectedSubscription={selectedSubscription}
      handleSubscriptionSelection={(subscriptionId) => {
        setSelectedSubscription(subscriptionId);
      }}
    />
  );

  const filterBox = (
    <Card>
      <CardHeader title={FilterTitleByMode[explorerMode]} />
      <CardContent>
        {explorerMode === "SubscriptionCourse" && subscriptionSelector}
        {explorerMode === "FreeCourse" && goalSelector}
      </CardContent>
    </Card>
  );

  const form = (
    <Paper
      variant="outlined"
      sx={{ m: 2, p: { md: 4, xs: 1 }, width: { md: 600, xs: 300 } }}
      square
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        rowSpacing={4}
        columnSpacing={4}
      >
        <Grid item md={12} xs={12}>
          {filterBox}
        </Grid>

        <Grid item md={6} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Last payment date"
              value={formData.lastPaymentDate}
              minDate={new Date("2017-01-01")}
              onChange={(newValue) => {
                setFormData({ ...formData, lastPaymentDate: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item md={6} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="End date"
              value={formData.endDate}
              minDate={new Date("2017-01-01")}
              onChange={(newValue) => {
                setFormData({ ...formData, endDate: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item md={12} xs={12}>
          <TextField
            sx={{ width: "100%" }}
            required
            name="wallet"
            value={formData.wallet}
            onChange={(e) =>
              setFormData({ ...formData, wallet: e.target.value })
            }
            type="number"
            id="outlined-required"
            label="Amount from Wallet"
            defaultValue={0}
          />
        </Grid>

        <Grid item md={12}>
          <ExcelToUploadPhone
            handleMergeUser={(users) => handleMergeUser(users)}
          />
        </Grid>

        <Grid
          sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          item
          md={12}
        >
          <Button
            onClick={handleSubmit}
            sx={{ width: "95%", color: "white", textTransform: "none" }}
            variant="contained"
          >
            Submit
          </Button>
        </Grid>

        {rejectedUser.length ? (
          <Grid
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            item
            md={12}
          >
            <RejectedOrderUserExportExcel rejectedUser={rejectedUser} />
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );

  return (
    <Box elevation={2} component={Paper} sx={{ p: 2 }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          sx={{ color: "#0000009e" }}
          fontWeight={800}
          variant="h5"
          gutterBottom
          align="center"
        >
          Create Subscription Order
        </Typography>
        {form}
      </Grid>
    </Box>
  );
}
