import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "../../../axios";
import { useSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import BasicCircularProgress from "../../../Components/BasicCircularProgress";

export default function PdfDialogModal(props) {
  const location = useLocation();
  const [pdfData, setPdfData] = useState();
  const [loading, setLoading] = useState(true);
  const pdfUrl = props.resultUrl;
  const { enqueueSnackbar } = useSnackbar();

  const config = {
    headers: {
      "x-auth-token": localStorage.getItem("user"),
    },
  };

  const fetchpdf = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/bucket/` + pdfUrl,
        { responseType: "blob" },
        config
      );
      setPdfData(response?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(
        "Written exam image data fetch failed: " + error.response,
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("writtenExamPdfUrl");
    };
  }, []);

  useEffect(() => {
    fetchpdf();
  }, [location]);

  const handleClose = () => {
    props.handleClose();
  };
  const appBar = (
    <AppBar sx={{ position: "relative" }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          View Pdf
        </Typography>
      </Toolbar>
    </AppBar>
  );

  return (
    <Dialog
      fullScreen
      disableEnforceFocus
      open={Boolean(props.open)}
      onClose={handleClose}
    >
      {appBar}
      {loading ? (
        <BasicCircularProgress />
      ) : (
        <Box sx={{ width: "100vw", height: "100vh" }}>
          <object
            data={URL.createObjectURL(pdfData)}
            type="application/pdf"
            width="100%"
            style={{ height: "100vh" }}
          />
        </Box>
      )}
    </Dialog>
  );
}
