import React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton';
export default function DoubtStatusSkeleton() {
    return (
        <Card sx={{ minWidth: 350 }}>
            <CardHeader title={
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            } />
            <CardContent>
                <Grid sx={{ mt: 2 }} container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                    <Grid item>
                        <Stack spacing={3}>
                            <Skeleton variant="rectangular" width={210} height={40} />
                            <Skeleton variant="rectangular" width={210} height={40} />
                            <Skeleton variant="rectangular" width={100} height={40} />
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
