import { Modal,Button } from "react-bootstrap"
import React from "react"
import axios from "../axios";

export default function DeleteModal({detail,url,showDelete,handleCloseDelete,reset}){
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const data = {
		headers: {
			"x-auth-token": token,
		},
	};
    return (
        <Modal show={showDelete} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Data??</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this data?</Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-success mr-2" onClick={handleCloseDelete}>
                    Close
                </Button>
                <Button
                    className="btn btn-danger mr-2"
                    onClick={() => {
                        axios
                            .delete(url + detail._id, data).then(
                                reset
                                
                            ).then(
                                handleCloseDelete()
                            )
                    }}
                >
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
