import React, { useEffect, useState } from "react"
import "../../Css/root.css"
import axios from "../../axios"
import api from "../../api"
import Grid from '@mui/material/Grid'
import BasicSelector from "../BasicSelector"

export default function CourseFilter(props) {
    // Data states
    const [goals, setGoals] = useState([])
    const [subscriptions, setSubscriptions] = useState([])
    const [courses, setCourses] = useState([])

    // Selection states
    const [selectedGoal, setSelectedGoal] = useState('')
    const [selectedSubscription, setSelectedSubscription] = useState('')

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    // Select data props
    const goalData = {
        title: "Goals",
        items: goals,
        selected: selectedGoal
    }

    const subscriptionData = {
        title: "Subscriptions",
        items: subscriptions,
        selected: selectedSubscription
    }

    const courseData = {
        title: "Courses",
        items: courses,
        selected: props.selectedCourse
    }

    // Select elements
    const goalSelector = (
        <Grid item>
            <BasicSelector data={goalData} sx={{ width: 200 }} handleSelection={handleGoalSelection} />
        </Grid>
    )

    const subscriptionSelector = (
        <Grid item>
            <BasicSelector data={subscriptionData} sx={{ width: 200 }} handleSelection={handleSubscriptionSelection} />
        </Grid>
    )

    const courseSelector = (
        <Grid item>
            <BasicSelector data={courseData} sx={{ width: 200 }} handleSelection={handleCourseSelection} />
        </Grid>
    )

    useEffect(() => {
        getGoalsForFilter();
    }, []);

    // Data fetching and state handling functions
    async function getGoalsForFilter() {
        axios
            .get(api.getFilterGoals, config)
            .then((response) => {
                setGoals(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    async function getSubscriptionsForFilter(goalId) {
        axios
            .get(api.getFilterSubscriptions + goalId, config)
            .then((response) => {
                setSubscriptions(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    async function getCoursesForFilter(subscriptionId) {
        axios
            .get(api.getFilterCourses + subscriptionId, config)
            .then((response) => {
                setCourses(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    function handleGoalSelection(goalId) {
        setSelectedGoal(goalId)
        setSelectedSubscription('')
        props.handleCourseSelection('')
        getSubscriptionsForFilter(goalId)
    }

    function handleSubscriptionSelection(subscriptionId) {
        setSelectedSubscription(subscriptionId)
        props.handleCourseSelection('')
        getCoursesForFilter(subscriptionId)
    }

    function handleCourseSelection(courseId) {
        props.handleCourseSelection(courseId)
    }

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
            {goalSelector}
            {subscriptionSelector}
            {courseSelector}
        </Grid>
    )
}