import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { useDispatch } from "react-redux";



/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
	email: "",
	password: "",
};

function Login(props) {
	const [loading, setLoading] = useState(false);
	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.email("Wrong email format")
			.min(3, "Minimum 3 symbols")
			.max(50, "Maximum 50 symbols")
			.required(
				"Email is required"
			),
		password: Yup.string()
			.min(3, "Minimum 3 symbols")
			.max(50, "Maximum 50 symbols")
			.required(
				"Password is required"
			),
	});
	const dispatch = useDispatch();
	const enableLoading = () => {
		setLoading(true);
	};

	const disableLoading = () => {
		setLoading(false);
	};

	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return "is-invalid";
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return "is-valid";
		}

		return "";
	};
	const formik = useFormik({
		initialValues,
		validationSchema: LoginSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			enableLoading();
			setTimeout(() => {
				login(values.email, values.password)
					.then((response) => {
						disableLoading();
						if (response.status === 200) {
							// const authToken = response.headers["x-auth-token"];

							localStorage.setItem("user", response.headers["x-auth-token"]);
							props.login(response.headers["x-auth-token"]);
							dispatch(props.setUser({ user: response.headers["x-auth-token"] }));
							dispatch(props.fulfillUser({ user: response.headers["x-auth-token"] }));
							disableLoading();
						} else {
							
						}
					})
					.catch(() => {
						setStatus(
							"Invalid Login"
						);
					})
					.finally(() => {
						disableLoading();
						setSubmitting(false);
					});
			}, 1000);
		},
	});

	return (
		<div className="login-form login-signin" id="kt_login_signin_form">
			{/* begin::Head */}
			<div className="text-center mb-10 mb-lg-20">
				<h3 className="font-size-h1">
				</h3>
				<p className="text-muted font-weight-bold">
					Enter your username and password
				</p>
			</div>
			{/* end::Head */}

			{/*begin::Form*/}
			<form
				onSubmit={formik.handleSubmit}
				className="form fv-plugins-bootstrap fv-plugins-framework"
			>
				<div className="form-group fv-plugins-icon-container">
					<input
						placeholder="Email"
						type="email"
						className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
							"email"
						)}`}
						name="email"
						{...formik.getFieldProps("email")}
					/>
					{formik.touched.email && formik.errors.email ? (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">{formik.errors.email}</div>
						</div>
					) : null}
				</div>
				<div className="form-group fv-plugins-icon-container">
					<input
						placeholder="Password"
						type="password"
						className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
							"password"
						)}`}
						name="password"
						{...formik.getFieldProps("password")}
					/>
					{formik.touched.password && formik.errors.password ? (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">{formik.errors.password}</div>
						</div>
					) : null}
				</div>
				<div className="form-group d-flex flex-wrap justify-content-between align-items-center">
					{/* <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
          </Link> */}
					<button
						id="kt_login_signin_submit"
						type="submit"
						disabled={formik.isSubmitting}
						className={`btn btn-primary font-weight-bold px-9 py-4 my-3 `}
					>
						<span>Sign In</span>
						{loading && <span className="ml-3 spinner spinner-white"></span>}
					</button>
				</div>
			</form>
			{/*end::Form*/}
		</div>
	);
}

export default connect(null, auth.actions)(Login);
