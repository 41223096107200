import React, { useEffect, useState } from "react";
import axiosStatic from "axios";

/* @MUI COMPONENT */
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MultiSelectorChip from "../../../Components/MultiSelectorChip";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import RestoreIcon from "@mui/icons-material/Restore";
import Stack from "@mui/material/Stack";

import { useSnackbar } from "notistack";

/* @CUSTOM COMPONENT */
import "../../../Css/root.css";
import axios from "../../../axios";
import api from "../../../api";
import BasicSelector from "../../../Components/BasicSelector";

export default function ExamReviewerDialog(props) {
  const [subjectsLoading, setSubjectsLoading] = useState(false);
  const [solversLoading, setSolversLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [examReviewers, setExamReviewers] = useState([]);

  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSolvers, setSelectedSolvers] = useState("");
  const [previousSolvers, setPreviousSolvers] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const config = {
    headers: {
      "x-auth-token": localStorage.getItem("user"),
    },
  };

  const subjectData = {
    title: "Subject",
    items: props.subjects,
    selected: selectedSubject,
  };

  const solverData = {
    title: "Solvers",
    index: "name",
    items: examReviewers,
    selected: selectedSolvers,
  };

 
  const handleSubjectSelection = (subjectId) => {
    setSelectedSubject(subjectId);
  };

  const handleSolverSelection = (value) => {
    setSelectedSolvers(value);
  };

  const handleRemoveSubjectfromSolver = async (solvers) => {
    axiosStatic
      .all(
        solvers.map((x) =>
          axios.delete(api.deleteExamReviewerFromSubject, {
            data: {
              subjectId: selectedSubject,
              reviewerId: x,
              courseId: props.courseId,
            },
            headers: {
              "x-auth-token": localStorage.getItem("user"),
            },
          })
        )
      )
      .then(
        axiosStatic.spread(function(...response) {
          setSubmitLoading(false);
          enqueueSnackbar("Exam Reviewer delete from subject successfully", {
            variant: "success",
          });
          props.handleClose()
        })
      )
      .catch((error) => {
        enqueueSnackbar("Exam Reviewer delete failed: " + error.response.data, {
          variant: "error",
        });
      });
  };

  const handleAddSubjectToSolver = async (newSolverIds) => {
    axiosStatic
      .all(
        newSolverIds.map((x) =>
          axios.post(
            api.addSubjectToExamReviewer,
            {
              courseId: props.courseId,
              subjectId: selectedSubject,
              reviewerId: x,
            },
            config
          )
        )
      )
      .then(
        axiosStatic.spread(function(...response) {
          setSubmitLoading(false);
          enqueueSnackbar(response[0]?.data, { variant: "success" });
          props.handleClose()
        })
      )
      .catch((error) => {
        enqueueSnackbar("Exam Reviewer add failed: " + error.response.data, {
          variant: "error",
        });
      });
  };

  const handleSubmit = () => {
    
    const newSolverIds = selectedSolvers.filter(
      (x) => !previousSolvers.includes(x)
    );
    const isRemovedSolver = previousSolvers.filter(
      (n) => !selectedSolvers.includes(n)
    );

    if (newSolverIds.length) {
      handleAddSubjectToSolver(newSolverIds);
    }

    if (isRemovedSolver?.length > 0) {
      handleRemoveSubjectfromSolver(isRemovedSolver);
    }
    setSubmitLoading(true);
  };

  const handleClose = () => {
    props.handleClose();
  };

  async function fetchExamReviewerByCourseSubject(courseId, subjectId) {
    setSubjectsLoading(true);
    axios
      .get(api.getCourseExplorerExamReviewerByCourseSubject + `?courseId=${courseId}&subjectId=${subjectId}`, config)
      .then((response) => {
        const solvers = response?.data?.map(function(item){ return item._id });
        setSelectedSolvers(solvers);
        setPreviousSolvers(solvers);
        setSubjectsLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar("Solver data fetch failed: " + error.response.data, {
          variant: "error",
        });
      });
  }

  async function fetchExamReviewers() {
    setSolversLoading(true);
    axios
      .get(api.getCourseExplorerExamReviewers, config)
      .then((response) => {
        setSolversLoading(false);
        setExamReviewers(response.data);
      })
      .catch((error) => {
        enqueueSnackbar("Solver data fetch failed: " + error.response.data, {
          variant: "error",
        });
      });
  }

  useEffect(() => {
    if (!props.open || !props.courseId) return;
    fetchExamReviewers(props.courseId);
  }, [props.open]);

  useEffect(() => {
    if (!props.open || !props.courseId || !selectedSubject) return;
    fetchExamReviewerByCourseSubject(props.courseId, selectedSubject);
  }, [props.open, selectedSubject]);


  const subjectSelector = (
    <BasicSelector
      data={subjectData}
      sx={{ width: 200 }}
      handleSelection={handleSubjectSelection}
    />
  );

  const solverSelector = (
    <MultiSelectorChip
      data={solverData}
      handleSelection={handleSolverSelection}
      sx={{ width: 400 }}
    />
  );

  return (
    <Dialog open={Boolean(props.open)} onClose={handleClose}>
      <DialogTitle>Exam Reviewers</DialogTitle>
      <DialogContent sx={{ width: 600 }}>
        <Stack direction="column" spacing={4}>
          {subjectSelector}
          {selectedSubject && solverSelector}
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          color="secondary"
          onClick={handleSubmit}
          loading={subjectsLoading || solversLoading || submitLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
        >
          Save
        </LoadingButton>
        <LoadingButton
          color="inherit"
          sx={{ ml: 2 }}
          onClick={handleClose}
          loading={subjectsLoading || solversLoading || submitLoading}
          loadingPosition="start"
          startIcon={<RestoreIcon />}
          variant="contained"
        >
          Cancel
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
