import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";

/* @mui component */
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

/* @custom component */
import InactiveSubscriptionFilter from "../component/InactiveSubscriptionFilter";
import SubscriptionPaymentExportExcel from "../component/SubscriptionPaymentExportExcel";
import SubscriptionSearchFilter from "../component/SubscriptionSearchFilter";
import axios from "../../../../axios";

export default function SubscriptionInActive() {
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState({ show: false, count: '', totalPaymentAmount: '', totalStoreAmount: '' })
  const { enqueueSnackbar } = useSnackbar();
  const token = localStorage.getItem("user");

  const handleSearchPayment = async () => {
    if (!selectedSubscription) return enqueueSnackbar("Subscription is not allowed to be empty", { variant: 'error' });

    let url = `/admin/payments/subscription-total-payment?subscriptionId=${selectedSubscription}`;
    if (fromDate && toDate) {
      url += `&fromDate=${moment(fromDate).startOf('day').utc()}&toDate=${moment(toDate).endOf('day').utc()}`
    } else {
      setFromDate(null)
      setToDate(null)
    }
    try {
      const response = await axios.get(url, {
        headers: {
          "x-auth-token": token,
        }
      });
      const count = response?.data[0]?.count ?? 0;
      const totalPaymentAmount = response?.data[0]?.totalPaymentAmount ?? 0;
      const totalStoreAmount = response?.data[0]?.totalStoreAmount ?? 0;
      setPaymentDetails({ show: true, count, totalPaymentAmount, totalStoreAmount });
    } catch (error) {
      enqueueSnackbar(error?.response?.data, { variant: 'error' })
    }
  }
  useEffect(() => {
    setPaymentDetails({ show: false })
  }, [fromDate, toDate, selectedSubscription])

  const searchSubscriptionAndSelect = (
    <SubscriptionSearchFilter
        selectedSubscription={selectedSubscription}
        hanldeSubscriptionSelection={(subscriptionId) => {
          setSelectedSubscription(subscriptionId)
        }}
    />
)

  const subscriptionSelector = (
    <InactiveSubscriptionFilter
      selectedSubscription={selectedSubscription}
      handleSubscriptionSelection={(subscriptionId) => {
        setSelectedSubscription(subscriptionId);
      }}
    />
  );
  const filterBox = (
    <Card>
      <CardHeader title="Search Inactive Subscription" />
      <CardContent>
        {searchSubscriptionAndSelect}
      </CardContent>
    </Card>
  );
  const form = (
    <Paper
      variant="outlined"
      sx={{ m: 2, p: { md: 4, xs: 1 }, width: { md: 600, xs: 300 } }}
      square
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        rowSpacing={4}
        columnSpacing={4}
      >
        <Grid item md={12} xs={12}>
          {filterBox}
        </Grid>
        <Grid item md={6} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="From Date"
              value={fromDate}
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item md={6} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="To Date"
              value={toDate}
              onChange={(newValue) => {
                setToDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item md={6} xs={12}>
          {/* @Inactive subscriptions payment export */}
          <SubscriptionPaymentExportExcel
            selectedSubscription={selectedSubscription}
            startDate={fromDate}
            endDate={toDate}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          {/* @search totalPayments, total storeAmount & count */}
          <Button onClick={handleSearchPayment} sx={{ width: '100%' }} variant="contained">Search</Button>
        </Grid>
        <Grid item md={12}>
          {/* @show totalPayments, total storeAmount & count */}
          {paymentDetails?.show && <Stack spacing={3}>
            <Typography sx={{ borderBottom: '3px solid black' }} display="inline" variant="h6">
              Payment Details
            </Typography>
            <Typography display="inline" variant="h6">
              Total Payment Amount: {Number(paymentDetails?.totalPaymentAmount).toFixed(2)}
            </Typography>
            <Typography display="inline" variant="h6">
              Total Store Amount: {Number(paymentDetails?.totalStoreAmount).toFixed(2)}
            </Typography>
            <Typography display="inline" variant="h6">
              Count: {paymentDetails?.count}
            </Typography>
          </Stack>}
        </Grid>
      </Grid>
    </Paper>
  );
  return (
    <Box sx={{ p: 2 }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {form}
      </Grid>
    </Box>
  );
}
