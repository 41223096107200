/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";

import EducatorExportExcel from "./EducatorExportExcel";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "../../Css/root.css";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import api from "../../api";
import TablePagination from "@mui/material/TablePagination";
import DeleteModal from "../../Components/DeleteModal";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import * as auth from "../../../app/modules/Auth/_redux/authRedux";
import ImageModal from "./ImageModal";

function Educator(props) {
  const [token, settoken] = useState(localStorage.getItem("user"));
  const [Datas, setData] = useState([]);
  const [row, setRow] = useState(10);
  const [page, setpage] = useState(0);
  var i = page * row + 1;
  const [Count, setCount] = useState();
  const dispatch = useDispatch();
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  const [lodaing, setlodaing] = useState(true);
  const [url, seturl] = useState(api.getEducator);
  const [searchSub, setsearchSub] = useState("");
  async function my_async_fn(row, page, url) {
    axios
      .get(url + "pageNo=" + page + "&row=" + row, data)
      .then((resp) => {
        setData(resp.data.result);
        setCount(resp.data.count);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          return dispatch(props.logout());
        } else {
          return error.response.status;
        }
      });
  }
  function handleSearch() {
    seturl(api.searchEducator + "nameOrEmailOrPhone=" + searchSub + "&");
    let url = api.searchEducator + "nameOrEmailOrPhone=" + searchSub + "&";
    my_async_fn(row, page, url);
  }

  useEffect(() => {
    my_async_fn(row, page, url);
  }, []);
  const data = {
    headers: {
      "x-auth-token": token,
    },
  };
  const handleChangePage = (event, newPage) => {
    setpage(newPage);
    my_async_fn(row, newPage, url);
  };

  const handleChangeRowsPerPage = (event) => {
    setRow(parseInt(event.target.value));
    setpage(0);
    my_async_fn(event.target.value, 0, url);
  };

  const educatorExport = (
    <Card sx={{ mb: 1 }}>
      <CardHeader title="Educator data export" />
      <CardContent>
        <Grid
          sx={{ mt: 1 }}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item>
            <Stack sx={{ width: 450 }} spacing={3}>
              <EducatorExportExcel />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <div>
      {showImageModal && selectedAvatar && (
        <ImageModal
          open={showImageModal}
          handleChangeImageModal={(state) => setShowImageModal(state)}
          title="Educator Profile Picture"
          avatar={`https://cdn.rootsedulive.com/${selectedAvatar}`}
        />
      )}
      <div>
        <div className="card card-custom">
          <div className="card-header border-0 py-5 cent">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Educator Search
              </span>
            </h3>
          </div>
          <div></div>
          <div className="card-body pt-0 pb-3 ">
            <div className="form-group row">
              <div className="col-lg-12">
                <form className="custom-form">
                  <div className="input-group col-lg-6 col-md-6 col-sm-6 cent">
                    <input
                      type="text"
                      id="search"
                      value={searchSub}
                      className="form-control"
                      placeholder="Search By title"
                      onKeyPress={(e) =>
                        e.key === "Enter" && e.preventDefault()
                      }
                      onChange={(e) => {
                        setsearchSub(e.target.value);
                      }}
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="btn btn-sm btn-success"
                        type="button"
                        onClick={handleSearch}
                      >
                        Search!
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        {/* Search Ends */}
        {educatorExport}
      </div>
      <div className={`card card-custom `}>
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Educator
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="pl-0" style={{ width: "100px" }}>
                    SERIAL
                  </th>
                  <th className="pr-0 " style={{ width: "200px" }} />
                  <th className="pr-0 " style={{ width: "200px" }}>
                    NAME
                  </th>
                  <th className="pr-0 " style={{ width: "150px" }}>
                    EMAIL
                  </th>
                  <th className="pr-0 " style={{ width: "150px" }}>
                    PHONE
                  </th>
                  <th className="pr-0 " style={{ width: "150px" }}>
                    CODE
                  </th>
                  <th style={{ minWidth: "150px" }}>PERCENTEGE</th>
                  <th
                    className="pr-0 text-center"
                    style={{ minWidth: "250px" }}
                  >
                    ACTION
                  </th>
                </tr>
              </thead>
              <tbody>
                {Datas.map((detail) => (
                  <tr>
                    <td className="pl-0">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {i++}
                      </span>
                    </td>
                    <td>
                      <div className="symbol symbol-50 symbol-lightv mt-1">
                        <IconButton
                          onClick={() => {
                            setSelectedAvatar(detail?.avatar);
                            setShowImageModal(true);
                          }}
                          aria-label="delete"
                        >
                          <AccountCircleIcon fontSize="large" />
                        </IconButton>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {detail.name}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {detail.email}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {detail.phone}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {detail.referral?.code?.code}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {detail.referral?.referralPercentage}
                      </span>
                    </td>
                    <td className="pr-0 text-center">
                      <Link
                        to={{
                          pathname: "/Update-Educator",
                          state: detail,
                        }}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-warning">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          ></SVG>
                        </span>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="row align-items-center">
              <div className="col-md-8 col-sm-8">
                <div className="d-flex flex-wrp py-2 mr-3">
                  <TablePagination
                    component="div"
                    count={Count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={row}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
              <br></br> <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
          {/* end::Table */}
        </div>
      </div>
    </div>
  );
}
export default connect(null, auth.actions)(Educator);
