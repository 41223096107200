import React from 'react';
import Button from '@mui/material/Button';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from "notistack"
import axios from "../../../axios";
import api from "../../../api";
import moment from "moment"

export default function UserDataExport({role, clas, goal, version, institution, gender, year, group, startTime, endTime }) {
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const [loading, setLoading] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const data = {
        headers: {
            "x-auth-token": token,
        },
    };
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "UserInfo"

    const fetchData = async () => {
        setLoading(true);
        const response = await axios.get(api.filterUserAndExport + `class=${clas}&studyVersion=${version}&goal=${goal}&batchYear=${year}&studyGroup=${group}&gender=${gender}&institution=${institution}&startTime=${moment(startTime).startOf('day').utc()}&endTime=${moment(endTime).endOf('day').utc()}&role=${role}`, data)
        return response.data.result;
    }

    const exportToCSV = async () => {

        //if (!selectedSubscription) return enqueueSnackbar("Subscription id is not allowed to be empty", { variant: 'error' });
        var importData = await fetchData();
        var excelData = [];
        for (var index in importData) {
            let obj = {
                Name: importData[index].name,
                Email: importData[index].email,
                Phone: importData[index].phone
            }
            if(role){
                obj.Role = role
            }
            if (clas) {
                obj.Class = clas
            }
            if (goal) {
                obj.Goal = goal
            }
            if (version) {
                obj.StudyVersion = version
            }
            if (institution) {
                obj.Institution = institution
            }
            if (gender) {
                obj.Gender = gender
            }
            if (year) {
                obj.BatchYear = year
            }
            if (group) {
                obj.StudyGroup = group
            }
            if(startTime && endTime){
                obj.Registraction_From_Date = moment(startTime).format("YYYY-MM-DD")
                obj.Registraction_To_Date = moment(endTime).format("YYYY-MM-DD")
            }
            excelData.push(obj);
        }

        setLoading(false);
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return <LoadingButton
        style={{ marginLeft: '10px' }}
        color="secondary"
        onClick={() => exportToCSV()}
        loading={loading}
        loadingPosition="start"
        variant="contained"
    >
        EXPORT TO EXCEL
    </LoadingButton>
}
