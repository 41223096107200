import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import moment from "moment";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import axios from "../../../../axios";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const ListContainer = styled('div')(() => ({
  maxHeight: "750px",
  overflow: 'auto',
}));

function RoomDetails(props) {
  const [loading, setLoading] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const config = {
    headers: {
      "x-auth-token": localStorage.getItem("user"),
    },
  };
  const toggleDrawer = (newOpen) => () => {
    props.handleToggleDetails(newOpen);
  };

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleJoinMeeting = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/admin/liveClass-room/join-test-meeting/${props?.roomId}`,
        config
      );
      window.location.replace(`${response.data.data}`);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(error?.response?.data, { variant: "error" });
    }
  };
  const container = undefined;

  const roomDetails = (
    <List sx={{ width: "100%", bgcolor: "background.paper", p: 1 }}>
      <Button onClick={handleJoinMeeting} sx={{ m: 1 }} variant="contained">
        Test Join
      </Button>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar>
            <LoyaltyIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${props?.name}`}
          secondary={
            <React.Fragment>
              <Typography sx={{ mr: 1, mt: 1 }}>
                Expiration date :{" "}
                {moment(props?.expiration).format("DD/MM/YYYY hh:mm A")}
              </Typography>
              {props?.isActive === true ? (
                <Chip
                  sx={{ mr: 1, mt: 1 }}
                  label={`Active : ${props?.isActive}`}
                  color="success"
                />
              ) : (
                <Chip
                  sx={{ mr: 1, mt: 1 }}
                  label={`Active : ${props?.isActive}`}
                  color="warning"
                />
              )}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
        Hosts
      </Typography>
      {props?.hosts?.map((item, index) => (
        <ListItem sx={{ mt: 1 }} key={index} alignItems="flex-start">
          <ListItemAvatar>
            <Avatar
              alt="Teacher Name"
              src={`https://cdn.rootsedulive.com/${item?.avatar}`}
            />
          </ListItemAvatar>
          <ListItemText
            primary={`${item?.name}`}
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline", mt: 1 }}
                  component="span"
                  variant="body1"
                  color="text.primary"
                >
                  <ContactPhoneIcon />
                  &nbsp;{item?.phone}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      ))}
    </List>
  );
  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(70% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={props.showdetail}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "relative",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ p: 2, color: "text.secondary" }}>
            1 result
          </Typography>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
            mt: -2,
          }}
        >
          <ListContainer>
          {" "}
          {!loading && roomDetails}
          {loading && <Skeleton variant="rectangular" height="100%" />}
          </ListContainer>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

export default RoomDetails;
