import React from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from "notistack"
import axios from "../../../../axios";
import moment from "moment"

export default function CatalogCoursePaymentExportExcel({ startDate, endDate, selectedCourseId }) {

    const [loading, setLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar()

    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "Catalog Course Payments"


    const [token, setToken] = React.useState(localStorage.getItem("user"));

    const fetchData = async () => {
        setLoading(true);
        let url = `/admin/payments/catalogCourse-payment?courseId=${selectedCourseId}`
        if(startDate && endDate){
            url += `&fromDate=${moment(startDate).startOf('day').utc()}&toDate=${moment(endDate).endOf('day').utc()}`
        }
        try {
            const response = await axios.get(url, {
                headers: {
                    "x-auth-token": token,
                }
            })
            return response.data;
        } catch (error) {
        }
    }

    const exportToCSV = async () => {

        if (!selectedCourseId) return enqueueSnackbar("Course id is not allowed to be empty", { variant: 'error' });
        var importData = await fetchData();
        var excelData = [];
        for (var index in importData) {
            excelData.push({
                Name: importData[index].userId?.name,
                Email: importData[index].userId?.email,
                Phone: importData[index].userId?.phone,
                Coupon: importData[index].coupon,
                Status: importData[index].status,
                TransactionId: importData[index].transactionId,
                StoreAmount: importData[index].storeAmount,
                PaymentAmount: importData[index].paymentAmount,
                PaymentDate: moment(importData[index].paymentDate).local().format('YYYY-MM-DD hh:mm A'),
                CreatedAt: moment(importData[index].createdAt).local().format('YYYY-MM-DD hh:mm A')
            });
        }

        setLoading(false);
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return <LoadingButton
        style={{width: '100%'}}
        color="secondary"
        onClick={() => exportToCSV()}
        loading={loading}
        loadingPosition="start"
        variant="contained"
    >
        EXPORT TO EXCEL
    </LoadingButton>
}
