import React from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from "notistack"
import axios from "../../../axios";

export default function DoubSolverExportSubjectWise({ courseId, subjectId }) {
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const [loading, setLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar()
    const data = {
        headers: {
            "x-auth-token": token,
        },
    };
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let fileName = "Doubt Solver- "

    const fetchData = async () => {
        setLoading(true);
        let url = `admin/doubts/filter-doubtSolver-course-subject?courseId=${courseId}&subjectId=${subjectId}`
        try {
            const response = await axios.get(url, data)
            return response.data;
        } catch (error) {
            setLoading(false)
            return enqueueSnackbar(" " + error.response.data, { variant: 'warning' })
        }
    }

    const exportToCSV = async () => {
        if (!courseId) return enqueueSnackbar("Course is not allowed to be empty", { variant: 'warning' })
        if (!subjectId) return enqueueSnackbar("Subject is not allowed to be empty", { variant: 'warning' })

        var importData = await fetchData();
        if (importData.length <= 0) {
            setLoading(false)
            return enqueueSnackbar("No records found ", { variant: 'warning' })
        }
        var excelData = [];
        for (var index in importData) {
            let obj = {}
            if (importData[index]?.name) {
                obj.Doubt_Solver = importData[index]?.name
            }
            if (importData[index]?.phone) {
                obj.Phone_number = importData[index]?.phone
            }
            excelData.push(obj);
        }
        fileName += `${importData[0]?.course?.title}-${importData[0]?.subject?.title}`
        setLoading(false);
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return <LoadingButton
        style={{ maxWidth: 200 }}
        color="secondary"
        onClick={() => exportToCSV()}
        loading={loading}
        loadingPosition="start"
        variant="contained"
    >
        Export to Excel
    </LoadingButton>
}
