import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Skeleton from "@mui/material/Skeleton";
import { useSnackbar } from "notistack";
import axios from "../axios";

export default function Roomlist({
  serverType,
  selectedRoom,
  handleSelectRoom,
}) {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  let config = { headers: { "x-auth-token": localStorage.getItem("user") } };

  useEffect(() => {
    // 👇️ set isMounted to true
    let isMounted = true;

    async function fetchData() {
      const result = await fetcheRooms();
      // 👇️ only update state if component is mounted
      if (isMounted) {
        setRooms(result);
        setLoading(false);
      }
    }
    fetchData();
    return () => {
      // 👇️ when component unmounts, set isMounted to false
      isMounted = false;
    };
  }, [serverType]);

  const fetcheRooms = async () => {
    try {
      if (serverType === "teachStack-studio") {
        const response = await axios.get(
          "/admin/liveClass-room/list?type=teachStack-studio",
          config
        );
        return response.data;
      } else if (serverType === "teachStack-remote") {
        const response = await axios.get(
          "/admin/liveClass-room/list?type=teachStack-remote",
          config
        );
        return response.data;
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data, { variant: "error" });
    }
  };
  
  return (
    <FormControl fullWidth>
      {loading ? (
        <Skeleton variant="rectangular" width={"100%"} height={70} />
      ) : (
        <>
          {rooms.length && (
            <>
              <InputLabel id="demo-simple-select-label">Room Id</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Room Id"
                value={selectedRoom ? selectedRoom : ""}
                onChange={(e) => handleSelectRoom(e.target.value)}
              >
                {rooms.map((item, index) =>
                <MenuItem key={index} value={item?._id}>{item?.name}</MenuItem>)}
              </Select>
            </>
          )}
        </>
      )}
    </FormControl>
  );
}

