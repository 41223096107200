import React, { useState } from "react"
import "../../Css/root.css"
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Paper from "@mui/material/Paper"
import AddIcon from '@mui/icons-material/Add'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CourseFilter from "../../Components/Filters/CourseFilter"
import CatalogCourseFilter from "../../Components/Filters/CatalogCourseFilter"
import PaymentPlanExplorerModeMenu from "./PaymentPlanExplorerModeMenu"
import SubscriptionFilter from "../../Components/Filters/SubscriptionFilter"
import PaymentPlanTableList from "./component/PaymentPlanTableList"
import Create from "./Create"
import { useSnackbar } from "notistack"
import { Typography } from "@mui/material"
import SubscriptionPaymentPlanList from "./component/SubscriptionPaymentPlanList"
import CoursePaymentPlanList from "./component/CoursePaymentPlanList"

const ExplorerMode = {
    SubscriptionPaymentPlan: "SubscriptionPaymentPlan",
    CoursePaymentPlan: "CoursePaymentPlan",
    PaymentPlanList: "PaymentPlanList"
}

const ExplorerModeMenu = {
    SubscriptionPaymentPlan: "Subscription Payment Plan",
    CoursePaymentPlan: "Course Payment Plan",
    PaymentPlanList: "PaymentPlanList"

}

const FilterTitleByMode = {
    SubscriptionPaymentPlan: "Select Subscription",
    CoursePaymentPlan: "Select Course",
    PaymentPlanList: "Select PaymentPlanList"
}

export default function PaymentPlanExplorer() {
    const [explorerMode, setExplorerMode] = useState(ExplorerMode.SubscriptionPaymentPlan)
    const [selectedGoal, setSelectedGoal] = useState('')
    const [selectedSubscription, setSelectedSubscription] = useState('')
    const [selectedCourse, setSelectedCourse] = useState('')
    const [createPaymentPlan, setCreatePaymentPlan] = useState(false)
    const [createCoursePaymentPlan, setCreateCoursePaymentPlan] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    const handleModeChange = (mode) => {
        setSelectedGoal('')
        setSelectedSubscription('')
        setSelectedCourse('')
        setExplorerMode(ExplorerMode[mode])
    }

    const subscriptionSelector = (
        <SubscriptionFilter
            selectedSubscription={selectedSubscription}
            handleSubscriptionSelection={(subscriptionId) => {
                setSelectedCourse('')
                setSelectedSubscription(subscriptionId)
                setCreatePaymentPlan(false)
            }}
        />
    )

    const catalogCourseSelector = (
        <CatalogCourseFilter
            selectedCourse={selectedCourse}
            handleCourseSelection={(courseId) => {
                setSelectedCourse(courseId)
                setCreatePaymentPlan(false)
            }}
        />
    )


    const filterBox = (
        <Card>
            <CardHeader
                title={FilterTitleByMode[explorerMode]}
                action={
                    <PaymentPlanExplorerModeMenu
                        currentMode={explorerMode}
                        explorerModes={ExplorerModeMenu}
                        handleModeSelection={handleModeChange}
                    />
                }
            />
            <CardContent>
                {explorerMode === "SubscriptionPaymentPlan" && subscriptionSelector}
                {explorerMode === "CoursePaymentPlan" && catalogCourseSelector}
            </CardContent>
        </Card>
    )

    const subsPaymentPlanCreate = (
        <Card sx={{ p: { xs: 4, md: 8 }, mt: 2, width: '100%' }}>
            <CardHeader
                title={"Create Subscription Payment Plan"}
                action={
                    <Tooltip title="Back to Payment Plan List">
                        <IconButton aria-label="add" onClick={() => setCreatePaymentPlan(false)}>
                            <ArrowBackIcon color="primary" sx={{ width: 30, height: 30 }} />
                        </IconButton>
                    </Tooltip>
                }
            />
            <CardContent>
                <Box variant="outlined" component={Paper} sx={{ width: '100%', p: 2, }}>
                    <Typography gutterBottom align="center" variant="h5">Create Subscription Payment Plan </Typography>
                    <Grid sx={{ p: 1 }} container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                        <Create handleSuccess={()=>setCreatePaymentPlan(false)}  explorerMode={explorerMode} selectedSubscription={selectedSubscription} />
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    )

    const coursePaymentPlanCreate = (
        <Card sx={{ p: { xs: 4, md: 8 }, mt: 2, width: '100%' }}>
            <CardContent>
                <CardHeader
                    title={"Create Course Payment Plan"}
                    action={
                        <Tooltip title="Back to Course Payment Plan List">
                            <IconButton aria-label="add" onClick={() => setCreateCoursePaymentPlan(false)}>
                                <ArrowBackIcon color="primary" sx={{ width: 30, height: 30 }} />
                            </IconButton>
                        </Tooltip>
                    }
                />
                <Box variant="outlined" component={Paper} sx={{ width: '100%', p: 2, }}>
                    <Typography gutterBottom align="center" variant="h5">Create Course Payment Plan </Typography>
                    <Grid sx={{ p: 1 }} container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                        <Create handleSuccess={()=>setCreateCoursePaymentPlan(false)} explorerMode={explorerMode} selectedCourse={selectedCourse} />
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    )

    const subsPaymentPlanList = (
        <Card sx={{ p: { xs: 2, md: 4 }, m: 2, width: '100%' }}>
            <CardHeader
                title={"Subscription Payment Plan"}
                action={
                    <Tooltip title="Add New Subscription Payment Plan">
                        <IconButton aria-label="add" onClick={() => setCreatePaymentPlan(true)}>
                            <AddIcon color="primary" sx={{ width: 30, height: 30 }} />
                        </IconButton>
                    </Tooltip>
                }
            />
            <CardContent>
                <SubscriptionPaymentPlanList subscriptionId={selectedSubscription} />
            </CardContent>
        </Card>
    )

    const coursesPaymentPlanList = (
        <Card sx={{ p: { xs: 2, md: 4 }, m: 2, width: '100%' }}>
            <CardHeader
                title={"Course Payment Plan"}
                action={
                    <Tooltip title="Add New Course Payment Plan">
                        <IconButton aria-label="add" onClick={() => setCreateCoursePaymentPlan(true)}>
                            <AddIcon color="primary" sx={{ width: 30, height: 30 }} />
                        </IconButton>
                    </Tooltip>
                }
            />
            <CardContent>
                <CoursePaymentPlanList courseId={selectedCourse} />
            </CardContent>
        </Card>
    )

    const paymentPlanList = (
        <Card sx={{ p: { xs: 2, md: 4 }, m: 2, width: '100%' }}>
            <CardContent>
                <PaymentPlanTableList />
            </CardContent>
        </Card>
    )


    return (
        <React.Fragment>
            {filterBox}
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>

                {/* Subscription Payment Plan Ui */}
                {explorerMode === "SubscriptionPaymentPlan" && selectedSubscription && !createPaymentPlan && subsPaymentPlanList}
                {explorerMode === "SubscriptionPaymentPlan" && selectedSubscription && createPaymentPlan && subsPaymentPlanCreate}

                {/* Subscription Payment Plan Ui */}
                {explorerMode === "CoursePaymentPlan" && selectedCourse && !createCoursePaymentPlan && coursesPaymentPlanList}
                {explorerMode === "CoursePaymentPlan" && selectedCourse && createCoursePaymentPlan && coursePaymentPlanCreate}

                {/* Course Payment Plan Ui */}
                {/* {explorerMode === "CoursePaymentPlan" && selectedCourse && coursePaymentPlanCreate} */}

                {/* Payment Plan list */}
                {explorerMode === "PaymentPlanList" && paymentPlanList}
            </Grid>
        </React.Fragment>
    )
}