import React from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from "notistack"
import axios from "../../axios";

export default function McqExamResultExport({ examId, handleCloseMenu }) {
    const token = localStorage.getItem("user");
    const [loading, setLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar()
    const data = {
        headers: {
            "x-auth-token": token,
        },
    };
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
   
    const fetchData = async () => {
        setLoading(true);
        let url = `admin/mcq-exams/view-rank?examId=${examId}`
        try {
            const response = await axios.get(url, data)
            return response.data;
        } catch (error) {
            setLoading(false)
            return enqueueSnackbar(" " + error.response.data, { variant: 'warning' })
        }
    }

    const exportToCSV = async () => {
        if (!examId) return enqueueSnackbar("Invalid Exam.", { variant: 'warning' })

        var importData = await fetchData();
    
        if (importData.length <= 0) {
            setLoading(false)
            return enqueueSnackbar("No records found.", { variant: 'warning' })
        }     
        var excelData = [];
        let rank = 1;
        for (var index in importData) {
            let obj = {}
            obj.Rank = rank;
            if (importData[index]?.user?.name) {
                obj.Name = importData[index]?.user?.name
            }
            if (importData[index]?.user?.phone) {
                obj.Phone = importData[index]?.user?.phone
            }
            if (importData[index]?.user?.email) {
                obj.Email = importData[index]?.user?.email
            }
            if (importData[index]?.user?.institution) {
                obj.Institution = importData[index]?.user?.institution
            }
            if (importData[index]?.user?.fatherPhone) {
                obj.fathersPhone = importData[index]?.user?.fatherPhone
            }
            if (importData[index]?.user?.motherPhone) {
                obj.MothersPhone = importData[index]?.user?.motherPhone
            }
            if (importData[index]?.obtainedMarks) {
                obj.ObtainedMarks = importData[index]?.obtainedMarks
            }
            excelData.push(obj);
            rank++;
        }
        const fileName = `McqExam Results`
        setLoading(false);
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        handleCloseMenu();
    };
    return <LoadingButton
        style={{ maxWidth: 200, textTransform: 'none' }}
        color="secondary"
        onClick={() => exportToCSV()}
        loading={loading}
        loadingPosition="start"
        variant="contained"
    >
        Result Export
    </LoadingButton>
}
