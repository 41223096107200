import React from "react";
import "../../Css/root.css";
import { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "../../axios";
import api from "../../api";
import Form from "react-bootstrap/Form";
import {actions} from "../../Components/Toaster/ToastReducer";
import {useDispatch } from "react-redux";
export default function CreateSms() {
    const dispatch = useDispatch();
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const [tohome, setTohome] = React.useState(false);

    const [selectedSubsCriptionID, setSelectedSubsCriptionID] = useState();
    const [maskID, setMaskID]=useState();
    const [message, setMessage]=useState();
    const [isActive, setisActive]=useState(false);
    const [subscriptionType, setSubscriptionType]=useState("All");
    const [subscriptionsWithoutGoal, setSubscriptionWithoutGoal] = useState([]);

    const [goals, setGoals] = useState();
    const [subscription, setSubsCription] = useState();
  

    let data = {
        headers: {
            "x-auth-token": token,
        },
    };

    useEffect(() => {
        goalsFetch();
        subscriptionWithoutGoal()
    }, []);

    async function subscriptionWithoutGoal (){
        try{
           const response = await axios.get(api.getSubscriptionWithoutGoal, data);
           setSubscriptionWithoutGoal(response.data.result)

        }catch(error){

        }
    }
    async function goalsFetch() {
        try {
            settoken(localStorage.getItem("user"));
            const response = await axios.get(api.smsGetGoals, data);
            setGoals(response.data);
        } catch (err) {
        }
    }
    async function getSubs(selected) {
        const response = await axios.get(api.smsGetSubscriptions + `?goalId=${selected}`, data);
        setSubsCription(response.data.subscriptions);
    }
    const handleSubmit =()=>{
        const url = `${api.SmsSend}subscriptionId=${selectedSubsCriptionID}&sId=${maskID}&parentSms=${isActive ? 1 : 0}&subscriptionType=${subscriptionType}`
        const body={
            smsBody : message
        }
        if (selectedSubsCriptionID && maskID && message) {
            axios
                .post(url, body, data)
                .then(async (resp) => {
                    const {
                        status
                    } = resp;
                    if (status === 200 ) {
                        dispatch(actions.Success("Sms send successfully"));
                        setTohome(true);
                    }
                })
                .catch((error) => {
                    dispatch(actions.Danger("Something Went Wrong"));
                });
        }
    }
    if (tohome === true) {
        return <Redirect to="/sms" />;
    }
    return (
        <div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-header">
                                    <h3 className="card-title"> Create SMS</h3>
                                    <div className="card-toolbar">
                                        <div className="example-tools justify-content-center">
                                            <Link
                                                className="btn btn-success font-weight-bolder font-size-sm"
                                                to="/subjects"
                                            >
                                                <i className="flaticon2-list"></i>
                                                Create SMS
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <form className="form">
                                    <input type="hidden" />
                                    <div className="card-body">



                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Goals
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => {
                                                        getSubs(e.target.value);
                                                    }}
                                                >
                                                    <option value="" defaultChecked>
                                                        Select goals
                                                    </option>
                                                    {goals ?
                                                        goals.map(object =>
                                                            <option value={object._id}>
                                                                {object.title}
                                                            </option>
                                                        ) : null
                                                    }
                                                </Form.Control>
                                            </div>
                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Subscription
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => {
                                                      setSelectedSubsCriptionID(e.target.value)
                                                    }}
                                                >
                                                    <option value="" defaultChecked>
                                                        Select Subscription
                                                    </option>
                                                    {subscription ?
                                                        subscription.map(object =>
                                                            <option value={object._id}>
                                                                {object.title}
                                                            </option>
                                                        ) : null
                                                    }
                                                </Form.Control>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                           <label className="col-lg-3 col-form-label text-left">
                                                Select Subscription Without Goal
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => {
                                                      setSelectedSubsCriptionID(e.target.value)
                                                    }}
                                                >
                                                    <option value="" defaultChecked>
                                                        Select Subscription
                                                    </option>
                                                    {subscriptionsWithoutGoal ?
                                                        subscriptionsWithoutGoal.map(object =>
                                                            <option value={object._id}>
                                                                {object.title}
                                                            </option>
                                                        ) : null
                                                    }
                                                </Form.Control>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Masking
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => {
                                                        setMaskID(e.target.value)
                                                    }}
                                                >
                                                    <option value="" defaultChecked>
                                                        Masking Type
                                                    </option>
                                                    <option value="sIdNonMasking">
                                                       sIdNonMasking
                                                    </option>
                                                    <option value="sIdMasking">
                                                      sIdMasking
                                                    </option>

                                                </Form.Control>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Subscription Type
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => {
                                                        setSubscriptionType(e.target.value)
                                                    }}
                                                >
                                                    <option value="ALL" defaultChecked>
                                                        ALL
                                                    </option>
                                                    <option value="Active">
                                                       Active Subscription
                                                    </option>
                                                    <option value="InActive">
                                                      InActive Subscription
                                                    </option>

                                                </Form.Control>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Parents SMS:
                                            </label>
                                            <div className="col-lg-3">
                                                <span className="switch switch-icon switch-success ">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isActive}
                                                            onChange={(e) => setisActive(e.target.checked)}
                                                        />
                                                        <span></span>
                                                    </label>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-6">
                                                <div className="form-group green-border-focus">
                                                    <label style={{fontSize:'12px'}} for="exampleFormControlTextarea5">write message</label>
                                                    <textarea onChange={(e)=> setMessage(e.target.value)} className="form-control" id="exampleFormControlTextarea5" rows="5"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row"></div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-lg-2"></div>
                                            <div className="col-lg-10">
                                                <button
                                                    type="button"
                                                    className="btn btn-success mr-2"
                                                    onClick={handleSubmit}
                                                >
                                                    Submit
                                                </button>
                                                <button type="reset" className="btn btn-secondary">
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
