import React, { useState, useEffect } from 'react'
import moment from 'moment'

/* @MUI COMPONENT */
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack'
import {DesktopDatePicker} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Button from "@mui/material/Button"
import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

/* @CUSTOM COMPONENT */
import PaymentChart from "./PaymentChart"
import axios from "../../axios";

const MemorizePaymentChart = React.memo(PaymentChart)

function addDays(originalDate, days) {
    var cloneDate = new Date(originalDate.valueOf());
    cloneDate.setDate(cloneDate.getDate() + days);
    return cloneDate;
};

export default function UserPayment() {

    //var initialDate = new Date();
    // initialDate.setDate(initialDate.getDate() - 1);

    const [selectedSubscription, setSelectedSubscription] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [labels, setLabels] = useState([])
    const [data, setData] = useState([])
    const [totalAmount, setTotalAmount] = useState("")
    const [totalStoreAmount, setTotalStoreAmount] = useState("")
    const [isSeacrh, setisSearch] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [isShowCount, setShowCount] = useState(false)

    let config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        },
    };

    const processData = (data) => {
        var tempLabel= []
        var tempData= []
        var totalAmount = 0 , total_StoreAmount= 0, total_count = 0
        data.map(item => {
            if(item.subscriptionArray[0] && item.subscriptionArray[0].title){
                tempLabel.push(item.subscriptionArray[0].title)
            }else if(item?.courseArray[0] && item?.courseArray[0].title){
                tempLabel.push(item.courseArray[0].title)
            }
            else{
                tempLabel.push(`invalid :${item.subscriptionId}`)
            }
            if(isShowCount){
                tempData.push(item.count)
            }else{
                tempData.push(item.totalAmount)
            }
            totalAmount += item.totalAmount
            total_StoreAmount += item.storeAmount
            total_count += item.count
        })
        setTotalCount(total_count)
        setLabels(tempLabel)
        setData(tempData)
        setTotalAmount(totalAmount)
        setTotalStoreAmount(total_StoreAmount)
    }

    useEffect(() => {
        fetchPaymentData()
    }, [])

    const handleSearchPayment = () => {
        fetchPaymentData()
        setisSearch(true)
    }

    const fetchPaymentData = async () => {
        try {
            let url = `/admin/payments/payment-graph?countSort=${isShowCount}`
            if (startDate && endDate) {
                url = `/admin/payments/payment-graph?fromDate=${moment(startDate).startOf('day').utc()}&toDate=${moment(endDate).endOf('day').utc()}&countSort=${isShowCount}`
            }
            const response = await axios.get(url, config);
            if (response.data.length > 0) {
                setTotalCount(response.data.length)
                processData(response.data)
            } else {
                setData([])
                setTotalAmount(0)
                setTotalCount(0)
                setTotalStoreAmount(0)
            }
        } catch (error) {
        }
    }
    const handleChangeStartDate = (newValue) => {
        setStartDate(newValue);
    };

    const handleChangeEndDate = (newValue) => {
        setEndDate(newValue);
    };

    const filterPayment = (
        <Card>
            <CardContent>
                <Grid sx={{ mt: 2 }} container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack sx={{ width: 450 }} spacing={3}>
                                <DesktopDatePicker
                                    label="START DATE"
                                    inputFormat="MM/dd/yyyy"
                                    value={startDate}
                                    onChange={handleChangeStartDate}
                                    renderInput={(params) => <TextField {...params} />}
                                />

                                <DesktopDatePicker
                                    label="END DATE"
                                    inputFormat="MM/dd/yyyy"
                                    value={endDate}
                                    onChange={handleChangeEndDate}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>

                        <FormControlLabel sx={{m :1}} control={<Switch onChange={()=>setShowCount(!isShowCount)} checked={isShowCount} />} label="Count vs Subscription/Course Graph" />

                        <Button onClick={handleSearchPayment} sx={{ mt: 1 , ml :2}} variant="contained">Search</Button>

                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )

    return (
        <>
            <Card sx={{ mt: 6, width: '100%' }}>
                <CardHeader title="Payment" />
                <CardContent>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                        <Grid item>
                            {filterPayment}
                        </Grid>

                        <Grid item md={12}>
                            <Card>{totalAmount && <Grid>Total Amount : {totalAmount}</Grid>}
                                {totalStoreAmount && <Grid>Total StoreAmount : {totalStoreAmount.toFixed(2)}</Grid>}
                                {totalCount && <Grid>Count : {totalCount}</Grid>}
                                {startDate && endDate && isSeacrh && <Grid>From : {moment(startDate).format('YYYY-MM-DD')}</Grid>}
                                {startDate && endDate && isSeacrh && <Grid>To : {moment(endDate).format('YYYY-MM-DD')}</Grid>}
                                {selectedSubscription && isSeacrh && <Grid>Subscription : {selectedSubscription}</Grid>}</Card>
                        </Grid>

                        <Grid md={12} item>
                            <MemorizePaymentChart timeLabel={labels} paymentsData={data} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

