import React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Skeleton from "@mui/material/Skeleton";
const Item = styled('div')(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
export default function BrandPartnerSkeleton() {
  return (
    <Box sx={{ maxWidth: "500px" }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Item>
            <Skeleton variant="rectangular" width={"100%"} height={40} />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <Skeleton variant="rectangular" width={150} height={40} />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <Skeleton variant="rectangular" width={150} height={40} />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <Skeleton variant="rectangular" width={80} height={30} />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <Skeleton variant="rectangular" width={80} height={30} />
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Skeleton width={100} variant="text" sx={{ fontSize: "1rem" }} />
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Skeleton variant="rectangular" width={80} height={30} />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
