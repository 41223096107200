import React, { useEffect, useState } from "react"
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
// import AddIcon from '@mui/icons-material/Add'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
// import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Tooltip from '@mui/material/Tooltip'
import CardHeader from '@mui/material/CardHeader'
import SimpleDeleteModal from "../../../Components/SimpleDeleteModal"
import Skeleton from '@mui/material/Skeleton'
import { useSnackbar } from "notistack"

const TAG = "CourseList"

export default function SearchCourseList(props) {

    const [courses, setCourses] = useState([])
    // const [openCourseDialog, setOpenCourseDialog] = useState(false)
    // const [dialogCourseId, setDialogCourseId] = useState('')

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deleteModalCourseId, setDeleteModalCourseId] = useState('')

    const [courseLoading, setCourseLoading] = useState(false)
    const [refreshKey, setRefreshKey] = useState(0)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    const refresh = () => {
        props.handleCourseSelection('')
        setRefreshKey(prevKey => prevKey + 1)
    }

    async function getCoursesBytitle(title) {
        setCourseLoading(true)
        axios
            .get(api.getCourseExplorerCoursesByTitle + title, config)
            .then((response) => {
                setCourses(response.data)
                setCourseLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Course fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function deleteCourse(courseId) {
        axios
            .delete(api.deleteCourse + courseId, config)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
                refresh()
            })
            .catch((error) => {
                enqueueSnackbar("Course delete failed: " + error.response.data, { variant: 'error' })
            })
    }

    function handleDetailsPressed(event) {
        props.handleCourseSelection(event.currentTarget.value)
    }

    // function handleAddOrEditPressed(event) {
    //     setDialogCourseId(event.currentTarget.value)
    //     setOpenCourseDialog(true)
    // }

    function handleDelete(event) {
        setDeleteModalCourseId(event.currentTarget.value)
        setOpenDeleteModal(true)
    }

    useEffect(() => {
        getCoursesBytitle(props.title)
    }, [props.title, refreshKey])

    const courseItem = (data) => {
        return (
            <Grid item key={data._id}>
                <Card sx={{ width: 240, height: 240 }}>
                    <CardMedia
                        component="img"
                        height="135"
                        image={"https://cdn.rootsedulive.com/" + data.banner}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="body2" component="div">
                            {data.title}
                        </Typography>
                    </CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                        <Tooltip title="Details">
                            <IconButton aria-label="details" value={data._id} onClick={handleDetailsPressed}>
                                <ArrowForwardIcon/>
                            </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="Edit">
                            <IconButton aria-label="edit" value={data._id} onClick={handleAddOrEditPressed}>
                                <EditIcon/>
                            </IconButton>
                        </Tooltip> */}
                        <Tooltip title="Delete">
                            <IconButton aria-label="delete" value={data._id} onClick={handleDelete}>
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Card>
            </Grid>
        )
    }

    const courseItemSkeleton = [1, 2, 3].map((value) =>
        <Grid item key={value}>
            <Card sx={{ width: 240, height: 240 }}>
                <CardMedia component="div">
                    <Skeleton variant="rectangular" width={240} height={135} />
                </CardMedia>
                <CardContent>
                    <Skeleton variant="text" width={200} />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Skeleton variant="text" width={100} />
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    )

    return (
        <Card>
            <CardHeader
                title="Inactive Courses"
            />
            <CardContent>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                    {courseLoading ? courseItemSkeleton : courses.map(courseItem)}
                </Grid>
            </CardContent>

            <SimpleDeleteModal
                title="Delete Course"
                open={openDeleteModal}
                handleClose={() => {
                    setDeleteModalCourseId('')
                    setOpenDeleteModal(false)
                }}
                handleDelete={() => deleteCourse(deleteModalCourseId)}
            />
        </Card>
    )
}