import React, { useEffect, useState } from 'react'
import MathJax from 'react-mathjax-preview'
import { useSnackbar } from "notistack"
import Divider from '@mui/material/Divider';
import axios from '../../axios';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import QuestionsLoading from './QuestionsLoading';

export default function QuestionPreview({ examId, handleLoading }) {
    const [loading, setLoading] = useState(false)
    const [examData, setExamData] = useState({})
    const { enqueueSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user")
        }
    }

    const fetchExamQuestions = async (examId) => {
        try {
            setLoading(true)
            const response = await axios.get(`admin/exam-explorer/exam-details-questions/${examId}`, config)
            setExamData(response?.data)
            handleLoading(false)
            setLoading(false)
        } catch (error) {
            enqueueSnackbar("Exam fetch failed: " + error?.response?.data, { variant: 'error' })
            handleLoading(false)
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchExamQuestions(examId)
    }, [examId])

    return (
        <div>
            {loading ? <QuestionsLoading /> :
                <Stack sx={{ p: 2 }} spacing={3}>
                    <Typography textAlign={'center'} fontWeight={650} variant='h5'>
                        {examData?.title}
                    </Typography>
                    <Divider />
                    <Box sx={{ mt: 2 }}>
                        {examData?.questions?.map(item =>
                            <div style={{ marginTop: '20px' }} key={item?._id}>
                                <Typography sx={{ textAlign: 'left' }} variant='h6'>
                                    {item?.serialNo}:&nbsp;<MathJax
                                        style={{ display: 'inline-block' }}
                                        sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }}
                                        math={`${item?.question}`}
                                    />
                                </Typography>
                                {item?.optionA &&
                                    <Typography sx={{ textAlign: 'left' }} variant='body1'>
                                        A:&nbsp;<MathJax
                                            style={{ display: 'inline-block' }}
                                            sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }}
                                            math={`${item?.optionA}`}
                                        />
                                    </Typography>
                                }
                                {item?.optionB &&
                                    <Typography sx={{ textAlign: 'left' }} variant='body1'>
                                        B:&nbsp;<MathJax
                                            style={{ display: 'inline-block' }}
                                            sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }}
                                            math={`${item?.optionB}`}
                                        />
                                    </Typography>
                                }
                                {item?.optionC &&
                                    <Typography sx={{ textAlign: 'left' }} variant='body1'>
                                        C:&nbsp;<MathJax
                                            style={{ display: 'inline-block' }}
                                            sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }}
                                            math={`${item?.optionC}`}
                                        />
                                    </Typography>
                                }
                                {item?.optionD &&
                                    <Typography sx={{ textAlign: 'left' }} variant='body1'>
                                        D:&nbsp;<MathJax
                                            style={{ display: 'inline-block' }}
                                            sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }}
                                            math={`${item?.optionD}`}
                                        />
                                    </Typography>
                                }
                                {item?.optionE &&
                                    <Typography sx={{ textAlign: 'left' }} variant='body1'>
                                        E:&nbsp;<MathJax
                                            style={{ display: 'inline-block' }}
                                            sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }}
                                            math={`${item?.optionE}`}
                                        />
                                    </Typography>
                                }
                                {item?.correctAns &&
                                    <Typography sx={{ textAlign: 'left' }} variant='body1'>
                                        Correct Answer:&nbsp;<MathJax
                                            style={{ display: 'inline-block' }}
                                            sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }}
                                            math={`${item?.correctAns}`}
                                        />
                                    </Typography>
                                }
                                {item?.solution &&
                                    <Typography sx={{ textAlign: 'left' }} variant='body1'>
                                        Solution:&nbsp;<MathJax
                                            style={{ display: 'inline-block' }}
                                            sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }}
                                            math={`${item?.solution}`}
                                        />
                                    </Typography>
                                }
                            </div>
                        )}
                    </Box>
                </Stack>
            }
        </div>
    )
}