import React, { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import BasicSelect from "./components/BasicSelect";
import AlertForm from "./components/AlertForm";
import NoticeForm from "./components/NoticeForm";
import UserSelectModal from "./components/UserSelectModal";

const notificationMenu = ["Alert", "Notice"];
export default function Notification() {
  const [mode, setMode] = useState("");
  const [alertData, setAlertData] = useState({ title: "", description: "" });
  const [noticeData, setNoticeData] = useState({
    title: "",
    shortDescription: "",
    description: "",
    expiryDate: new Date(),
    isActive: true
  });

  const [showModal, setShowModal] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: 1,
        },
      }}
    >
      <Paper sx={{ width: "100%", p: 4 }} elevation={3}>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <BasicSelect
            value={mode}
            title={"Select Mode"}
            data={notificationMenu}
            handleChangeMode={(state) => setMode(state)}
          />
          {mode == "Alert" && (
            <AlertForm
              handleSubmit={(props) => setShowModal(props)}
              handleChangeTitle={(state) =>
                setAlertData({ ...alertData, title: state })
              }
              handleChangeDescription={(state) =>
                setAlertData({ ...alertData, description: state })
              }
            />
          )}
          {mode === "Notice" && (
            <NoticeForm
              handleChangeTitle={(state) =>
                setNoticeData({ ...noticeData, title: state })
              }
              handleChangeShortDescription={(state) =>
                setNoticeData({ ...noticeData, shortDescription: state })
              }
              handleChangeDescription={(state) =>
                setNoticeData({ ...noticeData, description: state })
              }
              handleChangeIsActive={(state)=>
                setNoticeData({...noticeData, isActive:state})
              }
              handleChangeExpiryDate={(state)=>
                setNoticeData({...noticeData, expiryDate:state})
              }
              handleSubmit={(props) => setShowModal(props)}
            />
          )}
          {showModal && (
            <UserSelectModal
              open={showModal}
              mode={mode}
              alertData={alertData}
              noticeData={noticeData}
              handleCloseModal={(state) => setShowModal(state)}
            />
          )}
        </Stack>
      </Paper>
    </Box>
  );
}
