import React from "react";
import "../../Css/root.css";
import { useState, useEffect, useRef } from "react";
import { MultiSelect } from "react-multi-select-component";
import axios from "../../axios";
import api from "../../api";
import { Link, Redirect } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import {actions} from "../../Components/Toaster/ToastReducer";
import {useDispatch } from "react-redux";
import AddUpdateCatalogCourses from "./components/AddUpdateCatalogCourses";
import { useSnackbar } from "notistack";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function UpdateEducator(state) {
	const {enqueueSnackbar} = useSnackbar()
    const dispatch = useDispatch();
	const [Percentege, setPercentege] = useState(state.history.location.state.referral ? state.history.location.state.referral.referralPercentage : "")
	const [token, settoken] = React.useState(localStorage.getItem("user"));
	const optionsC = []
	const [Datas, setDatas] = useState([]);
	const [Code, setCode] = useState(state?.history?.location?.state?.referral?.code?._id ?? '')
	
	const [designation, setdesignation] = useState(state.history.location.state.educator ? state.history.location.state.educator.designation : '')
	const intro = useRef(null);
	let data = {
		headers: {
			"x-auth-token": token,
		},
	};
	const [tohome, setTohome] = React.useState(false);
	async function my_async_fn() {
		settoken(localStorage.getItem("user"));
		const response = await axios.get(api.getCouponBrandPartner, data);
		setDatas(response.data);
	}
	function sortData() {
		Datas.map((data) => optionsC.push({ label: data.code, value: data._id }));
	}

	function handleSubmit() {
		if(!designation) return enqueueSnackbar("Invalid designation", {variant: 'warning'});
		if(!intro.current.getContent()) return enqueueSnackbar("Invalid introduction", {variant: 'warning'});
		let referral = {
			code: null,
			referralPercentage: 0
		}

		if (Code.length) referral.code = Code
		if (Number(Percentege) > 0) referral.referralPercentage = Percentege

		axios
			.put(api.setEducator + state.history.location.state._id, {
				referral: referral,
				educator: {
					intro: intro.current.getContent(),
					designation: designation
				}
			}, data)
			.then((resp) => {
				if(resp.status == 200){
					dispatch(actions.Success("Educator updated successfully"));
					setTohome(true)
				}
			});
	}

	useEffect(() => {
		settoken(localStorage.getItem("user"));
		my_async_fn();
	}, []);
	sortData();
	if (tohome === true) {
		return <Redirect to="/educator" />;
	}

	return (
		<div>
			<div className="d-flex flex-column-fluid">
				<div className=" container ">
					<div className="row">
						<div className="col-md-12">
							<div className="card card-custom">
								<div className="card-header">
									<h3 className="card-title"> Update Educator</h3>
									<div className="card-toolbar">
										<div className="example-tools justify-content-center">
											<Link
												className="btn btn-success font-weight-bolder font-size-sm"
												to="/educator"
											>
												<i className="flaticon2-list"></i>
												All Educator
											</Link>
										</div>
									</div>
								</div>
								<form className="form">
									<input type="hidden" />
									<div className="card-body">
										<div className="form-group row">
											<div className="col-lg-4">
												<FormControl fullWidth>
													<InputLabel id="demo-simple-select-label">Coupon Code</InputLabel>
													<Select
														labelId="demo-simple-select-label"
														id="demo-simple-select"
														value={Code}
														label="Coupon Code"
														onChange={(e) => {
															const selected = e.target.value;
															setCode(selected)
														}}
													>
														<MenuItem  value={""}>No Coupon Code</MenuItem>
														{optionsC.map((details) => (
															<MenuItem key={details.value} value={details.value}>{details.label}</MenuItem>
														))}
													</Select>
												</FormControl>
											</div>
											
											<label className="col-lg-2 col-form-label text-left">
												Referral Percentege
											</label>
											<div className="col-lg-3">
												<input
													type="text"
													value={Percentege}
													className="form-control "
													placeholder="Enter Percentege"
													onChange={(e) => {
														setPercentege(e.target.value);
													}}
												/>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Designation
											</label>
											<div className="col-md-3">
												<Form.Control
													as="select"
													value={designation}
													onChange={(e) => {
														const selected = e.target.value;
														setdesignation(selected);
													}}
												>
												<option value={"Junior Educator"}>Junior Educator</option>
												<option value={"Educator"}>Educator</option>
												<option value={"Senior Educator"}>Senior Educator</option>
												<option value={"Iconic Educator"}>Iconic Educator</option>
												<option value={"Exceptional Educator"}>Exceptional Educator</option>
												</Form.Control>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Intro
											</label>
											<div className="col-lg-3">
												<Editor
													tinymceScriptSrc="https://cdn.rootsedulive.com/scripts/js/tinymce/tinymce.min.js"
													onInit={(evt, editor) => (intro.current = editor)}
													initialValue={state.history.location.state.educator ? state.history.location.state.educator.intro : ''}
													init={{
														height: 350,
														width: 300,
														menubar: false,
														plugins: [
															"advlist autolink lists link image charmap print preview anchor",
															"searchreplace visualblocks code fullscreen",
															"insertdatetime media table paste code help wordcount",
														],
														external_plugins: {
															tiny_mce_wiris:
																"https://cdn.jsdelivr.net/npm/@wiris/mathtype-tinymce4@7.24.0/plugin.min.js",
														},
														toolbar:
															"tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
														content_style:
															"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
													}}
													scriptLoading={{ async: true }}
												/>
											</div>
										</div>


										<div className="form-group row">
											<p>{Error}</p>
											<label
												n
												className="col-lg-2 col-form-label text-left"
												style={{ color: "red", fontSize: "22px" }}
											></label>
											<div className="col-lg-3" hidden>
												<span className="switch switch-icon switch-success ">
													<label>
														<input type="hidden" value="0" name="is_active" />
														<input type="checkbox" value="1" name="is_active" />

														<span></span>
													</label>
												</span>
											</div>
										</div>
										<div className="form-group row"></div>
									</div>
									<div className="card-footer">
										<div className="row">
											<div className="col-lg-2"></div>
											<div className="col-lg-10">
												<button
													type="button"
													className="btn btn-success mr-2"
													onClick={handleSubmit}
												>
													Submit
												</button>
												<button type="reset" className="btn btn-secondary">
													Reset
												</button>
											</div>
										</div>
									</div>
									<div className="form-group row">
										<AddUpdateCatalogCourses 
									    userId={state.history.location.state._id}
										personalCourses={state.history.location.state?.educator?.personalCourses} />
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
