import React, {useState } from "react"

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PolicyIcon from '@mui/icons-material/Policy';
import Grid from '@mui/material/Grid'
import Stack from "@mui/material/Stack"

import axios from "../../../../axios"
import BasicSelector from "../../../../Components/BasicSelector"

export default function SubscriptionSearchFilter(props) {
    
    // Data states
    const [subscriptions, setSubscriptions] = useState([])
    const [inputSubscriptionTitle, setInputSubscriptionTitle] = useState('')

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    // Select data props
    const subscriptionData = {
        title: "subscriptions",
        items: subscriptions,
        selected: props.selectedSubscription
    }

    // Select elements
    const subscriptionInput = (
        <Grid item>
            <Stack spacing={3}>
            <TextField
                id="input-with-icon-textfield"
                label="Subscription Title"
                onChange={(e)=>setInputSubscriptionTitle(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PolicyIcon />
                        </InputAdornment>
                    ),
                }}
                variant="standard"
            />
            {inputSubscriptionTitle && <Button onClick={getSubscriptionByTitle} variant="contained">Search</Button>}
            </Stack>
        </Grid>
    )

    const subscriptionSelect = (
        <Grid item>
            <BasicSelector data={subscriptionData} sx={{ width: 200 }} handleSelection={hanldeSubscriptionSelection} />
        </Grid>
    )

    // Data fetching and state handling functions
    async function getSubscriptionByTitle() {
        props.hanldeSubscriptionSelection('')
        axios
            .get(`admin/content-filter/get-inActive-subscriptions-by-title/${inputSubscriptionTitle}`, config)
            .then((response) => {
                setSubscriptions(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    function hanldeSubscriptionSelection(subscriptionId) {
        props.hanldeSubscriptionSelection(subscriptionId)
    }

    return (
        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={4}>
            {subscriptionInput}
            {subscriptions.length > 0 && subscriptionSelect}
        </Grid>
    )
}