import React from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from "notistack"
import axios from "../../../axios";
import api from "../../../api";
import moment from "moment"

export default function DoubtFilterSubjectWiseDataExport({startDate, endDate}) {
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const [loading, setLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar()
    const data = {
        headers: {
            "x-auth-token": token,
        },
    };
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "Doubt Filter"

    const fetchData = async () => {
        setLoading(true);
        let url = `/admin/doubts/filter-doubt-groupBy-subject`
        if(startDate && endDate){
            url += `?fromDate=${moment(startDate).startOf('day').utc()}&toDate=${moment(endDate).endOf('day').utc()}`
        }
        try{
            const response = await axios.get(url, data)
            return response.data.result;
        }catch(error){
            setLoading(false)
           enqueueSnackbar(""+error.response.data, {variant : "error"})
        }
    }

    const exportToCSV = async () => {
        var importData = await fetchData();
        if(importData == undefined) return enqueueSnackbar("No records found", {variant : "warning"})
        if(importData.length <= 0) return enqueueSnackbar("No records found", {variant : "warning"})
        
        //processs data 
        var proceessData = {}
        for(var index in importData){
            let key = `${importData[index].course[0]}-${importData[index].subject[0]}`
            if(proceessData[key]){
                if(importData[index]._id.status === "Solved"){
                   proceessData[key] = {
                    ...proceessData[key],
                    Solved : importData[index].count
                  }
                }
                if(importData[index]._id.status === "Unassigned"){
                    proceessData[key] = {
                    ...proceessData[key],
                    Unassigned : importData[index].count }
                }
                if(importData[index]._id.status === "Assigned"){
                    proceessData[key] = {
                      ...proceessData[key],
                    Assigned : importData[index].count}
                }
                 if(importData[index]._id.status === "Replied"){
                    proceessData[key] = {
                    ...proceessData[key],
                    Replied : importData[index].count }
                }
            }else{
                 var obj = { 
                   courseName : importData[index].courseArray?.title ?? "Invalid Course" ,
                   subjectName : importData[index].subjectArray?.title ?? "Invalid Subject"
                }

                if(importData[index]._id.status === "Unassigned"){
                    obj.Unassigned = importData[index].count
                }
                if(importData[index]._id.status === "Assigned"){
                    obj.Assigned = importData[index].count
                }
                
                if(importData[index]._id.status === "Solved"){
                    obj.Solved = importData[index].count
                }
            
                 if(importData[index]._id.status === "Replied"){
                    obj.Replied = importData[index].count
                }
                proceessData[key] = {...obj}
            }
        }
    
        //end process data
        var excelData = [];
        for (var index in proceessData) {
             let obj ={}
             if(proceessData[index]?.courseName){
                 obj.Course = proceessData[index].courseName
             }
             if(proceessData[index]?.subjectName){
                obj.Subject = proceessData[index].subjectName
            }
            obj.Assigned = proceessData[index]?.Assigned
            obj.Solved = proceessData[index]?.Solved
            obj.Unassigned = proceessData[index]?.Unassigned
            obj.Replied = proceessData[index]?.Replied
    
            if(startDate && endDate){
                obj.From_Date = moment(startDate).format("YYYY-MM-DD")
                obj.To_Date =  moment(endDate).format("YYYY-MM-DD")
            }
            excelData.push(obj);
        }

        setLoading(false);
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return <LoadingButton
        style={{ marginLeft: '10px' }}
        color="secondary"
        onClick={() => exportToCSV()}
        loading={loading}
        loadingPosition="start"
        variant="contained"
    >
        EXPORT TO EXCEL
    </LoadingButton>
}
