import React from "react";
import "../../../Css/root.css";
import { useState, useEffect, useReducer } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "../../../axios";
import api from "../../../api";
import { Form } from "react-bootstrap";
import moment from 'moment';
import { actions } from "../../../Components/Toaster/ToastReducer";
import { useDispatch } from "react-redux";
import Roomlist from "../../../Components/Roomlist"
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Select from "@mui/material/Select";
import TextField from '@mui/material/TextField';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import CatalogCourseFilter from "../../ExamExplorer/Filters/CatalogCourseFilter";

export default function CatalogCourseUpdateLiveClass(props) {

    const dispatch = useDispatch();
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const [title, setTitle] = useState(props.location.state.title);
    const [selectedSubscription, setSelectedSubscription] = useState();
    const [selectedCourseID, setSelectedCourseID] = useState(props.location.state.course ? props.location.state.course._id : null);
    const [selectedSubjectID, setSelectedSubjectID] = useState(props.location.state.subject ? props.location.state.subject._id : null);
    const [selectedTeacherID, setSelectedTeacherId] = useState(props.location.state.teacher ? props.location.state.teacher._id : null);

    const [startTime, setStartTime] = useState(moment(props.location.state.startTime).toDate());
    const [endTime, setEndTime] = useState(moment(props.location.state.endTime).toDate())

    const [serverType, setServerType] = useState(props.location.state.serverType);
    const [meetingId, setMeetingId] = useState(props.location.state.meetingId);
    const [meetingPass, setMeetingPass] = useState(props.location.state.meetingPassword ? props.location.state.meetingPassword : "");
    const [eventCode, setEventCode] = useState(props.location.state.eventCode);
    const [tohome, setTohome] = React.useState(false);
    const [goals, setGoals] = useState();
    const [courses, setCourses] = useState();
    const [subscription, setSubsCription] = useState();
    const [subjects, setSubject] = useState();
    const [teachers, setTeachers] = useState();
    const [selectedDropDown, setResetSelectedDropDown] = useState(false);

    let data = {
        headers: {
            "x-auth-token": token,
        },
    };

    useEffect(() => {
        getsubject(selectedCourseID);
    }, [selectedCourseID]);

    useEffect(() => {
        goalsFetch();
        fetchSubscriptionOfCourse(props.location.state.course._id)
        getsubject(props.location.state.course._id)
        fetchTeachersOfCourse(props.location.state.course._id)
    }, []);

    async function goalsFetch() {
        try {
            settoken(localStorage.getItem("user"));
            const response = await axios.get(api.liveClassGetGoal, data);
            setGoals(response.data);
        } catch (err) {
        }
    }

    async function fetchSubscriptionOfCourse(courseId) {
        try {
            const response = await axios.get(api.liveClassGetSubscriptionByCourse + courseId, data);
            setSelectedSubscription(response?.data);
        } catch (err) {
        }
    }

    async function fetchTeachersOfCourse(courseId) {
        try {
            const response = await axios.get(api.liveClassGetTeacher + `?courseId=${courseId}`, data);
            setTeachers(response.data.teachers)
        } catch (err) {
        }
    }

    async function getSubs(selected) {
        const response = await axios.get(api.liveClassGetSubs + `?goalId=${selected}`, data);
        setSubsCription(response.data.subscriptions);
    }
    async function getsubject(selected) {
        const response = await axios.get(api.liveClassGetSubject + `?courseId=${selected}`, data);
        setSubject(response.data.subjects)
        getteacher(selected)
    }
    async function getteacher(selected) {
        const response = await axios.get(api.liveClassGetTeacher + `?courseId=${selected}`, data);
        setTeachers(response.data.teachers)
    }
    async function getcourses(selected) {
        const response = await axios.get(api.liveClassGetCourse + `?subscriptionId=${selected}`, data);
        setCourses(response.data.courses)
    }
    const handleUpdateLiveClass = async () => {
      const body = {
        title: title,
        course: selectedCourseID,
        subject: selectedSubjectID,
        teacher: selectedTeacherID,
        startTime: startTime,
        endTime: endTime,
        serverType: serverType,
        meetingId: meetingId,
        meetingPassword: meetingPass,
      };

      axios
        .put(api.liveClassUpdate + `${props.location.state._id}`, body, data)
        .then(async (resp) => {
          const { status } = resp;
          if (status == 200) {
            dispatch(actions.Success("Live class updated Succssfully"));
            setTohome(true);
          }
        })
        .catch((error) => {
          dispatch(
            actions.Danger("Something went wrong" + error?.response?.data)
          );
        });
    };
    if (tohome === true) {
        return <Redirect to="/live-class" />;
    }

    const catalogCourseSelector = (
        <CatalogCourseFilter
            selectedCourse={selectedCourseID}
            handleCourseSelection={(courseId) => {
                setSelectedCourseID(courseId)
                setSelectedSubjectID('')
                setSelectedTeacherId('')
            }}
        />
    )

    return (
        <div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-header">
                                    <h3 className="card-title"> Update Live Class</h3>
                                    <div className="card-toolbar">
                                        <div className="example-tools justify-content-center">
                                            <Link
                                                className="btn btn-success font-weight-bolder font-size-sm"
                                                to="/live-class"
                                            >
                                                <i className="flaticon2-list"></i>
                                                Update Live Class
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <form className="form">
                                    <input type="hidden" />
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Enter Title :
                                            </label>
                                            <div className="col-lg-3">
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    placeholder="Title"
                                                    value={title}
                                                    onChange={(e) => {
                                                        setTitle(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                            </label>
                                            <div className="col-lg-6">
                                                {catalogCourseSelector}
                                            </div>
                                            <label className="col-lg-2 col-form-label text-left">
                                                Selected Course: {props?.location?.state?.course?.title}
                                            </label>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Subject
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    value={selectedSubjectID}
                                                    onChange={(e) => {
                                                        setSelectedSubjectID(e.target.value)
                                                    }}
                                                >
                                                    <option value="" defaultChecked>
                                                        Select Subject
                                                    </option>
                                                    {subjects ?
                                                        subjects.map(object =>
                                                            <option value={object._id}>
                                                                {object.title}
                                                            </option>
                                                        ) : null
                                                    }
                                                </Form.Control>
                                            </div>

                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Teacher
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    value={selectedTeacherID}
                                                    onChange={(e) => {
                                                        setSelectedTeacherId(e.target.value);
                                                    }}
                                                >
                                                    <option value="" defaultChecked>
                                                        Select Teacher
                                                    </option>

                                                    {teachers ?
                                                        teachers.map(object =>
                                                            <option value={object._id}>
                                                                {object.name}
                                                            </option>
                                                        ) : null
                                                    }

                                                </Form.Control>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <label className="col-lg-2 col-form-label text-left">
                                                </label>
                                                <div className="col-lg-3">
                                                    <DateTimePicker
                                                        label="Start Date"
                                                        value={startTime}
                                                        onChange={(newValue) => setStartTime(newValue)}
                                                        renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                                                    />
                                                </div>
                                                <label className="col-lg-2 col-form-label text-left">
                                                </label>
                                                <div className="col-lg-3">
                                                    <DateTimePicker
                                                        label="End Date"
                                                        value={endTime}
                                                        onChange={(newValue) => setEndTime(newValue)}
                                                        renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                                                    />
                                                </div>
                                            </LocalizationProvider>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Server
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => {
                                                        setServerType(e.target.value)
                                                        setMeetingId('')
                                                    }}
                                                >
                                                    <option selected={serverType === "teachStack-studio" ? true : false } value="teachStack-studio">
                                                        Teach Stack Studio
                                                    </option>
                                                    <option selected={serverType === "teachStack-remote" ? true : false } value="teachStack-remote">
                                                        Teach Stack Remote
                                                    </option>
                                                    <option selected={serverType === "zoom" ? true : false } value="zoom">
                                                        ZOOM
                                                    </option>
                                                </Form.Control>
                                            </div>

                                        </div>


                                        {
                                            serverType == 'zoom' ?
                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label text-left">
                                                        Meeting ID
                                                    </label>
                                                    <div className="col-lg-3">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Meeting Id"
                                                            value={meetingId}
                                                            onChange={(e) => {
                                                                setMeetingId(e.target.value)
                                                            }}
                                                        />
                                                    </div>

                                                    <label className="col-lg-2 col-form-label text-left">
                                                        Meeting Pass
                                                    </label>
                                                    <div className="col-lg-3">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Meeting Pass"
                                                            value={meetingPass}
                                                            onChange={(e) => {
                                                                setMeetingPass(e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div> : null
                                        }
                                        {
                                             serverType == 'teachStack-remote' || serverType == 'teachStack-studio' ?
                                                <div className="form-group row">
                                                    <div className="col-lg-5">
                                                        <Roomlist 
                                                        serverType={serverType}
                                                        selectedRoom={meetingId} 
                                                        handleSelectRoom={(state) => setMeetingId(state)} 
                                                        />
                                                    </div>
                                                </div> : null
                                        }
                                    <div className="form-group row"></div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-lg-2"></div>
                                            <div className="col-lg-10">
                                                <button
                                                    type="button"
                                                    className="btn btn-success mr-2"
                                                    onClick={handleUpdateLiveClass}
                                                >
                                                    Submit
                                                </button>
                                                <button type="reset" className="btn btn-secondary">
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
