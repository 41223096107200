import React, { useState, useEffect } from 'react'
import moment from 'moment'

/* @MUI COMPONENT */
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import {DesktopDatePicker} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Button from "@mui/material/Button"
import Grid from '@mui/material/Grid'

/* @CUSTOM COMPONENT */
import UserChart from "./UserChart"
import axios from "../../../axios";
const MemorizeUserChart = React.memo(UserChart)

export default function UserDataVisualize() {

    var initialDate = new Date();
    initialDate.setDate(initialDate.getDate() - 7);

    const [startDate, setStartDate] = useState(initialDate);
    const [endDate, setEndDate] = useState(new Date());
    const [labels, setLabels] = useState([])
    const [data, setData] = useState([])
    const [totalUser, setTotalUser] = useState(null)

    let config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        },
    };

    const processData = (data) => {
        var tempLabel = []
        var tempData = []
        var total = 0;
        data.map(item => {
            tempData.push(item.count)
            //tempLabel.push(moment(item.date[0]).local().format('YYYY-MM-DD'))
            tempLabel.push(item._id)
            total += item.count
        })
        setTotalUser(total)
        setLabels(tempLabel)
        setData(tempData)
    }

    useEffect(() => {
        fetchUserData()
    }, [])

    const handleSearchPayment = () => {
        fetchUserData()
    }

    const fetchUserData = async () => {
        try {
            let url = `/admin/users/userFilter-barPlot?`
            if (startDate && endDate) {
                url = `/admin/users/userFilter-barPlot?fromDate=${moment(startDate).startOf('day').utc()}&toDate=${moment(endDate).endOf('day').utc()}`
            }
            const response = await axios.get(url, config);
            if (response.data.length > 0) {
                processData(response.data)
            } else {
                setData([])
                setLabels([])
                setTotalUser(null)
            }
        } catch (error) {
        }
    }
    const handleChangeStartDate = (newValue) => {
        setStartDate(newValue);
    };

    const handleChangeEndDate = (newValue) => {
        setEndDate(newValue);
    };

    const filterUser = (
        <Card>
            <CardContent>
                <Grid sx={{ mt: 2 }} container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack sx={{ width: 450 }} spacing={3}>
                                <DesktopDatePicker
                                    label="START DATE"
                                    inputFormat="MM/dd/yyyy"
                                    value={startDate}
                                    onChange={handleChangeStartDate}
                                    renderInput={(params) => <TextField {...params} />}
                                />

                                <DesktopDatePicker
                                    label="END DATE"
                                    inputFormat="MM/dd/yyyy"
                                    value={endDate}
                                    onChange={handleChangeEndDate}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                        <Button onClick={handleSearchPayment} sx={{ mt: 1, ml: 2 }} variant="contained">Search</Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )

    return (
        <>
            <Card sx={{ mt: 6, width: '100%' }}>
                <CardHeader title="User bar plot" />
                <CardContent>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                        <Grid item>
                            {filterUser}
                        </Grid>
                        <Grid md={12} item>
                        {totalUser && <Typography gutterBottom>Total : {totalUser}</Typography>}
                        </Grid>
                        <Grid md={12} item>
                            <MemorizeUserChart Label={labels} userData={data} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}
