import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import axios from "../../../../axios";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import EmptyComponent from "../../../../Components/EmptyComponent";

function RecordingVideo(props) {
  const [loading, setLoading] = React.useState(true);
  const [recordedData, setRecordedData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const config = { headers: { "x-auth-token": localStorage.getItem("user") } };

  function convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
  }

  const fetchRecordings = async () => {
    try {
      const response = await axios.get(
        `/admin/liveClass-room/recorded-list?roomId=${props.roomId}`,
        config
      );
      const recordings = response?.data?.data?.recordings?.reverse();
      setRecordedData(recordings);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchRecordings();
  }, [props.roomId]);

  const roomDetails = (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {recordedData
        ?.slice((page - 1) * 10, (page - 1) * 10 + 10)
        ?.map((item, index) => (
          <div key={index}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar sx={{ mr: 1 }}>
                <Avatar
                  sx={{ width: "80px", height: "80px" }}
                  alt="recorded video"
                  src={item?.thumbnail_256x144_path}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`Duration: ${convertHMS(item.duration)}`}
                secondary={
                  <Stack spacing={2}>
                    <Chip label={item?.mode} />
                    <Stack sx={{ width: "500px" }} direction="row" spacing={2}>
                      <Button
                        target="_blank"
                        href={`${item?.processed_path}`}
                        variant="contained"
                      >
                        Download Processed
                      </Button>
                      <Button
                        target="_blank"
                        href={`${item?.raw_path}`}
                        variant="outlined"
                      >
                        Download Raw
                      </Button>
                    </Stack>
                  </Stack>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </div>
        ))}
      {recordedData?.length > 0 && (
        <Pagination
          sx={{ mt: 1, mb: 1 }}
          onChange={(event, page) => setPage(page)}
          page={page}
          count={Math.ceil(recordedData?.length / 10)}
          variant="outlined"
          shape="rounded"
        />
      )}
    </List>
  );

  return (
    <>
      {!loading && roomDetails}
      {loading && <Skeleton variant="rectangular" height="100%" />}
      {(!loading && recordedData?.length === 0) ||
        (recordedData?.length === undefined && (
          <EmptyComponent title={"No Recordings Found"} />
        ))}
    </>
  );
}
export default RecordingVideo;
