import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import OrderMenu from "./component/OrderMenu";
import SubscriptionActive from "./Subscription/SubscriptionActive";
import SubscriptionInActive from "./Subscription/SubscriptionInActive";
import CatalogCourse from "./CatalogCourse/CatalogCourse";
// import CatalogCourseInActive from "./CatalogCourse/CatalogCourseInActive";

const pages = [
  "subscription-active",
  "subscription-inActive",
  "catalogCourse-active",
  "catalogCourse-inActive",
];
export default function PaymentMode() {
  const [state, setState] = useState(pages[0]);
  return (
    <Grid
      container
      spacing={3}
      alignItems={"center"}
      justifyContent={{md: "space-between", xs: "center"}}
    >
      <Grid item md={3}>
        <OrderMenu
          selectedItem={state}
          pages={pages}
          handleChange={(pageName) => setState(pageName)}
        />
      </Grid>
      <Divider orientation={{md: "vertical", xs: "horizontal"}} flexItem/>
      <Grid item md={9}>
        {state === pages[0] && <SubscriptionActive />}
        {state === pages[1] && <SubscriptionInActive />}
        {state === pages[2] && <CatalogCourse />}
        {/* {state === pages[3] && <CatalogCourseInActive />} */}
      </Grid>
    </Grid>
  );
}
