import React from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from "notistack"

export default function RejectedBrandPartnerUserExportExcel({rejectedUser}) {

    const [loading, setLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar()

    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "Rejected brand partner user"

    const exportToCSV = async () => {
        var excelData = [];
        for (var index in rejectedUser) {
            excelData.push({
                Phone: rejectedUser[index],
            });
        }
        setLoading(false);
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return <LoadingButton
        color="secondary"
        onClick={() => exportToCSV()}
        loading={loading}
        loadingPosition="start"
        variant="contained"
    >
        EXPORT TO EXCEL
    </LoadingButton>
}
