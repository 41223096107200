import React, { useEffect, useState } from "react"
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import Grid from '@mui/material/Grid'
import BasicSelector from "../../../Components/BasicSelector"

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import PolicyIcon from '@mui/icons-material/Policy';

export default function SearchCourseFilter(props) {
    
    // Data states
    const [courses, setCourses] = useState([])
    const [inputCourseTitle, setInputCourseTitle] = useState('')

    // Selection states
    const [selectedGoal, setSelectedGoal] = useState('')
    const [selectedSubscription, setSelectedSubscription] = useState('')

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    // Select data props
    const courseData = {
        title: "Courses",
        items: courses,
        selected: props.selectedCourse
    }

    // Select elements
    const courseInput = (
        <Grid item>
            <TextField
                id="input-with-icon-textfield"
                label="Course Title"
                onChange={(e)=>setInputCourseTitle(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PolicyIcon />
                        </InputAdornment>
                    ),
                }}
                variant="standard"
            />
            {inputCourseTitle && <Button onClick={getCoursesForFilter} variant="contained">Search</Button>}
        </Grid>
    )

    const courseSelector = (
        <Grid item>
            <BasicSelector data={courseData} sx={{ width: 200 }} handleSelection={handleCourseSelection} />
        </Grid>
    )

    // Data fetching and state handling functions
    async function getCoursesForFilter() {
        props.handleCourseSelection('')
        axios
            .get(`admin/content-filter/get-courses-by-title/${inputCourseTitle}`, config)
            .then((response) => {
                setCourses(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    function handleCourseSelection(courseId) {
        props.handleCourseSelection(courseId)
    }

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
            {courseInput}
            {courses.length > 0 && courseSelector}
        </Grid>
    )
}