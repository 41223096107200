import React, { useState } from 'react'
import * as XLSX from 'xlsx';
import axios from "../../../axios"
import api from "../../../api"
import { useSnackbar } from "notistack"
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import Button from "@mui/material/Button"
import { styled } from '@mui/material/styles'
import { green } from "@mui/material/colors"

const Input = styled('input')({
    display: 'none',
})

export default function BulkUserRole(props) {
    const [users, setUsers] = useState([])
    const [walletAmount, setWalletAmount] = useState()
    const [fileInfo, setFileInfo] = useState({ fileName: '', fileSize: '', totalUser: 0 })
    const [rejectedUserLoader, setRejectetdUserLoader] = useState(false)
    const [rejectedUser, setRejectedUser] = useState([])

    const [role, setRole] = React.useState('');

    const handleChange = (event) => {
        setRole(event.target.value);
    };

    const { enqueueSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        },
    };
    const handleSubmit = async () => {
        if (!role) return enqueueSnackbar("Role name is not allowed to be empty", { variant: 'error' })
        if (!users.length) return enqueueSnackbar("User list not allowed to be empty", { variant: 'error' })
        try {
            const response = await axios.put(api.bulkUserRoleUpdate, { roleName: role, users: users }, config)
            enqueueSnackbar("" + response.data, { variant: 'success' })
        } catch (error) {
            enqueueSnackbar("Wallet update failed : " + error.response.data, { variant: 'error' })
        }
    }

    const onUploadExcel = (e) => {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var fileName = e.target.files[0]?.name
        var fileSize = e.target.files[0]?.size
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 0 });
            var users = []
            dataParse.filter(function (item) { users.push(item.Phone) })
            setUsers(users)
            setFileInfo({ fileName: fileName, totalUser: users.length, fileSize: fileSize })
        };
        reader.readAsBinaryString(f)
    }


    const fileInfoBox = (
        <Box component={Paper} elevation={2} sx={{ p: 1 }}>
            <Typography fontWeight={650} color={green[300]} variant='subtitle1' gutterBottom align='center'>
                File Name : {fileInfo.fileName}
            </Typography>
            <Typography fontWeight={650} color={green[300]} variant='subtitle1' gutterBottom align='center'>
                File Size  : {(fileInfo.fileSize / 1024).toFixed(2)} kb
            </Typography>
            <Typography fontWeight={650} color={green[300]} variant='subtitle1' gutterBottom align='center'>
                Total users  : {fileInfo.totalUser}
            </Typography>
        </Box>
    )

    const userFileUpload = (
        <Card sx={{ width: '100%', mt: 2, mr: 2, pr: 2 }}>
            <Typography fontWeight={500} variant='h6'>Column : [Phone] </Typography>
            {
                users.length > 0 ?
                    fileInfoBox : <Skeleton variant="rectangular" width={'100%'} height={118} />
            }
            <CardActions disableSpacing>
                <label htmlFor="subject-banner">
                    <Input onChange={onUploadExcel} accept="*" id="subject-banner" type="file" />
                    <Button component="span" size="small">Upload Excel</Button>
                </label>
            </CardActions>
        </Card>
    )


    const form = (
        <Paper variant="outlined" sx={{ m: 2, p: 2, width: { md: 400, xs: 300 } }} square>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={'4'}
            >
                <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={role}
                        label="Role"
                        onChange={handleChange}
                        sx={{ marginRight: '15px' }}
                    >
                        <MenuItem value={"superAdmin"}>superAdmin</MenuItem>
                        <MenuItem value={"admin"}>admin</MenuItem>
                        <MenuItem value={"contentAdmin"}>contentAdmin</MenuItem>
                        <MenuItem value={"questionAdmin"}>questionAdmin</MenuItem>
                        <MenuItem value={"smsAdmin"}>smsAdmin</MenuItem>
                        <MenuItem value={"programDirector"}>programDirector</MenuItem>
                        <MenuItem value={"student"}>student</MenuItem>
                        <MenuItem value={"educator"}>educator</MenuItem>
                        <MenuItem value={"brandPartner"}>brandPartner</MenuItem>
                        <MenuItem value={"doubtSolver"}>doubtSolver</MenuItem>
                        <MenuItem value={"orderAdmin"}>orderAdmin</MenuItem>
                        <MenuItem value={"affiliateAdmin"}>affiliateAdmin</MenuItem>
                    </Select>
                    {userFileUpload}
                    <Button onClick={handleSubmit} sx={{ w: '100%', color: 'green', mt: 2, mr: 2, color: 'white' }} variant="contained">Submit</Button>
                </FormControl>
            </Grid>
        </Paper>
    )
    return (
        <React.Fragment>
            <Box sx={{ p: 2, mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} component={Paper} elevation={4}>
                <Typography fontWeight={800} variant='h5' gutterBottom align='center'>Bulk user role update</Typography>
                {form}
            </Box>
        </React.Fragment>
    )
}
