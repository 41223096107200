import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Button from "@mui/material/Button";
import api from "../../api";
import axios from "../../axios";
import SimpleBackdrop from "../../Components/SimpleBackDrop";
import ResultsTable from "./component/ResultsTable";
import { useSnackbar } from "notistack";

export default function UserWrittenExam() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const config = {
    headers: {
      "x-auth-token": localStorage.getItem("user"),
    },
  };
  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        api.userGetWrittenExamsResult + `/88${phoneNumber}`,
        config
      );
      setData(response?.data);
      setLoading(false);
    } catch (error) {}
  };

  async function deleteResult(resultId) {
    setLoading(true);
    axios
      .delete(api.userWrittenExamDelete + `/${resultId}`, config)
      .then((response) => {
        enqueueSnackbar(response.data, { variant: "success" });
        setRefreshKey((prevState) => prevState + 1);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Result delete failed: " + error.response.data, {
          variant: "error",
        });
      });
  }
  useEffect(() => {
    if (phoneNumber.length == 11) handleSearch();
  }, [refreshKey]);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: 1,
        },
      }}
    >
      {loading && <SimpleBackdrop />}
      <Paper sx={{ width: "100%", p: 4 }} elevation={3}>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          <FormControl variant="standard">
            <InputLabel htmlFor="input-with-icon-adornment">
              Search By Phone
            </InputLabel>
            <Input
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
              id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              }
            />
          </FormControl>
          {phoneNumber.length === 11 && (
            <Button onClick={handleSearch} variant="contained">
              Search
            </Button>
          )}
          {!loading && data.length > 0 && (
            <ResultsTable
              data={data}
              handledeleteResult={(id) => deleteResult(id)}
            />
          )}
        </Stack>
      </Paper>
    </Box>
  );
}
