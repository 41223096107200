import React, { useState } from "react"
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import PolicyIcon from '@mui/icons-material/Policy';
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import CourseExplorerModeMenu from "../CourseExplorer/CourseExplorerModeMenu";
import AffiliatePaymentBrandPartner from "./AffiliatePaymentBrandPartner";
import AffiliatePaymentEducator from "./AffiliatePaymentEducator";

const ExplorerMode = {
    Educator: "Educator",
    Brandpartner: "Brandpartner"
}

const ExplorerModeMenu = {
    Educator: "Educator",
    Brandpartner: "Brandpartner"
}

const FilterTitleByMode = {
    Educator: "Select Educator",
    Brandpartner: "Select Brandpartner"
}

export default function AffiliatePaymentMode() {
    const [explorerMode, setExplorerMode] = useState(ExplorerMode.Brandpartner)
    
    const handleModeChange = (mode) => {
        setExplorerMode(ExplorerMode[mode])
    }

    const filterBox = (
        <Card sx={{width: '100%', mb: 1}}>
            <CardHeader
                title={FilterTitleByMode[explorerMode]}
                action={
                    <CourseExplorerModeMenu
                        currentMode={explorerMode}
                        explorerModes={ExplorerModeMenu}
                        handleModeSelection={handleModeChange}
                    />
                }
            />
            <CardContent sx={{width: '100%'}}>
            <Grid container alignItems={'center'} justifyContent={'center'}>
            {explorerMode === "Brandpartner" && <AffiliatePaymentBrandPartner/>}
            {explorerMode === "Educator" && <AffiliatePaymentEducator/>}
            </Grid>
            </CardContent>
        </Card>
    )

    return (
        <React.Fragment>
            {filterBox}
        </React.Fragment>
    )
}