import React from "react";
import "../../Css/root.css";
import { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "../../axios";
import api from "../../api";
import Form from "react-bootstrap/Form";
import {actions} from "../../Components/Toaster/ToastReducer";
import {useDispatch } from "react-redux";
export default function SingleUserSms() {
    const dispatch = useDispatch();
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const [tohome, setTohome] = React.useState(false);

    const [isMasking, setIsMasking] = useState(false);
    const [message, setMessage] = useState();
    const [isParentSendEnabled, setIsParentSendEnabled] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState();
    const [user, setUser] = useState();

    let data = {
        headers: {
            "x-auth-token": token,
        },
    };

    const handleSubmit = () => {
        const url = `${api.SmsSendSingleUser}isMasking=${isMasking ? 1 : 0}&parentSms=${isParentSendEnabled ? 1 : 0 }&userId=${user._id}`
        const body = {
            smsBody: message
        }
        if (user && message) {
            axios
                .post(url, body, data)
                .then(async (resp) => {
                    const {
                        status,
                    } = resp;
                    if (status === 200) {
                        dispatch(actions.Success("Sms send successfully"));
                        setTohome(true);
                    }
                })
                .catch((error) => {
                    dispatch(actions.Danger("Something went wrong"));
                });
        }
    }
    if (tohome === true) {
        return <Redirect to="/sms" />;
    }
    const handleSeacrhUser = () => {
        const url = `${api.smsSerachSingleUser}${phoneNumber}`;
        axios
            .get(url, data)
            .then((resp) => {
                const { user, message } = resp.data;
                setUser(user);
                setMessage(message);
            })
            .catch((error) => {
                if (error.response.status == 401) {
                } else {
                    return error.response.status;
                }
            });
    }
    return (
        <div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-header">
                                    <h3 className="card-title"> SEND SMS</h3>
                                    <div className="card-toolbar">
                                        <div className="example-tools justify-content-center">
                                            <Link
                                                className="btn btn-success font-weight-bolder font-size-sm"
                                                to="/"
                                            >
                                                <i className="flaticon2-list"></i>
                                                SEND SMS TO USER
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <form className="form">
                                    <input type="hidden" />
                                    <div className="card-body">

                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Phone Number :
                                            </label>
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    placeholder="01xxxxxxxxx"
                                                    onChange={(e) => {
                                                        setPhoneNumber(`88${e.target.value}`)
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-success mr-2"
                                                    onClick={handleSeacrhUser}
                                                >
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            user ?
                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label text-left">
                                                        User Found 
                                                    </label>
                                                    <label className="col-lg-6 col-form-label text-left">
                                                        {user.name}
                                                    </label>
                                                    <label className="col-lg-6 col-form-label text-left">
                                                        Phone-{user.phone}&nbsp;&nbsp; Email-{user.email}
                                                    </label>
                                                    
                                                </div> : <div className="form-group row">
                                                    <label style={{color:"red", fontSize:'12px'}} className="col-lg-2 col-form-label text-left">
                                                        Select a valid User
                                                    </label>
                                                </div>
                                        }

                                        {
                                            user ?
                                                <>
                                                    <div className="form-group row">
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Enable/Disable Masking
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <span className="switch switch-icon switch-success">
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={isMasking}
                                                                        onChange={(e) => setIsMasking(e.target.checked)}
                                                                    />
                                                                    <span></span>
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>


                                                    <div className="form-group row">
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Parents SMS:
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <span className="switch switch-icon switch-success ">
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={isParentSendEnabled}
                                                                        onChange={(e) => setIsParentSendEnabled(e.target.checked)}
                                                                    />

                                                                    <span></span>
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">

                                                        <div className="col-lg-6">
                                                            <div className="form-group green-border-focus">
                                                                <label style={{ fontSize: '12px' }} for="exampleFormControlTextarea5">write message</label>
                                                                <textarea onChange={(e) => setMessage(e.target.value)} className="form-control" id="exampleFormControlTextarea5" rows="5"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row"></div>

                                                </> : null
                                        }
                                    </div>
                                    {
                                        user ?
                                            <div className="card-footer">
                                                <div className="row">
                                                    <div className="col-lg-2"></div>
                                                    <div className="col-lg-10">
                                                        <button
                                                            type="button"
                                                            className="btn btn-success mr-2"
                                                            onClick={handleSubmit}
                                                        >
                                                            Submit
                                                        </button>
                                                        <button type="reset" className="btn btn-secondary">
                                                            Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
