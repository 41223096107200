import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {DesktopDatePicker} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSnackbar } from "notistack"
import MultiSelectorChip from "../../../../Components/MultiSelectorChip"
import axios from "../../../../axios"
import api from "../../../../api"

export default function EditDialog({ editOpen, handleCloseEdit, handleRefreshData, name, type, roomId, hosts, isActive, isLive, expiration }) {

    const [educatorLoading, setEducatorLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState({})
    const [educators, setEducators] = useState([])
    const hostIds = hosts.map(function (item) { return item._id })
    const [selectedEducators, setSelectedEducators] = useState(hostIds)
    const [formData, setFormData] = React.useState({ name: name, type:type === "teachStack-studio" ? true : false, expiration: expiration, isActive:isActive, hosts:hostIds })
    const { enqueueSnackbar } = useSnackbar()
    
    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    const educatorData = {
        title: "Educator",
        items: educators,
        index: "name",
        selected: selectedEducators
    }

    const handleEducatorSelection = (value) => {
        setSelectedEducators(value)
        setFormData({ ...formData, hosts: value })
    }

    const handleSubmit = async() => {
        if(!formData.expiration) return  enqueueSnackbar("Expiration is required", { variant: 'warning' })
        if(!formData.name) return  enqueueSnackbar("Name is required", { variant: 'warning' })
        if(!formData.type){
            if(formData.hosts.length <= 0) return enqueueSnackbar("Host is not allowed to be empty", { variant: 'error' })
        }
        let body = {
            ...formData
        }
        if (formData.type) {
            body.type = "teachStack-studio";
            body.hosts = []
          } else {
            body.type = "teachStack-remote";
            body.hosts = formData.hosts
        }

        try{
              const response = await axios.put(`/admin/liveClass-room/${roomId}`, {...body}, config)
              enqueueSnackbar(response?.data, { variant: 'success' })
              handleCloseEdit()
              handleRefreshData()
        }catch(error){
            enqueueSnackbar(error?.response?.data, { variant: 'error' })
        }
    }

    async function fetchEducators() {
        setEducatorLoading(true)
        axios
            .get(api.getCourseExplorerEducators, config)
            .then((response) => {
                setEducators(response?.data)
                setEducatorLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Educator fetch failed: " + error?.response?.data, { variant: 'error' })
            })
    }

    React.useEffect(() => {
        fetchEducators()
    }, [])

    const educatorSelector = (
        <MultiSelectorChip
            required
            error={!!errorMessage.educator}
            helperText={errorMessage.educator}
            data={educatorData}
            handleSelection={handleEducatorSelection}
            sx={{ width: '100%' }}
        />
    )


    return (
        <div>
            <Dialog open={editOpen} onClose={handleCloseEdit}>
                <DialogTitle>Edit live room</DialogTitle>
                <DialogContent>
                    <Box
                        component={Paper}
                        variant="outlined"
                        sx={{
                            p: 3,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        noValidate
                        autoComplete="off"
                    >

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            sx={{ p: { md: 4, xs: 2 } }}
                        >
                            <Grid item md={12} xs={12}>
                                <TextField
                                    sx={{ width: '100%' }}
                                    required
                                    id="outlined-required"
                                    label="Name"
                                    value={formData.name}
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                />
                            </Grid>

                           {!formData.type && <Grid item md={6} xs={12}>
                                {educatorSelector}
                            </Grid>}

                            <Grid item md={6} xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Expiry Date"
                                        inputFormat="MM/dd/yyyy"
                                        value={formData.expiration}
                                        onChange={(newValue) => setFormData({ ...formData, expiration: newValue })}
                                        renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            
                            <Grid item md={12} xs={12}>
                                <FormControl sx={{mr: 1}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox sx={{ width: '40px', height: '40px' }} checked={formData.isActive} onChange={() => setFormData({ ...formData, isActive: !formData.isActive })} name="isActive" />
                                        }
                                        label="Active"
                                    />
                                </FormControl>
                                <FormControl sx={{ml: 1}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox sx={{ width: '40px', height: '40px' }} 
                                            checked={formData.type} 
                                            onChange={() => setFormData({ ...formData, type: !formData.type })} 
                                            name="type" />
                                        }
                                        label="Teack Stack Studio"
                                    />
                                </FormControl>
                            </Grid>
                           

                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit}>Update</Button>
                    <Button onClick={handleCloseEdit}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
